.starlink--service_accounts--valid_status.MuiSvgIcon-root {
    margin-right: 0.5rem;
}

.MuiTextField-root.input-box-service {
    width: 300px;
  }

.MuiChip-root.blocked-category-chip{
    background-color: #F4F5FA;
    margin: 3px;
}


.MuiChip-root.allowed-blocked-domain-chip{
    background-color: white;
    margin: 3px;
}

.MuiChip-root.allowed-blocked-domain-chip-count{
    background-color: #F4F5FA;
}

.MuiChip-root.dialog-list-chip{
    background-color: #F4F5FA;
    margin: 3px;
}

.MuiDialogContent-root.dialog-list-content-filter-dialog-content{
    padding: 20px;
}

.MuiSvgIcon-root.content-filter-status-help-icon{
    width: 15px;
    height: 15px;
    cursor: default;
    position: relative;
    top: 4px;
}


.MuiPaper-root.content-filter--container {
    background-color: white;
    /* position: absolute;
    left: 0px;
    top: 50px;
    right: 0;
    bottom: 0; */
    padding:109px 20px 15px 20px;
    overflow: auto;
    height: calc(100vh - 140px);
}

.content-filter--container .pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    font-family: unset;
    margin-top: auto;
}

.content-filter--container .pagination-toolbar .records-select {
    font-size: 14px;
    font-family: unset;
    border-radius: 8px;
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
}

.content-filter--container .MuiMenuItem-root.records-select-item {
    font-size: 10px;
    font-family: unset;
}

.content-filter--base--padding_05x {
    padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.content-filter--table_container .MuiTableCell-root {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-collapse: collapse;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 1px;
    color: #45464E;
}

.content-filter--table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}

.content-filter--dashboard--actions{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 8rem;
}

.content-filter--base--flex--align--center {
    display: flex;
    align-items: center;
}

.content-filter-download-button {
    margin-left: 5px;
}