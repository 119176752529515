.labelAboveSelect{
    font-weight: 500;
    display: flex;
    align-items: center;
}

.selectBox{
    background-color: #fff
}

.vesselBox{
    width: 100%;
    background-color : #fff
}

.exportIcon{
    background-color: #2860b4;
    margin: 4px;
    border-radius: 4px;
    padding: 6px 8px;
    cursor: pointer;
    }

.tabs .paper {

    padding: 1rem;
    cursor: pointer
  }

  .tabs .selectedPaper {
    border-bottom:4px solid #2860b4;
    color: #2860b4
  }

  .unSelectedPaper{
    transition: 0.2s all ease-in-out,
  }

  .unSelectedPaper:hover{
    background-color: #dddddd;
  }



.text{
  font-size: 1.5rem;
  font-weight: 700 ;
}

.label{
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}

.d-flex{
    display: flex;
}

.align-center{
   align-items: center;
}

.justyfy-space-between{
    justify-content: space-between;
}

.justify-center{
    justify-content: center;
}

.tabs{
  margin: 0px 0px;
}
.shadow{
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-top: 15px;
}
.cellularMarginReset {
  margin-top: 0px;
}
.reportsHeader{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
}

.dropdownPadding{
  padding-left: 10px;
} 

.usage_select_margin {
  margin-left: 45px;
}

.graphical-justyfy-space-between {
  margin-top: 0px;
}

.innerScrollGDU .vessel-chart-cont {
  margin-right: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #fff;
  padding-bottom: 30px;
}

.vessel-chart-cont>div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 15px -10px 15px;
}

.vessel-chart-cont .vessel-chart-title {
  font-size: 14px;
  font-weight: 600;
  color: #6E7079;
  font-family: 'Inter';
  font-style: normal;
}

.vessel-chart-cont .vessel-chart {
  margin-top: 15px;
}

.Form .wan-usage-no-data {
  width: 100%;
  height: calc(100vh - 285px);
  border: 1px solid #EBEBEB;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  background: #fff;
  font-weight: bold;
  font-size: 15px;
  color: #303030;
  box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.tabs .paper {
  margin: 0px 0px 0px 0px;
  padding: 5px 0px 0px 0px;
}

.wan-usage-legend-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px 0px 10px 0px;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  line-height: 10px;
}

.wan-usage-legend-container .wan-usage-legends {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
}

.wan-usage-legends>div:nth-child(1) {
  width: 35px;
  height: 10px;
  background-color: #001a4d;
}

.wan-usage-legends .enable-usage-legends {
  font-weight: 500;
  color: #161250;
}

.wan-usage-legends .disable-wan-usage-legends {
  color: #aeaeb1;
  font-weight: 400;
}

.vessel-chart-usage-cont {
  width: 200px;
  background: #fff;
  padding: 10px 0px 10px 0px;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.vessel-chart-usage-cont>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 5px 15px;
}

.vessel-chart-usage-cont>div>span:nth-child(1) {
  font-size: 14px;
  font-weight: 600;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
}

.vessel-chart-usage-cont>div>span:nth-child(2) {
  font-size: 12px;
  font-weight: 600;
  color: #8B8D97;
  font-family: 'Inter';
  font-style: normal;
}

.wan-usage-legends .cursor {
  cursor: auto;
}

.innerScrollGDU {
  height: calc(100vh - 300px);
  overflow-y: auto;
  margin-top: 20px;
}

.graphical-justyfy-space-between .tabs {
  margin-left: 0px;
  margin-top: -8px;
}

.graphical-justyfy-space-between .tabs .top-widget-divs {
  padding: 8px 0px 9px 0px;
}

.reportsHeader .wan-usage-help {
  margin-left: 10px;
  cursor: pointer;
}

.custom-text-dialogue .MuiPaper-root {
  width: 600px;
  height: auto;
}

.custom-text-dialogue .text-msg-dialogue-title {
  background-color: #f5f5f5;
  color: #8B8D97;
  display: flex;
  justify-content: space-between;
}

.text-msg-dialogue .custom-text-dialogue .text-msg-dialogue-content {
  padding: 8px 16px;
}

.text-close {
  cursor: pointer;
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .top-widget-divs .d-flex {
    padding: 0px;
  }
  .innerScrollGDU {
    height: calc(100vh - 215px);
    overflow-y: auto;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1920px) {
  .top-widget-divs .d-flex {
    padding: 5px;
  }
}