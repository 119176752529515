body {
  overflow-y: hidden;
  overflow-x: hidden;
}

.MuiTablePagination-actions {
    background-color: #676984 !important;
    border: 1px solid #676984;
    border-radius: 20px;
    color: #fff !important;
  }
  
  /* .wan-links-input .MuiInputBase-root {
      padding: 0px !important;
  }
  
  .wan-links-input .MuiInputBase-root input{
      padding: 10px !important;
  } */
  
  .NewTableColor {
    color: #fff !important;
  }
  
  .tableHeaderFleetVesselData {
    border-bottom: 1.1px solid #676984;
    font-weight: 700;
    font-size: 12px;
    width: 42.05px;
  }
  
  .fleetDataTable {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    color: #8B8D97 !important;
    letter-spacing: 0.035em !important;
    text-transform: uppercase;
  }
  
  .fleetDataTable span {
    display: flex;
  }

  .fleetRowDataDetails .MuiChip-label {
    padding-top: 3px;
  }
  
  .fleetDataTable .ascendingOrderIcon {
    display: block;
  }
  
  .fleetDataTable .sortingActive {
    visibility: hidden;
  }
  
  .fleetDataTable .sortingImages {
    cursor: pointer;
    height: 8px;
    width: 10px;
  }
  
    .sort-icon-margin {
      margin-left: 10px;
    }
  
    .align-items-center {
      align-items: center;
    }

  .fleetRowRank {
    border-bottom: 0.5px solid #cfd0dd;
    font-weight: 700;
    font-size: 12px;
    color: rgba(39, 48, 80, 0.75) !important;
  }
  .fleetRowRank-odd {
    background-color: rgb(73, 132, 244, 0.05) !important;
    background-color: -moz-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -webkit-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -webkit-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -o-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -ms-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
  }

  .fleetRowRank-even {
    background-color: #ffffff !important;
  }
  
  .fleetRowDataDetails {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-transform: capitalize !important;
    letter-spacing: 0 !important;
    text-transform: capitalize !important;
    opacity: 0.75;
    position: relative;
  }

  /* @TODO: if it breaks some other place keep specific class name instead of span
  .fleetRowDataDetails span {
    display: flex;
  } */
  
  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px !important;
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
  }
  .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
    margin-left: -10px !important;
  }
  
  .paginationContainer {
    /* width: 206px; */
  }
  
  .paginationContainer .MuiPagination-ul li {
    /* background-color: lightcoral; */
    /* width: 160px; */
  }
  
  .paginationContainer .MuiPagination-ul li button {
    color: #676984;
    width: 5px;
    height: 25px;
    background: #f5f9ff;
    border: 1px solid #cad8f4;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }

  .paginationContainer .MuiPagination-ul li button.Mui-selected{
    border: 1px solid #264C86 !important;
    background: #2860B4;
    color: #fff;
  }

  .fleetRowDataDetails .statusRow {
    display: grid;
  }
 
  /* .FleetDashboard .fleetRowRank .fleetRowDataDetails .statusRow{
    height: 50px !important;
  } */
  
  .highlightedContInTable {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
  }

  .MuiTableHead-root .tableHeaderFleetVesselData .highlightedHeder {
    display: flex !important;
    justify-content: space-between;
    height: 42px !important;
    align-items: center;
  }

 .fleetDataTable {
    border-bottom: none;
    position: relative;
  }

  .highlightedContInTable .fleetRowDataDetails {
    border-bottom: none;
  
  }

  .FleetDashboard .MuiTableCell-root.twoSide {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .indStatus .dot {
    height: 6px;
    width: 6px;
    margin-top: 3px;
    margin-right: 4px;
    border-radius: 50%;
    display: inline-block;
    min-width: 10px;
  }

  .indStatus {
    display: flex;
    padding-top: 4px;
  }

  .indStatus img {
    width: 12px;
    height: 12px;
    padding-top: 1px;
    padding-right: 2px;
  }

  .latencyContent {
    padding-top: 4px;
  }

  .statusRow {
    width:100%;
    height: 100%;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 0px;
    padding-left: 25px;
    padding-right: 10px;

  }

  .emptyStatusRow {
    width:100%;
    height: 100%;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    border-right: 0px;
    padding-left: 25px;
    padding-right: 10px;
  }

  .throughput {
    width:100%;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 0px;
    border-right: 0px;
    padding-left: 25px;
    padding-right: 10px;
  }

  .latencyCol {
    width:100%;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 0px;
    border-right: 0px;
    text-align: center;
  }

  .emptyLatencyCol {
    width:100%;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    border-left: 0px;
    border-right: 0px;
    text-align: center;
  }

  .usageCol {
    height: 100%;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 0px;
    padding-left: 25px;
    padding-right: 10px;
  }

  .emptyUsageCol {
    height: 100%;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    border-left: 0px;
    padding-left: 25px;
    padding-right: 10px;
  }


  .forwardSlash {
    margin-left: 5px;
    margin-right: 5px;
  }

  .popup {
    position: absolute;
    background: black;
    color: white;
    border-radius: 20px;
    padding: 10px;
    bottom: 40px;
    left: -20px;
  }

  .content {
    /* width: 60px; */
    text-align: center;
  }

  .sort_icon_button {
    background: transparent;
  }
  .sort_menu {

  }

  .sort_icon_button {
    display: flex !important;
    min-width: auto !important;
    padding: 2px !important;
    background: none !important;
    box-shadow: none !important;
  }

  #menu-uptime-desc .MuiPaper-root {
    position: fixed;
  }

  #menu-uptime-asc .MuiPaper-root {
    position: fixed;
  }

  .usage span {
    padding-left: 26px;
  }
  #menu-uptime-desc .filterDropdownItem {
    font-size: 12px;
    font-weight: 500;
  }
  #menu-uptime-asc .filterDropdownItem {
    font-size: 12px;
    font-weight: 500;
  }

  .sort_menu .MuiPaper-root .MuiList-root .Mui-selected {
    background-color: #264C86 !important;
    color: #fff !important;
  }

  .statusOnHover{
    color: white;
    font-size: 12px;
  }

  .noteImg {
    height: 50px;
    width:50px;
    cursor: pointer;
  }

  .vesselNameBox{
    display: flex;
    align-items: center;
  }

  .fleetRowRank .identifier-font{
    font-size: 10px ;
    align-items: center;
    text-transform: none;
  }

  .vesselNameBox  .identifier-font{
    font-size: 10px ;
    align-items: center;
    text-transform: none;
  }

  .vesselNameBox .dot {
    min-width: 10px;
  }

  .accordionSummary .identifier-font12{
    font-size: 12px ;
  }

  .accordionSummary .MuiAccordionSummary-content {
    align-items: center;
  }

  .accordian{
    margin: 8px 0px;
  }

   .accordionSummary {
    flex-direction: row-reverse;
    align-items: center;
    min-height: 15px;
  }

  .identifierBox{
    display: flex;
    justify-content: space-between;
  }

  .accordian .MuiPaper-root .MuiButtonBase-root.Mui-expanded {
    min-height: 0px;
  }

  .accordian .MuiAccordionSummary-content.Mui-expanded{
    margin:0px
  }

  .accordianWidth {
    max-width: 83px;
    width: 83px;
  }

  .onlinePopupPadding .accordianWidth {
    padding: 8px;
  }

  .accWidUsage {
    max-width: 120px;
    width: 110px
  }

  .accWidSp {
    max-width: 80px;
    width: 75px;
  }

  .justfiyCenter {
    justify-content: center;
  }

  .padLeft40 {
    padding-left: 40px;
  }

  .padRight35 {
    padding-right: 35px;
  }

  .alertContentMain {
    text-decoration: none;
    display: block;
    color: #0000EE;
  }
  .alertContentAdj {
    padding-right: 10px;
    align-self: center;
  }

  .critAlertImg {
    width: 18px;
    height: 20px;
  }

  .totalAlertsCount {
    background: lightgray;
    padding: 5px;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
  }

  #greenDotSymbol {
    color: #2AB75A;
    background-color: #2AB75A;
    box-shadow: 0px 0px 6px 0px #2AB75A;
    border-radius: 50%;
    margin-right: 7px;
    width: 8px;
    height: 8px;
    display: inline-flex;
  }

  #redDotSymbol {
    color: red;
    background-color: red;
    box-shadow: 0px 0px 6px 0px red;
    border-radius: 50%;
    margin-right: 7px;
    width: 8px;
    height: 8px;
    display: inline-flex;

  }

  #greyDotSymbol {
    color: grey;
    background-color: grey;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;

  }

  #yellowDotSymbol {
    color: #F3CA52;
    background-color: #F3CA52;
    box-shadow: 0px 0px 6px 0px #F3CA52;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  .profileDotMargin {
    margin: 7px;
  }

  #usageLightblueDotSymbol {
    color: #C2C7E3;
    background-color: #C2C7E3;
    box-shadow: 0px 0px 6px 0px #C2C7E3;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  #usageBlueDotSymbol {
    color: #1B51A7;
    background-color: #1B51A7;
    box-shadow: 0px 0px 6px 0px #1B51A7;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  #usageRedDotSymbol {
    color: #cc3300;
    background-color: #cc3300;
    box-shadow: 0px 0px 6px 0px #cc3300;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  #usageYellowDotSymbol {
    color: #ffcc00;
    background-color: #ffcc00;
    box-shadow: 0px 0px 6px 0px #ffcc00;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  .criticalAlertsCount {
    background: lightgray;
    padding: 5px;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
  }

  .internetPresHeader {
    display: flex;
    align-items: center;
  }

  .internetPresHeader .justfiyCenter {
    min-width: 100px;
  }

  .interPrescSort {
    padding-top: 4px;
  }

  .internetPresence {
    text-align: left;
    cursor: pointer;
  }

  .Dashboard-siteContainer-NO-MAP {
    width: 100%;
  }

  .tooltipNewAcc {
    position: relative;
    display: inline-block;
  }
  
  .tooltipNewAcc .tooltiptextAcc {
    visibility: hidden;
    width: 150px;
    font-size: 12px;
    background-color: gray;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 3px 0;
    position: absolute;
    z-index: 1000;
    left: 30px;
  }
  
  .tooltipNewAcc:hover .tooltiptextAcc {
    visibility: visible;
  }

  .nowrapDisplayName {
    white-space: nowrap;
  }

  .paddingLogo {
    padding-left: 5px;
  }

  .fleetRowDataDetails.topAppsDetails {
    opacity: inherit;
    min-width: 100px;
  }

  .internetStatusTXT {
    margin-left: 1px;
    color: #0000EE;
  }

  .commonLoader-tableSummary {
    position: absolute;
    left: 160px;
  }

  .internetPresHeader .justfiyCenter-internet-status {
    cursor: pointer;
  }
  .vesselNameBox .quotaSatus {
    width: 15px;
    height: 15px;
  }

  .Table-Body-Opacity .Table-Row-Opacity .Table-Cell-Opacity {
    border: none;
    /* border-right: 1px solid #EBEBEB; */
    border-bottom: 1px solid #EBEBEB;
  }

  .vesselNameBox .bullseye-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    color: #2AB75A;
  }

  .mpk-text {
    font-size: 10px;
    color: #00e;
  }

  @media only screen and (max-height: 650px) {
    .Dashboard-siteContainer>div, .dashboardMapCOntainer {
      max-height: calc(100vh - 350px);
      overflow-y: hidden;
    }
  }

@media only screen and (min-height: 650px) and (max-height: 690px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 350px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height: 690px) and (max-height: 720px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 366px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height: 720px) and (max-height: 740px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 366px);
    overflow-y: hidden;
  }
}


@media only screen and (min-height: 740px) and (max-height: 765px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 350px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height: 765px) and (max-height: 790px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 350px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height: 790px) and (max-height: 849px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 350px);
    overflow-y: hidden;
  }
  .DashboardContainer {
    height: 62vh;
  }
}

@media only screen and (min-height: 849px) and (max-height: 899px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 320px);
    overflow-y: hidden;
  }
    .DashboardContainer {
      height: 64vh;
    }
}

@media only screen and (min-height:899px) and (max-height:950px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 330px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:950px) and (max-height:999px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 345px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:999px) and (max-height:1050px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 360px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1050px) and (max-height:1080px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 375px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1080px) and (max-height:1140px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 390px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1140px) and (max-height:1200px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 415px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1200px) and (max-height:1300px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 449px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1300px) and (max-height:1400px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 480px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1400px) and (max-height:1920px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 670px);
    overflow-y: hidden;
  }
}

/* styles.css */
.custom-table {
  border-collapse: collapse;
  width: 950px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  background-color: white; /* Add this line for black background */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.custom-table td {
  border: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  color: black;
  padding: 2px;
}

.custom-table th {
  border: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  padding: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 ;
  font-size: 10px ;
  line-height: 12px ;
  color: #fff ;
  letter-spacing: 0.035em ;
  text-transform: uppercase;
}

.custom-table th:first-child {
  border-top-left-radius: 10px;
}

.custom-table th:last-child {
  border-top-right-radius: 10px;
}

.MuiTooltip-tooltip.quotatooltipParent  {
  background: none;
}

.quotaDashBoardRow {
  background: #f5f5f5;
}

.quotaDashBoardRow .quotaDashBoardTableHeader {
  color: #8b8d97;
}

.dashboard-container {
  max-height: calc(100vh - 355px);
  margin-bottom: 10px;
}

.text-center {
  text-align: center;
}

.width15 {
  width: 15%;
}

.height40 {
  height: 40px;
}

.dashboard-tr .dash-inter-font {
  font-family: 'Inter';
  font-weight: 500;
  padding: 0px 10px;
  font-size: 12px;
  text-align: center;
}

.white-bg {
  background: #ffff;
}

.internet-status {
  font-weight: 700;
  letter-spacing: 1px;
  font-family: 'Inter';
}

.internet-status-sort {
  padding-left: 10px;
}

.quotaText {
  text-decoration: none;
  color: #4c3aff;
}

.elipse {
  white-space: nowrap;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vesselNameBox a {
  text-decoration: none;
  white-space: nowrap;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Table-Row-Opacity .organization-name {
  text-align: left;
  padding-left: 15px;
}

.topAppsFonts {
  color: #8B8D97;
  letter-spacing: 1px;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}


.table-parent {
  border: 1px solid rgba(224, 224, 224, 1);
  overflow-x: hidden;
}

.width-no-data {
  width: 600px;
}

.status-lists .font-status-pop-up {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: #0E0548;
  padding: 5px 0px;
  transition: 0.1s;
}

.wan-profile-tabs {
  display: flex;
  margin: 0px 0px 20px 0px;
}

.sub-tab-parent .wan-tab-btns {
  text-transform: none;
  color: black;
  background: #F9F9F9;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.chip-parent {
  padding: 5px 0px 0px 0px;
  gap: 5px;
  align-items: center;
}

.chip-parent .chip-color {
  background-color: #ffff;
  border: 1px solid #EBEBEB;
  font-size: 12px;
  height: 25px;
  font-family: 'Inter';
  margin-left: 0;
  font-weight: 500;
  color: #45464E;
}

.sub-tab-parent .sub-tab-width {
  width: 148.5px;
  height: 26px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
}

.sub-tab-parent .profile-parent-stack .MuiPaper-root {
  box-shadow: 0px 0px 7.5px 0px #00000017;
  border-radius: 4px;
  margin: 0px 10px 16px 5px;
}

.profile-parent .profile-heads {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-align: left;
}

.profile-sub-head-parent .profile-sub-head {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #8B8D97;
}

.profile-parent .profile-body .profile-sub-head-parent, .profile-parent .profile-body .profile-sub-head-table {
  padding: 16px 0px 0px 0px;
}

.device-data-body {
  overflow-y: auto;
  max-height: 350px;
  margin-top: -7px;
}

.device-border-right {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.sub-tab-parent .tabs-toggle-btns {
  width: 301px;
  height: 30px;
  border-radius: 4px;
  padding: 2px;
  margin-top: 6px;
}

#interface_select {
  background: #fff;
  box-shadow: 0px 0px 13px 0px #CBCBCB59;
  border-radius: 1px 2px 2px 1px;
  color: #0E0548;
  border: none;
}

#interface_unselect {
  color: #6E7079;
  border: none;
  transition: 0.1s;
}

.alertsTable-tableBody .wan-profile-small-table td {
  padding: 0px;
  text-align: left;
  padding-left: 15px;
  border: 1px solid #EBEBEB;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #45464E;
}

.profile-parent .profile-body .wan-profile-table-container {
  box-shadow: none;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  margin-bottom: 0px;
}

.wan-profile-small-table .profile-link-name {
  margin-top: 9px;
}

.interface-profile-popup .MuiDialog-paperWidthSm {
  max-width: 962px !important;
}

.sub-tab-parent .LAN-Status-div {
  margin-bottom: 15px;
  margin-left: 4px;
  text-align: left;
}

.LAN-Status-div .LAN-status-icons {
  width: 8px;
  height: 8px;
  margin-right: 7px;
}

.LAN-Status-div span {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  color: #45464E;
}

.LAN-Status-div .advanced_bonding {
  margin-left: 5px;
}

.width-752px~div {
  padding: 8px 4px;
}

.wan-profile-table-head-row .priority-column {
  width: 27%;
}

.wan-profile-table-head-row .status-column {
  width: 22%;
}

.wan-profile-table-container .wan-profile-table-head-row .font-wt-900 {
  border: 1px solid #EBEBEB;
  text-align: left;
  padding-left: 15px;
}

.wan-profile-no-data-text {
  height: 80px;
  border: 1px solid #EBEBEB;
  margin-right: 15px;
  font-size: 16px;
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #45464E;
}

.vesselNameBox .remove-margin-bullseye {
  margin: 7px;
}

.sub-tab-parent .lan-status-width {
  width: 100px;
}

.wan-rofile-top-chips {
  display: flex;
  gap: 25px;
  align-items: center;
}

.sub-tab-parent .wan-profile-interfaces-loader {
  margin: 45px -110px 0px 0px;
}

.yellowCell {
  background: yellow;
}

.redCell {
  background-color: rgba(255, 0, 0, 0.5);
}

.dashboard-tr .site-license-expiry {
  color: red;
  height: 20px;
  width: 20px;
  margin-right: -15px;
}

.accordianWidth .online-status-wan-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordianWidth .qoe-display-flex {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

#qoe-bacis-css {
  box-shadow: 0px 0px 6px 0px #2AB75A;
  border-radius: 50%;
  margin-right: 7px;
  width: 8px;
  height: 8px;
  display: inline-flex;
}