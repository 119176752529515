.starlink--base--container {
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #2F4B82;
    border-radius: 0.5rem;
}

.starlink--base--margin_1x {
    margin: 1rem;
}

.starlink--base--margin_05x {
    margin: 0.5rem;
}

.starlink--base--mtb_07x {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

.starlink--base--mt_1x {
    margin-top: 1rem;
}

.starlink--base--mb_1x {
    margin-bottom: 1rem;
}

.starlink--base--ml_1x {
    margin-left: 1rem;
}

.starlink--base--mr_1x {
    margin-right: 1rem;
}

.starlink--base--padding_1x {
    padding: 1rem;
}

.starlink--base--padding_05x {
    padding: 0.5rem;
}

.starlink--base--pb_1x {
    padding-bottom: 1rem;
}

.starlink--container .MuiTypography-root {
    font-family: 'Inter';
    font-weight: 500;
    color: #45464E;
}

.MuiTypography-root.starlink--base--font_07x {
    font-size: 0.7rem;
    font-weight: 600;
}

.MuiTypography-root.starlink--base--font_08x {
    font-family: 'Inter';
    font-size: 0.8rem;
}

.MuiTypography-root.starlink--base--font_09x {
    font-size: 0.9rem;
}

.MuiTypography-root.starlink--base--font_1x {
    font-size: 1rem;
}

.MuiTypography-root.starlink--base--font_bold {
    font-weight: 600;
}

.MuiTypography-root.starlink--base--font_2x {
    font-size: 1.5rem;
}

.MuiTypography-root.starlink--base--text_center {
    text-align: center;
}

.MuiTypography-root.starlink--base--text_start {
    text-align: start;
}

.MuiTypography-root.starlink--base--color_danger {
    color: #ed524a;
}

.MuiTypography-root.starlink--base--color_success {
    color: #6cce76;
}

.MuiTypography-root.starlink--base--color_primary {
    color: #2860b4;
}

.starlink--base--flex {
    display: flex;
    gap: 1rem;
}

.starlink--base--flex--gap--05x {
    display: flex;
    gap: 0.5rem;
}

.starlink--base--flex--column {
    display: flex;
    flex-direction: column;
}

.starlink--base--flex--row {
    display: flex;
    flex-direction: row;
}

.starlink--base--flex--justify--start {
    display: flex;
    justify-content: flex-start;
}

.starlink--base--flex--justify--end {
    display: flex;
    justify-content: flex-end;
}

.starlink--base--flex--justify--center {
    display: flex;
    justify-content: center;
}

.starlink--base--flex--justify--space_between {
    display: flex;
    justify-content: space-between;
}

.starlink--base--flex--justify--space_around {
    display: flex;
    justify-content: space-around;
}

.starlink--base--flex--align--start {
    display: flex;
    align-items: flex-start;
}

.starlink--base--flex--align--center {
    display: flex;
    align-items: center;
}

.starlink--base--cursor--pointer {
    cursor: pointer;
}

.MuiButtonBase-root.starlink--button--cancel {
    border: 1px solid #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.starlink--button--cancel:hover {
    border: 1px solid #EBEBEB;
}

.MuiButtonBase-root.starlink--button--cancel:disabled {
    background-color: #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.starlink--button_contained--primary {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.starlink--button_contained--primary:hover {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.starlink--button_contained--primary:disabled {
    background-color: #EBEBEB;
    color: #b1b1b1;
}

.MuiButtonBase-root.starlink--button_outlined--primary {
    border-color: #264C86;
    color: #264C86;
}

.MuiButtonBase-root.starlink--button_outlined--primary:hover {
    border-color: #264C86;
}

/* table style */

.starlink--table_container .MuiTableCell-root {
    background: #FFFFFF;
    border-collapse: collapse;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 1px;
    color: #45464E;
}

.starlink--table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}

/* Pagination Unset */

.starlink--container .pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    margin-top: auto;
    padding-left: unset;
}

.starlink--container .pagination-toolbar .records-select {
    font-size: 14px;
    border-radius: 8px;
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
}

.starlink--container .MuiMenuItem-root.records-select-item {
    font-size: 10px;
}

.starlink--container .pagination-toolbar .row-dropdown {
    width: unset;
    margin-left: 0.5rem;
}

.starlink--container .pagination-toolbar .page-dropdown {
    width: unset;
}

/* tooltip */

.MuiTooltip-tooltip.starlink--tooltip {
    background-color: black;
    color: white;
    padding: unset;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
}

.MuiTooltip-tooltip.starlink--tooltip .MuiTableCell-root {
    color: white;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.MuiTooltip-tooltip.starlink--tooltip .starlink--tooltip--value_title {
    opacity: 0.5;
}

/* comp level */

.MuiGrid-root.starlink--container {
    background-color: white;
    margin-top:109px;
}

.starlink--container .MuiPaper-root.starlink--navigation--container, .starlink--container .MuiPaper-root.starlink--admin_navigation--container {
    padding: 0rem 1rem;
    position: sticky;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 999;
}

.starlink--container .MuiPaper-root.starlink--admin_navigation--container {
    padding: 0rem 1rem;
    z-index: 998;
}

.starlink--container .MuiTabs-root,
.starlink--container .MuiTab-root {
    min-height: 42px;
    height: 42px;
}

.MuiGrid-root.starlink--tab_panel--container {
    padding: 0rem 1rem;
    margin: 0rem 0.2rem;
    height: calc(100vh - 165px);
    overflow: auto;
}

.starlink--container .starlink-page-container {
    height: unset;
    overflow-y: unset;
}

.starlink-page-container .add-srcoll-starlink-subtab-pages {
    height: calc(100vh - 265px);
    overflow-y: auto;
}

.MuiGrid-root.starlink--tab_panel--container {
    background-color: unset;
}

.starlink--loader--container {
    position: absolute;
}

.starlink--loader--container .loader {
    position: absolute;
    left: -1rem
}

.MuiSvgIcon-root.starlink--circle_icon {
    font-size: 0.75rem;
}

.starlink--container .MuiAlert-icon {
    padding: 7px 12px;
}

.starlink--container .MuiAlert-action, .starlink--container .MuiAlert-icon {
    margin-right: unset;
}