@import url(https://cdn.syncfusion.com/ej2/material.css);
.Form .outerDiv{
    background-color: #ffffff;
    margin-top: 2rem;
    padding: 2rem;
    /* width: 0px !important; */
    margin-top: 0px;
    border: 1px solid lightgray;
}

.Form .innerDiv{
    /* border: 2px solid #E5E5E5; */
    /* border: 1px solid #C2C2C2; */
    padding: 1rem;
    background-color: #EEF0F7;
    border: 1px solid #E5E5E5;
    /* margin-top: 2rem; */
}

.Form .horizontal-line{
    /* margin: 0.8rem -1rem 1rem -1rem; */
    border-bottom: 1px solid #E5E5E5;
}

.Form-close-icon {
    text-align: end;
}

.Form-title {
    font-size: 22px !important;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #384089 !important;
    font-style: normal;
    font-weight: 900;
}

.Form-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #384089;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}

.MuiDialog-paperWidthSm {
    max-width: 800px !important;
    height: 500px;
}


.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-track {
    background: #c4c4c4;
    border: 0.5px solid #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
    /* border: 3px solid orange; */
  }
  /* @media only screen and (min-width : 1200px) {
    .MuiInputBase-root{
        width: 323px !important ;
    }
}
   */

   .white-bg {
    background-color: #ffff;
   }
.login-input-border .MuiInput-underline:before .MuiInput-underline:before {
  /* border: 1px solid transparent !important; */
  /* background: #ffffff !important; */
  border-bottom: 0px;
}

.labelTextbox {
  height: 38.56px;
  /* top: 18%; */
  bottom: 76.77%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #676984 !important;
}

.UserNameInput {
  top: 10% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #676984 !important;
}

.inputRootContainer {
  /* margin-left: "30px"; */
  max-width: 620px !important;
  width: 100% !important;
}

.inputRootContainerForget {
  justify-content: flex-end;
  display: flex;
}

.inputRootSubContainer {
  margin-left: 30px;
  margin-top: 6px;
}

.RemeberMeContainer {
  flex-wrap: nowrap !important;
}

.checkboxRemeberME {
  width: 30px;
}

.loginButton.MuiButton-root:hover {
  background: #264c86 !important;
}

@media only screen and (max-width: 550px) {
  .loginButton {
    width: 92%;
    max-width: 350px;
    margin-bottom: 20px !important;
  }
}

@media only screen and (min-width: 600px) {
  .loginButton {
    width: 160px;
  }
}

.loginButton {
  /* width: 160px; */
  font-size: 11px;
  text-transform: uppercase;
  background-color: #264c86 !important;
  border: 1.5px solid #264c86;
  color: #ffffff !important;
  /* cursor: pointer; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.errorMessageAlert {
  margin-left: 35px !important;
  margin-top: 10px !important;
  color: #273050;
  background: rgba(192, 29, 29, 0.05);
  border: 1px solid #e96262;
  width: 80%;
  max-width: 592px;
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 10px;
}

.alertMessageImage {
  margin-left: 10px;
  margin-right: 10px;
}

.loginMobileText {
  position: absolute;
  top: 80px;
  left: 20px;
  color: #ffffff;
  font-size: 22.4421px;
  line-height: 26px;
}

@media only screen and (max-width: 550px) {
  .TextBoxMobileView {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 600px) {
  .TextBoxMobileView {
    margin-left: 25px;
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxMobileView {
    margin-right: 8%;
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxMobileView {
    margin-right: 22%;
  }
}

.poweredbyIcon {
  padding-left: 3px;
  padding-top: 4px;
  width: 15px;
  height: 15px;
}

.poweredByCover {
  margin-left: 5px;
  margin-right: 5px;
  height: 22px;
  width: 22px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.resendOtp {
  padding-top: 10px;
  justify-content: revert !important;
  margin-left: 12px;
}

.resendOtp button {
  margin-left: 9px;
  margin-top: 10px;
}

.otpVerifyButton {
  margin-right: 10px !important;
}

.verrifyOtpButtonContainer {
  margin-left: 20px;
  margin-top: 40px;
}

.otpContainer {
  margin-top: 10px;
}

.inputBoxMobileView .TextBoxMobileView .otPtextField {
  width: 40%;
  margin-left: 8px;
  margin-top: 15px;
}

.otpSuccessMessage {
  margin-left: 33px;
  color: green;
  font-weight: 400;
  margin-top:10px;
}

.otpDefaultMessage {
  margin-left: 33px;
  font-weight: 400;
  margin-top:10px;
  font-size: 12px;
}

.invalidOTP {
  color: red;
  margin-left: 33px;
}

.otpMessageContainer {
  display: grid;
  margin-bottom: 10px;
}

.userName {
  font-weight: 500;
  font-size: 13px;
}

.errorEmailAccess {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 100;
}
.dialog-title-close-icon {
    text-align: end;
}

.dialog-box-title {
    font-size: 22px !important;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #384089 !important;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #384089 !important;
    margin-top: 0px !important;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}
.MuiAlert-root {
    width: 100%;
    justify-content: center;
    text-align: center;
    padding: 0 !important;
}

.MuiAlert-message {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */
    text-align: center;
    color: #FFFFFF;
    /* padding-top: 10px;
    padding-bottom: 10px; */
}
.ComponentHeader {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #273050 !important;
  /* margin-top: 16px !important; */
  opacity: 0.75;
}

.componentContent {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #676984 !important;
  /* margin-top: 16px !important; */
  margin-left: 5px !important;
  text-transform: capitalize;
}

.marginComponent{
    margin-left: 30px !important;
}

.alert_history_header {
  background: white;
  width: 90px;
  font-weight: bold !important;
}

.alert_history_value {
  font-weight: normal !important;
}

.componentCatergory {
  width: 44px !important;
  height: 12px !important;
  text-transform: uppercase;
  background: #ffffff;
  font-size: 10px;
  font-weight: 500;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #273050;
  margin: -4px;
}

.componentStatus {
  width: 102px !important;
  height: 28px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.02em !important;
  white-space: nowrap !important;
  color: #273050 !important;
  background: #ffffff !important;
  border: 1px solid rgba(53, 83, 137, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
}

.Cancel-button {
  border: 1px solid #264c86 !important;
  color: #264c86 !important;
  width: -webkit-fill-available !important;
  height: 48px !important;
  border-radius: 4px !important;
  height: 38px !important;
}

/* .customTextArea .MuiInputBase-input {
  height: 60px !important;
} */

.scrollTooltip {
    max-width: 400px;
    max-height: 400px;
    overflow-x: auto;
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 10px;
    font-weight: 500;
    font-size: 12px;
}

.jsonTooltip {
    background: darkgrey;
}

.jsonTooltip .scrollTooltip {
    max-width: 800px;
}
body {
  overflow-y: hidden;
  overflow-x: hidden;
}

.MuiTablePagination-actions {
    background-color: #676984 !important;
    border: 1px solid #676984;
    border-radius: 20px;
    color: #fff !important;
  }
  
  /* .wan-links-input .MuiInputBase-root {
      padding: 0px !important;
  }
  
  .wan-links-input .MuiInputBase-root input{
      padding: 10px !important;
  } */
  
  .NewTableColor {
    color: #fff !important;
  }
  
  .tableHeaderFleetVesselData {
    border-bottom: 1.1px solid #676984;
    font-weight: 700;
    font-size: 12px;
    width: 42.05px;
  }
  
  .fleetDataTable {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    color: #8B8D97 !important;
    letter-spacing: 0.035em !important;
    text-transform: uppercase;
  }
  
  .fleetDataTable span {
    display: flex;
  }

  .fleetRowDataDetails .MuiChip-label {
    padding-top: 3px;
  }
  
  .fleetDataTable .ascendingOrderIcon {
    display: block;
  }
  
  .fleetDataTable .sortingActive {
    visibility: hidden;
  }
  
  .fleetDataTable .sortingImages {
    cursor: pointer;
    height: 8px;
    width: 10px;
  }
  
    .sort-icon-margin {
      margin-left: 10px;
    }
  
    .align-items-center {
      align-items: center;
    }

  .fleetRowRank {
    border-bottom: 0.5px solid #cfd0dd;
    font-weight: 700;
    font-size: 12px;
    color: rgba(39, 48, 80, 0.75) !important;
  }
  .fleetRowRank-odd {
    background-color: rgb(73, 132, 244, 0.05) !important;
    background-color: -moz-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -webkit-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -webkit-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -o-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -ms-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
  }

  .fleetRowRank-even {
    background-color: #ffffff !important;
  }
  
  .fleetRowDataDetails {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-transform: capitalize !important;
    letter-spacing: 0 !important;
    text-transform: capitalize !important;
    opacity: 0.75;
    position: relative;
  }

  /* @TODO: if it breaks some other place keep specific class name instead of span
  .fleetRowDataDetails span {
    display: flex;
  } */
  
  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px !important;
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
  }
  .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
    margin-left: -10px !important;
  }
  
  .paginationContainer {
    /* width: 206px; */
  }
  
  .paginationContainer .MuiPagination-ul li {
    /* background-color: lightcoral; */
    /* width: 160px; */
  }
  
  .paginationContainer .MuiPagination-ul li button {
    color: #676984;
    width: 5px;
    height: 25px;
    background: #f5f9ff;
    border: 1px solid #cad8f4;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }

  .paginationContainer .MuiPagination-ul li button.Mui-selected{
    border: 1px solid #264C86 !important;
    background: #2860B4;
    color: #fff;
  }

  .fleetRowDataDetails .statusRow {
    display: grid;
  }
 
  /* .FleetDashboard .fleetRowRank .fleetRowDataDetails .statusRow{
    height: 50px !important;
  } */
  
  .highlightedContInTable {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
  }

  .MuiTableHead-root .tableHeaderFleetVesselData .highlightedHeder {
    display: flex !important;
    justify-content: space-between;
    height: 42px !important;
    align-items: center;
  }

 .fleetDataTable {
    border-bottom: none;
    position: relative;
  }

  .highlightedContInTable .fleetRowDataDetails {
    border-bottom: none;
  
  }

  .FleetDashboard .MuiTableCell-root.twoSide {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .indStatus .dot {
    height: 6px;
    width: 6px;
    margin-top: 3px;
    margin-right: 4px;
    border-radius: 50%;
    display: inline-block;
    min-width: 10px;
  }

  .indStatus {
    display: flex;
    padding-top: 4px;
  }

  .indStatus img {
    width: 12px;
    height: 12px;
    padding-top: 1px;
    padding-right: 2px;
  }

  .latencyContent {
    padding-top: 4px;
  }

  .statusRow {
    width:100%;
    height: 100%;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 0px;
    padding-left: 25px;
    padding-right: 10px;

  }

  .emptyStatusRow {
    width:100%;
    height: 100%;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    border-right: 0px;
    padding-left: 25px;
    padding-right: 10px;
  }

  .throughput {
    width:100%;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 0px;
    border-right: 0px;
    padding-left: 25px;
    padding-right: 10px;
  }

  .latencyCol {
    width:100%;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 0px;
    border-right: 0px;
    text-align: center;
  }

  .emptyLatencyCol {
    width:100%;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    border-left: 0px;
    border-right: 0px;
    text-align: center;
  }

  .usageCol {
    height: 100%;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 0px;
    padding-left: 25px;
    padding-right: 10px;
  }

  .emptyUsageCol {
    height: 100%;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #F6F9FF;
    /* border: 1px solid rgba(0, 0, 0, 0.08); */
    border-left: 0px;
    padding-left: 25px;
    padding-right: 10px;
  }


  .forwardSlash {
    margin-left: 5px;
    margin-right: 5px;
  }

  .popup {
    position: absolute;
    background: black;
    color: white;
    border-radius: 20px;
    padding: 10px;
    bottom: 40px;
    left: -20px;
  }

  .content {
    /* width: 60px; */
    text-align: center;
  }

  .sort_icon_button {
    background: transparent;
  }
  .sort_menu {

  }

  .sort_icon_button {
    display: flex !important;
    min-width: auto !important;
    padding: 2px !important;
    background: none !important;
    box-shadow: none !important;
  }

  #menu-uptime-desc .MuiPaper-root {
    position: fixed;
  }

  #menu-uptime-asc .MuiPaper-root {
    position: fixed;
  }

  .usage span {
    padding-left: 26px;
  }
  #menu-uptime-desc .filterDropdownItem {
    font-size: 12px;
    font-weight: 500;
  }
  #menu-uptime-asc .filterDropdownItem {
    font-size: 12px;
    font-weight: 500;
  }

  .sort_menu .MuiPaper-root .MuiList-root .Mui-selected {
    background-color: #264C86 !important;
    color: #fff !important;
  }

  .statusOnHover{
    color: white;
    font-size: 12px;
  }

  .noteImg {
    height: 50px;
    width:50px;
    cursor: pointer;
  }

  .vesselNameBox{
    display: flex;
    align-items: center;
  }

  .fleetRowRank .identifier-font{
    font-size: 10px ;
    align-items: center;
    text-transform: none;
  }

  .vesselNameBox  .identifier-font{
    font-size: 10px ;
    align-items: center;
    text-transform: none;
  }

  .vesselNameBox .dot {
    min-width: 10px;
  }

  .accordionSummary .identifier-font12{
    font-size: 12px ;
  }

  .accordionSummary .MuiAccordionSummary-content {
    align-items: center;
  }

  .accordian{
    margin: 8px 0px;
  }

   .accordionSummary {
    flex-direction: row-reverse;
    align-items: center;
    min-height: 15px;
  }

  .identifierBox{
    display: flex;
    justify-content: space-between;
  }

  .accordian .MuiPaper-root .MuiButtonBase-root.Mui-expanded {
    min-height: 0px;
  }

  .accordian .MuiAccordionSummary-content.Mui-expanded{
    margin:0px
  }

  .accordianWidth {
    max-width: 83px;
    width: 83px;
  }

  .onlinePopupPadding .accordianWidth {
    padding: 8px;
  }

  .accWidUsage {
    max-width: 120px;
    width: 110px
  }

  .accWidSp {
    max-width: 80px;
    width: 75px;
  }

  .justfiyCenter {
    justify-content: center;
  }

  .padLeft40 {
    padding-left: 40px;
  }

  .padRight35 {
    padding-right: 35px;
  }

  .alertContentMain {
    text-decoration: none;
    display: block;
    color: #0000EE;
  }
  .alertContentAdj {
    padding-right: 10px;
    align-self: center;
  }

  .critAlertImg {
    width: 18px;
    height: 20px;
  }

  .totalAlertsCount {
    background: lightgray;
    padding: 5px;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
  }

  #greenDotSymbol {
    color: #2AB75A;
    background-color: #2AB75A;
    box-shadow: 0px 0px 6px 0px #2AB75A;
    border-radius: 50%;
    margin-right: 7px;
    width: 8px;
    height: 8px;
    display: inline-flex;
  }

  #redDotSymbol {
    color: red;
    background-color: red;
    box-shadow: 0px 0px 6px 0px red;
    border-radius: 50%;
    margin-right: 7px;
    width: 8px;
    height: 8px;
    display: inline-flex;

  }

  #greyDotSymbol {
    color: grey;
    background-color: grey;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;

  }

  #yellowDotSymbol {
    color: #F3CA52;
    background-color: #F3CA52;
    box-shadow: 0px 0px 6px 0px #F3CA52;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  .profileDotMargin {
    margin: 7px;
  }

  #usageLightblueDotSymbol {
    color: #C2C7E3;
    background-color: #C2C7E3;
    box-shadow: 0px 0px 6px 0px #C2C7E3;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  #usageBlueDotSymbol {
    color: #1B51A7;
    background-color: #1B51A7;
    box-shadow: 0px 0px 6px 0px #1B51A7;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  #usageRedDotSymbol {
    color: #cc3300;
    background-color: #cc3300;
    box-shadow: 0px 0px 6px 0px #cc3300;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  #usageYellowDotSymbol {
    color: #ffcc00;
    background-color: #ffcc00;
    box-shadow: 0px 0px 6px 0px #ffcc00;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-flex;
  }

  .criticalAlertsCount {
    background: lightgray;
    padding: 5px;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
  }

  .internetPresHeader {
    display: flex;
    align-items: center;
  }

  .internetPresHeader .justfiyCenter {
    min-width: 100px;
  }

  .interPrescSort {
    padding-top: 4px;
  }

  .internetPresence {
    text-align: left;
    cursor: pointer;
  }

  .Dashboard-siteContainer-NO-MAP {
    width: 100%;
  }

  .tooltipNewAcc {
    position: relative;
    display: inline-block;
  }
  
  .tooltipNewAcc .tooltiptextAcc {
    visibility: hidden;
    width: 150px;
    font-size: 12px;
    background-color: gray;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 3px 0;
    position: absolute;
    z-index: 1000;
    left: 30px;
  }
  
  .tooltipNewAcc:hover .tooltiptextAcc {
    visibility: visible;
  }

  .nowrapDisplayName {
    white-space: nowrap;
  }

  .paddingLogo {
    padding-left: 5px;
  }

  .fleetRowDataDetails.topAppsDetails {
    opacity: inherit;
    min-width: 100px;
  }

  .internetStatusTXT {
    margin-left: 1px;
    color: #0000EE;
  }

  .commonLoader-tableSummary {
    position: absolute;
    left: 160px;
  }

  .internetPresHeader .justfiyCenter-internet-status {
    cursor: pointer;
  }
  .vesselNameBox .quotaSatus {
    width: 15px;
    height: 15px;
  }

  .Table-Body-Opacity .Table-Row-Opacity .Table-Cell-Opacity {
    border: none;
    /* border-right: 1px solid #EBEBEB; */
    border-bottom: 1px solid #EBEBEB;
  }

  .vesselNameBox .bullseye-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    color: #2AB75A;
  }

  .mpk-text {
    font-size: 10px;
    color: #00e;
  }

  @media only screen and (max-height: 650px) {
    .Dashboard-siteContainer>div, .dashboardMapCOntainer {
      max-height: calc(100vh - 350px);
      overflow-y: hidden;
    }
  }

@media only screen and (min-height: 650px) and (max-height: 690px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 350px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height: 690px) and (max-height: 720px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 366px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height: 720px) and (max-height: 740px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 366px);
    overflow-y: hidden;
  }
}


@media only screen and (min-height: 740px) and (max-height: 765px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 350px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height: 765px) and (max-height: 790px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 350px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height: 790px) and (max-height: 849px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 350px);
    overflow-y: hidden;
  }
  .DashboardContainer {
    height: 62vh;
  }
}

@media only screen and (min-height: 849px) and (max-height: 899px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 320px);
    overflow-y: hidden;
  }
    .DashboardContainer {
      height: 64vh;
    }
}

@media only screen and (min-height:899px) and (max-height:950px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 330px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:950px) and (max-height:999px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 345px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:999px) and (max-height:1050px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 360px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1050px) and (max-height:1080px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 375px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1080px) and (max-height:1140px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 390px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1140px) and (max-height:1200px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 415px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1200px) and (max-height:1300px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 449px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1300px) and (max-height:1400px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 480px);
    overflow-y: hidden;
  }
}

@media only screen and (min-height:1400px) and (max-height:1920px) {
  .Dashboard-siteContainer>div, .dashboardMapCOntainer {
    max-height: calc(100vh - 670px);
    overflow-y: hidden;
  }
}

/* styles.css */
.custom-table {
  border-collapse: collapse;
  width: 950px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  background-color: white; /* Add this line for black background */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.custom-table td {
  border: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  color: black;
  padding: 2px;
}

.custom-table th {
  border: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  padding: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 ;
  font-size: 10px ;
  line-height: 12px ;
  color: #fff ;
  letter-spacing: 0.035em ;
  text-transform: uppercase;
}

.custom-table th:first-child {
  border-top-left-radius: 10px;
}

.custom-table th:last-child {
  border-top-right-radius: 10px;
}

.MuiTooltip-tooltip.quotatooltipParent  {
  background: none;
}

.quotaDashBoardRow {
  background: #f5f5f5;
}

.quotaDashBoardRow .quotaDashBoardTableHeader {
  color: #8b8d97;
}

.dashboard-container {
  max-height: calc(100vh - 355px);
  margin-bottom: 10px;
}

.text-center {
  text-align: center;
}

.width15 {
  width: 15%;
}

.height40 {
  height: 40px;
}

.dashboard-tr .dash-inter-font {
  font-family: 'Inter';
  font-weight: 500;
  padding: 0px 10px;
  font-size: 12px;
  text-align: center;
}

.white-bg {
  background: #ffff;
}

.internet-status {
  font-weight: 700;
  letter-spacing: 1px;
  font-family: 'Inter';
}

.internet-status-sort {
  padding-left: 10px;
}

.quotaText {
  text-decoration: none;
  color: #4c3aff;
}

.elipse {
  white-space: nowrap;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vesselNameBox a {
  text-decoration: none;
  white-space: nowrap;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Table-Row-Opacity .organization-name {
  text-align: left;
  padding-left: 15px;
}

.topAppsFonts {
  color: #8B8D97;
  letter-spacing: 1px;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}


.table-parent {
  border: 1px solid rgba(224, 224, 224, 1);
  overflow-x: hidden;
}

.width-no-data {
  width: 600px;
}

.status-lists .font-status-pop-up {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: #0E0548;
  padding: 5px 0px;
  transition: 0.1s;
}

.wan-profile-tabs {
  display: flex;
  margin: 0px 0px 20px 0px;
}

.sub-tab-parent .wan-tab-btns {
  text-transform: none;
  color: black;
  background: #F9F9F9;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.chip-parent {
  padding: 5px 0px 0px 0px;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

.chip-parent .chip-color {
  background-color: #ffff;
  border: 1px solid #EBEBEB;
  font-size: 12px;
  height: 25px;
  font-family: 'Inter';
  margin-left: 0;
  font-weight: 500;
  color: #45464E;
}

.sub-tab-parent .sub-tab-width {
  width: 148.5px;
  height: 26px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
}

.sub-tab-parent .profile-parent-stack .MuiPaper-root {
  box-shadow: 0px 0px 7.5px 0px #00000017;
  border-radius: 4px;
  margin: 0px 10px 16px 5px;
}

.profile-parent .profile-heads {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-align: left;
}

.profile-sub-head-parent .profile-sub-head {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #8B8D97;
}

.profile-parent .profile-body .profile-sub-head-parent, .profile-parent .profile-body .profile-sub-head-table {
  padding: 16px 0px 0px 0px;
}

.device-data-body {
  overflow-y: auto;
  max-height: 350px;
  margin-top: -7px;
}

.device-border-right {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.sub-tab-parent .tabs-toggle-btns {
  width: 301px;
  height: 30px;
  border-radius: 4px;
  padding: 2px;
  margin-top: 6px;
}

#interface_select {
  background: #fff;
  box-shadow: 0px 0px 13px 0px #CBCBCB59;
  border-radius: 1px 2px 2px 1px;
  color: #0E0548;
  border: none;
}

#interface_unselect {
  color: #6E7079;
  border: none;
  transition: 0.1s;
}

.alertsTable-tableBody .wan-profile-small-table td {
  padding: 0px;
  text-align: left;
  padding-left: 15px;
  border: 1px solid #EBEBEB;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #45464E;
}

.profile-parent .profile-body .wan-profile-table-container {
  box-shadow: none;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  margin-bottom: 0px;
}

.wan-profile-small-table .profile-link-name {
  margin-top: 9px;
}

.interface-profile-popup .MuiDialog-paperWidthSm {
  max-width: 962px !important;
}

.sub-tab-parent .LAN-Status-div {
  margin-bottom: 15px;
  margin-left: 4px;
  text-align: left;
}

.LAN-Status-div .LAN-status-icons {
  width: 8px;
  height: 8px;
  margin-right: 7px;
}

.LAN-Status-div span {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  color: #45464E;
}

.LAN-Status-div .advanced_bonding {
  margin-left: 5px;
}

.width-752px~div {
  padding: 8px 4px;
}

.wan-profile-table-head-row .priority-column {
  width: 27%;
}

.wan-profile-table-head-row .status-column {
  width: 22%;
}

.wan-profile-table-container .wan-profile-table-head-row .font-wt-900 {
  border: 1px solid #EBEBEB;
  text-align: left;
  padding-left: 15px;
}

.wan-profile-no-data-text {
  height: 80px;
  border: 1px solid #EBEBEB;
  margin-right: 15px;
  font-size: 16px;
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #45464E;
}

.vesselNameBox .remove-margin-bullseye {
  margin: 7px;
}

.sub-tab-parent .lan-status-width {
  width: 100px;
}

.wan-rofile-top-chips {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  align-items: center;
}

.sub-tab-parent .wan-profile-interfaces-loader {
  margin: 45px -110px 0px 0px;
}

.yellowCell {
  background: yellow;
}

.redCell {
  background-color: rgba(255, 0, 0, 0.5);
}

.dashboard-tr .site-license-expiry {
  color: red;
  height: 20px;
  width: 20px;
  margin-right: -15px;
}

.accordianWidth .online-status-wan-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordianWidth .qoe-display-flex {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

#qoe-bacis-css {
  box-shadow: 0px 0px 6px 0px #2AB75A;
  border-radius: 50%;
  margin-right: 7px;
  width: 8px;
  height: 8px;
  display: inline-flex;
}
.dashboard_popop_dialog .MuiPaper-root{
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(103 105 132 / 25%);
    border-radius: 8px;
}

.dashboard_popop_dialog .MuiDialogContent-root {
    overflow-y: visible;
    padding-left: 0px;
    min-width: 750px;
    max-height: 350px;
}

.dashboard_popop_dialog_sitelist .MuiPaper-root{
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(103 105 132 / 25%);
    border-radius: 8px;
}

.dashboard_popop_dialog_sitelist  .MuiDialogContent-root {
    overflow-y: visible;
    min-width: 750px;
    max-height: 350px;
}

.dashboard_popop_dialog_sitelist .MuiDialogContent-root {
    overflow-y: visible;
    min-width: 400px;
    max-height: 350px;
}
.custom_confirm_dialog_title{
    font-weight: 500 !important;
    font-size: 18px !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button{
    /* color: #264C86 !important; */
}
.custom_confirm_dialog_no_button{
    color: #264C86 !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-family: Roboto !important;
    font-style: normal !important;
    line-height: 21px  !important;
    align-items: center  !important;
}
.custom_confirm_dialog_sub_title{
    font-size: 13px !important;
}
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-weight: 500 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: none !important;
    border: 1px solid #264C86 !important;
}
.custom_confirm_dialog_yes_button{
    background: #264C86 !important;
    color: #fff !important;
}

.width-750 {
    min-width: 750px;
}
.width-750 .MuiPaper-root .MuiButtonBase-root.Mui-expanded {
    min-height: 50px;
}

.dashboard_popop_dialog .pop-head,
.dashboard_popop_dialog_sitelist .pop-head {
    background: #FFFFFF; 
    border-bottom: 1px solid #EFEFEF;
    padding-top: 15px;
    padding-bottom: 5px;
}


.dashboard_popop_dialog .width-752px {
    width: 752px;
}

.interface-profile-popup .width-752px {
    width: 914px;
}

.interface-profile-popup .MuiDialog-paperWidthSm {
    position: fixed;
    top: 100px;
}

.dashboard_popop_dialog .internet-status-popup-noData,
.dashboard_popop_dialog_sitelist .internet-status-popup-noData {
    text-align: center;
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
}

.width-750 .internet-status-popup-border {
    border: 1px solid #EFEFEF;
}

.site-name-header {
    color: black; 
    font-size: 14px;
}

.close-btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border: none;
    color: black;
}

.dialog-content-parent {
    padding: 8px 16px 8px 0px;
    margin-bottom: 10px;
}

.new-interface-profile-popup .MuiDialog-paperWidthSm {
    max-width: 1088px !important;
}

.new-interface-profile-popup .width-752px {
   width: 1040px;
}
.componentCatergory {
  width: 80px !important;
  height: 24px !important;
  text-transform: uppercase;
  background: #ffffff;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.componentStatus {
  width: 102px !important;
  height: 28px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.02em !important;
  white-space: nowrap !important;
  color: rgba(39, 48, 80, 0.75) !important;
  background: #ffffff !important;
  border: 1px solid rgba(53, 83, 137, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  margin: auto !important;
}


.vesselCounts {
  z-index: 1000;
  position: fixed;
  width: 94%;
  float: right;
  display: flex;
}

.vesselCounts .vCounts {
  margin-top: 2px;
  padding-right: 10px;
}

.vesselConatiner {
  display: flex;
  margin-left: auto;
  margin-right: 30px;
  margin-top: 10px;
  border: 1px solid black;
  background: white;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.leaflet-tile-container img {
    width: 256.8px !important;
    height: 256.8px !important;
}

.leaflet-touch .leaflet-control-attribution{
    bottom: 64px;
}
.map__container {
    width: 100vw;
  }

.header {
    display: flex;
    text-align: center;
    background-color: white;
    color: black;
    margin-left: 74px;
  }  

  .legend-network {
      display:flex
  }

/* footer {
  display: flex;
} */

.legendCard {
  height: 100px;
  padding-left: 150px;
  z-index: 1000;
}

.myLocationIcon {
  color:black;
  margin-left: 10px;
}

.leaflet-div-icon {
  border: none !important;
  background: none !important;
}

.leaflet-div-icon  .vesselIcon {
  height: 15px;
}

.leaflet-div-icon  .flightaware {
  height: 24px;
}

.leaflet-div-icon .vesselRedIcon {
  height: 30px;
}

.leaflet-div-icon .starlinklBigIcon {

  height: 30px;
}

.leaflet-div-icon .vesselRedBigIcon {
  height: 30px;
}

/* .polyLine {
  stroke-width: 1;
} */

.innerHex {
  stroke-width: 0.1;
}

.vesselTrack {
  stroke-width: 1;
}

#MaroMuje {
  height: 100vh;
}

.popUpChart {
  position: fixed;
  z-index: 1000;
  background: white;
  right: 0;
  width: 500px;
  height: 80%;
}   

.hexPopup {
  width: 400px;
  height: 150px;
}

 .vesselBothPopUp .leaflet-popup-content-wrapper {
  width: 400px !important;
  border-radius: 4px;
  background: black;
  color: white;
  opacity: 0.7;
}

.onHoverNameHEAD {
  font-weight: bold;
}

.leaflet-popup-content-wrapper {
  width: 400px;
  border-radius: 4px;
  background: black;
  color: white;
  opacity: 0.7;
}

.popupHeader {
  display: flex;
}

.leaflet-tooltip {
  background: none;
  margin-left: 12px;
  border: none;
  box-shadow: none;
  border-right-color: none;
}

.leaflet-tooltip-right::before {
  display: none;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 75px;
}

.mapLegends {
  display:flex;
  margin-top: 10px;
}

.vesselLocPopUp .leaflet-popup-tip-container {
  display: none;
}

.leaflet-top .leaflet-control-zoom {
  margin-top: 15px;
  position: fixed;
  right: 10px;
}

.leaflet-control-zoom.leaflet-bar {
  border: 2px solid rgba(0,0,0,0.2) !important;
}

.leaflet-control-zoom + .leaflet-control.leaflet-bar {
  margin-top: 90px;
  position: fixed;
  right: 10px;
}

.vesselLocPopUp .onHoverName {
  font-size: 12px !important;
  font-weight: bold;
  display: flex;
}

.vesselLocPopUp .modemNames {
  display: flex;
  font-size: 12px;
}

.dot {
  border-radius: 50%;
  display: inline-block;
}

.modemContents {
  padding-left: 5px;
  padding-right: 5px;

}

.modName {
  font-size: 10px;
  padding-left: 1px;
  font-weight: bold;
}

.customButtons {
  margin: 10px !important;
}

.layersIcon {
  color: black !important;
}

.mapCustButtonsGroup  button{
  padding: 0px;
  border: none;
  background: white;
  min-width: 30px !important;
  margin-right: 2px;
}

.leaflet-bar {
  border: none !important;
}

.errorCard {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  margin-left: 92px;
  margin-bottom: 80px;
  z-index: 10000000;
  height: 60px;
  width: 200px;
  border-radius: 30px;
  padding: 10px;
  color: blue;
  font-size: 12px;
  box-shadow: 0px 0px 9px black;
  font-weight: 500;
}

.errorCardForTrack {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: white;
  margin-left: 180px;
  margin-top: 180px;
  z-index: 10000000;
  height: 15px;
  width: 999px;
  border-radius: 30px;
  padding: 10px;
  color: blue;
  font-size: 12px;
  box-shadow: 0px 0px 9px black;
  font-weight: 500;
}

.errorWord {
  color: red;
}

.layersIcon .vsatIc {
  margin-top: 10px !important;
}

.towerIcon {
  height: 15px !important;
}

.towerTabRowHeader .towerTabHeader {
  font-weight: 500;
}

 .leaflet-popup-content-wrapper {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
/* 
.leaflet-marker-icon {
  margin-left: -7px !important;
  margin-top: -7px !important;
}  */

.pointsTrackIcons .pointsValue {
  padding: 5px !important;
}

.pointsTrackIcons {
  position: absolute;
  left: 30px;
  top: 30px;
}

.selectedTrack {
  z-index: 10000;
}

.dotCircle {
  height: 10px;
  width: 10px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
}

.mapCustButtonsGroup #layersSel {
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 6px;
}

.vesselHoverTab {
  max-height: 400px;
  overflow-y: scroll;
}

.popupColumn {
  width: 50%;
  float: left;
}

.vesselTrackIndCont {
  display: flex;
  justify-content: left !important;
}

.vesselTrackIndSecond {
  height: 89px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 8px;
  position: fixed;
  left: 739px;
  bottom: 10px;
  color: white;
  background: black;
  opacity: 0.7;
  z-index: 10000;
  padding: 1px;
}

.legendContainer {
  margin-left: 100px !important; /* Override conflicting styles */
}

.legendRow {
  margin-top: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.secondLegendRow {
  margin-top: 5px;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 5px;
}

.secondLegendRowData {
  align-items: center;
  margin-left: 5px;
  margin-bottom: 10px;
}


.legendLabel {
  font-size: 12px;
  font-weight: 500;
}

/* Adjustments for legend items */
/* .legendLabel,
.dotLegendSite,
.dotLegendStar {
  min-width: 35px;
  max-width: 35px;
  height: 15px;
  width: 15px;
  border-radius: 25%;
  display: inline-block;
  /* margin-left: 20px; */
  /* margin-right: 5px; */
  /* vertical-align: middle; */
/* } */
 

/* .dotLegendStar {
  margin-left: 26px !important;
} */

.legendLabelMainSite {
  margin-right: 10px;
  vertical-align: sub;
}

.siteLegendLabel {
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 140px;
  min-width: 140px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.siteLegendLabelHead {
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 60px;
  min-width: 60px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
}

.siteLegendLabelAvail {
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 80px;
  min-width: 80px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.siteValueLabelAvail {
  font-size: 12px;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 80px;
  min-width: 80px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.siteValueLabel {
  font-size: 12px;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 140px;
  min-width: 140px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.siteValueLabelHead {
  text-align: center;
  font-size: 12px;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 60px;
  min-width: 60px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
}

.parentVesselTrackInd {
  display: contents;
}

.leaflet-popup-close-button {
  display: none;
}
.layers-title-close-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid #EBEBEB ;
}

.layers-content-container{
    padding-left: 15px;
}

.layers-content-divider {
    border: none !important;
    border-top: 1px solid white !important;
    margin: 15px 15px 0px 15px;
}

.layers-title-close-icon span {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    color: white;
    opacity: 0.7;
}

.layers-title-close-icon img {
    border: 1px solid white;
    background: white;
    padding: 2px;
    width: 9.33px;
    height: 8.78px;
    color: white;
    cursor: pointer;
    opacity: 0.7;
}

.each-item-source {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    margin-bottom: 10px;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.each-content-title {
    color: white;
    opacity: 0.7;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px 5px 0px;
    display: flex;
}

.each-content-child {
    display: flex;
    grid-gap: 3px;
    gap: 3px;
    align-items: center;
}

.each-content-child .each-content-radio-btn {
    color: #1B51A7;
    width: 13.33px;
    cursor: pointer;
}

.each-content-child .location-eye-icons {
    width: 20px;
    height: 20px;
    color: white;
    opacity: 0.7;
    cursor: pointer;
}

.each-content-child span {
    color: white;
    opacity: 0.7;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
}

.each-item-source .MuiAutocomplete-inputRoot {
    height: 35px;
    border: 1px solid #eae4e4;
}

.each-item-source .MuiInputLabel-outlined {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    color: #BFBFBF;
}

.heat-maps-title-div {
    display: flex;
    align-items: center;
    grid-gap: 3px;
    gap: 3px;
}

.heat-maps-title-div .location-eye-icons {
    width: 20px;
    height: 20px;
    color: white;
    opacity: 0.7;
    padding-top: 5px;
    cursor: pointer;
}

.layers-content-container .title-location-maps {
    margin-bottom: 8px;
}

.each-item-source .hide-starlink-dd {
    visibility: hidden;
}

#combo-box-demo-map {
    color: white;
    opacity: 0.7;
}

.each-item-source .MuiAutocomplete-popupIndicator {
    color: white;
}

.marginTop10 {
    margin-top: 10px;
}

#maps-layers-new-container::-webkit-scrollbar {
    width: 8px;
}

#maps-layers-new-container::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    border-radius: 50px !important;
}

#maps-layers-new-container::-webkit-scrollbar-thumb:hover {
    background-color: #fff !important;
}

#maps-layers-new-container::-webkit-scrollbar-track {
    background-color: #333;
}
.serachBar {
  height: 38.56px;
  top: 18.99%;
  bottom: 76.77%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #676984 !important;
}
  
.serachLabel {
  top: 14.67% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #676984 !important;
}
  
.vesselPagination {
  /* position: absolute; */
  /* width: 105px; */
  height: 12px;
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  display: flex;
  align-items: center;
  color: #676984;
}
  
.vesselPageDetails {
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  align-items: center;
  color: #676984;
  justify-content: end;
}
  
/* .MuiInputBase-input {
  height: 15px !important;
} */
  
.autoCompleteBox {
  /* justify-self: center;
    display: flex;
    justify-content: center; */
  top: -10% !important;
}
  
.MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input, .MuiAutocomplete-option {
  font-family: 'Inter';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px;
  /* line-height: 16px; */
  color: #45464E;
}

.buttonCssFleet {
  width: 30%;
  height: 38.56px;
  margin-top: 8px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  text-transform: capitalize !important;
  /* border-radius: 8px !important; */
}

.buttonCssFleet_width20 {
  width: 20%;
  height: 38.56px;
  margin-top: 8px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  text-transform: capitalize !important;
  /* border-radius: 8px !important; */
}

.buttonCssFleet_width15 {
  width: 15%;
  height: 38.56px;
  margin-top: 8px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  text-transform: capitalize !important;
  /* border-radius: 8px !important; */
}

.MuiButtonBase-root.buttonCssFleet_width {
  padding-left: 1em;
  padding-right: 1em;
}

.prevPageNew{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.nextPageNewDisabled{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.nextPage{
  margin-left: 5px;
}
.interval_group {
  height: 29px;
}
.interval_group .MuiButton-root {
  border: 0.735125px solid #C7DAEB;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 8.8215px;
  line-height: 10px;
  text-transform: uppercase;
  color: #000000;
  width: "100%";
}
.interval_group .MuiButton-outlined {
  color: #000000 !important;
}

.interval_group .MuiButton-contained {
  background-color: #2860B4 !important;
  color: #ffffff;
}

.alertFilterButton {
  width: 50%;
  max-width: 120px;
  height: 38.56px;
  margin-top: 8px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  text-transform: capitalize !important;
  font-size: 11px !important;
}

.DashboardContainer {
  height: 63vh;
}

.dashboardMapCOntainer {
  width: 100%;
  height: 65vh;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.map__container {
  width: 100vw;
}

.dashboardMapCOntainer .LeafLetMaps {
  height: 100%;
}
.dashboard-filter button{
  margin-top: 8px;
  padding-bottom: 6.5px;
}
.rc--filter_menu_container_dashboard .MuiPaper-root{
  width: 274px;
  margin-left: 97px;
  margin-top: -5.7%;
  box-shadow:0px 0px 13px 0px rgba(203, 203, 203, 0.35);
}
.rc--filter_menu_container_dashboard  .am-table .FilterLeftContainer{
  height: auto;
}

.new-theme-dialogue .new-theme-dialogue-title {
  background: #f5f5f5;
  padding: 10px 10px 10px 25px;
}

.new-theme-dialogue-title .new-theme-dialogue-title-header {
  color: #45464E;
}

.MuiTextField-root.input-box-fleet-sel {
  margin: 8px;
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
}

.summary .pading-parent .padding-top-25 {
  padding-top: 25px;
}

.input-search-icons {
  position: absolute;
  right: 12px
}

.pading-parent .marg-left-auto {
  margin-left: auto;
}

.margin-left-filter {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 20px;
  margin-bottom: 12px;
}

.filter-divider-width {
  margin-top: 18px;
}

.apply-filter-divider-width {
  margin-top: 8px;
}

.filter-divider-width .filter-border-color {
  border-color: #EBEBEB;
}

.apply-filter-divider-width .filter-border-color {
  border-color: #EBEBEB;
}

.filter-header {
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 500;
  color: #45464E;
  line-height: 20px;
}

.internet-status-header {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color:#45464E;
  line-height:16.94px;
}

.filterComponents .sitesInternetStatusOptions {
  padding-bottom: 8px;
}

.sitesInternetStatusOptions .MuiFormControlLabel-label {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  color:#45464E;
  line-height: 14.52px;
}

.sitesInternetStatusOptions .MuiSvgIcon-fontSizeMedium {
  width: 16px;
  height: 16px;
  padding-right: 0px;
}

.filterComponents {
  color:#45464E;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter'; 
  line-height: 14.52px;
}

.sitesFiltersPadding {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.internetr-status-header-paddingBottom {
  padding-bottom: 12px;
}
.activityReportsFilter-close .clearFiletrBtnDiv .padding-right-0 {
  padding-right: 0;
}

.dialog-field-name-font-parent .dialog-field-name-font {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter';
  color: #45464E;
}

.title-head-parent .title-padding {
  padding: 10px 10px 10px 25px
}
.pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    font-family: 'Inter';
    margin-top: auto;
}

.pagination-toolbar .records-select {
    font-size: 14px;
    font-family: 'Inter';
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
    color: #6E7079;
}

.pagination-toolbar .page-dropdown {
    background: #f5f5f5;
    max-width: 80px;
    height: 23px;
    color: #6E7079;
    padding-top: 3px;
    border-radius: 8px;
}

.pagination-toolbar .row-dropdown {
    background: #f5f5f5;
    width: 72px;
    height: 23px;
    color: #6E7079;
    padding-top: 3px;
    border-radius: 8px;
    cursor: pointer;
}


.pagination-toolbar .MuiBox-root .page-arrow {
    background-color: #F5F5F5;
    border-radius: 8px;
    width: 19px;
    height: 24px;
    margin-right: 5px;
    color: #6E7079;
}

.MuiMenuItem-root.records-select-item {
    font-size: 10px;
    font-family: 'Inter';
}

.records-select.Mui-focused > .MuiSelect-select:focus {
    background-color: unset;
}

.pagination-toolbar .pagination-actions {
    flex-shrink: 0;
    margin-left: 20;
}

.pagination-spacer {
    flex: 1 1 100%;
}

.pagination-displayPages {
    flex-shrink: 0;
    color: #6E7079;
}

.pagination-selectLabel {
    flex-shrink: 0;
    color: #8B8D97;
}

.page-dropdown .MuiSelect-select.MuiSelect-filled  {
    text-overflow: unset;
}

.records-select .page-drop {
    pointer-events: none;
}
.notAuthorizedPage{
    width: 100% !important;
}
.notAuthorizedPage .title_div{
    background-color: #FAF9FC !important;
    
}
.notAuthorizedPage .page_title{
    font-family: Roboto;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 25px !important;
    display: flex !important;
    align-items: center !important;
    color: #676984 !important;
}
.notAuthorizedPage .message{
    height: 100px !important;
    font-family: Roboto;
    font-style: normal !important;
    font-weight: bold !important;
    color: #676984 !important;
    font-size: 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.card-container {
    padding: 8px;
}

.card-widget:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

#removeBoxShadow:hover {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
}

#addBoxShadow:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.card-widget {
    width: 100%;
    /* height: 65px; */
    border-radius: 10px;
    box-Shadow: none;
}

.flex_grow{
    flex-grow: 1;
}

.new_summary_grid_item{
    display: flex;
    flex-direction: column;
}

.card-box-parent .card-widget_NewSummary {
    /* width: 100%; */
    /* min-height: 118px; */
    border-radius: 10px;
    box-Shadow: none;
    padding: 16px;
    background-color: #F9F9F9;
}

.green-Background {
    background: #75DB92 !important;
}

.cards {
    justify-content: space-between;
    background-color:transparent;
    margin-bottom:5px;
    width: 100%;
    margin-left: -20px;
    padding:16px 0px;
}

.cards_NewSummary {
    justify-content: space-between;
    background-color:white;;
    width: 100%;
    margin-left: -20px;
    padding:16px 0px;
}
.cardContents {
    display: flex;
}

.cardContents {
    width: 100%;
    padding: 5px;
}

.cardActive {
    display: flex;
}


.cardContents_NewSummary {
    display: flex;
    padding-bottom: 11px;
}

.card-widget .cardTitle {
    padding-left: 5px;
    color: #2E1A70;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    padding-top: 5px;
    letter-spacing: 0.175em;
    display: inline-block;
}

.card-widget_NewSummary .cardTitle_NewSummary {
    color: #45464E;
    display: inline-block;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.new_summary_uptime{
    display: flex;
    align-items: center;
    justify-content: center;
}

.new_summary_uptime .new_summary_uptime_value{
    font-family: Inter;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #45464E;
}

.new_summary_uptime .new_summary_uptime_percent{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 8px;
    color: #8B8D97;
}

.new_summary_uptime .new_summary_uptime_typo{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #45464E;
}

.cardHeader .Availability {
    float: right;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 500;
}

.cardHeaderTile_NewSummary{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.cardHeaderImage_NewSummary{
    height: 28px;
    width: 28px;
    padding-right: 8px;
}

.cardLeftContent_NewSummary{
    display: flex;
    align-items: flex-end;
}

.card-widget  .cardContents {
    padding: 4px;
    justify-content: space-evenly;
    text-align: center;
}

.card-widget  .cardContents.cardNew {
    padding: 5px;
    justify-content: left;
    text-align: left;
}

.card-widget  .contentSubTitle {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
}

.card-widget .content {
    font-size: 25px;
    font-weight: 500;
    line-height: 26px;
}

.card-widget_NewSummary .content_NewSummary {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    color: #45464E;
}

.content_bottom_ns {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #8B8D97;
    margin-left: 10px !important;
}

.excepCardContent {
    padding-top: 8px;
}

.topWidgetInactiveCursor {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
}


@media (min-width: 1280px) {
    .SubHeader .cards .MuiGrid-grid-lg-2 {
        /* max-width: 20% !important; */
        flex-basis: 20% !important;
    }
}

.cardOnlineStatatus_ns{
    font-weight: 400;
    font-size: 12px;
    padding-right: 4px;
    font-family: Inter;
    line-height: 14.52px;
    color: #6E7079;
}

.cardOnlineStatatusWrapper_ns{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.cardOnlineStatatusData_ns{
    /* style={{ fontWeight: '700', fontSize:"12px" }} */
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #45464E;

}

.singleVesselCard{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Alerts Top Widget */

.flex_grow .alerts-heading-count-div {
    display: flex;
    justify-content: space-between;
}

.cardHeaderTile_NewSummary .alerts-heading-container {
    display: flex;
    align-items: center;
}

.alerts-heading-count-div .alerts-count-widget {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
}

.Alerts-topWidget-Card>span {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    margin-left: 30px;
}

.flex_grow .Alerts-topWidget-details {
    display: flex;
    justify-content: space-around;
}

.quota_admin_icons {
    padding-left: 7px;
    border-radius: 50%;
    margin-right: 6px;
}

.quota_admin_icons>img {
    height: 21px;
    width: 14px;
    padding-top: 3px;
}

.cursorAuto {
    cursor: auto;
}

.card-widget .advance-dashboard-card-css {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 4px;
    grid-gap: 5px;
    gap: 5px;
}

@media only screen and (max-width: 1260px){
    .card-widget .advance-dashboard-card-css {
      font-size: 8px;
    }
  }

@media only screen and (min-width: 1260px) and (max-width: 1350px){
  .card-widget .advance-dashboard-card-css {
    font-size: 9px;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1430px) {
    .card-widget .advance-dashboard-card-css {
        font-size: 10px;
    }
}

@media only screen and (min-width: 1430px) and (max-width: 1520px) {
    .card-widget .advance-dashboard-card-css {
        font-size: 11px;
    }
}

.card-box-parent .new-sum-cards-box-shadow {
    box-shadow: none;
    background-color: #F9F9F9;
}

.cardBorderTwoPXRed {
    border: 2px solid #d62728;
}

.cardBorderTwoPXOrange {
    border: 2px solid #ff7f0e;
}

.cardBorderTwoPXYellow {
    border: 2px solid #ffbf00;
}

.cardBorderTwoPXBlue {
    border: 2px solid #1f77b4;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(207, 208, 221, 1);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(207, 208, 221, 1);
}

.sideBar {
  position: fixed;
  width: 100%;
  z-index: 100;
}

.summary {
  border-radius: 4px;
  /* margin-left: 25px; */
  margin-right: 25px;
}

.SubHeader {
  padding-left: 14px !important;
  padding-right: 15px !important;
}

.new-summary-dash-container {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.summary-charts-comp {
  margin: 0px 17px;
}

.summary-charts-comp-avai {
  margin: 0px 18px 0px 22px;
}

.site-dashboard-NewSummary .padding-l-r {
  padding-left: 12px;
  padding-right: 7px;
}

@media only screen and (min-width: 600px) {
  .mega {
    position: fixed;
    margin-left: 45px;
    z-index: 1000;
  }
}

@media only screen and (max-width: 600px) {
  .mega {
    position: relative;
    margin-left: 45px;
    z-index: 1000;
  }
}

.site-dashboard--container.MuiGrid-root {
  /* margin-left: 88px; */
  width: 100%;
  margin-top: 80px;
  overflow-y: scroll;
  height: calc(100vh - 90px);
  background: white;
}

.summary {
  margin: 10px;
  padding: 0px 11px;
}

.SubHeader {
  background: white;
  z-index: 10;
  border-bottom: 1px solid #ccc;
}

.SubHeader_NewSummary {
  background: #ffffff;
  z-index: 10;
  padding-left: 21px ;
  padding-right: 21px ;
  /* border-bottom: 1px solid #ccc; */
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-17 {
  padding-right: 17px;
}

.padding-r-l-0 {
  padding-left: 0;
  padding-right: 0;
}

.site-dashboard-NewSummary.MuiGrid-root {
  /* margin-left: 88px; */
  width: 100%;
  overflow-y: hidden;
  max-height: calc(100vh - 79px);
}

.SubHeader.dashboardTabs {
  background-color: #ffffff;
  text-transform: none;
}

.NewSummaryDashContainer{
  height: calc(100vh - 150px);

  overflow-y: auto;
}
.breadcrumbs {
    padding-left: 15px;
    box-shadow: inset 0 0 3px grey;
    width: 100%;
    height: 30px;
    position: relative;
    padding-top: 3px;
} 

.breadcrumbs_NewSummary {
    padding-left: 20px;
    width: 100%;
    height: 62px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1299;
} 

.pageName {
    font-size: 14px;
    font-weight: 500;
}

.currentPageName {
    font-size: 14px;
    font-weight: 500;
}
.MuiBreadcrumbs-li {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #264C86;
    opacity: 0.85;
}

.MuiBreadcrumbs-li .MuiTypography-root {
    color: #264C86;
}

.MuiBreadcrumbs-li .decortion {
    text-decoration: none;
}
.MuiBreadcrumbs-separator{
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.edgeStatus {
    position: absolute;
    right: 140px;
    top: 4px;
    font-weight: 500;
    margin-top: 2px;
}
.edgeStatus img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.edgeOnline {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: #273050;
}

.edgeOffline {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: #273050;
}

.commonLoader {
    position: absolute;
    right: 150px;
    top: 15px;
}

.new-summary-filter-chips {
    margin-top: 8px;
}

.new-summary-filter-chips .MuiChip-label {
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 600px) {
    .breadcrumbs{
        position: fixed;
        top: 48px;
        background-color: #ffffff;
        z-index:  10;
    }

    .breadcrumbs_NewSummary{
        position: fixed;
        top: 49px;
        background-color: #FFFFFF;
        border: 1px solid #EBEBEB;
    }
}

@media only screen and (max-width: 599px) {
    .SubHeader{
        position: relative;
        background: #F4F7F8;
        z-index:  1299;
        border-bottom: 1px solid #000000;
    }

    .breadcrumbs {
        position: fixed;
        z-index: 11;
        margin-top: 48px;
    }
}

.commonFilters {
    display: flex;
}

.commonLoader_NewSummary {
    position: absolute;
    right: 150px;
    top: 30px;
}

.commonLoader_Topology{
    position: absolute;
    right: 30px;
    top: 90px;
}

.help_NewSummary {
    position: absolute;
    right: 100px;
    top: 12px;
}

.ht-wt-kc-icon {
    height: 24px;
    width: 24px;
}

.sport-user-guide {
    position: fixed;
    right: 12px;
    cursor: pointer;
    color: #2860B4;
}

.commonFilters .period-commonLoader_NewSummary-right-317 {
    position: fixed;
    right: 326px;
    top: 80px;
}

.commonFilters .period-commonLoader_NewSummary-right-65 {
    position: fixed;
    right: 65px;
    top: 80px;
}
.customVesselDropDown {
    display: flex;
    background: #2860B4;
    border-radius: 50px;
    color: #ffffff;
    margin-right: 34px;
}

.selectedVesselName {
    color: #ffffff;
    font-size: 14px !important;
    padding-left: 12px;
}
.ouDropDown {
    display: flex;
    background: #2860B4;
    border-radius: 50px;
    color: #ffffff;
    margin-right: 15px;
    width: 190px;
    position: relative;
}
.ouDropDown svg {
    position: absolute;
    right: 10px;
}

.selectedOuName {
    color: #ffffff;
    font-size: 14px !important;
    padding-left: 12px;
    font-weight: 500 !important;
}

.childHierarch:hover {
    /* padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 500px;
    width: 100%; */
}

.childHierarch {
    padding-left: 1px !important;
    padding-right: 5px !important;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
}

.childHierarch > span {
    text-overflow: ellipsis;
    overflow: hidden;
}

.childHierarchy {
    height: 400px;
    /* width: 300px; */
    /* overflow: auto; */
    margin-top: 22px;
    margin-left: 150px;
}

.childern {
    padding-left: 10px;
    padding-right: 5px;
}
#menu-ou {
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    letter-spacing: 0.05em !important;
    text-transform: capitalize !important;
    margin: 0px !important;
}
#menu-ou .MuiPaper-root{
    width: 190px !important;
    max-height: 300px;
    /* min-height: 200px; */
    background-color: #ffffff;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* box-shadow: none; */
}

.OuMenuChild {
    font-weight: 600 !important;
    line-height: 12px !important;
    letter-spacing: 0.05em !important;
    text-transform: capitalize !important;
    margin-left: 10px !important;
    color: #ffffff !important;
    padding: 0px !important;
    font-size: 1em !important;
    padding: 3px !important;
} 

.OuMenuChild .MuiListItemText-root .MuiTypography-root {
    font-size: 0.9em !important;
}

#single_vessel_selector {
    color: #ffffff;
    font-size: 14px;
    padding-left: 10px;
}


.tf-single-vessel .MuiInput-root:before, .MuiInput-root:hover {
    border-bottom: 0px !important;
}

.tf-single-vessel .MuiAutocomplete-endAdornment .MuiButtonBase-root{
    color: #ffffff;
}
.tf-single-vessel .MuiChip-root, .MuiChip-filled {
    color: #ffffff;
    background: #2860B4;
    height: 18px;
    margin: -5px;
}

.tf-single-vessel .MuiChip-root .MuiChip-deleteIcon{
    font-size: 15px;
}


.tf-single-vessel .MuiChip-root .MuiAutocomplete-tag {
    color: #ffffff;
}

.tf-single-vessel .MuiAutocomplete-tag {
    color: #ffffff;
}

#menu-vessels {
    max-height: 400px;
}

#menu-vessels .MuiPaper-root {
    background: #ffffff;
    border-radius: 15px;
    width: 190px;
    max-height: 300px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* box-shadow: none; */
}

#menu-vessels-new-summary .MuiPaper-root {
    background: #ffffff;
    border-radius: 15px;
    width: 130px;
    max-height: 300px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* box-shadow: none; */
}
#menu-vessels-new-summary-period.min-width .MuiPaper-root {
    width: 130px;
}

#menu-vessels-new-summary-period .MuiPaper-root {
    background: #ffffff;
    border-radius: 15px;
    width: 185px;
    max-height: none;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    /* box-shadow: none; */
}

.vessel_search_item form .MuiFormControl-root .MuiOutlinedInput-root{
    height: 20px !important;
}

.vessel_search_item form .MuiFormControl-root .MuiOutlinedInput-root .MuiInputAdornment svg{
    font-size: 12px;
}

.vessel_name_item {
    color: "#ffffff";
    padding: 5px 5px 5px 15px !important;
}
.vessel_name .MuiTypography-root {
    font-size: 12px;
    /* color: #ffffff; */
}

.vessel_name > span {
    text-overflow: ellipsis;
    overflow: hidden;
}

.vessel_name_item svg {
    font-size: 12px;
}

.searchtext {
    width: 100%;
}

.vesselDropdown {
    color: #ffffff !important;
    font-size: 14px !important;
    padding-left: 12px !important;
    display: flex !important;
    background: transparent !important;
    border-radius: 50px !important;
    margin-right: 24px !important;
    height: 26px !important;
    width: 190px !important;
    text-transform: capitalize !important;;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.05em !important;
    padding: 0px !important;
    align-items: center;
}

.layout_NewSummary .new-summary-vesselDropdown {
    color: #ffffff ;
    font-size: 14px ;
    padding-left: 12px ;
    display: flex ;
    background: transparent ;
    border-radius: 50px ;
    margin-right: 24px ;
    height: 26px ;
    width: 130px ;
    text-transform: capitalize ;;
    font-style: normal ;
    font-weight: 500 ;
    line-height: 16px ;
    letter-spacing: 0.05em ;
    padding: 0px ;
    align-items: center;
}

.vesselDropdownInput {
    color: black !important;
    font-size: 14px !important;
    padding-left: 12px !important;
    display: flex !important;
    background: #ffffff;
    border-radius: 4px !important;
    margin-right: 34px !important;
    height: 32px ;
    width: 2270px !important;
    text-transform: capitalize !important;;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.05em !important;
    padding: 0px !important;
}

.vesselDropdown .vesselDropdownInput {
    background: #fff;
}

.vesselDropdown .vesselDropdownInput:hover {
    background: #fff;
}

.background-gray .new-summary-vesselDropdown .MuiButton-root {
    background: #fff;
    box-shadow: none;
    border: 1px solid #EFEFEF;
    padding-right: 15px !important;
}

.layout_NewSummary .MuiButton-containedPrimary {
    margin: 15px 0px;
}

.padding-right-15 {
    padding-right: 15px;
}

.vesselDropdown-button{
    margin-right: 0px !important;
}
.vesselDropdown-button span {
    position: absolute;
    left: 10px;
}

.vesselDropdown-button svg {
    position: absolute;
    right: 10px;
}

.on_dropdown_open {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.textWrap {
    width: 154px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 17px;
    text-align: left;
    text-transform: none;
    font-family: 'Inter';
    font-size: 14Px;
    font-weight: 600;
    font-family: inter;
    line-height: 17px;
    height: 18px;
    color: #45464E;
}

.vesselDropdownInput .textWrapSpan{
    position: absolute;
    left: 100px;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: inter;
    font-weight: 600;
}

.vessel_name_item .textWrapInner {
    width: 120px ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 17px;
    text-align: left;
}

.vessel_name_item .textWrapInnerSL {
    width: 140px ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12px;
    text-align: left;
    display: block;
}

 .MuiTooltip-popper.MuiTooltip-popperInteractive .MuiTooltip-tooltipArrow.topZero {
    margin-top: 0px;
}

.layout_NewSummary{
    display: inherit;
    align-items: center;
}

.layout_NewSummary .globalFilterMarginRight {
    margin-right: 24px !important;
}

.mainGrid_NewSummary{
    margin-right: 15px;
}

.vesselDropdownInput .keyDownIcon_NewSummary{
    cursor: pointer;
     -webkit-transform: rotate(-180deg);
             transform: rotate(-180deg);
    margin-right: 15px;
}

.vesselDropdownInput .wan-keyDownIcon_NewSummary, .vesselDropdownInput .period-keyDownIcon_NewSummary {
    margin-right: 0px;
}

.page-drop {
    position: absolute;
    right: 0;
    padding-right: 10px;
    top: 8px;
    height: 10px;
    width: 10px;
}

.loader_NewSummary{
    position: absolute;
    top: 63px;
    right: 36px;
}

.checkedIcon_NewSummary{
    color: #ffffff;
    background: #2860B4;
}

.unCheckedIcon_NewSummary{
    color: #ffffff;
    border: 1px solid #2860B4;
}

.checkedSingleVessel{
    color:#2860B4;
    background-color: #ffffff;
}

.uncheckedSingleVessel{
    color:#ffffff;
    border:1px solid solid #2860B4;
    border-radius: 50%;
}

.span_NewSummary {
    font-size: 15px;
}

.serachBar .searchIcon_NewSummary{
    /* style={{ position: "absolute", right: "12px", fontSize: "12px" }} */
    position: absolute;
    right: 12px;
    font-size: 12px;
}

.serachBar .checkLine_NewSummary{
    position: absolute;
    right: 25px;
    font-size: 24px;
    font-weight: 600;
}

.applyButtonWrapper_ns{
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 10px 15px;

}

.applyButtonWrapper_ns .applyButtonCustomDate_ns{
    color:white;
    width: 39px;
    height: 25px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;     
    text-transform: none; 
    border-radius: 4px;
    background-color: #1B51A7;  

}

.applyButtonWrapper_ns .applyButtonCustomDate_ns:hover{
    background-color: #1B51A7;  
}

.applyButtonWrapper_ns .cancelButtonCustomDate_ns{
    color:#6E7079;
    width: 39px;
    height: 25px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;     
    text-transform: none; 
    border-radius: 4px;
    background-color: #ffffff;  

}

/* width */
.menuScroll ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  
  /* Track */
  .menuScroll ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 254, 254);
    border-radius: 18px !important;
    background: rgb(255, 255, 255);
  }
  
  /* Handle */
  .menuScroll ::-webkit-scrollbar-thumb {
    background:#D9D9D9 !important;
    border-radius: 0px !important;
  }
  
  /* Handle on hover */
  .menuScroll  ::-webkit-scrollbar-thumb:hover {
    background:#ffffff !important;
  }

  .span_TimePeriod .newSummaryTimePeriod{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }

  .ns_gray{
    color:#8B8D97;
  }

  .ns_white{
    color:#ffffff;
    padding-left: 16px;
  }

  .ns_time_period_container{
    display: flex;
    padding-top: 8px;
  }

  .layout_NewSummary .ns_common_filters_typo{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    padding-right: 16px;
    color:#8B8D97;
    height: 18px;
  }

.mainGrid_NewSummary #enable-cursor {
    cursor: pointer;
}

.mainGrid_NewSummary #disable-cursor {
    cursor: auto;
}

.vesselDropdown #enable-cursor {
    cursor: pointer;
    box-shadow: none;
    border: 1px solid #EFEFEF;
}

.margin-left-auto {
    margin-left: auto;
}

.search-dropdown-limit-div .margin-left-auto {
    margin-left: auto;
}

.vesselDropdown #disable-cursor {
    cursor: auto;
    background: #c8c8c8;
    opacity: 0.6;
}

.vesselDropdown .button-shadow {
    box-shadow: none;
    border: 1px solid #EFEFEF;
}

.padding-r-15 {
    padding-right: 15px;
}

#menu-vessels-new-summary-period .custom-date-selection-div .MuiFormControl-root{
    width: 155px;
}

.periodSelection {
    display: table;
    position: fixed;
    right: 44px;
    cursor: pointer;
    color: #2860B4;
}

.periodInnerContainer {
    display: table-cell;
    vertical-align: middle;
}

.plusMinusIc {
    color: black;
    font-size: 15px;
    margin-right: 10px;
}

.new-period-div-container {
    display: flex;
    align-items: center;
}

.new-period-div-container>span:nth-child(1) {
    position: fixed;
    right: 186px;
    width: 115px;
}

.new-period-div-container .newSummaryTimePeriod {
    font-size: 10px;
    color: #45464e;
    font-family: "Inter";
    font-weight: 600;
    font-size: 11px;
    font-family: monospace;
    font-weight: 500;
    color: rgba(110, 112, 121, 1);
}

.new-period-div-container .textWrap-new-period {
    left: auto;
    right: 10px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: unset !important;
    margin-right: unset;
}

#menu-vessels-new-summary-period .vessel_name_item {
    width: 100%;
}

.periodSelection .newPeriod-globalFilterMarginRight {
    margin-right: 0px !important;
    width: 251px;
}

.newPeriod-globalFilterMarginRight .vesselDropdownInput {
    height: 32px;
    width: 140px !important;
    position: fixed;
    right: 46px;
}

.commonFilters .sdwan-vesselDropdown {
    width: 255px !important;
}

.sdwan-vesselDropdown .textWrap {
    width: 230px !important;
}
.custom_confirm_dialog .MuiPaper-root{
    width: 403px;
    height: 250px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(103 105 132 / 25%);
    border-radius: 8px;
}
.custom_confirm_dialog_title{
    font-weight: 500 !important;
    font-size: 18px !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button{
    /* color: #264C86 !important; */
}
.custom_confirm_dialog_no_button{
    color: #264C86 !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-family: Roboto !important;
    font-style: normal !important;
    line-height: 21px  !important;
    align-items: center  !important;
}
.custom_confirm_dialog_sub_title{
    font-size: 13px !important;
    white-space: pre-line;
}
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-weight: 500 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: none !important;
    border: 1px solid #264C86 !important;
}
.custom_confirm_dialog_yes_button{
    background: #264C86 !important;
    color: #fff !important;
}

.custom_confirm_dialog .confirm-pad {
    padding: 10px 10px 10px 23px;
}
.alertsInterval {
  padding-left: 20px;
}

.alertsIntervalSelectorPadding {
  padding-left: 16px;
}

.labelDetails .MuiButtonBase-root  {
  height: 12px;
}

.alertsParentDashboard .alertsTable-FleetDashboard {
  border:1px solid #EBEBEB;
  width: 100%;
}

.alertsMainHyperLink {
  text-decoration: none;
  color: blue;
}

.alertsParentDashboard .alertsTable-FleetDashboard table {
  overflow-y: hidden;
}

.alertsTable-FleetDashboard::-webkit-scrollbar {
  width: 6px;
}

.alertsTable-FleetDashboard .alertsTable-tableHead {
  box-shadow: -10px -10px 0px 0px #ffffff;
}

.alertsTable-tableHead .alertReports-tableRow {
  background: #f5f5f5 ;
  height: 32px;
  border: 1px solid #EBEBEB ;
  align-self: stretch;
  box-sizing: border-box;
  box-shadow: -10px -10px 0px 0px #ffffff;
}

.alertsTable-action-deleteIcon {
  cursor: pointer;
}

.alertReports-tableRow .alertsTable-fleetDataTable {
  color: #8B8D97;
  text-align: center;
  letter-spacing: 1px;
  height: 35px;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  text-transform: uppercase;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* width: 16%; */
  padding: 0px;
}

.alertsData-tabelRow .alertsTable-tableCell{
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  background: #FFFFFF;
  padding: 10px 5px 10px 5px;
  font-weight: 500;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
}

.alertsData-tabelRow .alertsTable-tableCell .alertStatusWithIcon {
  display: flex;
  justify-content: center;
}

.userDataRightContainer .UsageLineChartComponent {
  margin: 24px 0px 0px 16px;
}



.alertsParentDashboard {
  height: calc(100vh - 165px);
  overflow-y: auto;
}

.alerts-pagination-position {
  margin-left: 17px;
  margin-top: 5px;
  margin-right: 30px;
}

.alerts-pagination-position .pagination-toolbar  {
  position: fixed;
  bottom: 15px;
  width: 86.9%;
}

.filtersButton .filtersImage {
  width: "16px";
  height: "16px";
  padding: "5px";
}

.AlertsPageFilters .filtersButtonContainer {
  display: flex;
  align-items: center;
}
.filtersButtonContainer .filtersButton.tkt_moreFilters_button {
  top: 0px;
  border: 1px solid grey !important;
}

.filtersButtonContainer button {
  border: "1px solid #ebebeb !important";
  margin-top: "-4px";
  display: "flex";
  justify-content: "center";
}

.alertFilterIconContainer .alertFilterIcon {
  height: 26px;
  width: 26px;
}

.alertFilterIconContainer .alertFilterIcon img {
  height: 20px;
  width: 18px;
}

.alertFilterIconContainer .MuiButton-startIcon {
  margin: 0px;
}

.filtersButtonContainer .alertFilterIconContainer {
  color: black;
}

.AlertsPageFilters .alertPage-search {
  max-width: 180px;
}


.noDataAvailableCss {
  color: #273050 !important;
  background-color: white !important;
  font-size: 12px !important;
  font-weight: 400 !important;

}

.tableCssCell {
  font-size: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


.alertParentPage .AlertsPageFilters {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tooltipNew {
  position: relative;
  display: inline-block;
}

.tooltipNew .tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: gray;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  top: -30px;
  left: 30px;
}

.tooltipNew:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-height: 400px){
  .alertPage-table {
    max-height: 150px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .alertPage-table {
    max-height: 150px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .alertPage-table {
    max-height: 200px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .alertPage-table {
    max-height: 250px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .alertPage-table {
    max-height: 300px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .alertPage-table {
    max-height: 350px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 650px) and  (max-height: 690px){
  .alertPage-table {
    max-height: 400px;
    overflow-y: auto;
  }
}

 @media only screen and (min-height: 690px) and  (max-height: 740px){
  .alertPage-table {
    max-height: 440px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 740px) and  (max-height: 790px){
  .alertPage-table {
    max-height: 490px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 790px) and  (max-height: 849px){
  .alertPage-table {
    max-height: 540px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 849px) and  (max-height: 899px){
  .alertPage-table {
    max-height: 600px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:899px) and (max-height:950px){
  .alertPage-table {
    max-height: 650px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:950px) and (max-height:999px){
  .alertPage-table {
    max-height: 700px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:999px) and (max-height:1050px){
  .alertPage-table {
    max-height: 750px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1050px) and (max-height:1080px){
  .alertPage-table {
    max-height: 790px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1080px) and (max-height:1140px){
  .alertPage-table {
    max-height: 820px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1140px) and (max-height:1200px){
  .alertPage-table {
    max-height: 890px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1200px) and (max-height:1300px){
  .alertPage-table {
    max-height: 940px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1300px) and (max-height:1400px){
  .alertPage-table {
    max-height: 1040px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1400px) and (max-height:1920px){
  .alertPage-table {
    max-height: 1640px;
    overflow-y: auto;
  }
}

.alertParentPage .margin-left-30 {
  margin-left: 30px;
}

.checkboxDetails {
  display: flex;
  align-items: center;
  margin-left: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.inline-checkbox {
  display: inherit;
}

.margin-left-right {
  margin: 0px 20px;
}

.AlertsPageFilters .marg-left-auto {
  margin-left: auto;
}

.disabled-button-csv-download {
  margin-left: auto;
  display: flex;
  cursor: not-allowed;
}

.disp-flex {
  display: flex;
}

.Form .topology-alerts-conatiner {
  height: calc(100vh - 190px);
  overflow-y: auto;
  margin-right: 10px;
  padding-right: 10px;
}

.alertsData-tabelRow .alerts-interface-width {
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  background: #FFFFFF;
  padding: 10px 5px 10px 5px;
  font-weight: 500;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flex-dispaly {
  display: flex;
  align-items: center;
}

.alertsData-tabelRow .alerts-site-ellipse, .alertsData-tabelRow .alerts-devicename-ellipse {
    white-space: nowrap;
    max-width: 215px;
    text-overflow: ellipsis;
    overflow: hidden;
}


.SearchBar .MuiFormControl-root label {
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.alertsFilterContainerBox .alertslabel {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;

}

.alertsFilterContainerBox .searchComponent {
    margin-right: 30px;
}

.MuiTextField-root.input-box-search-bar {
    margin: 8px;
    width: 100%;
    max-width: 300px;
    margin-left: 0px;
}

.searchbarInputs .alertsSearchbarInputMarginReset {
    margin: 0px;
}
.dropDownCardItem {
    margin-left: 30px;
}

.dropDownCardItem .dropDownCardSelected {
    font-weight: bold;
}

.dropDownCard {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s im !important;
    border-radius: 0px !important;
    height: 64px;
    width:190px
}

.dropDownCardCollapsePart {
    position: absolute;
    z-index: 1000;
    width: 188px;;
    background: white;
}
.MuiInputLabel-root.dropDownCardLabel {
    display: block;
    font-size: 10px;
    color: #9c9c9c;
    letter-spacing: 3px;
    font-weight: normal;
    margin-bottom: 2px;
}

.dropDownCardItem .dropDownCardSelected {
    font-size: 14px;
}

.MuiFormControl-root .headerLabel {
    font-size: 8px;
    color: #9c9c9c;
    letter-spacing: 3px;
    font-weight: 700;
    margin-bottom: 2px;
    margin-left: 8px;
}

.headerDropDownSelect div {
   font-size: 12px;
   padding: 8px;
   background: white;
}

.dropDown .drpdownLoabel {
    background: white;
    padding: 4px;
    margin-top: 4px;
    font-size: 8px;
    font-weight: bold;
}

.headerDropDownSelect {
    padding: 1px;
}

.ReportPagesContainer .alertsContainerGlobal {
    height: calc(100vh - 170px);
    overflow-y: auto;
}

.Alerts-TabsContainer {
    /* margin: 0px 15px 0px 25px; */
}

.Alerts-NavigationBtns {
    width: 100%;
    height: 48px;
    display: flex;
    margin-top: 35px;
    background: white;
    justify-content: space-between;
    margin-bottom: 10px;
}

.Alerts-navbarBtns {
    margin-left: 20px;
    padding-top: 15px;
}

.Alerts-NavigationBtns .usage-buttonCssFleet {
    padding-top: 10px;
    border-radius: 0px;
    text-transform: none;
    border-bottom:  2px solid #2860B4;
    color: #2F4B82;
}

.Alerts-NavigationBtns .simAnalysis-buttonCssFleet {
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #2860B4;
    color: #2F4B82;
}

.Alerts-NavigationBtns .dataUsage-buttonCssFleet {

    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #2860B4;
    color: #2F4B82;
}

.Alerts-NavigationBtns .graphicalDataUsage-buttonCssFleet {
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #2860B4;
    color: #2F4B82;
}

.Alerts-NavigationBtns .inActiveUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
}

.Alerts-NavigationBtns .inActiveSimAna-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
}

.Alerts-NavigationBtns .inActiveDataUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    width: 100px;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
}

.Alerts-NavigationBtns .inActiveGrapDataUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
}

.EdgeEvent_date_picker,
.EdgeEvent_date_picker>span {
    max-width: 250px;
    height: 35px;
}


.EdgeEvent_date_picker>span:focus {
    border-bottom: 0px;
    outline: none;
    box-shadow: none;
}

.EdgeEvent_date_picker.e-daterangepicker {
    top: 70px;
}

.EdgeEvent_date_picker>span {
    font-family: Roboto;
    font-style: normal;
    color: #676984;
    font-size: 14px;
    font-weight: bold;
    font-weight: normal;
    border: 0.5px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    border-radius: 6px;
    padding-left: 10px;
}

.EdgeEvent_date_picker_calendar.e-daterangepicker {
    top: 70px;
}

.marg-L-12 .FiltersButtonContainer {
  top: 0;
}

.container-edgeEvent .margin-events{
  margin-bottom:10px;
}

.container-edgeEvent .align-filters {
  display: flex;
  align-items: center;
}

.align-filters .position-relative {
  position: relative;
}

.FiltersButtonContainer .EdgeEvent_moreFilters_button {
    /* color: #080000; */
    /* top: 10px; */
    height: 38px;
    /* background: #ffffff; */
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    letter-spacing: 0.035em;
    margin-top: 0px;
    margin-left: 25px;
}

.filter-row .edgeEvents-paginationComp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: -70px;
}

.filter-row .edgeEvents-download {
  margin: 10px 5px 0px auto;
}

.edgeEvents-search .events-searchBar {
  width: 200px;
}

.container-edgeEvent .globalEvents-FleetDashboard {
  max-height: calc(100vh - 170px);
}

.padding-edge-events-container {
  padding: 20px;
}

.alertsTable-tableBody .events-description-width {
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  background: #FFFFFF;
  padding: 10px 5px 10px 5px;
  font-weight: 500;
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.alertsTable-tableCell-source {
  text-transform: capitalize;
}

.alertReports-tableRow .width-20 {
  width: 20%;
}

@media only screen and (max-height: 400px){
  .filter-row+div>div {
    max-height: 130px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .filter-row+div>div {
    max-height: 160px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .filter-row+div>div {
    max-height: 205px;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .filter-row+div>div {
    max-height: 255px;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .filter-row+div>div {
    max-height: 305px;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .filter-row+div>div {
    max-height: 355px;
  }
}

  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .filter-row+div>div {
      max-height: 405px;
    }
  }

   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .filter-row+div>div {
      max-height: 435px;
    }
  }

  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .filter-row+div>div {
      max-height: 500px;
    }
  }

  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .filter-row+div>div {
      max-height: 535px;
    }
  }

  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .filter-row+div>div {
      max-height: 610px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .filter-row+div>div {
      max-height: 650px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:950px) and (max-height:999px){
    .filter-row+div>div {
      max-height: 700px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:999px) and (max-height:1050px){
    .filter-row+div>div {
      max-height: 750px;
      overflow-y: auto;
    }
  }

 @media only screen and (min-height:1050px) and (max-height:1080px){
    .filter-row+div>div {
      max-height: 800px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1080px) and (max-height:1140px){
    .filter-row+div>div {
      max-height: 830px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1140px) and (max-height:1200px){
    .filter-row+div>div {
      max-height: 890px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1200px) and (max-height:1300px){
    .filter-row+div>div {
      max-height: 950px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1300px) and (max-height:1400px){
    .filter-row+div>div {
      max-height: 1050px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1400px) and (max-height:1920px){
    .filter-row+div>div {
      max-height: 1150px;
      overflow-y: auto;
    }
  }

  .filter-row.marg-L-12 {
    margin-top: 15px;
  }
  
.container-edgeEvent .MuiTextField-root label, .container-edgeEvent .MuiFormControl-root label, .siteCharts__filters .MuiTextField-root label, .siteCharts__filters .MuiFormControl-root label {
  top: 14.67%;
  font-style: normal;
  font-weight: bold;
  font-size: 0.65em;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #676984;
}

.container-edgeEvent .MuiTextField-root legend, .container-edgeEvent .MuiFormControl-root legend, .siteCharts__filters .MuiTextField-root legend, .siteCharts__filters .MuiFormControl-root legend {
  font-size: 0.65em;
}

.font13 {
  font-size: 13px;
}

.MuiTextField-root.input-box-events {
  width: 100%;
  max-width: 300px;
}

.MuiTextField-root:focus {
  outline: none !important;
  border:1px solid red;
  box-shadow: 0 0 10px #719ECE;
}

.MuiOutlinedInput-input {
  padding: 0;
}

.edge-evt-scroll {
  height: calc(100vh - 185px);
  overflow-y: auto;
}

.eventsFilterPadding {
  padding-left: 16px;
}

.edgeEventsPadding {
  padding: 0px 16px 0px 16px;
}
.filterTextWrap {
    width: 170px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edge_events_more_filters_dialog .MuiPaper-root{
    width: 274px;
    height: 601px;
    background: #FFFFFF;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
    border-radius: 8px;
    position: absolute;
    top: 30px;
}

.edge_events_more_filters_dialog_status{
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    border-radius: 10px;
    width:auto;
}

.marginBottom20px{
    margin-bottom: 20px;
}

.edge_events_more_filters_dialog_status .edge_events_more_filters_dialog_status_title{
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    align-items: center;
    color: #45464E;
    letter-spacing: 0.05rem;
}

.edge_events_more_filters_dialog_status .edge_events_more_filters_dialog_status_title{
    font-weight: 600 ;
    margin-bottom: 12px;
}

.events-gridflex {
    display: flex;
    margin: 0px 16px 8px 0px;
    padding-bottom: 0px;
    height: 16px;
}

.events-gridflex .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
}

.events-gridflex .filterTextWrap {
    padding-bottom: 0px;
    padding-left: 8px;
    font-family: 'Inter';
    line-height: 14.52px;
    font-size: 12px;
}

.events-search .events-search-bar {
    width: 242px;
    height: 32px;
    margin-bottom: 12px;
}

.events-search .no-device-text-events {
    margin: -5px 0px 5px 5px;
    width: 150px;
    font-size: 12px;
    color: red;
    display: block;
}

 #pop-up1 {
    margin-left: 445px;
}

#pop-up2 {
    margin-left: 353px;
}

.edge-events-filter-actions {
    display: flex;
    position: absolute;
    bottom: 0px;
    width: 274px;
    padding: 20px 16px 20px 16px;
    background-color: white;
    border-top: 1px solid #EBEBEB;
}

.filter-parent .filter-top {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    padding-left: 0;
}

.filter-parent .eventFilterBorderColor {
    border-color: #EBEBEB;
}

.clear-edge-filter {
    /* margin-left: auto; */
    padding-right: 0px;
    /* justify-content: end; */
}

.filter-body-container {
    height: calc(100vh - 357px);
    overflow-y: auto;
    max-height: 475px;
}

.filter-head-parent {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 18px;
    margin-bottom: 18px;
    width: 242px;
}

.clearFiletrBtnDiv .margin-left-80 {
    margin-left: 16px;
}

.dp-accordTree {
    padding: 0.2em 2em 0.2em 2em;


    .accordionTableHeader {
        border: 1px solid #ddd;
        background: #F5F5F5;
        display: flex;
        border-radius: 4px 4px 0px 0px;
    }

    .accordionTableHeader .eachAccordionHeadItem {
        border-right: 1px solid #ddd;
        padding: 10px;
        text-align: center;
        box-sizing: border-box;
    }

    .eachAccordionHeadItem .eachAccordColHead {
        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
    }

    .eventsHeading {
        padding: 2px;
    }

    .alertNameText input {
        padding: 5px;
        font-size: 12px;
    }

    .alertDescriptionText {
        width: 94%;
    }

    .alertDescriptionText input {
        padding: 5px;
        font-size: 12px;
    }

    /* .gateSection {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
  } */

    .radioButton {
        margin-left: 50px;
    }

    .GreenColor {
        color: #25C381;
    }

    .RedColor {
        color: red
    }

    .YellowColor {
        color: rgb(233, 233, 3)
    }

    .OrangeColor {
        color: #CF833D;
    }

    .GreenColor,
    .RedColor,
    .YellowColor {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        font-family: 'Inter';

    }

    .alertStatusAccordhead {
        display: flex;
    }

    .alertStatusWithIcon {
        display: flex;
        align-items: center;
        margin-left: 2px;
    }

    .alertStatusAccordhead .alertName {
        margin-left: 30px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Inter';
    }

    .alertStatusAccordhead .alertDescription {
        color: grey;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Inter';
    }

    .alertStatusAccordhead .gateName,
    .eventsHeading .rulesHead {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
    }

    .alertStatusAccordhead .gateName {
        text-transform: uppercase;
        color: #2F4B82;
        height: 15px;
    }

    /* .firstInnerTableCol {
    height: 40px;
    display: flex;
    align-items: center;
    border-right: 1px solid #ddd;
    min-width: 165px;
  } */

    .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        left: 0;
    }

    .childAccordion .accordionSummaryAlert {
        padding: 0px;
    }

    /* .accordionSummaryAlert .MuiAccordionSummary-content {
    align-items: stretch;
  } */
    .accordionSummaryAlert .eachChildAccordCol {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ddd;
        box-sizing: border-box;
    }

    .accordionSummaryAlert.childAccord .MuiAccordionSummary-content {
        align-items: stretch;
    }

    .accordian .alertsAccordions {
        box-shadow: none;
        padding: 0.7em 2em 0.7em 2em;
    }

    .alerts-config-Accordions {
        background-color: #F5F5F5;
    }

    .accordian .alertsAccordions .accordionSummaryAlert.Mui-expanded {
        border-bottom: 1px solid #ddd;
    }

    .statusName {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        font-family: 'Inter'
    }

    .cursor-auto {
        cursor: auto;
    }


    .display-flex {
        display: flex;
    }

    .moreLabelsDialog .MuiDialog-scrollPaper {
        justify-content: end;
    }

    .moreLabelsDialog .moreLabelsDialogGrid {
        padding: 10px 5px 15px 5px;
        grid-gap: 15px;
        max-height: 150px;
        overflow-y: auto;
    }

    .moreLabelsDialogCloseIconGrid {
        height: 15px;
        display: flex;
        justify-content: end;
    }

    .moreLabelsDialogCloseIconGrid img {
        height: 25px;
        width: 25px;
        cursor: pointer;
    }

    .moreLabelsDialog .MuiPaper-root {
        margin-top: -11%;
    }


    .create_alert--form_group {
        display: flex;
        grid-gap: 0.65rem;
        gap: 0.65rem;
        margin: 1rem;
        flex-wrap: wrap;
    }

    .create_alert--form_group--input {
        border: 1px solid #E7E7E7;
        border-radius: 4px;
        padding: 0.65rem 0.75rem;
    }

    .create_alert--form_group--input_text {
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 0.8rem;
        color: #45464E;
    }

    .dpAlertTypeHeader {
        font-size: 12px;
    }

    .dpAlertTypeInputContainer {
        display: flex; 
        flex-direction: column;
        justify-content: flex-start;
    }

    .dpLAInvalidThreshold {
        font-size: 10px;
        color: red;
    }

    .dpAlertTypeInputSubContainer {
        display: flex; 
        flex-direction: row;
        justify-content: flex-start; 
        align-items: center; 
        grid-gap: 1em; 
        gap: 1em;
    }

    .dpAlertLAContainer {
        margin-top: 10px;
    }

    .linkAvailabilityWanTypeSelect {
        width: 120px; 
        height: 35px;
    }

    .dpAlertTypeLAInputBox {
        width: 100px;
    }

    .dpAlertTypeInputBox {
        width: 200px;
    }

    .dpAlertTypeHeaderIcon {
        color: #2F4B82;
    }

    .dpAlertTypeFormIcon {
        cursor: pointer;
        color: grey;
    }

}

.dp-accordTree li {
    list-style-type: none;
    position: relative;
    background-color: #F5F5F5;
}

.dp-accordTree li li {
    list-style-type: none;
    position: relative;
}

.dp-accordTree li li:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid #ddd;
    width: 20px;
    height: 15px;
}

.dp-accordTree li li:before {
    border-bottom: 1px solid #ddd;
}

.dp-accordTree li li:after {
    position: absolute;
    content: "";
    top: 5px;
    left: -20px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    width: 20px;
    height: 100%;
}

.dp-accordTree li li:last-child:after {
    display: none;
}

.accordionContainer {
    width: 100%;
}



.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    height: 20px;
}

.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiSvgIcon-root {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    height: 20px;
}

.accordionSummaryAlert .MuiAccordionSummary-content {
    align-items: center;
    margin: 0px;
}

.accordian .accordionSummaryAlert {
    /* border-bottom: 1px solid #ddd; */
    min-height: auto;
}

/* .gateSection,.accordionHead {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #ddd;
  } */



.dp-accordTree ul {
    margin: 0px;
}


.dp-alert-logs {
    margin: 0.2em 2em 0.2em 2em;

    .audit-data-container .audit-logs-font,
    .audit-filters-container .audit-logs-font {
        font-size: 12px;
        font-family: 'Inter';
        color: #45464E;
    }

    .audit-data-container .audit-logs-font-header {
        font-size: 12px;
        font-family: 'Inter';
        font-weight: 600;
        color: #45464E;
    }

    .audit-filters-container .audit-filter {
        font-size: 14px;
        font-family: 'Inter';
        font-weight: 500;
        color: #45464E;
    }

    .audit-container {
        padding: 10px 13px;
        margin-top: 75px;
        height: calc(100vh - 150px);
        overflow-y: scroll;
    }

    .audit-layout {
        background-color: #f9f9f9;
        /* position: absolute;
    left: 85px;
    top: 50px;
    right: 0;
    bottom: 0; */
        padding: 40px 5px 5px 5px;
    }

    .audit-filter-list {
        display: flex;
        align-items: center;
    }

    .audit-filter {
        font-size: 14px;
    }

    .audit-data-container .audit-filter-list .audit-filter {
        font-size: 12px;
        font-weight: 600;
    }

    .audit-filter-list .filter-clear-all {
        margin-left: auto;
        color: #2F4B82;
        text-transform: none;
        text-decoration: none;
    }

    .expand-accordin-root.MuiAccordion-root {
        width: 100%;
    }

    .expand-summary .expand-summary-content,
    .expand-summary .expand-summary-content.Mui-expanded {
        margin: 5px 0;
        color: #45464E;
    }

    .expand-summary.Mui-expanded[aria-expanded="true"] {
        min-height: 48px;
    }

    .filter-accordian {
        font-size: 12px;
        font-weight: 600;
        padding-left: 17px;
    }

    .no-padding {
        padding: 0px;
    }

    .rc--form-control .no-padding {
        padding: 0px;
    }

    .padding-control .no-padding-top-btm {
        padding-top: 0;
        padding-bottom: 0;
    }

    .filter-accordian-label {
        font-size: 10px;
        font-weight: 500;
    }

    .audit-log-item .filter-meta {
        font-size: 12px;
        font-weight: 600;
        font-family: 'Inter';
    }

    .audit-filter-inputs .MuiInputBase-root {
        height: 34px;
        font-size: 12px;
    }

    .audit-filter-inputs .MuiInputLabel-root {
        font-size: 12px;
    }

    .audit-data-head .audit-data-header {

        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */
        letter-spacing: 1px;
        -webkit-font-feature-settings: "smcp";
                font-feature-settings: "smcp";
        font-variant: small-caps;
        /* Tertiary black */
        color: #8B8D97;
    }

    .audit-filters-container {
        /* display: flex;
    gap: 10px;
    flex-direction: column;
    height: calc(100vh - 80px); */
    }

    .filter_menu_container_auditlogs {
        margin: 110px 0px 0px 210px;
    }

    .filter_menu_container_auditlogs>.MuiPopover-paper {
        overflow-y: hidden;
    }

    .audit-btn-parent .audit-filter-apply {
        text-transform: none;
        background-color: #2F4B82;
        border-radius: 4px;
        margin: auto 15px 15px 15px;
        font-size: 12px;
        font-family: 'Inter';
        font-weight: 600;
        width: 90%;
        margin: 0 10px;
    }

    .audit-filter-items {
        overflow-y: hidden;
    }

    .audit-table-data.MuiTable-root {
        overflow-y: auto;
    }

    .audit-table-data.MuiTable-root .MuiTableHead-root th {
        background-color: #f5f5f5;
    }

    /* .audit-table-data.MuiTable-root .MuiTableHead-root th:not(:first-child):not(:last-child),
.audit-table-data.MuiTable-root .MuiTableBody-root td:not(:first-child):not(:last-child) {
    border-right: 1px solid rgba(224, 224, 224, 1);
} */

    .audit-data-container {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
    }

    .audit-data-pagination {
        margin-top: auto;
    }

    .audit-log-item {
        display: flex;
        width: 100%;
        grid-gap: 10px;
        gap: 10px;
        flex-direction: column;
    }

    .syntax-items {
        background-color: #F4F5FA;
        display: flex;
        width: 100%;
        min-height: 50px;
        position: relative;
        flex-direction: column;
        counter-reset: syntax-line-number;
        border-radius: 4px;
    }

    .syntax-items .syntax-row {
        counter-increment: syntax-line-number;
        display: grid;
        grid-column-gap: 5px;
        -webkit-column-gap: 5px;
                column-gap: 5px;
        grid-template-columns: minmax(15px, 25px) 1fr;
    }

    .syntax-items .syntax-row>pre {
        margin: 0;
    }

    .syntax-items .audit-syntax-copy {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .audit-syntax-copy .copy-audit-metas {
        background-color: #2F4B82;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-transform: none;
    }

    .syntax-items .syntax-row>* {
        padding: 1px;
        color: #6E7079;
        font-size: 12px;
        font-family: 'Inter';
    }

    .syntax-items .syntax-row:first-child>* {
        padding-top: 8px;
    }

    .syntax-items .syntax-row:last-child>* {
        padding-bottom: 8px;
    }

    .syntax-items .syntax-line-item {
        background-color: #E3E6F3;
        color: #2F4B82;
        -webkit-user-select: none;
                user-select: none;
        text-align: center;
        font-weight: 500;
        font-family: 'Inter';
        font-size: 12px;
    }

    .syntax-items .syntax-line-item::before {
        content: counter(syntax-line-number);
    }

    .copy-audit-metas .MuiButton-startIcon .copy-icon {
        font-size: 12px;
    }

    .accordian-expand .accordian-arrow {
        font-size: 16px;
        color: #6E7079;
    }

    .pagination-position {
        height: 40px;
    }

    .audit-container .audit-container-data {
        width: 100%;
        overflow: hidden;
        height: auto;
        overflow-y: auto;
        border: 1px solid #EBEBEB;
        border-radius: 0px;
        box-shadow: none;
    }

    .audit-table-root .audit-logs-no-data {
        font-size: 12px;
        font-family: 'Inter';
    }

    .audit-loader {
        position: absolute;
        top: 14px;
        right: 28px;
    }

    .audit-filter-items .audit-pop-up-height {
        overflow-y: auto;
        max-height: 50vh;
        margin-bottom: 10px;
    }
}
.SideNavDrawer .New-UX-NavigationTabs-Conatiner {
    margin-top: 112px;
    height: 42px;
    padding-left: 20px;
    border: 1px solid #EBEBEB;
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

#SubTabs-Container-marginTop-112 {
    margin-top: 112px;
    margin-bottom: unset;
}

#SubTabs-Container-marginTop-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.NewTabsDiv-InfoIconsDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin-left: -8px;
    height: 41px;
    width: 100%;
}

.tabButtons-parent-div {
    display: flex;
    align-items: center;
}

.tabs-alert-status {
    display: flex;
    align-items: center;
    margin: 0px 25px;
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-activeTab, .New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-inActiveTab, .New-UX-NavigationTabs-Conatiner .MuiTab-root {
    padding: 12px 0px 10px 0px;
    margin: 0px 8px 0px 8px;
    text-transform: None;
    cursor: pointer;
    border-radius: 0px;
    min-width: unset;
    min-height: 42px;
    height: 42px;
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-activeTab {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: rgba(47, 75, 130, 1) !important;
    border-bottom: 2px solid rgba(47, 75, 130, 1);
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-inActiveTab {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(139, 141, 151, 1);
    border-bottom: 2px solid transparent;
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-activeTab:hover {
    background: unset;
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-inActiveTab:hover {
    background: rgba(25, 118, 210, 0.04);
    padding: 12px 8px 10px 8px;
    margin: 0px;
}

.NewTabsDiv-InfoIconsDiv .tabs-helpIcon {
    cursor: pointer;
    color: #2860b4;
}

.New-UX-NavigationTabs-Conatiner .backButtonImg {
    margin-right: 20px;
    cursor: pointer;
}
.charts-filters-topbar {
    display: flex;
    width: 100%;
    margin-right: 15px;
    justify-content: space-between;
    align-items: center;
}

.charts-filters-topbar>div {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.charts-events-btns button {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    padding-top: 13px;
}

.charts-events-btns .selectedTab {
    color: #1B51A7;
    border-bottom: 2px solid #1B51A7;
    border-radius: 0px;
    padding-bottom: 9px;
    text-transform: none;
}

.charts-events-btns .unSelectedTab {
    color: #8B8D97;
    padding-bottom: 9px;
    text-transform: none;
}

.maps-charts-filters-container {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    margin: 18px 0px 5px 10px;
}

.maps-charts-filters-container button:nth-child(1) {
    height: 36px;
    border-radius: 4px;
    padding: 8px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
}

.maps-charts-filters-container .MuiButton-outlinedPrimary {
    color: #1B51A7;
    border: 1px solid #244C84;
    width: 85px;
    margin-bottom: -5px;
}

.mapCharts__filters-reset-zoom .MuiFormControlLabel-label {
    color: #45464E;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;    
}

.maps-charts-dialogue .css-ypiqx9-MuiDialogContent-root {
    padding: 0px !important;
}

.maps-charts-dialogue .MuiDialog-paperScrollPaper {
    /* width: 675px; */
    max-height: calc(100vh - 190px);
    width: 650px;
    position: fixed;
    top: 140px;
    right: 35px;
}

.maps-charts-dialogue .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    position: relative;
}

.maps-charts-dialogue .MuiBackdrop-root, .maps-charts-dialogue .MuiModal-backdrop, .maps-charts-dialogue .css-919eu4 {
    position: relative;
}

.maps-charts-filters-container .padding-left-25px {
    padding-left: 27px;
}

.maps-charts-filters-container .padding-right-22px {
    margin-left: -16px;
}

.maps-charts-filters-container .MuiOutlinedInput-root {
    height: 36px;
}

.map-charts-highchart .highcharts-root {
    margin-top: -15px;
}

.maps-charts-dialogue {
    margin-top: 220px;
    margin-right: 35px;
    width: 45%;
    margin-left: auto;
    background: #FFFFFF;
    border-radius: 10px;
    background-color: #0000;
}

.maps-charts-dialogue .css-ypiqx9-MuiDialogContent-root {
    overflow: hidden;
}

.maps-charts-dialogue .create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    height: 10px;
    color: #45464E;
    background-color: #f5f5f5 ;
}

.chartEvent-FleetDashboard .maps-charts-fleetDataTableRow th {
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid #EBEBEB;
}

#maps-sdwan-charts-btns .New-UX-NavigationTabs-Conatiner {
    margin-top: 0px;
    padding-left: 20px;
    border: 1px solid #ebebeb;
}

.chartsEventTableContainer .chartsEventTable {
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}
.Chart {
    font-family: sans-serif;
    text-align: center;
    width:100%;
  }

.chartContainer {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  border: 0.5px solid #0000FF;
}

.customTitle {
  display: flex;
  border-bottom: 0.5px solid #0000FF;
}

.customizedChartTitle {
  font-size: 18px;
  color: #2cc1de;
  padding: 20px;
  font-weight: 700;
}

.lineChartRates {
  margin-left: auto;
  padding: 20px;
  display: flex;
}

.rateTitle {
  color: #2cc1de;
  margin-left: 20px;
  margin-top: 3px;
}

.uploadArrow {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg)
}

.downloadArrow {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg)
}

.rate {
  margin-top: 3px;
}

.usageContainer {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}

.total {
  margin-top: 3px;
  margin-left: 20px;
}

.chartsEventTableContainer .chartsEventTable {
  background: white;
  padding: 10px 10px 2px 10px;
  margin-top: 10px;
}

.chartsEventTable .usageReports-FleetDashboard {
  margin-bottom: 15px;
  border: 1px solid #EBEBEB;
  border-bottom: none;
}

.chartEvent-tableRow .chartEvent-tablecell {
  padding: 0px;
  font-size: 11px;
}

.chartsEventTableContainer .chartEvent-FleetDashboard {
  height: auto;
}

.chartEvent-tablecell-source {
  text-transform: capitalize;
}

@media only screen and (max-width: 599px) {
  .lineChartRates{
    display: block;
  }
}

.Hub-Topology-SDWAN-Progress-Chart .highcharts-xaxis-labels, .Hub-Topology-SDWAN-Progress-Chart .highcharts-xaxis {
  display: none;
}

#legendTableCollapseHeader {
  width: 1%;
}

.qoe-hmap-chat-title {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-transform-origin: left;
          transform-origin: left;
  white-space: nowrap;
  margin: 5px 0px -15px 10px;
}

.qoe-hmap-chat-title>span {
  color: rgb(102, 102, 102);
  font-size: 0.8em;
}

.qoe-hmap-chat-title #helpIcon-reports {
  cursor: pointer;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
}

.qoe-application-score-table, .qoe-application-score-table-each-row>td {
  border: 1px solid #ebebeb;
}

.qoe-application-score-content {
  padding: 8px 16px;
  padding-top: 20px;
}
.chartsEventTableContainer .chartsEventTableFlights {
    height: auto !important;
    overflow-y: auto;
    overflow-x: hidden;
}


.selected-row .highlight {
    background-color: lightblue !important;
}

.maps-charts-dialogue {
    inset: auto !important;
}
.popupHeader .popUpHeaderBtn {
    margin-right: 10px;
    font-size: 10px;
    width: 10px;
    color: black;
    height: 26px;
    background: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s im !important;
}

.hexPopup .leaflet-popup-content {
    margin: 4px 4px;
    width: 98% !important;
}

.hexPopup .leaflet-popup-content .popUpDivider {
    margin-top: 4px;
    height: 3px;
}

.leaflet-popup-content {
  /* Resetting margin, padding, and any other styles */
  margin: 0;
  padding: 0;
  /* Add any other reset styles as needed */
}

.isActive {
    background: #1976d2  !important;
}

.popUpContents {
    height: 100px;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 10px;
    background: #b2b0b0;
    opacity: 0.0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #498AEC; 
    border-radius: 10px;
  }

  .coverageMapContainer {
        /* margin-left: 88px !important;
        position: fixed; */
        width: 100%;
        right: 0px;
  }

  .popUpChart {
      background: rgba(255, 255, 255, 0.55);
      margin:10px;
      top:136px;
      right: 48px;
      margin-right: 50px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
      transition: 0.3s im !important;
      border-radius: 5px ;
      height: 600px;
      width:900px;
  }

  .popUpTable {
      width: 100%;
  }

  .popUpTable tr {
      text-align: center;
  }

  .popUpTable th {
    text-align: center;
}

.mccMncDataCol .mccMncDivider {
    background: #D4E0F4;
    margin-left: 10px;
    margin-right: 10px;
}

.popUpHeadDivider {
    background: #EDF3FD;
}

.popUpHeadTable {
    text-align: center;
    width: 100%;
}

.mccMncDataContainer {
    display: flex;
}

.mccMncCodes {
    flex-grow: 1;
}

.mccMncNoData {
    text-align: center;
}

.layerButton {
    margin: 10px;
    margin-top: 140px;
    margin-left: 96px;
    position: fixed;
    z-index: 10000;
    width: 25px;
    height: 25px;
    background: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s im !important;
    border-radius: 5px;
}

.coverageMapContainer .layersSelectection {
    margin-left: 65px;
    position: fixed;
    z-index: 10000;
    width: 300px;
    overflow: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #FAF9FC;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s im !important;
    border-radius: 5px;
    overflow: hidden;
    padding-bottom: 10px;
}

.mccMncTableHeader {
    text-align: center;
    width: 50%;
}

.layerSelLabel {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}
                
.layerSub {
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    text-align: center;
}

.layerSubHead {
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
}

.allLayerLink {
    text-align: center;
    bottom: 0;
    margin: 10px;
    text-decoration: underline;
    cursor: pointer;
}

.coloredLegendBox {
    margin-left: 20px;
  }

.legendCard .footerContent {
    display: grid
}

.legendLabels {
    justify-content: space-around;
    width: 275px;
    display: flex;
    font-size: 12px;
}

.startLegendLabels {
    justify-content: space-around;
    width: 225px;
    display: flex;
    font-size: 12px;
}

.startLegendLabelsSpeed {
    justify-content: space-around;
    width: 240px;
    display: flex;
    font-size: 12px;
}

.mapLegends {
    /* width: 300px; */
}

.visibilityIcon {
    min-width: 40px !important;
    cursor: pointer;
}

.legendCard {
    margin-left: 88px;
}

.coverageMapContainer .popUpChart {

    background: rgba(255, 255, 255, 0.55);
}

.hichartTitle {
    letter-spacing: 0.06em;
    font-size: 15px;
    color: #676984;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
  }

  .customTMapitle {
    padding: 10px;
    padding-left: 18px;
    background: rgba(255, 255, 255, 0.55);
  }

  .coverageMapContainer .mapChartContainer {
      margin-top: 155px;
  }

  .coverageMapContainer .rightContainer {
    border-radius: 10px;
    top: 170px;
    right: 65px;
    position: fixed;
    width: 450px;
    z-index: 100000;
    /* height: 400px; */
    background: white;
      /* height: 75vh;
      overflow: scroll;
      padding-right: 10px;
      margin-top: 57px;
      border-top: 1px solid gray; */
  }

  .popUpchartContainer .maps-vessel-charts-container {
    height: calc(100vh - 256px);
    overflow: auto;
  }

  .popupChartHeader {
    border-bottom: 1px solid #D3D3D3;
    padding: 5px;
      display: flex;
      width: 100%;
      /* padding-top: 10px; */

  }

  .popupChartHeader .popUpVesselName {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    padding-left: 5px;
    padding-top: 5px;
  }


/* .chartContents {
    display: flex;
} */

.chartContents .highChartContainer {
    width: 504px;
}

.vesselHoverName {
    font-size: 12px;
    font-weight: bold;
    display: flex;
}

.geofence {
    padding-bottom: 0px;
}

.layersSelectection .customSlider {
    padding: 0px;
}

.chartLoader {
    text-align: center;
    z-index: 1000;
}    

.popUpchartContainer {
    width: 100%;
}

.chartCollection {
    margin-bottom: 20px !important;
}

.chartCollection .chartAccordions.MuiAccordion-root {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 100px;
    border-radius: 13px;
    background: #F8F8F8;
    -webkit-backdrop-filter: blur(17px);
            backdrop-filter: blur(17px);
}

.chartCollection .chartAccordions.MuiAccordion-root:before {
    background: none;
}

.chartAccordions .accordionTittle {
    font-size: 14px;
    color: #273050;
 }
 .popUpchartContainer .metricsTableInfo {
     margin-left: 20px;
     margin-right: 20px;
 }

 .popUpchartContainer {
    /* height: 350px; */
    width: 470px;
    /* margin: 0.5rem;
    margin-top: 20px; */
 }

 .popUptableOnHex .MuiPaper-root {
    height: 120px !important;
 }

 .isKassNotactive {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    background: #e0dddd;
    border-radius: 30px;
    margin-bottom: 100px;
 }

 .closeIc {
    cursor: pointer;
 }

 /* .legendCard {
    display: flex;
    justify-content: center;
 } */

 .vesselTrackIndCont {
    /* display: block !important; */
 }

 /* .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  } */

  /* .dotLegend {
    width: 15px;
    border-radius: 25%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .dotLegendSite {
    min-width: 35px;
    width: 15px;
    border-radius: 25%;
    display: inline-block;
    /* margin-left: 20px;
    margin-right: 5px; */
    /* vertical-align: middle;
  } */

  /* .dotLegendStar {
    min-width: 35px;
    height: 20px;
    width: 30px;
    margin-left: 15px;
    border-radius: 25%;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }  */

  /* .vesselTrackInd {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid gray;
    border-radius: 18px;
    position: fixed;
    left: 225px;
    bottom: 10px;
    background: white;
    z-index: 10000;
  } */

  /* .vesselTrackHead {
    font-weight: 500;
  } */

  .tooltip {
    color: #264c86;
    cursor: pointer;
  }
  .tooltip .tooltipText {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltipText {
    visibility: visible;
  }

  .legendLabelMainSite {
    font-weight: bold;
    padding-left: 50px;
    padding-right: 20px;
  }

  .legendLabelMainStar {
    font-weight: bold;
    padding-left: 32px;
    padding-right: 15px;
    vertical-align: bottom;
  }

  .meterlegendLabels {
    display: flex;
    justify-content: space-between;
  }

  .colorMeterUnit {
    font-size: 12px;
    text-align: center;
  }

  .meterlegendLabel {
    font-size: 12px;
  }

.colorMeter {
    min-width: 200px;
  }   

.maps-layers-new-container {
    z-index: 1000;
    position: fixed;
    top: 170px;
    right: 65px;
    opacity: 0.8;
    background: black;
    width: 200px;
    box-shadow: 0px 0px 12.3px 0px #97979782;
    border-radius: 8px;
    padding-bottom: 5px;
    max-height: calc(100vh - 285px);
    overflow-y: auto;
}

.colorMeter {
  height: 68px;
  left: 507px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 8px;
  position: fixed;
  bottom: 10px;
  background: black;
  color: white;
  opacity: 0.7;
  z-index: 100000;
  padding: 11px;
}

.colorMeterTwo {
  height: 68px;
  left: 554px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 8px;
  position: fixed;
  bottom: 10px;
  background: black;
  color: white;
  opacity: 0.7;
  z-index: 100000;
  padding: 11px;
}

.vesselTrackInd {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 8px;
  position: fixed;
  left: 225px;
  bottom: 10px;
  background: black;
  opacity: 0.7;
  color: white;
  z-index: 10000;
}
.vesselVoyage {
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 18px;
  position: fixed;
  left: 225px;
  bottom: 10px;
  background: black;
  opacity: 0.7;
  color: white;
  z-index: 10000;
  padding: 26px;
  width: 265px;
}

.vesselPrompt {
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 18px;
  position: fixed;
  left: 225px;
  top: 250px;
  background: black;
  opacity: 0.7;
  color: white;
  z-index: 1000;
  padding: 20px;
}

.legendRow {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.legendRow:first-child {
  justify-content: flex-start;
}

.legendLabel {
  text-align: center;
  min-width: 60px; /* Adjust as needed */
}

.dotLegendSite,
.dotLegendStar {
  width: 15px;
  height: 15px;
  min-width: 60px;
  /* Add any additional styling you need for the dots */
}

.dotLegendStar {
  padding-left: 20px;
}

.dotLegendStarone {
  padding-left: 10px;
  width: 15px;
  height: 15px;
  min-width: 60px;
}

.dotLegendStartwo {
  width: 15px;
  height: 15px;
  min-width: 60px;
}

.custom-tooltip-site {
  position: fixed;
  bottom: 60px;
  left: -20px;
  min-width: 420px;
}

.custom-tooltip-starlink {
  position: fixed;
  bottom: 80px;
  left: -20px;
  min-width: 420px;
}

.legendLast {
  padding-right: 8px;
}

.pointerCursor {
  cursor: pointer;
}

.dotLegend {
  height: 10px;
  width: 15px;
  border-radius: 25%;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.mapNav {
  position: absolute;
  margin-top: 110px !important;
}

.mapNewNav {
  position: absolute;
  margin-top: 135px !important;
}

.coverageMapContainer .New-UX-NavigationTabs-Conatiner {
  width: 100%;
  position: fixed;
  z-index: 500;
}

.New-UX-NavigationTabs-Conatiner .selectedSiteTabs {
  display: flex;
  align-items: center;
}

.popUpchartContainer .chartCollection-margin-Top {
  margin-top: 45px;
}

#Maps-Hex-Charts-Container .New-UX-NavigationTabs-Conatiner {
  margin-top: 0px;
  width: 450px;
}

.flashTab {
  position: relative;
}

.flashTab::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;  /* Adjust height for the bottom border thickness */
  width: 100%;
  background: linear-gradient(90deg, transparent, #2860B4, transparent);
  background-size: 200%;  /* Makes the gradient fill twice the element’s width */
  -webkit-animation: knightRider 1.5s ease-in-out infinite;
          animation: knightRider 1.5s ease-in-out infinite;  /* Slower speed for a smoother effect */
  border-radius: 50px;
}

@-webkit-keyframes knightRider {
  0% { background-position: 0% 0; }
  70% { background-position: 100% 0; }  /* Moves to the right */
  100% { background-position: 0% 0; }  /* Returns to the left */
}

@keyframes knightRider {
  0% { background-position: 0% 0; }
  70% { background-position: 100% 0; }  /* Moves to the right */
  100% { background-position: 0% 0; }  /* Returns to the left */
}

.siteCharts__charts-container {
    margin-top: 5px;
    padding: 10px; 
}

.topologyConatiner .sdwan-siteCharts__charts-container {
    margin-top: 0px;
    padding-top: 0px;
}

.tab-container:has(.siteCharts__filters) {
    margin: 12px;
    margin-top: 15px !important;
}

.topologyConatiner .tab-container:has(.topology-sdwan-container) {
    margin-top: 10px !important;
}

.topologyConatiner .topology-sdwan-container {
    height: 45px;
}

.siteCharts__charts-paper {
    height: 100%;
}

.legend-table-container {
    height: auto;
}

.siteCharts__charts-container-freeFormTool .legend-table-container {
    height: auto;
    margin: 10px 0px;
}

.siteCharts__filters-date-time-picker .rdtPicker {
    left: -30px
}

.siteCharts__filters-date-time-picker .rdt:nth-of-type(2) .rdtPicker {
    left: -65px
}

.siteCharts__charts-container {
    max-height: calc(100vh - 235px);
    overflow-y: auto;
}

@media only screen and (max-width: 924px) {
    .siteCharts__charts-container {
        max-height: calc(100vh - 334px);
        overflow-y: auto;
    }
}

.coverageMapContainer .siteCharts__charts-container {
    max-height: unset;
}

.site-filter-container{
    display: flex;
    justify-content: space-between;
}

.site-filter-item{
    display: flex;
    grid-gap: 0.05rem;
    gap: 0.05rem;
    align-items: flex-end;
    padding: 0px 10px;
}

.jc-end{
    justify-content: flex-end;
}

.site-filter-analyst-mode{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
}

.sdwanTopbar {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.pieChat-selectTag .sdwan-select-bin {
    margin-top: -20px;
}

.chatContainer .noData {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.chartReset {
  right: 30px;
  display: flex;
  margin-left: auto;
  z-index: 10000;
  border: none;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: inset 0 0 5px grey;
  cursor: pointer;
}

.histogramChart {
  height: 300px !important;
  margin-bottom: 20px;
}

.resetButtonContainer {
  position: relative;
  display: flex;
  padding-bottom: 5px;
}
/* 
.chatContainer {
  display: block;
}

 .Chart {
    font-family: sans-serif;
    text-align: center;
    height: 100%;
  }



.chartMapContainer {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.customTMapitle {
  display: flex;
  border-bottom: 0.5px solid grey;
}

.customizedMapChartTitle {
  font-size: 15px;
  color: #000000;
  padding: 5px;
  font-weight: 700;
}

.lineChartRates {
  margin-left: auto;
  padding: 10px;
  display: flex;
}

.rateTitle {
  color: #2cc1de;
  margin-left: 20px;
  margin-top: 3px;
}

.usageContainer {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}


.lineChartRates .modemButton {
  border: 1px solid black;
  background: white;
  margin-right: 5px ;
  font-size: 10px;
}

@media only screen and (max-width: 599px) {
  .lineChartRates{
    display: block;
  }
}  */
.histoInterval .histoIntervalInternal {
    height: 10px;
}

.histoInterval {
    height: 20px;
}

.histoInterval .MuiOutlinedInput-root {
    height: 20px;
}
.tableTitle {
    letter-spacing: 0.06em;
    font-size: 8px;
    color: #676984;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    width: 100%;
}

.noData {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    font-size: 15px;
}

.tableHeaderRow .tableHeader {
    color: #2860B4;
    font-size: 12px;
    font-weight: bold;
}

.MuiTableRow-head .tableHeader {
    font-size: 12px
}
.mccMncTable {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 94% !important;
}
.MapPage {
    display: block;
}

.coverageMapContainer .allMapFilters {
    margin-top: 109px;
    z-index: 1298;
}
.iconColor {
  width: 30px;
  cursor: pointer;
}

.iconColorDisable {
  width: 30px;
  cursor: not-allowed !important;
}

.MuiDialog-paperWidthSm {
  height: -webkit-max-content;
  height: max-content;
}

.paginationTable {
  margin-right: auto;
  margin-left: 10px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

input:-webkit-autofill {
  color: brown !important;
}

.send-button {
  border: 1px solid #676984 !important;
  /* color: #fff !important; */
  width: -webkit-fill-available !important;
  height: 38px !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  line-height: 12px !important;
}

.role-button {
  border: 1px solid #264c86 !important;
  background-color: #264c86 !important;
  color: #fff !important;
  width: -webkit-fill-available !important;
  height: 35px !important;
  border-radius: 4px !important;
}

.overflow-test {
  white-space: nowrap !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  text-overflow: hidden !important;
  width: 228px ;
}

.account-column {
  min-width: 70px;
}

.row-odd {
  background: "#ffff";
  color: #273050;
  height: 35.69px !important;
}

.row-even {
  background-color: -webkit-linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  background-color: -moz-linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  background-color: -ms-linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  background-color: -o-linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  background-color: linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  color: #273050;
  height: 35.69px !important;
}

.icon-cursor {
  cursor: pointer;
}

.ResendInviteButton {
  background: #ffffff !important;
  border: 1px solid rgba(53, 83, 137, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em !important;
  text-transform: capitalize !important;
  color: #002a7c !important;
  white-space: nowrap;
  box-shadow: 0px 4px 10px 0px #6769841a;
}

.AccountRowUserName {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0 !important;
  opacity: 0.75;
}

.AccountRowUserName span {
  display: flex;
}

.AccountRowUserName .statusRow {
  display: grid;
}

.highlightedContInTable .AccountRowUserName {
  border-bottom: none;
}

.AccountRowUserName .MuiChip-label {
  padding-top: 3px;
}

.CancelInviteButton {
  border: 1px solid #264c86 !important;
  color: #264c86 !important;
  width: -webkit-fill-available !important;
  border-radius: 4px !important;
}

.CancelRoleEditButton {
  border: 1px solid #264c86 !important;
  color: #264c86 !important;
  height: 35px;
  width: -webkit-fill-available !important;
  border-radius: 4px !important;
}

.onHover:hover {
  text-decoration: underline;
  color: #264c86;
  cursor: pointer;
}

.newBoxRole {
  background-color: #f5f9ff;
  max-width: 630px;
  /*height: 170px;*/
  height: auto;
  padding-bottom: 10px;
  border-radius: 15px;
  margin-top: 22px;
}

.textFieldRole {
  margin-left: 20px !important;
}

.newRoleName {
  margin-top: 20px !important;
}

.newResouceText {
  /* background-color: #fff; */
}

.newRoleNameText {
  margin-top: 20px !important;
  margin-left: 60px !important;
  background-color: #fff;
}

.addPolicyButton {
  background: #264c86;
  border-radius: 5px !important;
  color: #fff;
  height: 37px;
  margin-top: 15px !important;
  border: 1px solid #676984 !important;
}

.autocompleteText {
  margin-bottom: 15px;
}

.cssForTextFliedSpace {
  margin-top: 20px !important;
  margin-left: 20px !important;
  /* background-color: #fff; */
}

.autocompleteCssPadding.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root {
  height: 48px !important;
  overflow: hidden;
  padding-right: 15px !important;
}

.searchFilterText{
  height: 55px !important;
}

.accountManagement-filter>div{
  margin-top: 15px ;
}
.accountManagement-filter+div{
  margin-top: 16px ;
}

.tableBody {
  z-index: 1;
}

.tableHead { 
  position: -webkit-sticky; 
  position: sticky; 
  top: 0;
  z-index: 999;
}

.RolesList>div {
  margin: 1px;
}
.FleetDashboard.account-table .account-group-column-hide {
  display: none;
}

.am-table .accounts-help-container {
  display: flex;
  justify-content: flex-end;
}

.accounts-help-container .buttonCssFleet_width {
  margin-right: 0px;
}

.accounts-help-container .buttonCssFleet_width:hover {
  background: none;
}

.Form-accountManagement>div>div:nth-child(2){
  height: calc(100vh - 330px);
}

.accounts-fleetdashboard.MuiTableContainer-root {
  width: unset;
  overflow-x: unset;
}

/* @media only screen and (max-height: 400px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 130px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 185px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 235px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 285px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 335px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 340px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 650px) and  (max-height: 690px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 410px;
    overflow-y: auto;
  }
}

 @media only screen and (min-height: 690px) and  (max-height: 740px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 385px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 740px) and  (max-height: 790px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 485px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 790px) and  (max-height: 849px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 505px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 849px) and  (max-height: 899px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 630px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:899px) and (max-height:950px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 680px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:950px) and (max-height:999px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 730px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:999px) and (max-height:1050px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 780px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1050px) and (max-height:1080px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 830px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1080px) and (max-height:1140px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 860px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1140px) and (max-height:1200px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 920px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1200px) and (max-height:1300px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 980px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1300px) and (max-height:1400px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 1080px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1400px) and (max-height:1920px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 1660px;
    overflow-y: auto;
  }
} */

.accountHead .createAccountHeaders {
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #676984;
  line-height: 14px;
}

.userDp .DPVesselName{
  width: 300px;
  margin-top: 10px;
}

.twofaCheckBox {
  margin-left: 5px;
}

.filterStateName_filterBy .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  padding: 0px !important;
}

.updatePrivilegesValue{
  font-size: 16px;
  color: #264C86;
  line-height: 14px;
}

.updatePrivilegesHeading{
  font-size: 16px; 
  color: #676984;
  line-height: 14px;
}

.paddingTop10{
  padding-top: 10px;
}

.noWrapAccounts {
  text-wrap: nowrap;
  width: auto;
}

.dp-textWrap {
  width: 20ch ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  text-transform: none;
}

.accounts-tabelRow .accounts-tableCell{
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  background: #FFFFFF;
  padding: 10px 5px 10px 5px;
  font-weight: 500;
  /* width: 100px; */
  white-space: nowrap;
  /* max-width: 100px; */
  text-overflow: ellipsis;
  overflow: hidden;
}

.inter-font {
  font-family: 'Inter';
  font-size: 14px;
  color: #45464E;
}

.inter-font-parent .inter-font {
  font-family: 'Inter';
  font-size: 14px;
  color: #45464E;
}

.inter-font-parent .margin-adj {
  margin-top: -8px;
}

.menuScroll .invite-user-padding {
  padding: 10px 10px 0px 24px;
}

.set-pwd-pad-parent .set-pwd-pad {
  padding: 10px 10px 0px 20px;
}
.text-field{
    /* width: 296px; */
    max-width: 300px !important;
    height: 48px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    border-radius: 8px;
    color: #676984 !important;
    padding-left: 10px;
}

.fleetRowDataDetails .tableRowType {
    border: 1px solid white ;
}

.elementDataType {
    font-size: 12px;
    color: #262525 ;
    border: none;
    font-weight: 500;
}

.multipleElementDataType {
    padding: 5px;
    color: #262525;
    margin-left: 5px;
    border: 1px solid white;
    font-weight: 500;
}

.Warehouse-tableRow .warehousetableType {
  padding-left: 20px;
}

.Warehouse-tableRow .warehousetableSn {
  padding-left: 40px;
}

.Warehouse-tableRow .warehousetableAction {
    padding-left: 20px;
}

.serachBarWarehouse {
    width: 330px;
    font-size: 13px !important;
    padding: 2px;
    padding-bottom: 4px;
    padding-top: -2px;
}

.SearchbarTextFieldBox {
    margin-left: 35px;
    margin-bottom: 3px;
    width: 330px;
}

.assignSearchIcon {
    margin-left: 8px;
}

.noVesselInSearchIcon {
    margin-top: 3px;
}

 .AssigndialogueButtons {
  width: 170px;
  padding-left: -20px ;
  margin-top: 10px ;
  z-index: 5;
  position: relative;
  margin-right: 0;
}

 .AssigndialogueButtons .dialog_CancelButton {
    background-color: white;
    color: gray;
    border: 1px solid #c3d6e7;
    font-size: 11px;
    width: 40px;
    padding: 7px 35px 7px 35px;
    border-radius: 5px;
}

.AssigndialogueButtons .dialog_AssignButton {
    color: white;
    border: 1px solid #c3d6e7;
    font-size: 11px;
    width: 40px;
    padding: 7px 35px 7px 35px;
    border-radius: 5px;
}

.AssigndialogueButtons .dialog_CreateAssignButton{
    background-color: #264c86 !important;
    color: white;
    border: 1px solid #c3d6e7;
    font-size: 11px;
    width: 40px;
    padding: 7px 15px 7px 15px;
    border-radius: 5px;
    height: 36px;
}

.assignButton {
    border: 1px solid #c3d6e7;
    padding: 5px 23px 5px 23px;
    border-radius: 5px;
    background-color: white;
    color: #262525;
    font-weight: 500;
    cursor: pointer;
}

.noVesselError {
  width: 330px;
  height: 90px;
  border: 1px solid #c3d6e7;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  margin-top: -69px ;
  background-color: white;
  margin-bottom: 20px;
  margin-left: 28px;
}

 .vesselToCreate {
    height: 39%;
    width: 330px;
    padding: 8px 0px 7px 19px;
    color: #5d647c;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
}

.noVesselCreateMsg {
   height: 62%;
   font-size: 13px;
   color: #5d647c;
   padding-left: 15px;
   padding-top: 10px;
   display: flex;
   grid-gap: 10px;
   gap: 10px;
}

.errorIcon {
    color: #df8340;
    margin-right: -5px;
    cursor: pointer;
}

.warehouseAssignContentData {
   height: 290px;
}

.warehouseAssignContent {
   height: 250px;
}

.warehouseSiteListEmpty {
    border: 1px solid white;
}

.warehouseSiteListData {
    border:1px solid #c3d6e7;
    border-radius: 8px;
    color: #5d647c;
    margin-top: -8px;
    width: 380px;
    position: absolute;
    z-index: 5;
    background: white;
    max-height: 130px;
    overflow-y: auto;
    border-radius: 5px;
    margin-left: 0px;
    margin-bottom: 10px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-left: 10px;
}

.assignVesselDetails {
    margin: 15px 0px 0px 10px;
    line-height: 20px;
    font-size: 13px;
    color: #5d647c;
    padding-left: 7px;
    margin-bottom: -10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
}

.selectedProductType {
    color: #5d647c;
    font-size: 13px;
    font-weight: 600;
}

.CreatingVesselRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c3d6e7;
    border-radius: 5px;
    height: 40px;
}

.CancelErrMessage {
    padding: 5px 10px 5px 0px;
    color: #5d647c;
}
.successDialog {
  width: 500px;
  margin: auto;
}

.DialogueContent {
    height: 135px;
    color: #5d647c;
    font-weight: 500;
}

.successdialog_title {
    height: 20px;
    background-color: #ededed;
    border-radius: 8px;
}

.SuccesGreenLogo {
    margin: 5px 0 0 155px;
    position: absolute;
    z-index: 1;
}

.SuccesGreenLogo>div:nth-child(2){
  position: absolute;
  z-index: 2;
  margin-left: 25px;
  margin-top: -58px;
}

.DialogueContent>div{
    align-items: center;
    margin-top: 20px;
    font-size: 13px;
    padding: 25px 0px 10px 30px;
}

.createdVessel {
    margin: 10px 0px 0px 115px;
}

.Close_button {
    width: 100px;
    height: 40px;
    background-color: #375fae;
    color: white;
    border: none;
    border-radius: 8px;
    margin-left: 145px;
    margin-top: 20px;
    cursor: pointer;
}

 .mainAssignActiontitle .AssignActiontitle {
    font-size: 14px;
    margin-left: 0px;
    color: #45464E;
}

#DPmenu-ou {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    margin: 0px;
}

#DPmenu-ou .MuiPaper-root{
    width: 342px;
    max-height: 200px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    border: 1px solid #c3d6e7;
}

#DPmenuSmall-ou {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    margin: 0px;
}

#DPmenuSmall-ou .MuiPaper-root{
    width: 242px;
    max-height: 200px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    border: 1px solid #c3d6e7;
}

.DPchildHierarch {
    font-family: 'Inter';
    font-size: 14px;
    color: #45464E;
}

.inv_assign_dp{
    margin-top:20px;
    margin-left:35px;
    width:330px;
}
.inv_assign_dp .DPVesselName {
    border-radius: 8px;
}
.DPVesselName {
    border: 1px solid #c3d6e7 !important;
    width: 330px;
    height: 40px;
    background-color: #ffffff !important;
    box-shadow: none !important;
}

.DPtextWrap {
    color: #45464E;
    margin-right: 10px ;
    font-family: 'Inter';
    font-size: 14px;
    width: 300px;
    text-align: left;
    width: 400px;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DPdropdownArrow {
    color: #5d647c;
    margin-left: 30px ;
}


 .warehouse-inputRow {
  display: flex;
}
  
 .warehouse-inputRow>div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
}
  
 .limitContainer {
  margin-left: 10px;
}
  
 .inventorypaperComponent {
 font-size: 12px;
}

.warehouse-fleetRowRank .warehouse-fleetRowDataDetails {
  width: 33%;
}

 .inventoryPagination-list {
  width: 100%;
  align-items: center;
  margin-left: 5px;
}

 .selectedDeviceDetails {
  font-size: 12px;
  line-height: 20px;
  color: #5d647c;
  padding-top: 5px;
  margin-top: 6px;
}

 .selectedProductType, .selectedDeviceId {
  font-weight: 500;
  color: #5d647c;
  font-size: 13px;
}

 .VesselsListForAssign {
  width: 100%;
  overflow-y: auto;
}

 .ParentDivForVesselList {
  width: 340px;
  display: flex;
}

 .actionButtons {
  width: 45%;
  margin-right: 0;
}

 .SitesListNames {
  height: 25px;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 15px;
  font-size: 11px;
  border: 5px;
  cursor: pointer;
}

.fleetDataTable.cellWidthForColmns .align-center {
    justify-content: center;
}
.Warehouse-FleetDashboard .inventory-group-column-hide{
    display: none;
}

.deploy-inventory-content .DPvesselDropdown {
    margin-right: 0px !important;
}

.DPvesselDropdown .DPVesselName {
    width: 342px;
}

.assign-create-actions {
    width: 230px;
    margin: 15px 0px 0px 379px;
}

.assign-create-actions .assign-cancel-btn {
    color: #1B51A7;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin-right: 20px
}

.assign-create-actions .assign-create-btn {
    width: 80px;
    height: 32px;
    background: #1B51A7;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 4px;
    color: #fff;
    text-transform: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px
}

/* golden config */

.usageData-tabelRow .config-device-deploy {
    color: #1B51A7;
    font-weight: 600;
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
    cursor: pointer;
}

.config-deploy-inventory-popup {
    width: 45%;
    margin: auto;
    background: #FFFFFF;
    border-radius: 10px;
    background-color: #0000;
}

.config-deploy-inventory-popup .create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    height: 10px;
    color: #45464E;
    background-color: #f5f5f5 ;
}

.create-default-config-title img {
    width: 25px;
    cursor: pointer;
}

.deploy-inventory-content .config-select-site-deploy {
    display: flex;
    margin-top: 15px ;
    align-items: center;
    margin-bottom: -5px;
}

.select-site-check .input-text-char-check {
    color: red;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    margin-right: 75px;
    margin-top: 5px;
}

.deploy-inventory-content .select-site-check>div {
    display: flex;
    align-items: center;
    margin-top: 15px
}

.deploy-inventory-content  .select-site-check>div>span,
.deploy-inventory-content .config-select-site-deploy>span,
.deploy-inventory-content .config-apply-config-deploy>span,
.deploy-inventory-content .config-configuration-form-deploy>span {
    width: 40%;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.deploy-inventory-content .config-apply-config-deploy {
    display: flex;
    align-items: center;
}

.deploy-inventory-content #removeMarginBottom {
    margin-bottom: 0px;
}

.deploy-inventory-content .config-configuration-form-deploy {
    display: flex;
    margin-top: 15px;
    align-items: center;
}

.deploy-inventory-content .config-apply-config-deploy>div:nth-child(2) {
    margin-left: -8px;
}

.configuration-form-build span {
    color: #9A9A9A;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.deploy-inventory-content .config-create-actions {
    width: 100%;
    bottom: 0;
    right: 0;
    padding-right: 12px;
    margin-top: 20px;
}

.deploy-inventory-content .config-create-actions .config-cancel-btn {
    color: #1B51A7;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin-right: 20px;
    cursor: pointer;
    float: right;
}

.deploy-inventory-content  .config-create-actions .config-create-btn {
    width: 127px;
    height: 32px;
    background: #1B51A7;
    border-radius: 4px;
    color: #fff;
    text-transform: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px;
    border: 1px solid #676984;
    cursor: pointer;
    float: right;
}

.config-create-actions .config-create-btn:hover {
    background: #1B51A7;
}

.name-input-default-config .config-radio-button {
    margin: 10px 25px 0px -4px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.successDialog .inventory-success-popup {
    width: 436px;
}

.warehouse-apply-config-props {
    max-height: 150px;
    font-size: 12px;
}

.config-textField {
    width: 342px;
    font-size: 12px;
}

.config-apply-config-deploy>span {
    padding-top: 10px;
}
.deploy-inventory-content .selected-config-details>div{
    width: 59%;
}
.deploy-inventory-content .selected-config-details>div>div {
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #9A9A9A;
    margin-bottom: 5px;
}
.deploy-inventory-content .selected-config-details .value {
    color: #676984;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}

.new-summary-usage-tr > .text-center {
    text-align: center;
}

.MuiTextField-root.input-box-warehouse {
    margin: 8px;
    width: 100%;
    max-width: 300px;
    margin-left: 0px;
}
.inventoryWarehouse.register-icon{
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding-right: 5px;
    padding-bottom: 2px;
}

/* Edit Actions Popup */

.warehouse-edit-actions-content .device-border-right {
    margin-top: 5px;
}

.assignInventory-container .MuiDialogContent-root {
    padding: 0px;
}

.assignInventory-container .DPVesselName {
    width: 250px;
}

.config-configuration-form-deploy .warehouseChangeOrdDropdwn .DPvesselDropdown .MuiButtonBase-root {
    width: 242px;
}

.warehouseOrgDropdown .DPVesselName {
    width: 242px;
}

.assignInventory-container .deploy-inventory-content {
    overflow-x: hidden;
}

.assignInventory-container .deploy-inventory-content .config-create-actions {
    margin-left: -15px;
}

.inventory-edit-actions-container .warehouse-edit-actions-content .device-data-body {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    min-height: 375px;
}

.assignInventory-container .register-edit-actions-confirm-btns {
    width: 100%;
}

.register-edit-actions-confirm-btns .confirm-dialogue-new-divider {
    margin-bottom: 20px;
    margin-right: 10px;
}

.register-edit-actions-confirm-btns .confirm-dialogue-new-btns {
    display: flex;
    justify-content: end;
    margin-right: 10px;
    margin-bottom: 13px;
}

.assignInventory-container .MuiDialogContent-root {
    min-height: 296px;
}

.assignInventory-container .register-edit-actions-confirm-btns .confirm-dialogue-proceed-notAllow {
    color: rgb(38, 76, 134);
}

.register-inventory-content .edit-reg-radio-btn .config-radio-button, .selected-config-details .new-selected-config-details .margin-top-10px-reg, .register-inventory-content .select-site-check .margintop-10px {
    margin-top: 10px;
}

.greySettings {
    color: grey;
}
/* .buttonCssFleet {
    width: 33%;
    height: 38.56px;
    margin-top: 8px !important;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    text-transform: capitalize !important;
} */

.account-create-button {
  max-width: 159.95px;
  width: 100%;
  height: 37px;

  background-color: #264c86 !important;
  border-radius: 5px!important;
  color: #ffffff!important;
  text-transform: uppercase!important;

  font-style: normal!important;
  font-weight: bold!important;
  font-size: 10px!important;
  line-height: 12px!important;

  text-align: right!important;
  letter-spacing: 0.035em!important;
  margin-right: 10px !important;
}

.account-create-button-disable {
  max-width: 159.95px;
  width: 100%;
  height: 37px;
  border: 1px solid #264c86 !important;
  background-color: #e8f0fd !important;
  border-radius: 5px !important;
  color: #264c86 !important;
  text-transform: uppercase !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  line-height: 12px !important;

  text-align: right !important;
  letter-spacing: 0.035em !important;
  margin-right: 10px !important;
}

.DPvesselDropdown .create-DPVesselName {
    box-shadow: none;
}

.Form .create-DPvesselDropdown {
    margin-top: 5px;
    margin-left: 5px;
}

.childDP-create p {
    color: #6c6b85;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
}

.SearchbarTextField .createDP-serachBarWarehouse {
    width: 340px;
    padding-bottom: 3px;
    margin-left: -7px;
}

.SearchbarTextField .dp-assignSearchIcon {
  margin-top: 6px;
}

.clear-create-subDp-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 35px;
}

.enable-disable-two2fa-div {
  width: 100%;
  justify-content: flex-end;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 15px;
}

.clear-create-subDp-div .clearSubDP-button ,.enable-disable-two2fa-div .clearSubDP-button{
    width: 100px;
    background: #ffff; 
    color: #264c86;
    cursor: pointer;
    height: 35px;
    margin-left: 35px;
    border: 1px solid #264c86;
    font-size: 12px;
    font-weight: bold;
}

.clear-create-subDp-div .createSubDP-button {
    width: 100px;
    background: #2860B4; 
    color: #ffffff;
    cursor: pointer;
    height: 35px;
    margin-left: 5px;
    border: 1px solid #264c86;
    font-size: 12px;
    font-weight: bold;
}

.enable-disable-two2fa-div .twoFaaSubDP-button {
  background: #2860B4; 
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  margin-left: 5px;
  border: 1px solid #264c86;
  font-size: 12px;
  font-weight: bold;
}

.childDP-create .SearchbarTextFieldBox-create {
    margin-top: 0px;
}

.subdp-create-assignDialog {
  max-width: 500px;
  margin: auto;
}

.subdp-create-assignDialog>div:nth-child(1) {
  width: 100%;
}

.mainAssignActiontitle>div:nth-child(2) {
  margin-left: auto;
}

.tkt_new_ticket_dialogContent {
  align-items: center;
}

.cretesubDP .cursorPointer {
  position: absolute;
  right: 12px;
  font-size: 12px;
}

.OuMenuChild .createDp-checkIcon {
  color: #fff;
  background: #2860B4;
  font-size: 12px;
}

.dp-dropdown-dp-create {
  font-size: 14px;
}

.childDP-create-name p {
  margin-left: 0px;
}

.SearchbarTextFieldBox .Mui-error.MuiFormHelperText-sizeSmall{
  width: 300px;
}

.Form .Login-History-Page-iam {
  padding-left: 30px;
  padding-bottom: 112px;
  background: #FFFFFF;
  height: 100vh;
}

.notAuthorized .notAuthorizedPage {
  margin-top: 80px;
}

.pagination-main {
  margin: 10px 25px 0px 25px;
}

.Accounts-NavigationBtns {
  width: 100%;
  height: 48px;
  display: flex;
  margin-top: 100px;
  /* margin-left: 90px; */
  z-index: 1000;
  background: white;
  justify-content: space-between;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.Accounts-navbarBtns {
  margin-left: 21px;
  /* padding-top: 5px; */
}

.Accounts-NavigationBtns .usage-buttonCssFleet {
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
  border-bottom:  2px solid #2860B4;
  color: #2F4B82;
}

.Accounts-NavigationBtns .simAnalysis-buttonCssFleet {
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
  border-bottom: 2px solid #2860B4;
  color: #2F4B82;
}

.Accounts-NavigationBtns .dataUsage-buttonCssFleet {

  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
  border-bottom: 2px solid #2860B4;
  color: #2F4B82;
}

.Accounts-NavigationBtns .graphicalDataUsage-buttonCssFleet {
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
  border-bottom: 2px solid #2860B4;
  color: #2F4B82;
}

.Accounts-NavigationBtns .inActiveUsage-buttonCssFleet {
  border-bottom: #ffffff;
  color: #8B8D97;
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
}

.Accounts-NavigationBtns .inActiveSimAna-buttonCssFleet {
  border-bottom: #ffffff;
  color: #8B8D97;
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
}

.Accounts-NavigationBtns .inActiveDataUsage-buttonCssFleet {
  border-bottom: #ffffff;
  color: #8B8D97;
  width: 100px;
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
}

.Accounts-NavigationBtns .inActiveGrapDataUsage-buttonCssFleet {
  border-bottom: #ffffff;
  color: #8B8D97;
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
}

.Accounts-navbarBtns .Accounts-tab{
  padding-bottom: 5px;
}

.MuiTextField-root.input-box-accnt-mgnt {
  margin: 8px;
  width: 100%;
  max-width: 320px;
  margin-left: 22px;
  margin-top: 20px;
}

.MuiTextField-root.input-box-accnt-mgmts {
  margin: 8px;
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
}

.SearchbarTextFieldBox-create {
  margin-left: 10px;
}

.align-item-center {
  align-items: center;
}

.align-item-center .marg-l-auto {
  margin-left: auto;
}

.align-item-center .marg-r-20 {
  margin-right: 25px;
}

.subdp-create-assignDialog .edit-org-btns-div {
  padding-left: 45px;
  display: flex;
  justify-content: center;
}

.edit-org-btns-div button {
  font-size: 12px;
  text-align: center;
  height: 26px;
  box-shadow: 0 0 13px hsla(0, 0%, 79.6%, .35);
  border-radius: 1px;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;
}
  
.active-cteare-org-accounts {
  border: none;
  background: #fff;
  color: #0e0548;
  border-bottom: 2px solid #2860b4;
  width: 85px;
}

.inactive-create-org-accounts {
  border: none;
  color: #8b8d97;
  background: #f8f8f854;
  width: 85px;
}

.alignTopbarItemsCenter .ac-searchbar-padding-width {
  padding-left: 25px;
  max-width: 355px;
}
.config-container .reports-login-history {
    width: 100%;
    padding: 24px 10px 0px 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    margin: auto;
    margin-top: 20px;
}

.TopUsersConatiner .loginHistory-tableContainer {
    margin-top: -8px;
    margin-bottom: 20px;
    height: auto;
}

.reports-login-history>div:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.config-search-bar-text .login-history-search-bar {
    margin-top: -22px;
    height: 32px;
}

.login-left-align .login-history-dp-name {
    color: #8B8D97;
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    cursor: pointer;
}

.loginHistory-tableRow td {
    text-align: left;
    padding: 5px;
    padding-left: 15px;
}

.login-tableRow .login-tableCell {
    background: #f5f5f5;
}

.loginHistory-tableRow .login-left-align {
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    background: #FFFFFF;
}

.loginHistory-tableRow .selectedClick {
    cursor: pointer;
    color: #4c3aff;
}

.config-login-container .login-history {
    width: 100%;
    padding: 0px 10px 0px 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    margin: auto;
    margin-top: 20px;
}

.MuiTextField-root.input-box-login-hist {
    margin: 8px; 
    width: 100%; 
    max-width: 300px; 
    margin-left: 10px;
}
.loginHistoryWidget-container {
    padding-bottom: 5px;
}

.loginHistoryWidget-container>p {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.topWidget-loginHistory-card {
    height: 86px;
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
}

.topWidget-loginHistory-card .parent-card {
    width: 50%;
    height: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding-top: 38px;
}

.parent-card .tot_login, .parent-card .tot_logouts, .parent-card .fail_login, .parent-card .success_login {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.parent-card .login-top-widget {
    background: #FFF9E3;
}

.parent-card .tot_login {
    background: #FFF9E3;
}

.parent-card .tot_logouts {
    background: #FFE3FE;
}

.parent-card .fail_login {
    background: #FAE8E8;
}

.parent-card .success_login {
    background: #E2F8E4;
}

.parent-card>div:nth-child(1) img {
    margin: auto;
    width: 12px;
    height: 12px;
}

.parent-card>div:nth-child(2) {
    line-height: 0px;
}

.card-login-detaild>p:nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #45464E;
}

.card-login-detaild>p:nth-child(2) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #6E7079;
    letter-spacing: 1px;
    width: 150%;
}

.card-login-detaild>p:nth-child(2) {
    cursor: pointer;
    color: #4c3aff;
}
.FilterLeftContainer .login-history-filter-inputs {
    height: 80%;
}

.login-rc--filter_section .login-history-input {
    width: 242px;
}

.login-rc--filter_section>p {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    margin-top: -5px;
}

.accountActivityOptionsResponse {
    overflow-y: auto;
    height: calc(100vh - 423px);
    max-height: 402px;
}

.login-history-filter-inputs ::-webkit-scrollbar{
    width: 4px;
}

.login-history-filter-inputs ::-webkit-scrollbar-thumb{
    background: #ebebeb;
}

.login-history-filter-inputs ::-webkit-scrollbar-track{
    background: white;
    box-shadow: none;
}

.login-rc--filter_section .app-names-checkbox {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #45464E;
    padding: 0px 0px 8px 0px;
}

.app-names-checkbox .MuiSvgIcon-fontSizeSmall {
    width: 16px;
    height: 16px;
}

.app-names-checkbox .accountActivityFilteroptionsbox {
    height: 16px;
    padding: 0px 8px 0px 0px;
    margin: 0px 0px 4px 0px;
}

.app-names-checkbox .accountActivityFilteroptions {
    height: 16px;
    padding: 0px 8px 0px 0px;
    margin: 0px 0px 8px 0px;
}

.login-history-filter-inputs .login-filter-clearFilter {
    margin-top: -2px;
}

.optionHeadingPadding12 {
    margin-bottom: 12px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
}
.login-history-bar-chart-comp>div {
    height: 300px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    background: #fff;
    padding-top: 5px;
}

.login-history-bar-chart-comp .alignTopbarItemsCenter {
    height: 64px;
}

.login-history-bar-chart-comp>p {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.login-history-bar-chart-comp .login-legends {
    width: 45%;
    margin-top: 8px;
    display: flex;
    justify-content: center;
}

.login-history-bar-chart-comp .resetZoom-usage {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
}

.login-history-bar-chart-comp .login-resetZoom {
    height: 40px;
}
.LoginHistoryPage-Container {
    padding-right: 10px;
    margin-left: -10px;
    height: 600px;
    width: 100%;
    padding-top: 7px;
}

.LoginHistoryPage-Data {
    overflow-x: hidden;
    padding-right: 10px;
}

.loginHistory-filters-comp {
    margin-top: 0px;
    margin-bottom: 10px;
}

.rc--filter_menu_container_login .MuiPaper-root {
    width: 275px !important;
}

.accountsActivity-Filter {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
  }
.group-settings{
    overflow-x: auto;
    margin-left: 20px;
    margin-top: 5px; 
}
.group-settings-data{
    width: 50%;
    padding: 0px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #6E7079;
}
.group-settings-data > div{
    display: flex;
    border-bottom: 1px solid #EBEBEB;
    padding: 10px 5px 10px 5px;
}
.group-settings-data > div:last-child {
    border-bottom: 0px;
}
.group-settings-data > div .key{
    width: 50%;
    margin-right: 20px;
}
.group-settings-data > div .value{
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.key.logo{
    display: flex;
    align-items: center;
}
.value img {
    width: 100%;
}
.value.link a{
    text-decoration: none;
    cursor: pointer;
}

.MuiButtonBase-root.update-org-settings-button {
    max-width: 159.95px;
    width: 100%;
    height: 37px;
  
    background-color: #264c86 ;
    border-radius: 5px;
    color: #ffffff;
    text-transform: uppercase;
  
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
  
    text-align: right;
    letter-spacing: 0.035em;
    margin-right: 10px;
    margin-top: 20px;
}

.MuiButtonBase-root.update-org-settings-button:hover {  
    background-color: #264c86 ;
    color: #ffffff;
}

.MuiButtonBase-root.edit-org-settings-button {
    background-color: #264c86 ;
    color: #ffffff;
    max-width: 159.95px;
    width: 100%;
    height: 37px;
  
    border-radius: 5px;
    text-transform: uppercase;
  
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
  
    text-align: right;
    letter-spacing: 0.035em;
    margin-right: 10px;
    margin-top: 20px;
}

.MuiButtonBase-root.edit-org-settings-button:hover {  
    background-color: #264c86 ;
    color: #ffffff;
}

.MuiButtonBase-root.cancel-org-settings-button {
    color: #264c86 ;
    max-width: 159.95px;
    width: 100%;
    height: 37px;
  
    border-radius: 5px;
    text-transform: uppercase;
  
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
  
    text-align: right;
    letter-spacing: 0.035em;
    margin-right: 10px;
    margin-top: 20px;
}

.MuiFormControl-root.two-fa-timeout-text-field input{
    height: 12px;
    width: 45px;
    padding: 12px 0px 12px 12px;
}
.group-settings-data .notification{
    display: block;
    padding-top: 10px;
}
.group-settings-data .notification > div {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 5px 5px;
}
.group-settings-data .notification > div:last-child {
    padding: 10px 5px 0px 5px;
}
.group-settings-data > div:nth-last-child(2) {
   border-bottom: 1px solid black;
  }
.group-settings-data .notification .value{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
}
.group-settings-data .title {
    font-size: 16px;
    color: #45464e;
    font-weight: 600;
}
.group-settings-data .emailSignature {
    width: 100%;
}

.MuiFormControl-root.text_field_height input{
    height: 26px;
    padding-left: 5px;
}

.twofaTimeoutTooltip {
    position: relative;
    left: 90px;
    cursor: pointer;
}

.timeoutValueDiv{
    position: relative;
    top: -5px;
}

.org-settings-email-address-leftdiv {
    width: 53%;
}

.org-settings-email-address-leftdiv .email-error-msg {
   color: red;
   display: flex;
   width: 95%;
   font-size: 12px;
}

.align-div-items{
    display: flex;
    align-items: center;
}

.srvc_mgmt_table .tableThead, .srvc_mgmt_table .tableTbodyTd{
    font-weight: 500 !important;
    font-style: normal !important;
    /* font-weight: bold !important; */
    font-size: 12px !important;
    line-height: 14px !important;
    /* text-align: left !important; */
    /* padding: 14px 14px !important; */
    color: #273050 !important;
    opacity: 0.75;
}
.srvc_mgmt_table .tableThead{
    color: #CFD0DD !important;
    border-bottom: 0.5px solid #676984 !important;
}
.srvc_mgmt_table .tableTbodyTd{
    align-items: center !important;
    color: #676984 !important;
}
.srvc_mgmt_table .uppercaseText{
    text-transform: uppercase;
}
.srvc_mgmt_table td[rowspan] {
    /* vertical-align: top; */
    text-align: left;
}
.srvc_mgmt_table .borderBottom{
    border-bottom: 0.5px solid #CFD0DD !important; 
}
.srvc_mgmt_table .removeBorderBottom{
    border-bottom: 0px !important; 
}
.srvc_mgmt_table .plan-type {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    color: #676984;
}
.srvc_mgmt_table .plan-name{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    color: #676984;
}
.srvc_mgmt_paginationDiv{
    background-color: rgb(250, 249, 252);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    padding: 14px 20px 0px 0px;
}
.srvc_mgmt_paginationDiv > div{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #676984;
    align-items: center;
    display: flex;
    justify-content: center;
}
.srvc_mgmt_paginationDiv + div{
    margin-top: 0px !important;
}
.srvc_mgmt_paginationDiv .prevPage{
    margin-left:10px;
    margin-right:15px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.srvc_mgmt_paginationDiv .prevPageDisabled{
    margin-left:10px;
    margin-right:15px;
}
.srvc_mgmt_paginationDiv .nextPageDisabled{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.cursorPointer{
    cursor: pointer !important;
}
.srvc_mgmt_table .vessel-name {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-transform: capitalize !important;
    color: #273050 !important;
    /* opacity: 0.75 !important; */
}

.tableHeaderFleetVesselData > .tableHeaderBg{
    background-color: #2860b4;
}

.alignCenter {
    text-align: center;
}

@media only screen and (max-height: 400px){
  .serviceManagement-table>div {
    max-height: 195px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .serviceManagement-table>div {
    max-height: 255px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .serviceManagement-table>div {
    max-height: 305px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .serviceManagement-table>div {
    max-height: 355px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .serviceManagement-table>div {
    max-height: 405px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .serviceManagement-table>div {
    max-height: 455px;
    overflow-y: auto;
  }
}
  
  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .serviceManagement-table>div {
      max-height: 500px;
      overflow-y: auto;
    }
  }
  
   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .serviceManagement-table>div {
      max-height: 540px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .serviceManagement-table>div {
      max-height: 590px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .serviceManagement-table>div {
      max-height: 640px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .serviceManagement-table>div {
      max-height: 700px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .serviceManagement-table>div {
      max-height: 750px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:950px) and (max-height:999px){
    .serviceManagement-table>div {
      max-height: 800px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:999px) and (max-height:1050px){
    .serviceManagement-table>div {
      max-height: 850px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1050px) and (max-height:1080px){
    .serviceManagement-table>div {
      max-height: 900px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1080px) and (max-height:1140px){
    .serviceManagement-table>div {
      max-height: 930px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1140px) and (max-height:1200px){
    .serviceManagement-table>div {
      max-height: 990px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1200px) and (max-height:1300px){
    .serviceManagement-table>div {
      max-height: 1050px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1300px) and (max-height:1400px){
    .serviceManagement-table>div {
      max-height: 1140px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1400px) and (max-height:1920px){
    .serviceManagement-table>div {
      max-height: 1600px;
      overflow-y: auto;
    }
  }
body {
    overflow-y: hidden;
}

ul {
    list-style-type: none;
    padding: 2px;
  }

.simAnalysisHeader{
display: flex;
align-items: center;
width: 60%;
justify-content: space-around;
}

.deviceId{
    font-size: 14px;
}

.hide-overlap {
    z-index: 1;
}

.searchDevice{
    position: absolute;
    right: 12px;
    font-size: 12px;
}

.fontWeightBold{
    font-weight: bold !important;
}

.tableHeaderFleetVesselData .MuiTableCell-root.provider-padding{
    padding-left: 50px !important;
} 

.simStatusIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.marg5 {
    margin-left: 5px;
}

.tooltipBr {
    display: flex;
    flex-direction: column;
}

.fleetRowDataDetails .noLeftPadding {
    padding-left: 0px;
    padding-right: 30px;
}

.alertsTable-tableHead  .login-tableRow .align-left {
    text-align: left;
}

.alertsTable-tableBody .pl-15 {
    padding-left: 15px;
}

.fleetRowDataDetails .marg-right30 {
    margin-right: 30px;
}

.tableBody {
    z-index: 1;
  }
  
.tableHead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.noRecords {
    text-align: center;
    padding: 1rem 0;
    font-size: 15px;
    font-weight: 400;
    font-style: italic;
    color: black;
}

._provider {
    color: red;
  }

.filterAlertCss .css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 0 0 10px;
}

.filterAlertCss .css-1lambp5 .MuiAutocomplete-inputRoot {
    padding: 0 0 0 10px;
}

.bodyScroll .Form {
    margin-top: -15px;
}

/* Cellular Chart */

.Form .reports-cellular-chart {
    margin-top: -10px;
    margin-bottom: 10px;
}

.reportsHeader .cellular-lte-usage {
    width: 100px;
    margin-left: 25px;
    color: #2860b4;
    font-size: 24px;
    font-weight: 700;
}

.simAnalysisScroll {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
}

.noWrap {
    text-wrap: nowrap;
}

.cellularTableHeight {
    height: 72vh;
    overflow-y: auto;
}

.cellularData-tabelRow .cellularTable-tableCell {
    font-size: 12px;
    color: #45464E;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    padding: 0;
    font-weight: 500;
}

.cellular-tableRow .cellular-fleetDataTable {
    color: #8B8D97;
    background: #F5F5F5;
    border: 1px solid #EBEBEB;
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    -webkit-font-feature-settings: "smcp";
    font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    width: 10%;
    padding: 0;
}

.providerAlign {
    text-align: left;
    margin-left: 5px;
}

.menuScroll .cellular-table {
    height: calc(100vh - 300px);
}

.Form .cellular-charts {
    height: calc(100vh - 300px);
}

.MuiTextField-root.input-box {
    margin: 8px; 
    width: 100%; 
    max-width: 300px; 
    margin-left: 10px;
}

.sim-justyfy-space-between .sim-anal-csv {
    align-self: center;
    margin-left: auto;
}

.Cellular-Form .cellular-charts .cellular-no-data {
    width: 99%;
    height: calc(100vh - 327px);
}

.cellularSearchbarPadding {
    padding-left: 16px;
}
.verticalCenter{
    display: flex;
    align-items: 'center';
}

.usage_select{
    font-weight: 500;
    display: 'flex';
    align-items: 'center';
}

.usage_select_margin {
    margin-left: 45px;
}

.sel_padding{
    padding: 10px;
}


.exportIcon{
background-color: #2860b4;
margin: 4px;
border-radius: 4px;
padding: 6px 8px;
cursor: pointer;
color: white;
}

.exportUsage{
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #2860b4;
}

.container {
    max-height:'71vh';
    border-radius:'15px';
}

.d-flex{
    display: flex;
}

.align-center{
   align-items: center;
}

.justyfy-space-between{
    justify-content: space-between;
}

.dataUsage-justyfy-space-between {
    margin-top: 80px;
}

.justify-center{
    justify-content: center;
}

.usage_pagination{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.reportsDataHeader{
    width: 35%;
    display: flex;
    align-items: center;
}

.text-align-center{
    text-align: center;
}

.mt-2{
    margin-top: 10px;
}

.reportsTableContainer{
        max-height: 68vh;
        border-radius: 15px;  
        border-left: 1px solid rgba(20, 99, 172, 0.24);
        border-bottom: 1px solid rgba(20, 99, 172, 0.24);
}

.ml-10{
    margin-left: 10px;
}

.reportsTableContainer .reportsTableCell{
font-family:  "Roboto" ;
  font-weight: 500;
  font-size: 10px;
  color: #fff;
  background-color: #264C86;
  text-transform: uppercase;
}

.paperFont{
    font-size: 12px;
}

.reportsTableCell2{
    padding: 5px 9px;
    font-weight: 300;
    font-size: 0.9rem;
    color:black;
    /* border-left:  1px solid rgba(20, 99, 172, 0.24) !important; */
}

 .padLeft {
     padding-left: 10px;
 }

.selBackground{
    background-color: white;
}


.fleetRowRank {
    border-bottom: 0.5px solid #cfd0dd;
    font-weight: 700;
    font-size: 10px;
    color: #000000DE !important;
    height: 30px;
  }

  .fleetRowRank-odd {
    background-color: rgb(73, 132, 244, 0.05) !important;
    background-color: -moz-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -webkit-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -webkit-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -o-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -ms-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
  }

  .fleetRowRank-even {
    background-color: #ffffff !important;
  }

  .dataUsageHeader{
    height: 46px;
  }

  .fleetRowRank .fleetRowRank-dataUsage {
    font-size: 12px;
    color: #6c6b6b;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-weight: 500;
    padding: 10px 0px 10px 0px;
    align-items: center;
    border-bottom: 1px solid #c3d6e7;
  }

  .throttledQuota {
    color: #757575;
    font-size: 12px;
    font-weight: 600;
  }

  .fleetRowRank .activePlanSites {
    color: #81aef2;
    font-size: 13px;
    cursor: pointer;
  }

  .fleetRowRank .inActivePlanSites {
    color: #caa596;
    font-size: 13px;
    cursor: pointer;
  }

  @media only screen and (max-height: 600px) {
    .dataUsageScroll {
        max-height: 390px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 600px) and (max-height: 650px) {
    .dataUsageScroll {
        max-height: 440px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 650px) and (max-height: 690px) {
    .dataUsageScroll {
        max-height: 485px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 690px) and (max-height: 710px) {
    .dataUsageScroll {
        max-height: 530px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 710px) and (max-height: 740px) {
    .dataUsageScroll {
        max-height: 550px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 740px) and (max-height: 760px) {
    .dataUsageScroll {
        max-height: 580px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 760px) and (max-height: 790px) {
    .dataUsageScroll {
        max-height: 600px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 790px) and (max-height: 820px) {
    .dataUsageScroll {
        max-height: 630px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 820px) and (max-height: 849px) {
    .dataUsageScroll {
        max-height: 660px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 849px) and (max-height: 870px) {
    .dataUsageScroll {
        max-height: 690px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 870px) and (max-height: 899px) {
    .dataUsageScroll {
        max-height: 710px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:899px) and (max-height:950px) {
    .dataUsageScroll {
        max-height: 735px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:950px) and (max-height:970px) {
    .dataUsageScroll {
        max-height: 790px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:970px) and (max-height:999px) {
    .dataUsageScroll {
        max-height: 810px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:999px) and (max-height:1020px) {
    .dataUsageScroll {
        max-height: 835px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1020px) and (max-height:1050px) {
    .dataUsageScroll {
        max-height: 855px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1050px) and (max-height:1080px) {
    .dataUsageScroll {
        max-height: 880px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1080px) and (max-height:1100px) {
    .dataUsageScroll {
        max-height: 915px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1100px) and (max-height:1140px) {
    .dataUsageScroll {
        max-height: 930px;
        overflow-y: auto;
    }
}
@media only screen and (min-height:1140px) and (max-height:1160px) {
    .dataUsageScroll {
        max-height: 965px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1160px) and (max-height:1200px) {
    .dataUsageScroll {
        max-height: 990px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1200px) and (max-height:1230px) {
    .dataUsageScroll {
        max-height: 1030px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1230px) and (max-height:1260px) {
    .dataUsageScroll {
        max-height: 1059px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1260px) and (max-height:1300px) {
    .dataUsageScroll {
        max-height: 1090px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1300px) and (max-height:1330px) {
    .dataUsageScroll {
        max-height: 1130px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1330px) and (max-height:1360px) {
    .dataUsageScroll {
        max-height: 1160px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1360px) and (max-height:1400px) {
    .dataUsageScroll {
        max-height: 1190px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1400px) and (max-height:1920px) {
    .dataUsageScroll {
        max-height: 1220px;
        overflow-y: auto;
    }
}





.labelAboveSelect{
    font-weight: 500;
    display: flex;
    align-items: center;
}

.selectBox{
    background-color: #fff
}

.vesselBox{
    width: 100%;
    background-color : #fff
}

.exportIcon{
    background-color: #2860b4;
    margin: 4px;
    border-radius: 4px;
    padding: 6px 8px;
    cursor: pointer;
    }

.tabs .paper {

    padding: 1rem;
    cursor: pointer
  }

  .tabs .selectedPaper {
    border-bottom:4px solid #2860b4;
    color: #2860b4
  }

  .unSelectedPaper{
    transition: 0.2s all ease-in-out
  }

  .unSelectedPaper:hover{
    background-color: #dddddd;
  }



.text{
  font-size: 1.5rem;
  font-weight: 700 ;
}

.label{
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}

.d-flex{
    display: flex;
}

.align-center{
   align-items: center;
}

.justyfy-space-between{
    justify-content: space-between;
}

.justify-center{
    justify-content: center;
}

.tabs{
  margin: 0px 0px;
}
.shadow{
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-top: 15px;
}
.cellularMarginReset {
  margin-top: 0px;
}
.reportsHeader{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
}

.dropdownPadding{
  padding-left: 10px;
} 

.usage_select_margin {
  margin-left: 45px;
}

.graphical-justyfy-space-between {
  margin-top: 0px;
}

.innerScrollGDU .vessel-chart-cont {
  margin-right: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #fff;
  padding-bottom: 30px;
}

.vessel-chart-cont>div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 15px -10px 15px;
}

.vessel-chart-cont .vessel-chart-title {
  font-size: 14px;
  font-weight: 600;
  color: #6E7079;
  font-family: 'Inter';
  font-style: normal;
}

.vessel-chart-cont .vessel-chart {
  margin-top: 15px;
}

.Form .wan-usage-no-data {
  width: 100%;
  height: calc(100vh - 285px);
  border: 1px solid #EBEBEB;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  background: #fff;
  font-weight: bold;
  font-size: 15px;
  color: #303030;
  box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.tabs .paper {
  margin: 0px 0px 0px 0px;
  padding: 5px 0px 0px 0px;
}

.wan-usage-legend-container {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 0px 10px 0px;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  line-height: 10px;
}

.wan-usage-legend-container .wan-usage-legends {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;
}

.wan-usage-legends>div:nth-child(1) {
  width: 35px;
  height: 10px;
  background-color: #001a4d;
}

.wan-usage-legends .enable-usage-legends {
  font-weight: 500;
  color: #161250;
}

.wan-usage-legends .disable-wan-usage-legends {
  color: #aeaeb1;
  font-weight: 400;
}

.vessel-chart-usage-cont {
  width: 200px;
  background: #fff;
  padding: 10px 0px 10px 0px;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.vessel-chart-usage-cont>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 5px 15px;
}

.vessel-chart-usage-cont>div>span:nth-child(1) {
  font-size: 14px;
  font-weight: 600;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
}

.vessel-chart-usage-cont>div>span:nth-child(2) {
  font-size: 12px;
  font-weight: 600;
  color: #8B8D97;
  font-family: 'Inter';
  font-style: normal;
}

.wan-usage-legends .cursor {
  cursor: auto;
}

.innerScrollGDU {
  height: calc(100vh - 300px);
  overflow-y: auto;
  margin-top: 20px;
}

.graphical-justyfy-space-between .tabs {
  margin-left: 0px;
  margin-top: -8px;
}

.graphical-justyfy-space-between .tabs .top-widget-divs {
  padding: 8px 0px 9px 0px;
}

.reportsHeader .wan-usage-help {
  margin-left: 10px;
  cursor: pointer;
}

.custom-text-dialogue .MuiPaper-root {
  width: 600px;
  height: auto;
}

.custom-text-dialogue .text-msg-dialogue-title {
  background-color: #f5f5f5;
  color: #8B8D97;
  display: flex;
  justify-content: space-between;
}

.text-msg-dialogue .custom-text-dialogue .text-msg-dialogue-content {
  padding: 8px 16px;
}

.text-close {
  cursor: pointer;
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .top-widget-divs .d-flex {
    padding: 0px;
  }
  .innerScrollGDU {
    height: calc(100vh - 215px);
    overflow-y: auto;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1920px) {
  .top-widget-divs .d-flex {
    padding: 5px;
  }
}
.am-table .AppUsageContainer {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 8px;
}

.AppUsageContainer>div:nth-child(1) {
    height: 5px;
    background: white;
    padding:'0'
}

.TopApplicationData {
    height: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    margin: 20px 0px 0px 20px;
}

.AppUsageTableContainer .AppUsage-FleetDashboard {
    width: 100%;
    height: auto;
    border: 1px solid #EBEBEB;
}

.AppUsageTableContainer .AppUsage-FleetDashboard::-webkit-scrollbar {
    width: 6px;
}

.AppUsageTableContainer .AppUsage-FleetDashboard::-webkit-scrollbar-thumb {
    background-color: #EBEBEB !important;
}

.AppUsageTableContainer .AppUsage-FleetDashboard::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f5f5f5;
}

.usage-tableHead .appUsage-tableRow {
    height: 32px;
    border: 1px solid #EBEBEB;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 11;
}

.appUsage-tableRow .appUsage-fleetDataTable {
    width: 139px;
    height: 32px;
    border: 1px solid #EBEBEB;
    background: #F5F5F5;
    box-sizing: border-box;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #8B8D97;
    border: 1px solid #EBEBEB;
    padding: 5px 0px 5px 15px;
    letter-spacing: 1px;
}

.appUsage-tableRow .appUsage_fleetDataTable {
    width: 249px;
    height: 32px ;
    border: 1px solid #EBEBEB;
    background: #F5F5F5;
    box-sizing: border-box;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #8B8D97;
    border: 1px solid #EBEBEB;
    padding: 5px 0px 5px 15px;
    letter-spacing: 1px;
}

.appUsage-tableRow .activity-appUsage-fleetDataTable, .appUsage-tableRow .activity-appUsage_fleetDataTable {
    width: unset;
}

.tabelRow .appUsage-tableCell span {
    width: 139px;
    background: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    padding: 0;
    width: 100%;
}

.tabelRow .activity-appUsage-tableCell span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tabelRow .appUsage_tableCell {
    width: 249px;
    border: 1px solid #EBEBEB;
    background: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    padding: 0;
}

.usage-innerTableRow {
    height: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
}

.usage-innerTableRow .usage-innerTableCell, .usage-innerTableRow .usage_innerTableCell {
    border: none;
}

.usage-innerTableRow .activity-usage-innerTableCell, .usage-innerTableRow .activity-usage_innerTableCell {
    padding: 10px 0px 10px 5px;
}

.usage-innerTableRow .usage-innerTableCell>div{
    width: 105px ;
    height: 5px;
    border-radius: 24px;
}

.usage-innerTableRow .activity-usage-innerTableCell>div {
    width: unset;
}

.usage-innerTableRow .usage_innerTableCell span{
    width: 82px;
    height: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    display: flex;
}

.appUsage_tableCell .blockedHits {
    margin-left: 15px;
}

.appUsage-tableRow .activityTableCell-logo>div {
    display: flex;
    align-items: center;
}

.logo-activity {
    position: relative;
    width: 15px;
    height: 15px;
    padding-right: 5px;
}

.appUsage-tableRow td {
    padding: 10px;
    width: 50%;
}

.noData-appDetails .noDataAvailableCss {
    padding: 10px;
}

.usage-innerTableCell .MuiLinearProgress-root {
    height: 8px;
}

.totalUsageChartContainer {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    min-height: 271px;
    border-radius: 4px;
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-family: 'Inter';
}

.totalUsageChartContainer>div:nth-child(2) {
    width: 55%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity-totalUsageChartContainer>div:nth-child(2) {
    display: block;
    padding-top: 50px;
}

.totalUsageChartContainer>div:nth-child(2)::-webkit-scrollbar {
    width: 6px;
}

.totalUsageChartContainer>div:nth-child(2)::-webkit-scrollbar-thumb {
    background-color: #EBEBEB !important;
}

.totalUsageChartContainer>div:nth-child(2)::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f5f5f5;
}

.totalUsageChartContainer>div:nth-child(1) {
    width: 40%;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #45464E;
    margin: 20px 0px 20px 10px;
    padding-top: 15px;
}

.usage-doughnutChart {
    width: 146px;
    height: 146px;
    margin-left: -10px;
}

.chartContainer-usage {
    display: flex;
}

.usageDetailsWithCategory {
    margin-top: 10px;
    display: grid;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 10px;
}

.appcategoryUsed-usage {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 7px;
    display: flex;
}

.appcategoryUsed-usage>div:nth-child(2){
    width: 160px;
    height: 30px;
    margin-top: -12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    text-align: center;
}

.appcategoryUsed-usage>div:nth-child(2)>span {
    width: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: start;
}

.appcategoryUsed-usage>div:nth-child(2)>p {
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.activity-pieChart-app {
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;
    cursor: pointer;
    color: #0000ff;
}
 
.usagepercentage {
    width: 55px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
}

.usageDot-1 {
    width: 10px;
    height: 10px;
    background: #00AD64;
    border-radius: 100px;
    margin-top: -2px;
    margin-right: 5px;
}

.rc--filter_search_categoryCont {
    position: relative;
}

.rc--filter_search_category {
    position: absolute;
    z-index: 1;
    width: 218px;
    height: 88px;
    background: white;
    border-radius: 4px;
    margin: 2px 0px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.usageTitleOverTime {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #45464E;
    width: 186px;
    height: 18px;
    margin: 10px 16px 0px 16px;
}

.selectTop5AppsBtn {
    width: 202px;
    height: 34px;
    background: #F4F5FA;
    border-radius: 8px;
    margin: 8px 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2F4B82;
    border: none;
    text-align: left;
    padding-left: 8px;
    cursor: pointer;
}

.rc--filter_header_appCategory {
    width: 170px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #45464E;
}

.rc--filter_header_appCategory .icon-entryLimit {
    margin-left: 13px;
}

.piechart-selectContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rc--filter_pie-usage {
    margin-top: -20px;
}

.pieChat-selectTag .selectEntry-pie {
    min-width: 40px;
    margin-left: 15px;
}

.appcategoryUsed-usage .pieChart-activity {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pieChart-activity>img {
    width: 15px;
    height: 15px;
}

.totalUsageChartContainer .usage-activity-pie {
    margin-left: -15px;
}

.activity-Form, .activity-Form .am-table, .am-table .activity-totalUsageChartContainer {
    height: 100%;
}

#activity-pie-container {
    display: block;
}

#activity-pie-container-1 {
    width: 100%;
}

#activity-pie-container-2 {
    width: 100%;
    padding-top: 0px;
}

#activity-appcategoryUsed-usage {
    width: 200px;
}

@media only screen and (max-width: 1500px) {
    .activity-totalUsageChartContainer {
        display: block;
    }
    .activity-totalUsageChartContainer>div:nth-child(1) {
        width: 100%;
    }
    .activity-totalUsageChartContainer>div:nth-child(2) {
        width: 100%;
    }
    .activity-Form .appcategoryUsed-usage>div:nth-child(2) {
        width: 200px;
    }
    .activity-totalUsageChartContainer>div:nth-child(2) {
        padding-top: 0px;
    }
}


.header-name {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 600;
}

.rule-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #6E7079;
}

.firewall-filters .firewall-stack-value {
    margin-bottom: 10px;
}

.rule-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.packet-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.doughnut-graph-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #45464E;
}

.margin-b-10 {
    margin-bottom: 10px;
    margin-top: 15px;
}

.margin-t-15 {
    margin-top: 15px;
}

.padding-15 {
    padding: 15px;
}

.firewall-table-data .firewall-table-cell {
    font-size: 12px;
    color: #45464E;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    /* border: 1px solid #EBEBEB; */
    padding: 10px 5px 10px 5px;
}

.firewall-tableRow .firewall-table-data {
    color: #8B8D97;
    border: 1px solid #EBEBEB;
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    padding: 0;
}

.firewall-table-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.table-head-parent {
    margin: 15px 0px;
}

.table-head-parent .firewall-add-rule {
    float: right;
    height: 25px;
    width: 100px;
    background-color: #1B51A7;
    font-size: 12;
    border-radius: 4px;
    text-transform: none;
    margin: 15px 0px;
}

div.usage-doughnutChart {
    width: unset;
    height: unset;
    margin-left: 0;
}

.widgetContainer {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-family: 'Inter';
    align-items: center;
}

.padding-filters {
    padding-left: 8px;
}

.firewalLineChartContainer {
    width: 100%;
    padding: 20px 10px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
    font-family: inter;
    font-style: normal;
}

div.graph-totalUsageTitle {
    padding-top: unset;
}

.outer-datepicker {
    padding: 0px 10px;
}

.filter-reset-pos {
    display: flex;
    justify-content: end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
}

.list-box-props {
    max-height: 200px;
    font-size: 13px;
}

.chip-margin {
    margin-left: 10px;
}

.filrewall-date-filter-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}

.deploy-inventory-content .fw-actions {
    margin-top: 0px;
}

.firewall-filters .rule-value, .firewalLineChartContainer .line-cardFilter, .firewall-table-data .table-cardFilter {
    color: #0f09ff;
    cursor: pointer;
}

.margin-t-15 .firewall-table-pagination .usage-pagination {
    min-height: 40px;
    margin-bottom: 0px;
}

.firewallReports-Filter .firewall-container-height {
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
}

.topSites-download .firewall-table-download {
    display: flex;
    margin-top: 15px;
}

.filter-reset-pos #select-bin-remove-margin {
    margin-right: 0px;
}

.filter-reset-pos #select-bin-add-margin {
    margin-right: 10px;
}

.margin-left-60 {
    margin-left: 60px;
}

.deploy-inventory-content .fw-actions .select-action-warning {
    color: red;
    font-size: 11px;
    width: auto;
}
.tab-container:has(.rc--container) {
    margin: 0 !important;
    margin-top: 10px !important;
}

.padding-l-r-15 {
    padding: 0px 20px;
}

.rc--container {
    background: #F5F5F5;
}

.rc--container {
    display: flex;
    flex-wrap: wrap ;
}

@media only screen and (max-width: 760px) {
    .rc--container {
        max-height: calc(92vh - 270px);
    }
}

.rc--content_containers {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    display: flex;
    justify-content: center;
}

.rc--links_container {
    height: 100%;
    flex-grow: 8.5;
    flex-shrink: 17;
    flex-basis: 0em;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
}

.rc--filter_container {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;

    flex-grow: 2.5;
    flex-shrink: 1;
    flex-basis: 0em;
    align-items: stretch;

    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}

.rc--filter_section {
    border-bottom: 1px solid #EBEBEB;
    padding: 16px;
}

.rc--filter_header {
    display: flex;
    align-items: center;
}

.rc--content_headers {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #45464E;
}

.rc--content_sub_headers {
    font-weight: 500;
    font-size: 14px;
    line-height: 0.95em;
    color: #45464E;
}

.rc--content_sub_headers_filter {
    font-weight: 600;
    font-size: 12px;
    line-height: 0.95em;
    color: #45464E;
    font-family: 'Inter';
}

.rc--links {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #0E0548;
    cursor: pointer;
}

.rc--filter_search_container {
    padding-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 2.5%;
    gap: 2.5%
}

/* .rc--filter_search_container>div {
    margin: 2% 0%;
} */

.rc--filter_search {
    display: flex;
    position: relative;
}

.rc--filter_search_input {
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding: 1em 0.5em;

    width: 100%;

    font-weight: 400;
    font-size: 0.9em;
    line-height: 0.9em;
}

.rc--filter_search_input_icon {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
}

.rc--filter_search_input_icon:hover{
    cursor: pointer;
}

.rc--filter_search_chip_container {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5em 0em;
    grid-gap: 0.5em;
    gap: 0.5em;

}

.rc--filter_search_chip.MuiChip-root {
    background: #F4F5FA;
    margin: 0.2rem;
}

.rc--filter_search_chip .MuiSvgIcon-root.MuiChip-deleteIcon {
    color: #B3B9D8;
}

.rc--filter_search_chip .MuiChip-label {
    font-weight: 500;
    font-size: 1.1em;
    line-height: 0.9em;
    color: #2F4B82;
}

.rc--form-control input[type="checkbox"] {
    accent-color: #2F4B82;
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    margin-right: 1em;
}

.rc--filter_result_count {
    font-weight: 500;
    font-size: 0.9em;
    line-height: 1.25em;
    color: #6E7079;
    margin-bottom: 0.5em;
}

.rc--scanned_devices_tab_container {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #EBEBEB;
    font-family: 'Inter';
}

.rc--generated_links_container {
    margin: 10px;
}

.rc--links_container_header {
    padding: 1em;
}

.rc--sd_tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
    margin: 0.5em 0em;
    word-break: break-all;
}

.rc--sd_tab:hover, .rc--generate_link_button:hover {
    background: #F4F5FA;
    cursor: pointer;
}

.rc--sd_tab_selected {
    background: #F4F5FA;
    color: #2F4B82;
}

.rc--generated_links_topbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid #EBEBEB;
}

.rc--generated_links_topbar--meta {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    justify-content: center;
}

.rc--generated_links_topbar--meta .rc--content_sub_headers,
.rc--generated_links_topbar--meta .rc--content_headers {
    margin: 0.5em 0em;
    flex-grow: 1;
    flex-shrink: 1;
}

.rc--generated_links_topbar--meta .rc--content_sub_headers {
    font-weight: unset;
}

.rc--content_sub_headers_type {
    font-weight: 400;
    font-size: 12px;
    line-height: 0.95em;
    color: #45464E;
    font-family: 'Inter';
}

.rc--content_sub_headers_filters {
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    color: #45464E;
    font-family: 'Inter';
}

.rm-conn-scroll .remoteConnectionsFilterGenerateLinkBotton {
    padding: 7.825px;
}
.filter-clearFilter {
    display: flex;
    /* justify-content: space-between; */
    padding: 10px 16px 18px 16px;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
}

.Filters_Title {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #45464E;
    display: flex;
    align-items: center;
}

.clearFiletrBtnDiv {
    border: none;
    display: flex;
    align-items: center;
}

.clearFiletrBtnDiv .clearFiletrBtnUsage {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    text-transform: none ;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
    min-width: 78px;
}

.clearApplyFilters {
    display: flex;
    padding: 10px 16px 12px 16px;
}

.clearApplyFilters .usage-applyFilterBtn {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-transform: none;
    color: white;
    background-color: #1B51A7;
    width: 103px;
    height: 29px;
    left: 62px;
}

.clearApplyFilters .usage-applyFilterBtn:hover {
    background-color: #1B51A7;
}
.clearApplyFilters .clearFiltersButton {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    text-transform: none;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
    margin-left: 0px;
    justify-content: left;
}

.dash-filter-pop .firewallFilterOptions {
    border-bottom: 1px solid #EBEBEB;
    padding: 16px 16px 8px 16px;
}

.firewallFilterOptions .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
}

.firewallFilterOptions .MuiFormControlLabel-label {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #45464E;
}

.firewallFilterOptions .MuiFormControlLabel-labelPlacementEnd {
    height: 16px;
    padding-bottom: 8px;
}

.clearFiletrBtnDiv .margin-l-115 {
    margin-left: 115px;
}

.clearFiletrBtnDiv .margin-left-80 {
    margin-left: 0px;
}

.Usage-dateRangeSelection {
    font-size: 12px;
}

.Usage-dateRangeSelection>div {
    margin-bottom: 5px;
}

.Usage-dateRangeDiv {
    margin-top: 5px;
}

.Usage-dateRangeDiv .dateRangeInputField {
    width: 220px;
}

.Usage-dateRangeDiv .dateRangeInputTypography {
    font-size: 12px;
}

.Usage-dateRangelabel {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #6E7079;
}

.toDate-filter {
    display: flex;
    justify-content: flex-start;
}

.rc--filter_search_container .userName-usageData {
    height: 34px;
    border-radius: 4px;
}

.rc--filter_search_input-usage::-webkit-input-placeholder {
    font-size: 13px;
    font-weight: 400;
    color: #6E7079;
}

.rc--filter_search_input-usage::placeholder {
    font-size: 13px;
    font-weight: 400;
    color: #6E7079;
}

.FilterLeftContainer>div:nth-child(1) {
    overflow-y: auto;
}

.FilterLeftContainer>div:nth-child(1)::-webkit-scrollbar {
    width: 6px;
}

.am-table .FilterLeftContainer {
    width: 95%;
    background: white;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    margin: 24px 0px 0px 15px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column ;
    height: calc(100vh - 200px);
    font-family: 'Inter';
    font-style: normal;
}

.am-table .dash-filter-pop {
    margin: 0px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column ;
    font-family: 'Inter';
    font-style: normal;
}

.FilterLeftContainer>.usage-applyFilterBtn {
    text-transform: none;
    background-color: #2F4B82;
    border-radius: 4px;
    margin: auto 15px 15px 15px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.FilterLeftContainer>.usage-applyFilterBtn:hover {
    background-color: #2F4B82;
}

.dash-filter-pop>.usage-applyFilterBtn {
    text-transform: none;
    background-color: #1B57A7;
    border-radius: 4px;
    margin-right: 0px;
    margin-left: 60px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: white;
    width: 103px;
    height: 29px;
    float: right;
}

.dash-filter-pop>.usage-applyFilterBtn:hover {
    background-color: #2F4B82;
}

.rc--content_sub_headers-select {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    color: #45464E;
}

.audit-filter-inputs-usage {
    width: 242px;
}

.userDataContainer {
    background-color: #FFFFFF;
    width: 100%;
    height: 85vh;
    display: flex;
    height: calc(100vh - 135px);
    margin-top: -20px;
}

.userDataContainer .usageFilterComponent {
    width: 18%;
}
.userDataContainer .usageDataComponent {
  width: 100%;
}

.activityReports-Filter {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.rc--filter_menu_container_reports .MuiPaper-root {
  width: 274px;
  margin-left: 120px;
  margin-top: 10px;
  overflow-y: hidden;
  box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
}

.sitesFilterHeaderMargin {
  display: flex;
  margin: 10px 16px 18px 16px;
}

.activityReportsFilter-close {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  margin-left: 174px;
  cursor: pointer;
}

.activityReportsFilter-close>img {
  margin-left: 173px;
  cursor: pointer;
}

.activityReportsFilter-close>div:nth-child(2) {
  font-size: 15px;
  margin-top: 3px;
  font-family: 'Inter';
  font-style: normal;
}

.outer-selectDaterangeFilter {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  margin-left: 100px;
  margin-top: -39px;
}

.filter-dateRange-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.download-reports-btn {
  width: 168px;
  height: 32px;
  background: #2860B4;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px 0px 0px;
  margin-right: 10px;
}

.download-reports-btn button {
  width: 168px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: none;
  margin-left: 5px;
}

.download-reports-btn img {
  width: 25px;
  height: 15px;
  margin-right: 5px;
}

.download-reports-container {
  display: flex;
  justify-content: flex-end;
}

/* Filters Popup */

.selectedDeviceDetails .generate-report-title>span:nth-child(1) {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #45464E;
  opacity: 0.75;
}

.selectedDeviceDetails .generate-report-title>span:nth-child(2) {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6E7079;
}

.list-filtered-filter {
  color: #8B8D97;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.deploy-inventory-content .suggested-filters {
  margin-bottom: 35px;
}

.list-filtered-filter .select-site-input {
  width: 315px;
  margin-bottom: 7px;
}

.DPvesselDropdown .dp-dropdown-activity-filters {
  width: 315px;
}

.extand-width .config-textField {
  width: 315px;
}

.activity-filters-popup-dialogue .css-ypiqx9-MuiDialogContent-root {
  padding: 20px 0px 20px 24px;
  overflow: hidden;
}

#dpDropdownMenu-ou {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  margin: 0px;
}

#dpDropdownMenu-ou .MuiPaper-root{
  width: 315px;
  max-height: 200px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: none;
  border: 1px solid #c3d6e7;
}

.deploy-inventory-content .DPI-checkbox {
  margin: 0px 0px 35px 0px;
}

.siteCharts__filters-date-time-picker .date-range-activity {
  width: 170px;
}

/* Reports */

.deploy-inventory-content .check-interval-text:nth-child(1) {
  color: #6E7079;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 20px 0px 75px 0px;
}

.deploy-inventory-content .check-interval-text>span:nth-child(1) {
  margin-bottom: 35px;
}

.check-interval-text .continue-generate {
  color: #45464E;
  opacity: 0.75;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
.am-table .userDataRightContainer {
    width: 100%;
    height: calc(100vh - 225px);
    border-radius: 8px;
    overflow-y: auto;
    margin-top: 24px;
    overflow-x: hidden;
}

.overview_card {
  display: flex;
}

.Reports-overView {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0;
    color: #45464E;
}

.Reports-Usage {
    position: relative;
    height: 19px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 24px 0px 16px 0px;
    color: #45464E;
}

.UsageContainer-overTime {
    display: flex;
}

.Reports-overView-topUsers {
    margin: 0 0 0 18px;
    margin: 24px 0px 16px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #45464E;
}

.userDataRightContainer .Usage-TableComponent {
    margin-left: 4px;
    margin-right: 5px;
    /* height: 560px; */
}

.TopUsersList-Reports {
    height: auto;
    width: 1072px;
    margin: 0px 0px 0px 9px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.TopUsersList-Reports .TopUsersConatiner {
    width: 100%;
    height: auto;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    background: white;
}

.TopUsersConatiner .Reports-graph_table{
    display: flex;
    justify-content: space-between;
}

.IPAddressList>div:nth-child(1) {
    margin: 31px 0px 59px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
}

.exportBtnClick {
    border: 1px solid #2F4B82;
    border-radius: 4px;
    width: 113px;
    height: 30px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 24px;
    margin: 16px;
}

.exportBtnClick .exportBtnClick-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 11px ;
    line-height: 15px;
    color: #2F4B82;
    margin-left: 12px;
}

.Reports-graph_table>span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    margin: 24px 16px 16px 16px;
}

.usageReports-FleetDashboard {
    width: 100%;
    height: auto;
    overflow-y: auto;
    border: 1px solid #EBEBEB;
}

.usageReports-FleetDashboard::-webkit-scrollbar {
    width: 6px;
}

.usageReports-tableHead .UsageReports-tableRow {
    background: #f5f5f5 ;
    height: 35px;
    border: 1px solid #EBEBEB ;
    box-sizing: border-box;
    margin: 0px;
    position: -webkit-sticky;
    position: sticky;
    top: -0.5px;
    z-index: 11;
}

.UsageReports-tableRow .usage-fleetDataTable {
    color: #8B8D97;
    /* border: 1px solid #EBEBEB; */
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    width: 11%;
    padding: 0;
}

.UsageReports-tableRow .user-fleetDataTable {
    width: 16%;
    height: 35px;
    border: 1px solid #EBEBEB;
    text-align: center;
    color: #8B8D97;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 1px;
}

.UsageReports-tableRow .userName-fleetDataTable {
    width: 15%;
}

.UsageReports-tableRow .total-fleetDataTable {
    width: 6%;
}

.UsageReports-tableRow .ip_port-fleetDataTable {
    width: 8%;
}

.UsageReports-tableRow .vlan-fleetDataTable {
    width: 10%;
}

.UsageReports-tableRow .topApp-sitestable {
    width: 6%;
}

.UsageReports-tableRow .topApp-sitestable-user {
    width: 8%;
}

.usageData-tabelRow .usagedata-tableCell{
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    padding: 5px;
}

.usage-tableBody .usageData-tabelRow td {
    padding: 5px ;
}

.userDataRightContainer .UsageLineChartComponent {
    margin: 24px 10px 0px 0px;
    width: 98%;
    padding: 0;
}

.userDataRightContainer .Usage-AppUsageComponent {
    margin: 24px 0px 0px 16px;
}

.Usage-AppUsageComponent .appUsageParent {
    width: 100%;
    margin-left: -2px;
    height: 407px;
}

.TopUsersConatiner .usage-pagination {
    padding-bottom: 15px ;
    border-top: 1px solid #EBEBEB;
}

.usageDetails-cards {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 24px 10px 10px 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.usage-selectedTabs {
    margin: 13px 20px;
    display: flex;
    justify-content: space-between;
}

.usage-selectedTabs .usageActiveAppClass {
    width: 135px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-selectedTabs .usageInactiveAppClass {
    width: 135px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.usage-selectedTabs .usageActiveDomainClass {
    width: 115px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-selectedTabs .usageInactiveDomainClass {
    width: 115px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.usage-selectedTabs .usageActiveIpClass {
    width: 135px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-selectedTabs .usageInactiveIpClass {
    width: 135px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.usage-selectedTabs .usageActiveCFClass {
    width: 135px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-selectedTabs .usageInactiveCFClass {
    width: 135px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.cardsParentComp {
    margin: 24px 20px;
}

.appUsageParent p {
    margin-top: -1px;
    margin-bottom: 3px;
    width: 332.33px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #8B8D97;
}

.selectUserTag-bottomTable .options-bottomTable {
    min-width: 70px;
}

.usageData-tabelRow .selected-site {
    cursor: pointer;
    color: #0000ff;
}

.usageData-tabelRow .siteName-tableCell {
    width: 16%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.UsageReports-tableRow .topApp-icons-sitestable {
    width: 5%;
}

.UsageReports-tableRow .site-fleetDataTable {
    width: 15%;
}

.usageData-tabelRow .tableSites-topIcons {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    width: 85%;
    margin: auto;
}

.usageData-tabelRow  .css-1ex1afd-MuiTableCell-root {
    padding: 5px;
}

.pieChart-entryLimit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.TopUsersConatiner .activity-usage {
    margin-bottom: 0px;
}

.tableSites-topIcons .activity-logos {
    width: 18px;
}

.Usage-TableComponent .topSites-download {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.align-text-center {
    align-self: center;
}

.marg-left-auto {
    margin-left: auto;
}

.risk-score-container .MuiDialog-paperWidthMd {
    width: 350px;
}

.risk-score-container .risk-score-title {
    width: 300px;
}

.risk-score-container .risk-score-content {
    margin-top: 15px;
    line-height: 17px;
}

.risk-score-container .remove-margin-top {
    margin-top: 20px;
}

.risk-score-container .table-risk-list {
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
}

.risk-score-content .risk-list-loading {
    margin-top: 20px;
}

.usageData-tabelRow .risk-score {
    cursor: pointer;
    color: #0000ff;
}

.usage-cardmain .widget-risk-tooltip {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.widget-risk-tooltip>div {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
}

.UsageReports-tableRow .add-cursor-threat {
    cursor: pointer;
}

.topBlockedUsage .widget-risk-score-number:hover {
    color: #0f09ff;
    cursor: pointer;
}
 
.risk-score-card .reports-totalDetails .netify-cardFilter {
    color: #0f09ff;
    cursor: pointer;
}

.topBlockedUsage .riskScore-riskLevel {
    font-weight: 700;
    font-size: 14px;
}

.activity-can-user-info {
    font-size: 10px;
    margin: 5px 0px 0px 5px;
    color: #45464E;
}

.Usage-TableComponent .usageData-tabelRow .usagedata-tableCell {
    text-align: left;
    padding-left: 16px;
}

.Usage-TableComponent .font-wt-900 {
    text-align: left;
    padding-left: 16px;
}

.Usage-TableComponent .usageReports-tableHead .font-10px {
    font-size: 10px;
    font-weight: 600;
}

.TopUsersList-Reports .width-13-per {
    width: 13%;
}

.users-activity-table .new-summary-left {
    width: 7%;
}

.usageReports-tableHead .user-device {
    width: 23%;
}
.usageOverTimeMainContainer {
    width: 100%;
    height: auto;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    font-family: 'Inter';
    font-style: normal;
}

.usageOverTimeContainer {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}

.usageOverTimeContainer>div:nth-child(1) {
    margin: 19px 0px 15px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #45464E;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.usageDetails-color{
    width: 20%;
    display: flex;
    grid-gap: 3%;
    gap: 3%;
    margin: auto;
    align-items: center;
}

.usageDetails-color>div {
    display: flex;
    width: 33%;
    height: 35px;
    font-size: 11px;
    text-align: center;
}

.totalUsageColor {
    background: #3C7DD9;
    width: 8px;
    height: 8px;
    margin: 7px 4px 0px 0px;
    border-radius: 20px;
}

.name-color .lineChart-legends {
    text-align: center;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 10px;
    color: #0e0548;
    padding-top: 6px;
    cursor: pointer;
}

.totalincomingColor {
    background: #C1D5F6;
    width: 9px;
    height: 9px;
    margin: 7px 4px 0px -9px;
    border-radius: 20px;
}

.totalOutgoingColor {
    background: #2039B5;
    width: 9px;
    height: 9px;
    margin: 7px 4px 0px 0px;
    border-radius: 20px;
}

.usage-lineChartTabs {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
}

.usage-lineChartTabs .usageActiveClass {
    width: 105px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-lineChartTabs .usageInactiveClass {
    width: 105px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.usage-lineChartTabs .topUsageActiveClass {
    width: 150px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-lineChartTabs .topUsageInactiveClass {
    width: 150px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.topApplicationNames {
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
    margin:auto;
    padding-bottom: 15px;
}

.name-color {
    display: flex;
}

.usageOverTimeContainer .resetZoom-usage {
   width: 360px;
   margin: 10px 15px 0px 0px;
   display: flex;
   justify-content: flex-end;
}

.resetZoom-usage .resetZoomBtn-usage {
    color: #8B8D97;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #8B8D97;
    border-radius: 4px;
    height: 30px;
    width: 110px;
}

.resetZoom-usage .entryLimit-lineChart {
    margin-left: 25px;
}

.usageOverTimeContainer .capsule-lineChart {
    min-width: 110px;
    position: relative;
    top: -8px;
    left: 20px;
}

.capsule-lineChart .title-capsule-line {
    display: flex;
    justify-content: space-between;
    height: 23px;
}

.name-color .disable-lineChart-legends {
    color: #a2a2a6;
    text-align: center;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    padding-top: 6px;
    cursor: pointer;
}
.Form .usage-cardmain {
    display: flex;
}

.Reprts-CardContainer {
    width: 100%;
    height: 115px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.total-logoDiv {
    display: flex;
    justify-content: space-between;
    height: 50%;
    padding-left: 10px;
}

.reports-totalDetails {
    line-height: 55px;
}

.reports-totalDetails>div:nth-child(1) {
    position: relative;
    height: 21px;
    left: 0px;
    top: 11px;
    color: #6E7079;;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 21px;
    letter-spacing: 1px;
}

.reports-totalDetails>div:nth-child(2) {
    position: relative;
    width: 160px;
    height: 21px;
    left: 0px;
    top: 10px;
    color: #45464E;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    font-family: 'Roboto Mono';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reports-propsLogo {
    position: relative;
    width: 28px;
    height: 28px;
    right: 16.25px;
    top: 17px;
    background: rgba(172, 85, 241, 0.08);
    border-radius: 50%;
    display: flex;
   justify-content: center;
}

.reports-propsLogo-total {
    background: #FFF9E3;
}

.reports-propsLogo-site {  
    background: #F1F4FE;
}

.reports-propsLogo-user {
    background: #F1F9FE;
}

.reports-propsLogo-app {
    background: #FFF3E8;
}

.reports-propsLogo-blocked {
    background: #fef3f3;
}

.reports-propsLogo-total>img, .reports-propsLogo-site>img, .reports-propsLogo-user>img, .reports-propsLogo-app>img, .reports-propsLogo-blocked>img {
    margin: auto;
    width: 13px;
    height: 13px;
}

.commonGBClass {
    position: relative;
    width: 13px;
    height: 13px;
    color: #6E7079;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
}

.reports-up_down-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50%;
    padding-left: 10px;
    margin-top: 15px;
    padding-right: 10px;
}

.upArrow-details {
    display: flex;
}

.upArrow-details>div:nth-child(2) {
    font-style: normal;
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #45464E;
    margin-left: 3px;
    letter-spacing: 1px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.statusTitle_up {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #45464E;
}

.topBlockedUsage {
    margin: 14px 12px 10px 12px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #45464E;
}

.topBlockedUsage>div {
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #6E7079;
    margin-bottom: 2px;
}

.reports-totalDetails .activity-cardFilter {
    color: #0f09ff;
    cursor: pointer;
}

@media only screen and (max-width: 1260px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 6px;
    }
}

@media only screen and (min-width: 1260px) and (max-width: 1310px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 7px;
    }
}

@media only screen and (min-width: 1310px) and (max-width: 1385px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 8px;
    }
}

@media only screen and (min-width: 1385px) and (max-width: 1470px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 9px;
    }
}

@media only screen and (min-width: 1470px) and (max-width: 1550px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 10px;
    }
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 11px;
    }
}

@media only screen and (min-width: 1650px) and (max-width: 1750px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 12px;
    }
}
.Reports-NavigationBtns {
    width: 100%;
    height: 50px;
    display: flex;
    margin-top: 95px;
    /* margin-left: 90px; */
    background: white;
    justify-content: space-between;
}

.Reports-navbarBtns {
    padding-left: 21px;
    padding-top: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    width: 100%;
}

.inventory-navbarBtns {
    padding-left: 15px;
    padding-top: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    width: 100%;
}

.sw-navbarBtns {
    padding-left: 15px;
    padding-top: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    width: 100%;
}

.Reports-NavigationBtns .usage-buttonCssFleet {
    padding-top: 15px;
    border-radius: 0px;
    text-transform: none;
    border-bottom:  2px solid #1976d2;
    color: #1976d2;
    padding-bottom: 4px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .simAnalysis-buttonCssFleet {
    padding-top: 13px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #1976d2;
    color: #1976d2;
    padding-bottom: 4px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .dataUsage-buttonCssFleet {
    padding-top: 13px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #1976d2;
    color: #1976d2;
    padding-bottom: 4px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .graphicalDataUsage-buttonCssFleet {
    padding-top: 14px;
    border-radius: 0px;
    text-transform: uppercase;
    border-bottom: 2px solid #1976d2;
    color: #1976d2;
    padding-bottom: 5px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .inActiveUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 14px;
    border-radius: 0px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .inActiveSimAna-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .inActiveDataUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    width: 100px;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .inActiveGrapDataUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: uppercase;
}

.ReportPagesContainer {
    background-color: white;
    height:100vh;
}

.ReportPagesContainer>div{
    width: 100%;
}

.ReportPagesContainer>div>div {
    width: 100%;
    padding: 10px 0px 0px 0px;
}

.ReportPagesContainer .firewall-scroll .firewall-scroll {
    padding: 0px;
}

.accountManagementPagination {
    margin-top: 0px;
}

.Reports-TabsContainer {
    margin: 0px 22px;
}

.fw-reports-tabsContainer {
    margin: 0px 14px;
}

.usage-commonLoader {
    position: absolute;
    right: 130px;
    top: 30px;
}

.cellular-sub-tabs {
    display: flex;
    justify-content: center;
    margin: 0px 0px 20px 0px;
}

.cellular-sub-tabs .table-active-tab {
    background: white;
    font-size: 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 26px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 1px;
    color: #0E0548;
    background-color: #fff;
    font-weight: 500;
    text-transform: none;
    border-bottom: 2px solid #2860b4;
}

.cellular-sub-tabs .chart-active-tab {
    border-bottom: #fff;
    color: #8b8d97;
    font-size: 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 26px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 1px;
    font-weight: 500;
    text-transform: none;
}

.Reports-NavigationBtns .navigation-buttons-reports-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation-buttons-reports-div .helpIcon-reports {
    padding-right: 11px;
    padding-top: 16px;
    cursor: pointer;
    color: #2860B4;
}

.gen-rep-height {
    height: calc(100vh - 170px);
    overflow-y: scroll;
}
.operationalTopbar {
    display: flex;
    align-items: center;
    height: 64px;
}

.perSiteperWanUsageTableMargin {
    margin: 0px 20px;
  }

.perSiteperWanUsageClose {
    margin-left: 16px;
    cursor: pointer;
    color: #45464E;
}

.perSiteperWanUsageCloseIcon {
  height: 20px;
  width: 20px;
}

.TopUsersConatiner.perSiteperWanUsageTableMargin {
  margin: 0px 20px 20px 20px;
}

.timeseriesDwn.MuiGrid-grid-md-4 {
  margin-left: auto;
  max-width: 83%;
  flex-basis: 83%;
}

.operationalFilterPopup .MuiPaper-root {
  position: absolute;
  width: 274px;
  left: 185px;
  top: 180px;
  height: 323px;
  max-height: 323px;
}

.operationFilterpadding {
  padding: 18px 16px;
  display: flex;
  border-bottom: 1px solid #ebebeb;
}

.operationalClearApplyFilters {
  display: flex;
  padding: 20px 16px;
}

.tableBody .operationalTableData {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #45464E;
}

.greyDwnLink {
  color: #45464E;
}

.operationalTableClickableColumns {
  cursor: pointer;
  color: blue;
}
.generated-reports-container {
    margin: 10px 0px 15px 0px;
}

.generated-reports-container>div:nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #45464E;
    margin-bottom: 15px;
}

.generatedReportsContainer .generatedReportsCount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #45464E;
}

.generated-reports-table {
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1)
}

.reports-gene-container {
    margin-bottom: 10px;
}

.config-tableRow .generated-reports-head {
    background: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    padding-left: 16px;
    letter-spacing: 1px;
}

.config-tableRow .s-no {
    width: 3%;
    text-align: center;
    padding: 0px;
}

.config-tableRow .metadata {
    width: 5%;
}

.config-tableRow .actions {
    width: 5%;
    text-align: center;
    padding: 0px;
}

.usage-tableBody .gene-repo td {
    padding: 0px 7px 0px 16px;
}

.gene-repo .download-report {
    cursor: pointer;
    color: #0000ff;
}

.gene-repo .download-report-inProg {
    color: #45464E;
}

.generated-report-status {
    display: flex;
    justify-content: left;
    align-items: center;
    text-transform: capitalize;
}

.search-report-count .input-box-generated-reports {
    margin-left: 0px;
}
.search-report-count {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.generatedReportsContainer .topbarWidth.generatedReportsMarginReset {
    margin: 0px;
}
.am-table .contentFilterContainer {
    margin-top: 10px;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
}

.config-container .reports-login-history .content-filter-table-container {
    display: block;
}

.reports-login-history .table-header-cf {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
    color: #615469;
    margin: -10px 0px 10px 7px;
}

.login-tableCell .table-cell-span {
    display: flex;
    align-items: center;
}

.content-filter-table-container .CF-sites {
    margin-top: 0px;
}

.TopUsersConatiner .CF-usage {
    margin-bottom: 8px;
}

.login-tableRow .CF-cell {
    padding-left: 15px;
}

.tableBody .new-deployed-usage-tr .CF-cell {
    padding-left: 15px;
}

/* Top Widget */

.Form .content-filter-widget-card {
    width: 100%;
    height: 115px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.content-filter-widget-card .top-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 15px;
    height: 55%;
}

.details>p:nth-child(1) {
    font-family: "Roboto Mono";
    color: #6e7079;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
}

.details>p:nth-child(2) {
    width: 225px;
    font-size: 16px;
    font-family: "Roboto Mono";
    font-weight: 600;
    color: #0000ff;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-top: -5px;
}

.hit-details {
    padding: 0px 15px 0px 15px;
    line-height: 5px;
}

.hit-details>p:nth-child(1) {
    font-family: "Roboto Mono";
    color: #6e7079;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
}

.hit-details>p:nth-child(2) {
    font-family: "Roboto Mono";
    font-size: 14px;
    font-weight: 700;
    color: #45464e;
}

.top-details .logo-div {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.top-details .logo-div>img {
    width: 13px;
    height: 13px;
    margin-top: 7px;
}

.content-filter-widget-card .top-details .fqdn>img {
    width: 15px;
    height: 15px;
    margin-top: 6px;
    margin-left: 4px
}

.Form .top-overview {
    font-size: 14px;
    color: #45464e;
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    margin: 15px 0px 10px 0px;
    letter-spacing: 0.5px;
}

.top-details .cf-widget-name {
    margin-right: -10px
}

/* pie chart */

.pie-container-cf {
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.Form .pie-summary {
    font-size: 14px;
    color: #45464e;
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    margin: 25px 0px 15px 0px;
    letter-spacing: 0.5px;
}

.pieChart-activity .content-filter-filt {
    color: #2a46ff;
    cursor: pointer;
    font-size: 12px;
}

.piechart-selectContainer .pie-content-title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'Inter';
}

.am-table .totalUsageChartContainer .content-filter-pie-chart-div {
    justify-content: flex-start;
}

.usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-gap: 0px;
    gap: 0px;
}

.usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
    width: 214px;
}

.appcategoryUsed-usage .con-filt-dot {
    margin-right: 0px;
}

.Form .pie-chart-limit-div-cf {
    margin: 15px 0px 0px 0px;
}

/* line chart */

.login-history-bar-chart-comp .cf-linechart {
    height: auto;
    padding-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.line-cf-chart>div:nth-child(2) {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 11px;
}

.cf-linechart .cf-legends {
    margin-top: 5px;
}

.cf-linechart .hits-cate {
    font-size: 13px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    color: #45464f;
    margin: 0px 0px 0px 25px;
}

.cf-linechart .chart-option-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chart-option-btn .content-filter-actions-div {
    display: flex;
}

/* Bottom table */

.usageDetails-cards .content-filter-table-limit {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    margin: 0px 25px -25px 0px;
}

.loginHistory-tableRow .notUserClick {
    color: #45464E;
    cursor: auto;
}

@media only screen and (max-width: 1300px) {
    .details>p:nth-child(2) {
        width: 180px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
        width: 210px;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
        width: 125px;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
        width: 150px;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {
    .usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
        width: 185px;
    }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
    .details>p:nth-child(2) {
        width: 200px;
    }
}

@media only screen and (min-width: 1401px) and (max-width: 1450px) {
    .details>p:nth-child(2) {
        width: 230px;
    }
}

@media only screen and (min-width: 1451px) and (max-width: 1500px) {
    .details>p:nth-child(2) {
        width: 245px;
    }
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
    .details>p:nth-child(2) {
        width: 255px;
    }
}

.content-filter-body-content {
    height: calc(100vh - 251px);
    overflow-y: auto;
    max-height: 574px;
}

.overflow-hidden {
    overflow: hidden;
}
.marginBottom20px{
    margin-bottom: 20px;
}
.marginBottom10px{
    margin-bottom: 12px;
}
.paddingTop16px{
    padding-top: 16px;
}
.customCheckBox{
    display: flex;
    padding:10px;
}
.gridflex{
    display: flex;
    grid-gap: 0px;
    gap: 0px;
    align-items: baseline;
}

.gridflex .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
    padding-right: 8px;
}

.white{
    background-color: #FFFFFF;
}

.tkt_more_filters_dialog .MuiPaper-root{
    width: 274px;
    height: 834px;
    background: #FFFFFF;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
    border-radius: 8px;
    position: absolute;
    top: 30px;
}

.dialog_title{
    background: #f5f5f5;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 15px !important;
}

.dialog_title_notes{
    background: #264C86;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 21px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.edge_events_more_filters_dialog .dialog_title2{
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    color: #45464E;
    padding: 0px;
}

.dialog_title > div{
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #45464E;
    align-items: center;
}
.dialog_title > div > div {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.dialog_closeIcon{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.dialog_closeIcon-filters{
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    left: 233px;
}
.dialog_clearAllButton{
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    color: #676984 !important;
}
.dialog_clearAllButton, .dialog_okButton{
    border-radius: 5px !important;    
    width: 123px;
    height: 37px;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
    text-align: right !important;
    letter-spacing: 0.035em !important;
    text-transform: none !important;
}

.dialog_okButton {
    background: #264C86 !important;
    color: #fff !important;
}

.updateSiteAdressbtn .dialog_okButton {
    min-width: 132px !important;
}

.tickets-apply-btn .dialog_okButton{
    background: #1B51A7;
    color: #FFFFFF;
    text-transform: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    Line-height: 16.94px;
    align-items: center;
    width: 103px;
    height: 29px;
    left: 54px;
}

.tickets-apply-btn .dialog_okButton :hover {
    background: #1B51A7;
}
.tickets-apply-btn .ticketsClearFilterButton {
    text-transform: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
}
.tkt_more_filters_dialog_status{
    padding: 16px;
    border-radius: 10px;
    width:auto;
}
.tkt_more_filters_dialog_status_title, .tkt_more_filters_dialog_timeframe_title,
.tkt_more_filters_dialog_timeframe_SelectorBox, .timeframeDropdownMenuItem,
.tkt_more_filters_dialog_time_range_title, .tkt_more_filters_date_picker > span > input{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
    align-items: center !important;
    color: #45454E !important;
    letter-spacing: 0.05rem;
}
.tkt_more_filters_dialog_status_title_two{
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 13px !important;
    line-height: 14px !important;
    align-items: center !important;
    color: #676984 !important;
}
.tkt_more_filters_dialog_status_title, .tkt_more_filters_dialog_timeframe_title,
.tkt_more_filters_dialog_time_range_title{
    font-weight: 600 !important;
}
.tkt_more_filters_dialog_timeframe_SelectorBox, .timeframeDropdownMenuItem,
.tkt_more_filters_date_picker > span > input{
    font-weight: normal !important;
}
.tkt_more_filters_dialog_status_selection{
    display: flex;
}
.tkt_more_filters_dialog_status_unselected{
    border: 1px solid rgba(20, 99, 172, 0.24);
    background: none;
    color: #676984;
}
.tkt_more_filters_dialog_status_selection div{
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}
.tkt_more_filters_dialog_status_open{
    margin-right: 20px;
}
.tkt_more_filters_dialog_status_selected{
    background: #2860B4;
    border: 1px solid rgba(20, 99, 172, 0.24);
    color: #FFFFFF; 
}
.gridflex .typo{
    font-family: 'Inter';
    font-size: 12px !important;
    color:#45464E;
    line-height: 14.52px;
    padding-bottom: 8px;
}
.typo2{
    font-size: 12px !important;
    padding: 3px 0px 0px 3px;
    text-transform: capitalize;
    color: #676984;
    font-weight: 500 !important;
}
.typo3{
    font-size: 11px !important;
    padding: 0px 3px;
    text-transform: capitalize;
    font-weight: 300 !important;
    color: #676984;
    white-space:nowrap;
}
.show_filter{
    top: 5px;
    height: 45px !important;
    background: #ffffff !important;
    border-radius: 5px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 12px !important;
    text-transform:capitalize;
    letter-spacing: 0.035em !important;
    margin-top: 3px !important;
    height: 45px !important;
    margin-right: 10px !important;
    border:1px solid rgba(20, 99, 172, 0.24);
}

.tkt_more_filters_dialog_timeframe_SelectorBox {
    width: 250px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box !important;
    border-radius: 8px !important;
}
.timeframeDropdownMenuItem{
    width: 250px;
    line-height: 18px;
    display: flex;
    align-items: center;
}
.tkt_more_filters_date_picker > span {
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    padding-left: 10px;
}
.tkt_more_filters_date_picker, 
.tkt_more_filters_date_picker > span{
    width: 100% !important;
    height: 48px !important;
}
.tkt_more_filters_date_picker > span:focus {
    border-bottom: 0px !important;
    outline: none !important;
    box-shadow: none !important; 
}
.tkt_more_filters_dialog_timeframe_SelectorBox > fieldset{
    border-width: 0px !important;
 }
 .tkt_more_filters_date_picker_calendar.e-daterangepicker{
    top: 70px !important;
 }

 #ticket-pop-up1 {
     margin-left: 412px;
 }

 #ticket-pop-up2 {
     margin-left: 316px;
 }

 .padding-10 {
    align-items: center;
 }

 .tkt_more_filters_dialog .pad-10 {
     padding: 0px;
 }

.tkt_more_filters_dialog .tickets-title {
     display: flex;
     align-items: center;
     padding: 0px;
 }

 .tkt_more_filters_dialog .tickets-apply-btn {
    display : flex;
    justify-content: left;
    border-top: 1px solid #ebebeb;
    padding: 20px 16px 20px 16px;
 }

.tkt_new_ticket_dialog .MuiPaper-root{
    width: 1600px;
    height: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(103, 105, 132, 0.25);
    border-radius: 8px;
}
.tkt_new_ticket_dialog_subject, .tkt_new_ticket_dialog_description, .tkt_new_ticket_dialog_assignee,
.tkt_new_ticket_dialog_event, .tkt_new_ticket_dialog_attachments, .tkt_new_ticket_dialog_attachments_preview{
    margin-top: 10px;
}
.tkt_new_ticket_dialog_description_title, .tkt_new_ticket_dialog_subject_title, 
.tkt_new_ticket_dialog_assignee_title, .tkt_new_ticket_dialog_attachments_title,
.tkt_new_ticket_dialog_event_title, .tkt_new_ticket_dialog_attachments_preview_title {
    margin-bottom: 5px;
    margin-top: 20px;
}
.tkt_new_ticket_dialog_subject_title,
.tkt_new_ticket_dialog_description_title,
.tkt_new_ticket_dialog_event_title,
.tkt_new_ticket_dialog_assignee_title,
.tkt_new_ticket_dialog_attachments_title,
.tkt_new_ticket_dialog_attachments_preview_title{
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #676984 !important;
    text-transform: uppercase;
}
.tkt_new_ticket_dialog_description_textfield,
.tkt_new_ticket_dialog_description_textfield > div, 
.tkt_new_ticket_dialog_description_textfield > div > textarea,
.tkt_new_ticket_dialog_attachments_drop_area{
    width: 100%;
    height: 80px !important;
}
.tkt_new_ticket_dialog_subject_textfield > div > fieldset,
.tkt_new_ticket_dialog_description_textfield > div > fieldset,
.tkt_new_ticket_dialog_assignee_name > fieldset,
.tkt_new_ticket_dialog_assigneeAutocomplete  fieldset{
    border: none !important;
}
.tkt_new_ticket_dialog_subject_textfield > div,
.tkt_new_ticket_dialog_description_textfield > div{
    padding: 0px !important;
}
.tkt_new_ticket_dialog_subject_textfield > div >textarea,
.tkt_new_ticket_dialog_description_textfield > div > textarea{
    padding: 10px;
}
.tkt_new_ticket_dialog_subject_textfield > div > textarea,
.tkt_new_ticket_dialog_description_textfield > div > textarea,
.tkt_new_ticket_dialog_eventDatePicker > span > input,
.tkt_new_ticket_dialog_assignee_name > input,
.tkt_new_ticket_dialog_assigneeAutocomplete input,
.tkt_new_ticket_dialog_assigneeAutocompleteListItems{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #676984 !important;
}
.tkt_new_ticket_dialog_subject_textfield > div > textarea,
.tkt_new_ticket_dialog_description_textfield > div > textarea,
.tkt_new_ticket_dialog_eventDatePicker > span,
.tkt_new_ticket_dialog_assignee_name,
.tkt_new_ticket_dialog_assigneeAutocomplete,
.tkt_new_ticket_dialog_attachments_preview_image{
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
}
.tkt_new_ticket_dialog_subject_textfield,
.tkt_new_ticket_dialog_subject_textfield > div,
.tkt_new_ticket_dialog_subject_textfield > div > textarea,
.tkt_new_ticket_dialog_eventDatePicker, 
.tkt_new_ticket_dialog_eventDatePicker > span,
.tkt_new_ticket_dialog_assignee_name,
.tkt_new_ticket_dialog_assigneeAutocomplete,
.tkt_new_ticket_dialog_attachments_preview_image {
    width: 100%;
    height: 50px !important;
}
.tkt_new_ticket_dialog_eventDatePicker > span{
    padding-left: 10px;
}
.tkt_new_ticket_dialog_eventDatePicker > span:focus {
    border-bottom: 0px !important;
    outline: none !important;
    box-shadow: none !important; 
}
.tkt_new_ticket_dialog_assigneeAutocomplete > div,
.tkt_new_ticket_dialog_assigneeAutocomplete > div > div,
.tkt_new_ticket_dialog_assigneeAutocomplete > div > div > div{
    height: 100%;
    /* width: 100%; */
}
.tkt_new_ticket_dialogContent{
    display:flex;
}
.tkt_new_ticket_dialogContent_part1, .tkt_new_ticket_dialogContent_part2{
    width: 50%;
}
.tkt_new_ticket_dialogContent_part1{
    padding-right: 12px;

}
.tkt_new_ticket_dialogContent_part2{
    padding-left: 12px;
}
.tkt_new_ticket_dialog_attachments_drop_area{
    padding: 0px !important;
    border-width: 0px !important;
    background: #EEF0F7 !important;
    border-radius: 8px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #676984 !important;
}
.tkt_new_ticket_dialog_attachments_drop_area > div{
    padding: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tkt_new_ticket_dialog_attachments_drop_area > div > div{
    padding-left: 10px;
}
.tkt_new_ticket_dialog_attachments_drop_area .support{
    font-size: 9px !important;
    line-height: 11px !important;
    color: #A7A9C7 !important;
}
.tkt_new_ticket_dialog_attachments_preview{
    width: 100%;
}
.tkt_new_ticket_dialog_attachments_preview_remove_all{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #F12222;
    display: block;
    float: right;
    text-transform: none;
    cursor: pointer;
}
.tkt_new_ticket_dialog_attachments_preview_image {
    display: flex;
    padding: 5px;
    overflow-x: auto;
}
.tkt_new_ticket_dialog_attachments_preview_base64_image{
    border-radius: 4px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.tkt_new_ticket_dialog_attachments_preview_deleteIcon{
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: -5px;
}
.tkt_new_ticket_dialog_attachments_preview_image div{
    position: relative;
    width: 50px;
    min-width: 50px;
    margin-right: 10px;
}
.tkt_new_ticket_dialog_attachments_drop_area:focus{
    outline-width: 0px;
}

.cur-point {
    cursor: pointer;
    margin-bottom: 10px;
}

.ticket-description-alert {
    font-size: 11px;
    color: red;
}

.ticket-dialogue-actions .send-ticket-enable {
    background: rgb(38, 76, 134);
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 35px;
    margin-left: 5px;
    border: 1px solid #264c86;
    font-weight: 500;
    font-size: 11px;
    padding: 0px 25px;
}

.ticket-dialogue-actions .send-ticket-enable:hover {
    background: rgb(38, 76, 134);
}

.ticket-dialogue-actions .send-ticket-disable {
    background-color: rgb(232, 240, 253);
    color: rgb(38, 76, 134);
    height: 35px;
    margin-left: 5px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    font-weight: 500;
    font-size: 11px;
    padding: 0px 25px;
    cursor: auto;
}

.ticketArrowIconPaddingReset .MuiIconButton-root {
    padding: 0px;
}

.ticketArrowIconPaddingReset .MuiAutocomplete-endAdornment {
    top: auto;
}

.ticketAssigneeRequired {
    position: absolute;
    bottom: 8px;
    left: 120px;
}

.ticketSelectWidth {
    width: 100%;
}

.creatTicketSelecters {
    width: 100%;
    height: 38px;
}

.creatTicketSelecters .MuiInputBase-root {
    padding: 7.5px 14px 7.5px 14px;
}

#createTicketEventDate {
    width: 100%;
    height: 38px;
}

#createTicketEventDate > span {
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 12px;
    height: 38px;
}

#createTicketEventDate > span > input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #676984;
}

.tkt_ticket_details_dialog .MuiPaper-root{
    width: 800px;
    height: 570px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(103, 105, 132, 0.25);
    border-radius: 8px;
}
.tkt_ticket_details_menuIcon{
    margin-right: 25px;
    color: #ffffff;
}
.tkt_ticket_details_menu .MuiPaper-root{
    /* width: 128px;
    height: 105px;
    background: #FFFFFF; */
    box-shadow: 0px 4px 15px rgba(103, 105, 132, 0.25);
    border-radius: 4px;
}
.tkt_ticket_details_menuItem{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    color: #676984 !important;
    padding: 10px 20px !important
}
.tkt_ticket_details_dialog_content{
    padding-right: 20px !important;
    padding-left: 24px !important;
    display: flex;
    /* overflow: hidden; */
}
.tkt_ticket_details_dialog .dialog_title{
    margin-bottom: 0px !important;
}
.tkt_ticket_details_dialog_content_status{
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.tkt_ticket_details_dialog_content_subject_title{
    margin-top: 25px;
}
.tkt_ticket_details_dialog_content_description_content{
    /* margin-bottom: 20px !important; */
    padding-bottom: 15px;
    border-bottom: 0.5px solid #C7DAEB;
}
.tkt_ticket_details_dialog_content_subject_title,
.tkt_ticket_details_dialog_content_description_title,
.tkt_ticket_details_dialog_content_event_title,
.tkt_ticket_details_dialog_content_creator_title,
.tkt_ticket_details_dialog_content_assignee_title,
.tkt_ticket_details_dialog_content_status_title,
.tkt_ticket_details_dialog_content_comments_title,
.tkt_ticket_details_dialog_content_commentedBy,
.tkt_ticket_details_dialog_content_comment_commentDate{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #676984;
    text-transform: uppercase;
    display: flex;
    /* align-items: center; */
}
.tkt_ticket_details_dialog_content_subject_content,
.tkt_ticket_details_dialog_content_description_content,
.tkt_ticket_details_dialog_content_event_content,
.tkt_ticket_details_dialog_content_creator_content,
.tkt_ticket_details_dialog_content_assignee_content,
.tkt_ticket_details_dialog_content_assignee_searchBox > input,
.tkt_ticket_details_dialog_content_status_content,
.tkt_ticket_details_dialog_content_comment_text,
.multi_line_textfield_parent > div > div > textarea{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #676984 !important;
    word-break: break-word;
}
.tkt_ticket_details_dialog_content_description_content,
.tkt_ticket_details_dialog_content_comment_text{
    white-space: pre-wrap;
}
.tkt_ticket_details_dialog_content_status_content{
    display: flex;
}
.tkt_ticket_details_dialog_content_status_content span{
    justify-content: center;
    align-items: center;
    display: flex;
}
.tkt_ticket_details_dialog_content_markAsClosed{
    width: 130px;
    height: 32px;
    margin-left: 15px !important;
}
/* Button commom css */
.tkt_ticket_details_dialog_content_button{
    background: #AAABC4 !important;
    border-radius: 4px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 15px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #FFFFFF !important;
    text-transform: none !important;
}
/* End -- Button commom css */
.tkt_ticket_details_dialog_content_comment{
    width: 90%;
}
/* .tkt_ticket_details_dialog_content_comments{
    max-height: 96.5%;
    overflow: auto;
}
.tkt_comments_overflow.tkt_ticket_details_dialog_content_comments{
    min-height: 55%;
    max-height: 55%;
} */
.tkt_ticket_details_dialog_content_comments_content{
    display: flex;
    padding: 15px 15px 15px 0px;
    border-bottom: 1px solid #C7DAEB;
}
.tkt_ticket_details_dialog_content_comments_content:last-child {
    border-bottom: 0px !important ;
}
.tkt_ticket_details_dialog_content_comments_content.tkt_clicked_ticket{
     background: #FAF9FC;
}
.tkt_ticket_details_dialog_content_commenter > div{
    /* width: 10%; */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #676984;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    color: #FFFFFF;
    margin: 0px 10px;
    text-transform: uppercase;
    word-break: break-word;
}
.tkt_ticket_details_dialog_content_comment_text{
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
}
.tkt_ticket_details_dialog_content_comment > div{
    display: flex;
}
.tkt_ticket_details_dialog_content_commentedBy,
.tkt_ticket_details_dialog_content_comment_commentDate{
    text-transform: none !important;
}
.tkt_ticket_details_dialog_content_comment_commentDate{
    margin-left: 20px;
}
.tkt_ticket_details_dialog_content_comment_deleteComment{
    float: right;
}
.tkt_ticket_details_dialog_content_comment_addComment{
    /* padding: 10px; */
    margin-top: 10px;
}
.tkt_ticket_details_dialog_content_description_content > div > div > textarea,
.tkt_ticket_details_dialog_content_comment_addComment > div > div > textarea {
    height: 100px !important;
}
.tkt_ticket_details_dialog_content_subject_content > div > div > textarea {
    height: 50px !important
}

/* multiline input common CSS */
.multi_line_textfield_parent,
.multi_line_textfield_parent > div, 
.multi_line_textfield_parent > div > div > textarea {
    width: 100%;
}
.multi_line_textfield_parent > div > div > fieldset{
    border: none !important;
}
.multi_line_textfield_parent > div,
.multi_line_textfield_parent > div > div{
    padding: 0px !important;
}
.multi_line_textfield_parent > div > div > textarea {
    border: 0.5px solid #C7DAEB !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    padding: 10px;
}
/* End-- multiline input common CSS */
.tkt_ticket_details_dialog_content_comment_sendButton,
.tkt_ticket_details_dialog_content .editTicketActions{
    /* width: 96px;
    height: 32px; */
    float: right;
    margin-top: 20px !important;
}
.displayNone{
    display: none;
}
.tkt_ticket_details_edit_ticket_buttons{
    display: flex;
    justify-content: flex-end;
    height: 75px;
    flex-direction: column;
}
.tkt_ticket_details_edit_ticket_buttons > div{
    justify-content: flex-end;
    display: flex;
}
.tkt_ticket_details_dialog_content_button.edit_close_button,
.tkt_ticket_details_dialog_content_button.edit_submit_button{
    width: 96px;
    height: 32px;
    margin-left: 30px;
}

.tkt_ticket_details_dialog_content_assignee_searchBox > fieldset{
    border: none !important;
}
.tkt_ticket_details_dialog_content_assignee_searchBox,
.tkt_ticket_details_dialog_content_assigneeAutocomplete {
    border: 0.5px solid #C7DAEB !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
}
.tkt_ticket_details_dialog_content_assignee_searchBox,
.tkt_ticket_details_dialog_content_assigneeAutocomplete{
    width: 90%;
    height: 40px;
}
.tkt_ticket_details_assignee_clearImage{
    height: 12px;
}
.tkt_ticket_details_dialog_content_assignee_searchBox img{
    margin-left: 24px;
}
.tkt_ticket_details_dialog_content_assigneeAutocomplete  fieldset{
    border: none !important;
}

.tkt_ticket_details_dialog_content_assigneeAutocomplete  input,
.tkt_ticket_details_dialog_content_edit_tkt_assigneeAutocompleteListItems{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #676984 !important;
}
.tkt_ticket_details_dialog_content_assigneeAutocomplete > div,
.tkt_ticket_details_dialog_content_assigneeAutocomplete > div > div,
.tkt_ticket_details_dialog_content_assigneeAutocomplete > div > div > div{
    height: 100%;
    width: 100%;
}

.status {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #676984;
    margin-left: 5px;
}
.tkt_ticket_details_dialog_content .editTicketActions .dialog_okButton {
    margin-left: 8px;
}
.tkt_ticket_details_dialog_content .eventDate, .tkt_ticket_details_dialog_content .creator,
.tkt_ticket_details_dialog_content .assignee {
    border: 1px solid #C7DAEB;
    padding: 14px 0px 14px 50px !important;
}
.tkt_ticket_details_dialog_content .editAssignee {
    border: 1px solid #C7DAEB;
}
.tkt_ticket_details_dialog_content .editAssignee .tkt_ticket_details_dialog_content_assignee_title{
    padding: 14px 0px 0px 50px !important;
}
.tkt_ticket_details_dialog_content_assignee_content > div{
    display: flex;
    justify-content: center;
}


.t_border{
border: 2px solid #DCE8F3;
}

::-webkit-scrollbar{
    background: #e0dddd;
}

::-webkit-scrollbar-thumb{
    background: #264C86;
}

.flex{
    display: flex;
}

.justyfy-center{
    justify-content: center;
}

.justyfy-space-btwn{
    justify-content: space-between;
}

.align-center{
    align-items: center;
}

.hover:hover{
background:#c7cad0;
}


.subject{
    height: auto !important;
    margin: 20px !important;
    border-radius: 15px !important;
}

.border_radius_15{
border-radius: 15px !important;
}

.font10{
    font-size: 10px !important;
}

.font12{
    font-size: 12px !important;
}

.font15{
    font-size: 15px !important;
}

.bold{
    font-weight: bold !important;
}

.transformUpper{
    text-transform: uppercase;
}

.textOverflow{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.bullet{
    height: 10px;
    width: 10px;
    border-radius: 50px;
    margin-right: 10px;
}

.image_conatiner{
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    /* width: 100%; */
    height: 100px !important;
    display: flex;
    position: relative;
    width: 110px;
    cursor: pointer;
    background: #E8EFF7    ;
    /* min-width: 50px; */
    margin-right: 10px;
    margin-top: 10px;
}

.commentor{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #676984;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    color: #FFFFFF;
    margin: 0px 10px;
    text-transform: uppercase;
    word-break: break-word;
}

.icon-cursor2{
    cursor: pointer;
    border: 1px solid #dbdbff;
    margin-right: 5px;
}

.image-placeholder{
    margin: 20px;
    height: 60px;
}

.icon-email{
    cursor: pointer;
    margin-left: 10px;
    height: 17px;
    width: 17px;
}

.email-popup{
    border: 4px solid #498AEC;
}

.typos{
    color: #4D73E7 !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.typo2{
    font-size: 12px !important;
    font-style: italic !important;
    padding: 5px !important;
    text-align: center;
}

.send-email-button{
    cursor: pointer;
    background: #264C86 !important;
    color: #FFFFFF !important;
    border-radius: 5px !important;    
    width: 80px;
    height: 37px;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    border: none;
}

.ticket-view__contianer {
    max-height: calc(100vh - 170px);
    overflow-y: auto;
}

.ticket-view__details-container {
    padding: 20px;
}

.ticket-attachment-popup .image-filename {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #45464e;
}

.ticket-attachment-popup .close{
    cursor: pointer;
    float: right;
}

.ticket-attachment-popup .image-content {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
}

.ticket-attachment-popup .image-content img{
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.ticket-view__details-container > div > .MuiGrid-container {
    flex-wrap: nowrap;
}

.Form .tickets-view-navigation-btns {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 10px 25px 0px 20px;
}

.ticket-view__details-container .tickets-view-btns button {
    height: 37px;
    width: 120px;
    text-transform: uppercase;
}

.tickets-view-navigation-btns .enable-prev-btn {
    box-shadow: 0 0 13px 0 hsla(0, 0%, 79.6%, .35);
    background-color: #264C86;
    color: #fff;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    height: 29px;
    text-transform: none;
    border-radius: 4px;
}

.tickets-view-navigation-btns .enable-prev-btn:hover {
    background-color: #264C86;
}

.tickets-view-navigation-btns .disable-prev-btn, .tickets-view-btns .ticketView-disable-update-btn {
    background-color: #e8f0fd;
    color: #264c86;
    height: 29px;
    border-radius: 4px;
    text-transform: none;
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    border: 1px solid #676984;
    cursor: auto;
}

/* .MuiInputBase-input{
    height: auto !important;
}  */
.tkt_search, .tkt_creator, .tkt_assignee, .tkt_more_filters,.tkt_new_ticket{
    padding: 8px 0px 8px 0px;
}

.verticalLineImageParentDiv img{
    padding-left: 12px; /*same as  padding-left of .tkt_creator*/
}
.tkt_search .searchBox,
.tkt_creator .creatorSelectBox,
.tkt_assignee .assigneeSelectorBox,
.tkt_new_ticket .tkt_new_ticket_button {
    width: 90%;
}
.tkt_more_filters .tkt_moreFilters_button{
    width: 100%;
}
.ticket-create-button{
    max-width: 159.95px;
    width: 100%;
    height: 37px;

    background-color: #264C86 !important;
    border-radius: 5px!important;
    color: #ffffff!important;
    text-transform: uppercase!important;

    font-style: normal!important;
    font-weight: bold!important;
    font-size: 10px!important;
    line-height: 12px!important;

    text-align: right!important;
    letter-spacing: 0.035em!important;
    margin-right: 25px !important;
}
.searchText, .creatorText, .assigneeText{
    font-family: Roboto;
    font-style: normal  !important;
    font-weight: normal  !important;
    font-size: 12px  !important;
    line-height: 15px  !important;
    color: #676984;
    padding-bottom: 10px;
}
.searchBox, .creatorSelectBox, .assigneeSelectorBox{
    /* width: 254px; */
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CFD0DD;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-family: Roboto;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #AAABC4 !important;
}
.searchBox > fieldset, .creatorSelectBox > fieldset,
.assigneeSelectorBox > fieldset, .creatorMenuItem  fieldset,
.assigneeMenuItem  fieldset {
    border-width: 0px !important;
 }
.verticalLineImageParentDiv{
    align-items: center;
}
.verticalLineImageParentDiv img{
    margin:auto;
}
.moreFilters{
    width: 144px;
    height: 48px;
    background: #AAABC4;
    border-radius: 4px;
}
.creatorMenuItem, .assigneeMenuItem{
    /* width: 254px; */
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.creatorMenuItem:focus,  .creatorMenuItem:hover, .creatorMenuItem:visited, .creatorMenuItem:active,
.assigneeMenuItem:focus,  .assigneeMenuItem:hover, .assigneeMenuItem:visited, .assigneeMenuItem:active
{
    background-color: initial !important;
    border-bottom: 0px !important;
}
.creatorMenuItem  fieldset, .assigneeMenuItem  fieldset  {
    border: 1px solid #CFD0DD !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #AAABC4 !important;
}
.creatorMenuItem, .assigneeMenuItem {
    background: initial !important;
}
.creatorMenuItem span, .assigneeMenuItem span {
    background-color: initial !important;
    border-bottom: 0px !important;
}
.creatorMenuItem > div > input,
.assigneeMenuItem > div > input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #AAABC4;
}
.tkt_moreFilters_button{
    color: #080000 !important; 
    /* width: 144px !important;  */
    top: 5px;
    height: 37px !important;
    background: #ffffff !important;
    border-radius: 5px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 12px !important;
    text-transform: uppercase !important;
    letter-spacing: 0.035em !important;
}
.tkt_new_ticket_button{
    /* width: 210px !important;  */
    height: 40px !important; 
    background: #676984 !important; 
    border-radius: 4px !important; 
    color: #ffffff !important; 
    font-family: Roboto !important;
    font-style: normal !important; 
    font-weight: bold !important; 
    font-size: 14px !important; 
    line-height: 18px !important; 
    text-transform: none !important; 
}
.ticketsPaginationDiv{
    display: flex;
    justify-content: flex-end;
    height: 50px;
    padding: 15px 20px 0px 10px;
}
.ticketsPaginationDiv > div {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #676984;
    align-items: center;
    display: flex;
    justify-content: center;
}
.ticketsPaginationDiv + div {
    margin-top: 0px !important;
}
.ticketsTable{
    width: 100% !important;
    border-collapse: separate !important;
    border-spacing: 0 1em !important;
}
.ticketsTableTheadTd{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #CFD0DD !important;
}
.ticketsTableTheadTd span{
    display: flex;
}
.ticketsTableTheadTd .sortingImages{
    margin-left: 10px;
    cursor: pointer;
}
.ticketsTableTheadTd .sortingActive{
    visibility: hidden;
}
.ticketsTableTheadTd .ascendingOrderIcon{
    display: block;
}
.ticketsTableTbodyTr{
    background: #EEF0F7;
    border: 1px solid #EEF0F7 !important;
    border-radius: 4px;
}
.ticketsTableTbodyTd{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 15px !important;
    text-align: left !important;
    padding: 16px !important;
    align-items: center !important;
    color: #676984 !important;
    border: 0px !important;
}
.markAsClosed{
    /* width: 96px !important; */
    height: 32px !important;
    background: #FFFFFF !important;
    border-radius: 4px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 13px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #273050 !important;
    text-transform: capitalize !important;
    letter-spacing: 0.02em !important;
    border: 1px solid rgba(53, 83, 137, 0.35) !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    min-width: 100px !important;
}
.tkt_comments{
    margin-right: 10px;
}
.tkt_arrow{
    float: right;
}
.creatorMenuItem, 
.assigneeMenuItem{
    cursor: initial !important;
}
.creatorMenuItem > div,
.assigneeMenuItem > div{
    width: 100% !important;
}
.creatorMenuItem > div >img, 
.assigneeMenuItem > div >img {
    float: right;
    cursor: pointer;
}
.creatorMenuItem_showAllUsers,
.assigneeMenuItem_showAllUsers{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    -webkit-text-decoration-line: underline !important;
            text-decoration-line: underline !important;
    color: #676984 !important;
    cursor: pointer !important
}
.creatorMenuItem_actions, 
.assigneeMenuItem_actions {
    display: flex;
}
.creatorMenuItem_clearAllButton,
.creatorMenuItem_okButton,
.assigneeMenuItem_clearAllButton,
.assigneeMenuItem_okButton {
	width: 103px !important;
    height: 36px !important;
    border-radius: 4px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
	text-transform: none !important;
    cursor: pointer;
}
.creatorMenuItem_clearAllButton,
.assigneeMenuItem_clearAllButton {
    border: 1px solid rgba(103, 105, 132, 0.2) !important;
    box-sizing: border-box !important;
	color: #676984 !important;
    margin-right: 16px !important;
}
.creatorMenuItem_okButton,
.assigneeMenuItem_okButton {
    background: #264C86 !important;
    color: #FFFFFF !important;
}
.tkt_show_more_text{
    text-decoration: underline;
}
.ticketsPaginationDiv .prevPage{
    margin-left:10px;
    margin-right:15px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.ticketsPaginationDiv .prevPageDisabled{
    margin-left:10px;
    margin-right:15px;
}
.ticketsPaginationDiv .nextPageDisabled{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.typo3 {
    padding-left: 7px;
}

@media only screen and (max-height: 400px){
  .FleetDashboard-tickets>div {
    max-height: 150px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .FleetDashboard-tickets>div {
    max-height: 195px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .FleetDashboard-tickets>div {
    max-height: 245px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .FleetDashboard-tickets>div {
    max-height: 270px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .FleetDashboard-tickets>div {
    max-height: 310px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .FleetDashboard-tickets>div {
    max-height: 335px;
    overflow-y: auto;
  }
}
  
  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .FleetDashboard-tickets>div {
      max-height: 440px;
      overflow-y: auto;
    }
  }
  
   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .FleetDashboard-tickets>div {
      max-height: 422px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .FleetDashboard-tickets>div {
      max-height: 530px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .FleetDashboard-tickets>div {
      max-height: 580px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .FleetDashboard-tickets>div {
      max-height: 640px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .FleetDashboard-tickets>div {
      max-height: 690px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:950px) and (max-height:999px){
    .FleetDashboard-tickets>div {
      max-height: 740px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:999px) and (max-height:1050px){
    .FleetDashboard-tickets>div {
      max-height: 790px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1050px) and (max-height:1080px){
    .FleetDashboard-tickets>div {
      max-height: 840px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1080px) and (max-height:1140px){
    .FleetDashboard-tickets>div {
      max-height: 870px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1140px) and (max-height:1200px){
    .FleetDashboard-tickets>div {
      max-height: 930px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1200px) and (max-height:1300px){
    .FleetDashboard-tickets>div {
      max-height: 990px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1300px) and (max-height:1400px){
    .FleetDashboard-tickets>div {
      max-height: 1090px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1400px) and (max-height:1920px){
    .FleetDashboard-tickets>div {
      max-height: 1600px;
      overflow-y: auto;
    }
  } 

    .wrappingContent {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .font-12 {
      font-size: 12px;
    }

    .markAsClosed.marg-left-20 {
      margin-left: 20px;
    }

    .tickets-main-container {
      width: 100%;
      padding-top: 109px;
    }

    .tickets-main-container .tickets-page-frontView {
      height: calc(100vh - 125px);
      overflow-y: auto;
    }

.MuiTextField-root.input-box-ticket-tbl {
  width: 100%;
  max-width: 300px;
}

.usage-tableBody .loginHistory-tableRow .ticketClick{
  cursor: pointer;
  color: #0000f1;
}

.tickets-csv-btn {
  display: flex;
  padding: 0 10px;
  margin-top: 20px;
  margin-right: 25px;
}

.ticketsTopbar {
  background: #FFFFFF;
  justify-content: space-between;
}

.ticketsTopbar .tkt-searchbar-filter-div {
  margin-left: 15px;
  display: flex;
  max-width: 438px;
}

.ticketFilterButton {
  padding-left: 16px;
}

.createTicketButton {
  text-align: right;
}

.usage-tableBody .ticketsTableRowsPadding td {
  padding: 5px;
  text-align: center;
}
.vessel_summary_page{
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.vessel_summary_page .topology {
    display: flex;
    margin-bottom: 20px;
    background-color: #FFFFFF;
}
.vessel_summary_topology_diagram{
    background: #FFFFFF;
    border: 1px solid rgba(20, 99, 172, 0.24);
    border-radius: 10px;
    min-height: 400px;
}
.vessel_summary_product_details{
   padding-left: 20px;
}
.vessel_summary_product_details .vessel_summary_product {
    /* background: #FFFFFF;
    border: 1px solid rgba(20, 99, 172, 0.24);
    border-radius: 10px; */
    padding: 10px 0px 10px 15px;
    /* margin-bottom: 15px; */

    background: #FFFFFF;
    border: 1px solid #F4F7F8;
    border-radius: 10px 10px 0px 0px;
}
.vessel_summary_product_details .vessel_summary_product_device{
    /* background: #FFFFFF;
    border: 1px solid rgba(20, 99, 172, 0.24);
    border-radius: 10px; */
    padding: 0px 0px 10px 0px;
    /* margin-bottom: 15px; */
}
.vessel_summary_product_name{
    font-family: inter !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.05em !important;
    color: #45464e !important;
}
.vessel_summary_product_name.productName{
     float: left;
}
.vessel_summary_product .product_table .MuiTableCell-head, .vessel_summary_product .product_table .MuiTableCell-body {
    border: 0px !important;
}
.product_table{
   width: 50% !important;
}
.vessel_summary_product .product_table .MuiTableCell-head, .vessel_summary_product .product_table .MuiTableCell-body,
.vessel_summary_product_device .device_table .MuiTableCell-head, .vessel_summary_product_device .device_table .MuiTableCell-body{
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    letter-spacing: 0.035em !important;
}
.vessel_table_cell{
    text-align: center !important;
}
.vessel_summary_product_device .device_table{
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
}
.vessel_summary_product .product_table .MuiTableCell-head, .vessel_summary_product .product_table .MuiTableCell-body{
    color: #45464e !important;
    padding: 10px 10px 0px 0px !important;
    width: 30%;
}
.vessel_summary_product_device .device_table .MuiTableCell-head, .vessel_summary_product_device .device_table .MuiTableCell-body{
    color: #676984 !important;
    padding: 10px 10px 10px 15px !important;
    width: 18%;
}

.VesselMMSI {
    font-size: 14px;
    font-weight: 500;
    color:#3c7bd3;
}

.mmsi-streetAdrdess {
    margin-top: -20px;
    font-size: 13px;
    color: #676a8e;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    padding: 10px;
}

.MMSIDiv {
    border: 1px solid #c3d6e7;
    margin-top: 8px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    width: 450px;
}

.addressDiv {
    border: 1px solid #c3d6e7;
    padding: 10px;
    border-radius: 5px;
    width: 450px;
    margin-bottom: 5px;
}

.addressLineDiv {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
}

.scrollVesselDetail {
    max-height: calc(100vh - 255px);
    overflow-y: auto;
}

/* Update SITE pop up CSS */

.updateSiteNameBox {
    max-width: 500px;
    margin-bottom: 5px;
}

.desc:hover {
    background-color: #f1f1f1;
}

.errorspan {
    font-size: 15px;
    font-style: italic;
    padding: 5px 10px;
    color: red;
}

.noteDialog {
    max-width: 675px;
    margin-left: 33%;
}

.siteUpdate>.marBtm20 {
    margin-bottom: 0px;
    text-transform: none;
}
.siteUpdate{
    display: flex;
    align-items: center;
}
.topology-ou-name {
    color: #676984;
    padding: 0px 0px 8px 8px;
}
.fontWt {
    font-weight: 500;
}
.topolgoy_update_device_info.dialog_content {
    padding: 24px;
}
.parentSetting .label{
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
}
.parent-enable-disable-icon {
    height: 20px;
    width: 30px;
}
.parent-enable-disable-icon.enable {
    cursor: default;
}
.parent-enable-disable-icon.disable{
    cursor: not-allowed;
}
.dialog_title {
    border-radius: 0px;
}

.deviceUpdate {
    width: 17px;
    height: 17px;
    margin-bottom: -5px;
    padding-left: 5px;
}

.margAuto {
    margin-left: auto;
}

.closeConfirmBorder {
    border: 4px solid #264C86;
}

.closeConfirm {
    display: flex;
    justify-content: space-around;
}

.updateSiteNameBtn {
    text-align: right;
    align-self: center;
}

.updateSiteAdressbtn {
    margin-right: 60px;
}
.siteNameEditContainer .editName .MuiInputBase-input {
    text-overflow: ellipsis;
}

.vessel_table_cell--portal_link {
    cursor: pointer;
}

.topologySitePopUp {
    width: 100%;
}

.vessel_summary_product_device .topology-tab-actions > span {
    margin-right: 10px;
}
.vessel_summary_product_device .topology-tab-actions span:last-child {
    margin-right: 0;
}
.topology-tab-actions .modify-config-icon,
.topology-tab-actions .backup-config-now-icon {
    cursor: pointer;
}
.topology-tab-actions .modify-config-icon svg,
.topology-tab-actions .backup-config-now-icon svg{
    color:#1B51A7 ;
    width: 25px;
    height: 32px;
}
.topology-tab-actions .modify-config-icon.disable,
.topology-tab-actions .backup-config-now-icon.disable {
    cursor: not-allowed;
    opacity: 0.5;
}
.topology-tab-actions .modify-config-icon svg path,
.topology-tab-actions .backup-config-now-icon svg path {
    stroke: #ffffff;
    stroke-width: 0.6;
}

.border-right-topology {
    background-color: #F5F5F5;
}

.width-20-percent {
    width: 20%;
}

.width-12-percent {
    width: 12%;
}

.width-8-percent {
    width: 8%
}

.topology-device-name-with-warning-icon, .topology-device-name-with-warning-icon .warningIcon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.topology-device-name-with-warning-icon .warningIcon img {
    height: 20px;
    width: 20px;
}

.justifyContenetCenter {
    justify-content: center;
}
.manage-config-log-status-td {
  cursor: pointer;
  color: #0000ee;
}

.manage-config-log-status-td {
  cursor: pointer;
}

.manage-config-log-head-name {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #45464e;
  padding: 0px 0px 8px 8px;
}

.manageConfiglogHeadName {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #45464e;
}

.manage-config-log-left-align {
  text-align: center;
}

.manage-config-log-head {
  margin-top: 60px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 1rem;
}

.manage-log-pagnition-top-10px {
  padding-top: 10px;
}

.manage-config-log-device-cell > div {
  text-overflow: ellipsis;
  font-size: 10px;
  background: #fff;
  display: flex;
}
.manage-config-log-status .tableHead th {
  text-align: left;
  padding-left: 15px;
}

.manage-config-log-status .tableBody td{
  text-align: left;
  padding: 15px;
}

.manage-config-log-status .tableBody td button{
  padding: 0;
}

.manage-config-log-status .tableBody td .pointer {
  cursor: pointer;
}

.logs-table-search-container {
  display: flex;
}

.logs-table-search-container .config-logs-download-loader-div {
  display: flex;
  align-items: center;
  justify-content: end;
  grid-gap: 20px;
  gap: 20px;
  margin-right: 10px;
}

.logs-table-search-container .config-logs-loader {
  position: relative;
}
.manage-config-inventory-popup {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.manage-config-inventory-popup {
  width: 59%;
}

.manage-config-inventory-popup {
  display: flex;
  margin-bottom: 5px;
}

.manage-config-inventory-popup {
  color: #9a9a9a;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
}

.manage-config-inventory-popup {
  width: 40%;
  color: #45464e;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
}

.manage-config-inventory-popup {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #676984;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manage-config-inventory-popup {
  margin-top: 0px;
}

.config-deploy-inventory-popup {
  margin-bottom: 0px;
}

.manage-config-inventory-popup .select-config-from {
  margin-bottom: 0px;
}

.manage-config-inventory-popup {
  width: auto;
  background-color: #264c86;
}

.manage-config-inventory-popup {
  border: 1px solid #264c86;
  color: #264c86;
}

.manage-config-inventory-popup {
  height: 35px;
  text-transform: uppercase;
}

.manage-config-inventory-popup {
  cursor: not-allowed;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.disabledButton {
  opacity: 0.5; /* Darken the button when disabled */
  pointer-events: none; /* Prevent clicks on the button when disabled */
}

.mng-config-btn {
  background-color: #00008b;
  color: #fff;
}

.hub-config-hub-name, .hub-config-hub-ip {
  width: 300px;
  height: 37px;
}

.hub-config-hub-name .MuiInputBase-root, .hub-config-hub-ip .MuiInputBase-root {
  width: 100%;
}

.hub-ip-config-type-copy-or-configure, .hub-ip-action-add-or-replace {
  display: flex;
  align-items: center;
}

.manage-config-pop-up .hub-ip-config-type-tabs, .manage-config-pop-up .hub-ip-config-type-tabs div {
  height: 38px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  cursor: pointer;
}

.manage-config-pop-up .hub-ip-config-type-tabs .active-tab {
  font-weight: 600;
  color: #496191;
  border-bottom: 1px solid #2F4B82;
}

.manage-config-pop-up .hub-ip-config-type-tabs .not-active-tab {
  font-weight: 500;
  color: #8B8D97;
  border-bottom: 0px;
}

.manage-config-pop-up .config-configuration-form-deploy .hub-ip-config-source-target-header {
  font-size: 16px;
  font-weight: 600;
}

.hub-config-hub-ip .MuiFormHelperText-root {
  font-family: 'Inter';
  color: red;
}

.config-deploy-inventory-popup .traffic-policy-site div:nth-child(1) {
  height: auto !important;
}

.manage-config-pop-up .config-configuration-form-deploy .serving-hub {
  width: unset;
}

.manage-config-pop-up .menuScroll .MuiChip-label {
  width: 100%;
  height: 27px;
  padding-top: 5px;
}

.config-configuration-form-deploy .serving-hub-checkbox {
  padding: 0px;
}
.device-system-config{
    padding-bottom: 10px;
}

.device-system-config>div {
    display: flex;
    color: #45464E;
    font-family: 'Inter';
    font-size: 14px;
    height: 37px;
    margin: 2px 0px;
}

.device-system-config .MuiOutlinedInput-notchedOutline {
    height: 40px;
}

.device-system-config .content-left {
    width: 40%;
    display: flex;
    align-items: center;
}

.device-system-config .content-right {
    display: flex;
    align-items: center;
    width: 55%;
}

.device-system-config .content-right.action {
    justify-content: space-between;
}

.device-system-config .content-right.action .action-button {
    color: #fff;
    border: 1px solid #1B51A7;
    text-transform: capitalize;
    width: 100px;
    padding: 3.5px;
    margin-top: -2px;
    box-shadow: 0px 0px 13px 0px #CBCBCB59;
    background: #1B51A7;
    letter-spacing: unset;
    font-weight: 600;
    font-family: "Inter";
    background-color: #244c84;
    border-radius: 4px;
}

.device-system-config .content-right.action .action-button.disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    box-shadow: 0px 0px 13px 0px #cccccc;
}

.device-system-config .content-right.action .user-entered-config-name input{
    padding-left: 12px;
}
.device-system-config .content-right.action .user-entered-config-name,
.device-system-config .content-right.action .select-config > div,
.device-system-config .content-right .select-licence > div,
.device-system-config .content-right .select-licence-term > div {
    width: 250px;
}

.device-system-config .content-right.action .device-konnect-status .dot {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.device-system-config .content-right.action .device-konnect-status .online {
    background: rgb(105, 160, 62);
}

.device-system-config .content-right.action .device-konnect-status .offline {
    background: red;
}

.device-system-config .content-right.action .device-konnect-status .unknown {
    background: gray;
}
.device-system-config .change-license-info-icon{
    width: 20px;
    color: #45464E;
    margin-top: 2px;
    padding-left: 5px;
    cursor: pointer;
}
.device-system-config .config-upload-note {
    color: #ff0000;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}
.inventory-am-table {
  margin-left: 20px;
  margin-right: 20px;
}

.inventoryContailer {
  margin-left: 18px;
  padding-right: 25px;
}

.inventoryContailer_Tabs {
  margin-left: 20px;
  padding-right: 25px;
}

.deployed-inputRow {
display: flex;
}

.deployed-pagination-list {
  margin-left: 5px;
  align-items: center;
  width: auto;
}

.deployed-inputRow>div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
}

.parPageContainerOfDeployed {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
}

.inventory-tableRow {
  border-bottom: 1.1px solid #676984 ;
  font-weight: 700;
  font-size: 12px;
}

.fleetDataTableSite {
  cursor: pointer;
  padding-left: 20px;
}

.fleetDataTableType {
  padding-left: 30px;
}

.fleetDataTableRow {
  padding-left: 45px;
}

.elementData {
  padding: 5px;
}

.limitContainer {
  margin-left: 10px;
}

.cursorPointer {
  position: absolute;
  right: 12px;
}

.inventoryPagination-list{
width: 100%;
align-items: center;
margin-left: 5px;
}

.inventorypaperComponent {
  font-size: 12px;
}

.inventoryFleetRowRank .deployed-fleetRowDataDetails {
    font-size: 12.5px;
    font-weight: 500;
    border-bottom: 0px;
}

.inventoryFleetRowRank .deployedRowType {
  color: #6e6f71 ;
  font-weight: 500 ;
}

.cellWidthForColmns {
  width: 33%;
}
.inventoryDeployedAction {
  cursor: pointer;
  color: #2860B4;
  padding-right: 4px;
  width: 25px;
  height: 32px;
}
.Deployed-FleetDashboard .inventory-group-column-hide{
  display: none;
}
.tableHead .group{
  cursor: pointer;
}

.inventory-am-table .deployedContainer {
  margin: 0px 0px 10px 0px;
}

.deployedContainer .Deployed-FleetDashboard {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}

.inventoryFleetRowRank .deployed-config {
  cursor: pointer;
}

.inventoryFleetRowRank .deployed-config>span {
  color: #0000ee;
}
.deployed-fleetRowDataDetails.deployedRowType.inventory-kass-exp-date.grey{
  color: red;
}
.deployed-fleetRowDataDetails .group-change-icon,
.deployed-fleetRowDataDetails .upload-config-icon,
.deployed-fleetRowDataDetails .backup-config-now-icon {
  margin-left: 8px;
  width: 25px;
}

.change-group-actions button {
  background-color: #264c86;
  color: #fff;
  border: 1px solid #c3d6e7;
  font-size: 11px;
  width: 145px;
  padding: 7px 15px;
  border-radius: 5px;
  height: 36px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.change-group-actions button:hover {
  background-color: #264c86;
}

.mainAssignActiontitle .change-group-title {
  margin-left: 0px;
}

.assignDialog .change-group-container {
  width: 436px;
}

.mainAssignActiontitle .change-group-cancel-icon {
  margin-left: auto;
}

.changeDp-content .changeDp-content-div {
  display: block;
  align-items: center;
}

.changeDp-content-div .change-group-dropdown {
  margin-top: 5px;
  margin-left: 15px;
  width: 330px;
}

.selectedDeviceDetails .current-group-name {
  font-weight: 500;
  color: #5d647c;
  font-size: 14px;
}

.changeDp-content-div .change-group {
  margin-left: 15px;
  padding-top: 6px;
}
.inventoryDeployedAction.disable,
.inventoryDeployedAction.group-change-icon.disable,
.inventoryDeployedAction.upload-config-icon.disable,
.inventoryDeployedAction.delete-site-icon.disable,
.inventoryDeployedAction.backup-config-now-icon.disable {
  cursor: not-allowed;
  opacity:0.5;
}
.parPageContainerOfDeployed .inventory-deployed-download {
  margin-left: 20px;
}
.deployed-fleetRowDataDetails.display-flex{
  display: flex;
}
.inventory-deployed-tab-action {
  justify-content: center;
}
.inventoryDeployedAction.upload-config-icon svg,
.inventoryDeployedAction.delete-site-icon svg,
.inventoryDeployedAction.backup-config-now-icon svg {
  color: #1B51A7;
  width: 25px;
  height: 32px;
}
.inventoryDeployedAction.upload-config-icon svg path,
.inventoryDeployedAction.delete-site-icon svg path,
.inventoryDeployedAction.backup-config-now-icon svg path {
  stroke: #ffffff;
  stroke-width: 0.6;
}
.inventoryDeployedAction.backup-config-now-icon svg{
 color:#1B51A7 ;
}

.deployed-container {
  margin-bottom: 10px;
}

.deployed-left-align .deployed-history-dp-name {
  color: #8B8D97;
  letter-spacing: 1px;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  padding-left: 10px;
  cursor: pointer;
}

.iconNowrap {
  display: flex;
}

.align-self-center {
  align-self: center;
}

.deployed-container .usage-tableBody .deployed-action {
  padding-left:10px;
  height: 25px;
  text-align: center;
  /* display: flex; */
}

.new-deployed-usage-tr .inter-font {
  font-family: 'Inter';
  font-weight: 500;
  padding: 5px 5px;
  font-size: 12px;
}

.MuiTextField-root.input-box-deployed {
  margin: 8px;
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
}

.inventory-deployed-container {
  height: calc(100vh - 170px);
  overflow-y: scroll;
}
.inventory-site-name-with-action-inprogress-icon, .inventory-site-name-with-action-inprogress-icon .warningIcon{
  display: flex;
  align-items: center;
}
.inventory-site-name-with-action-inprogress-icon .warningIcon img{
  height: 20px;
  width: 20px;
}
.inventory-ellipsis {
  max-width: 15ch;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}


.font-size-12px {
  font-size: 12px;
}

.inventorySerachAndSwitch {
  display: flex;
}

.inventoryCheckBox {
  display: flex;
  margin-top: 20px;
}

.device-history-popup .device-history-content {
  margin-top: 20px;
}

.device-history-content .device-history-table {
  border: 1px solid #EBEBEB;
}

.login-tableRow .device-history-th {
  text-align: left;
}

.login-tableRow .dh-width-350px {
  width: 300px;
}

.login-tableRow .dh-fullname {
  width: 105px;
}

.deployed-table-headers .CF-cell {
  text-align: left;
}

/* Edit Actions Dialogue */

.inventory-edit-icon {
  color: #4873b8;
  cursor: pointer;
}

.config-deploy-inventory-popup .edit-actions-title {
  border-bottom: 1px solid #efefef;
  background: white;
  padding: 18px 10px 18px 15px;
}

.config-deploy-inventory-popup .edit-actions-content {
  padding: 0px;
}

.inventory-edit-actions-container .MuiDialog-container {
  width: 900px;
  margin-left: -100px;
}

.config-deploy-inventory-popup .edit-change-group-btn {
  position: absolute;
  bottom: 20px;
  right: 25px;
  border: 1px solid #264c86;
  background: #264c86;
  color: #fff;
  height: 35px;
  border-radius: 4px;
  width: 125px;
}

.config-deploy-inventory-popup .edit-change-group-btn:hover {
  background: #264c86
}

.inventory-edit-actions-container .edit-action-changeDp-content-div {
  margin-top: 0px;
  display: block;
  margin-left: -10px;
}

.menuScroll .edit-actions-data-body {
  margin-top: 5px;
  margin-left: 5px;
}

.menuScroll .downloadConfig-actions, .Form .applyConfig-actions {
  padding: 0px;
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.downloadConfig-actions>button, .applyConfig-actions>button {
  letter-spacing: 0px;
}

.deploy-inventory-content .config-apply-config-target-width .user-entered-config-name {
  width: 210px;
}

.edit-actions-content .actions-device-data-body {
  max-height: 380px;
}

.menuScroll .edit-actions-overflow-hidden {
  overflow-x: hidden;
}

.edit-actions-confirm-btns {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  bottom: 20px;
  width: 63%;
  right: 20px;
}

.sub-tab-parent .edit-actions-subTitle {
  margin-top: 20px;
  color: rgba(0, 0, 0, 1);
  margin-right: 15px;
}

.EOS-version-div .EOS-version {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #676984;
}

.upgrade-license-btn-div button {
  height: 35px;
}

#actionType-disabled {
  cursor: auto;
}

#actionType-enabled .font-status-pop-up {
  color: rgba(69, 70, 78, 1);
  cursor: pointer;
}

#actionType-disabled .font-status-pop-up {
  color: #c4c4c4;
  cursor: auto;
}

.edit-actions-content .deployed-device-list {
  height: 380px;
  overflow-y: auto;
  margin-top: 5px;
}

.edit-actions-content .inventory-deployed-device-list {
  overflow-y: auto;
  margin-top: 5px;
}

.status-lists .selectedAction {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  color: #0E0548;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.status-lists .NotselectedAction {
  color: rgba(69, 70, 78, 1);
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.edit-actions-data-body .edit-actions-confirm-btns {
  display: block;
}

.edit-actions-confirm-btns .confirm-dialogue-new-divider {
  margin-bottom: 10px;
}

.edit-actions-confirm-btns .confirm-dialogue-new-btns {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed {
  box-shadow: 0px 0px 13px 0px #CBCBCB59;
  background-color: #1B51A7;
  color: #ffffff;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed:hover {
  background-color: #1B51A7;
}

.confirm-dialogue-new-btns .confirm-dialogue-cancel {
  color: #0E0548;
  height: 30px;
  width: 100px;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed-notAllow {
  background-color: rgb(232, 240, 253);
  color: rgb(38, 76, 134);
  border: 1px solid #676984;
  cursor: auto;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed,
.confirm-dialogue-new-btns .confirm-dialogue-cancel,
.confirm-dialogue-new-btns .confirm-dialogue-proceed-notAllow {
  height: 30px;
  width: 100px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-transform: none;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed-notAllow:hover {
  background-color: rgb(232, 240, 253);
}

#content-left-text {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  width: 190px;
}

#managed-config-content-left-text span {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  width: 190px;
}

#content-right-text {
  color: rgba(43, 35, 94, 1);
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
}

.deploy-inventory-content .new-selected-config-details {
  line-height: 10px;
  margin-left: 190px;
  width: 400px;
}

.change-org-dp-dropdown .DPvesselDropdown .MuiButtonBase-root {
  width: 250px;
}

.EOS-version-div .org-change-remove-margintop, .selected-config-details .register-remove-margin-top {
  margin-top: 0px;
}

.EOS-version-div .org-change-10px-margintop, .apply-inventory-content .config-apply-config-target {
  margin-top: 10px;
}

.edit-actions-title .selected-site-device {
  margin-left: 182px;
}

.edit-actions-title .selected-site-device.starlink-admin-operations-popup-service-line-name {
  margin-left: 184px;
}

.edit_confirm_dialog_sub_title_text {
  font-size: 14px;
  white-space: pre-line;
}

.status-lists.manage-config {
  display: block;
}

.manage-config-options {
  padding-left: 10px;
}

.manage-hub-ip-config-options {
  padding-left: 20px;
}

.manage-config-options .selected-manage-config-type,
.selected-manage-config-hub-ip-type {
  font-weight: 500;
  color: rgba(69, 70, 78, 1);
}

.manage-config-options div,
.manage-hub-ip-config-options div {
  padding-bottom: 10px;
  cursor: pointer;
  color: rgba(69, 70, 78, 1)
}

.status-lists.manage-config .font-status-pop-up,
.manage-config-hub-ip-option {
  display: flex;
  align-items: center;
}

.status-lists.manage-config .font-status-pop-up > svg,
.manage-config-hub-ip-option > svg {
  margin-left: 5px;
}

.edit-actions-content .Mui-selected.status-lists,
.edit-actions-content .Mui-selected.status-lists:hover,
.edit-actions-content .Mui-selected.status-lists:focus {
  background-color: #F4F5FA;
}

.change-license-info-div {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.change-license-info-div .helpIcon-reports, #helpIcon-reports {
  color: #2860b4;
}

.change-license-info-div .help-icon-actions {
  width: 20px;
  position: relative;
  top: 2px;
}

.deploy-inventory-content .change-license-info-msg {
  font-family: 'Roboto';
  font-style: normal;
  line-height: 20px;
  font-size: 13px;
  margin: 30px 20px 20px 0px;
}

.new-deployed-usage-tr .konnectStatus-site-cell {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}

.source-device-serving-hub-info {
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 37px;
}
.source-device-serving-hub-info .serving_hub-name{
  font-weight: 500;
}

.edit-actions-data-body .width-76-percent {
  width: 76%;
}

.usageReports-tableHead .login-tableRow .history-device-change {
  width: 28%;
}

.login-tableRow .align-left {
  text-align: left;
}

.loginHistory-tableRow .short-text-change {
  max-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loginHistory-tableRow .short-text-username {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .status,
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .status {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .status .text,
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .status .text{
  max-width: 100px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .status .text,
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .status .text,
.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .noData {
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  background: #FFFFFF;
  text-transform: none;
}

.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .noData {
  text-align: center;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content {
  margin-top: 0px;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tab-content {
  padding: 15px 0 0 0;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #8b8d97;
  border-bottom: 2px solid transparent;
}
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader button span {
  width: 100%;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader button,
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader button:hover {
  padding: 12px 0 10px;
  margin-right: 15px;
  text-transform: None;
  cursor: pointer;
  border-radius: 0;
  /* min-width: unset; */
  min-height: 42px;
  height: 42px;
  background-color: transparent;
  outline: none;
  transition: none;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader .Mui-selected {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #2f4b82 !important;
  border-bottom: 2px solid #2f4b82;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .noDataAvailableCss {
  font-family: "Inter";
  text-align: center;
}
.device-organization-config>div {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #45464E;
    font-family: 'Inter';
    font-size: 14px;
}

.device-organization-config-no-padding>div {
    padding: 0px;
}

.device-organization-config .current-organization {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.device-organization-config .content-left {
    width: 40%;
    display: flex;
    align-items: center;
}

.device-organization-config .content-right {
    display: flex;
    align-items: center;
    width: 55%;
}

.device-organization-config .content-right.action {
    justify-content: space-between;
}

.device-organization-config .content-right.action .action-button {
    color: #fff;
    border: 1px solid #1B51A7;
    text-transform: none;
    width: 150px;
    padding: 3.5px;
    box-shadow: 0px 0px 13px 0px #CBCBCB59;
    background: #1B51A7;
    letter-spacing: unset;
    font-weight: 600;
    font-family: "Inter";
    background-color: #244c84;
    border-radius: 4px;
}

.device-organization-config .current-organization {
    width: 200px;
}

.device-organization-config .change-org-dp-dropdown .DPvesselDropdown .MuiButtonBase-root {
    width: 200px;
    height: 37px;
}

.device-organization-config .change-org-width-250px .DPvesselDropdown .MuiButtonBase-root {
    width: 250px;
    height: 37px;
}

.device-organization-config  .action-button-disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    box-shadow: 0px 0px 13px 0px #cccccc;
    text-transform: none;
}

.device-organization-config .action-button-disabled:hover {
    background-color: #cccccc;
}

.Note-Message {
    font-size: 14px;
    margin-top: 30px;
    font-family: 'Inter';
    line-height: 18px;
    color: #666666;
}

.custom-word {
    font-weight: 600;
    color: #45464e;
}

.edit-actions-subTitle .move-to-warehouse-confirm-btn {
    color: #1b51a7;
    border: 1px solid #1b51a7;
    text-transform: capitalize;
    width: 65px;
    padding: 2px;
    box-shadow: 0 0 13px 0 hsla(0, 0%, 79.6%, .34901960784313724);
    margin-right: 25px;
    cursor: pointer;
}

.content-right .de-red-reason-text input {
    width: 100%;
}

#errorspan-inventory {
    font-size: 12px;
    font-family: 'Inter';
    color: red;
    margin-left: 370px;
    margin-top: -8px;
}

.change-org-dp-dropdown .DPvesselDropdown .DPtextWrap, .change-org-dp-dropdown .DPvesselDropdown .MuiTouchRipple-root {
    width: 100%;
}

.change-org-dp-dropdown .DPdropdownArrow {
    margin-left: unset;
}

.popup-fixed-position+#DPmenu-ou .MuiPaper-root {
    width: 250px;
}

.popup-fixed-position+#DPmenu-ou .DPchildHierarch .MuiTypography-root {
    text-overflow: ellipsis;
    overflow: hidden;
}

.change-org-width-250px .DPvesselDropdown .MuiButtonBase-root {
    border-radius: 8px;
}
.inventory-edit-actions-container .device-config-pop-config-list-menu {
    width: 20%;
}

.inventory-edit-actions-container .device-config-pop-config-list-menu-content {
    width: 80%;
    padding: 10px;
}

.inventory-edit-actions-container .device-config-pop-config-list-menu-content .MuiDialogContent-root:first-child {
    padding-top: 0px;
}

.create-default-config-title.edit-actions-title .device-configuration-popup .selected-site-device.device-configuration-popup-devicename{
    margin-left: 44px;
}

.popup-fixed-position .MuiDialog-paperFullWidth {
    position: fixed;
    top: 82px;
    left: 200px;
    width: 836px;
    max-height: calc(100vh - 130px);
}

#popup-fixed-position-200px .MuiDialog-paperFullWidth {
    left: 200px;
}

#popup-fixed-position-77px .MuiDialog-paperFullWidth {
    left: 77px;
}

.desc:hover{
    background-color: #f1f1f1;
}

.errorspan{
    font-size: 15px;
    font-style: italic;
    padding: 5px 10px;
    color: red;
}

.font14{
    font-size: 14px !important;
}

.noteDialog{
    border-radius: '13px' ; 
    margin-top: "-100px";
    width:'400px';
}

.flex{
    display: flex;
}

.justify-center{
    justify-content: center;
}

.notePadding{
    padding: 0px 20px 20px 20px;
    color: #273050;
}

.width-80{
    width: 80%;
}

.noteGrid{
    padding: 2rem 52px;
    height: calc(100vh - 185px);
    overflow-y: auto;
}

.notesHistory {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    border-radius: 10px;
    width: 30%;
}

.textUpperCase{
    text-transform: uppercase;
}

.fontBold{
    font-weight: bold;
}

.notesHistoryForm{
    color: #2860B4;
    font-size: 14px !important;
}

.sectionStyle{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    border-bottom: 2px solid #2860b4;
    transition: 0.2s all ease-in-out;
    height: 30px;
    width: 70%;
    cursor: pointer;
}

.subjectstyle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: white;
    margin: 5px 10px;
    border: 5px;
}

.noteGrid .no-notes-div-per-site {
    height: 45px;
    border: 1px solid rgb(199, 218, 235);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
}
.createBtn {
    width: 150px;
    height: 38px;
}

.ParentContainer .UpdateBtn {
    margin-right: 30px;
    width: 100px;
    height: 38px;
    background: #2860B4 !important;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #C7DAEB;
}

.ParentContainer .syncBtn {
    margin-right: 30px;
    width: 100px;
    height: 38px;
    background: #2860B4 !important;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #C7DAEB;
}

.createParentContainer .CreateBtn, .createParentContainer .CreateBtn-cursor-not-allowed{
    margin-right: 30px;
    width: 100px;
    height: 38px;
    border-radius: 8px;
    border: 1px solid #C7DAEB;
}
.createParentContainer .CreateBtn,.createParentContainer .CreateBtn:hover {
    background: #2860B4;
    color: #ffffff;
}
.createParentContainer .CreateBtn-cursor-not-allowed {
    cursor: not-allowed;
    color: #666666;
}
.ParentContainer>div:nth-child(1) {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.ParentContainer>div:nth-child(3) {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.autoCompleteDiv {
    width: 250px;
}

.radioButtonClick {
    margin-top: 3px;
    width: 150px;
    display: flex;
    grid-gap: 2px;
    gap: 2px;
}

.servingDevice {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2860b4;
    font-weight: 400;
}

.update-syncBtn {
    margin-left: 350px;
}

.createParentContainer>div:nth-child(1) {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.createParentContainer>div:nth-child(3) {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.ha-logs{
    margin-top: 10px;
    overflow: auto;
}
.ha-logs th{
    border-top: 1px solid rgba(224, 224, 224, 1);
    background-color: #f5f5f5;
}
.ha-logs .ha-logs-header-metadata {
    text-align: center;
}
.ha-logs th, .ha-logs td {
    border-left: 1px solid rgba(224, 224, 224, 1);
}
.ha-logs-table-div {
    background-color: #ffffff;
}
.ha-logs-filter-div{
    margin-top: 20px;
    display: flex;
}
.ha-logs-filter-div .MuiFormControl-root{
    margin-left: 0px;
    width: 20%;
}
.ha-logs-filter-div .empty {
    width: 5%;
}
.ha-logs-filter-div .empty, .ha-logs-filter-div .filter-button-div {
   margin-top: 8.9px;
}
.ha-logs-filter-div .filter-button-div button{
    background-color: #ffffff;
}
.ha-log-filters-pop-up {
    margin-left: 9.7%;
    display: flex;
    justify-content: flex-start;
    margin-top: 8.5%;
}
.ha-log-filters-pop-up .dialog_title2{
    padding: 10px 10px 0px 10px;
}
.ha-log-filters-pop-up ul, .ha-log-filters-pop-up .expand-summary{
    padding-top: 0px;
}
.ha-logs-filter-div .filter-button-div button{
    border: 1px solid rgba(20, 99, 172, 0.24);
    display: flex;
    justify-content: center;
}
.ha-logs-filter-div .filter-button-div button img {
    width: 16px;
    height: 16px;
    padding: 5px;
}
.cursor-not-allowed{
    cursor: not-allowed;
}

.audit-container-data .HA-pagination {
    margin-bottom: 0px;
}

.ha-main-container {
    padding: 15px 22px;
}
.alert-config-scroll {
    height: calc(100vh - 205px);
    overflow-y: auto;
}
.site-level-config-container {
    margin-top: 2%;
}
.site-level-alerts-accordTree {
    padding: 0.2em 2em 0.2em 2em;

    .accordionTableHeader {
        border: 1px solid #ddd;
        background: #F5F5F5;
        display: flex;
        border-radius: 4px 4px 0px 0px;
    }

    .accordionTableHeader .eachAccordionHeadItem {
        border-right: 1px solid #ddd;
        padding: 10px;
        text-align: center;
        box-sizing: border-box;
    }

    .eachAccordionHeadItem .eachAccordColHead {
        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
    }

    .eventsHeading {
        padding: 2px;
    }

    .alertNameText input {
        padding: 5px;
        font-size: 12px;
    }

    .alertDescriptionText {
        width: 94%;
    }

    .alertDescriptionText input {
        padding: 5px;
        font-size: 12px;
    }

    /* .gateSection {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
  } */

    .radioButton {
        margin-left: 50px;
    }

    .GreenColor {
        color: #25C381;
    }

    .RedColor {
        color: red
    }

    .YellowColor {
        color: rgb(233, 233, 3)
    }

    .OrangeColor {
        color: #CF833D;
    }

    .GreenColor,
    .RedColor,
    .YellowColor {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        font-family: 'Inter';

    }

    .alertStatusAccordhead {
        display: flex;
        width: 100%;
    }

    .alertStatusWithIcon {
        display: flex;
        align-items: center;
        margin-left: 2px;
    }

    .alertStatusAccordhead .alertName {
        margin-left: 30px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Inter';
    }

    .alertStatusAccordhead .alertDescription {
        color: grey;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Inter';
    }

    .alertStatusAccordhead .gateName,
    .eventsHeading .rulesHead {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
    }

    .alertStatusAccordhead .gateName {
        text-transform: uppercase;
        color: #2F4B82;
        height: 15px;
    }

    /* .firstInnerTableCol {
    height: 40px;
    display: flex;
    align-items: center;
    border-right: 1px solid #ddd;
    min-width: 165px;
  } */

    .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        left: 0;
    }

    .childAccordion .accordionSummaryAlert {
        padding: 0px;
    }

    /* .accordionSummaryAlert .MuiAccordionSummary-content {
    align-items: stretch;
  } */
    .accordionSummaryAlert .eachChildAccordCol {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ddd;
        box-sizing: border-box;
    }

    .accordionSummaryAlert.childAccord .MuiAccordionSummary-content {
        align-items: stretch;
    }

    .accordian .alertsAccordions {
        box-shadow: none;
        padding: 0.7em 2em 0.7em 2em;
        background-color:#f5f5f5;
    }

    .accordian .alertsAccordions .accordionSummaryAlert.Mui-expanded {
        border-bottom: 1px solid #ddd;
    }

    .statusName {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        font-family: 'Inter'
    }

    .cursor-auto {
        cursor: auto;
    }


    .display-flex {
        display: flex;
    }

    .moreLabelsDialog .MuiDialog-scrollPaper {
        justify-content: end;
    }

    .moreLabelsDialog .moreLabelsDialogGrid {
        padding: 10px 5px 15px 5px;
        grid-gap: 15px;
        max-height: 150px;
        overflow-y: auto;
    }

    .moreLabelsDialogCloseIconGrid {
        height: 15px;
        display: flex;
        justify-content: end;
    }

    .moreLabelsDialogCloseIconGrid img {
        height: 25px;
        width: 25px;
        cursor: pointer;
    }

    .moreLabelsDialog .MuiPaper-root {
        margin-top: -11%;
    }


    .create_alert--form_group {
        display: flex;
        grid-gap: 0.65rem;
        gap: 0.65rem;
        margin: 1rem;
        flex-wrap: wrap;
    }

    .create_alert--form_group--input {
        border: 1px solid #E7E7E7;
        border-radius: 4px;
        padding: 0.65rem 0.75rem;
    }

    .create_alert--form_group--input_text {
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 0.8rem;
        color: #45464E;
    }

    .dpAlertTypeHeader {
        font-size: 12px;
    }

    .dpAlertTypeInputContainer {
        display: flex; 
        flex-direction: column;
        justify-content: flex-start;

    }
    
    .dpLAInvalidThreshold {
        font-size: 10px;
        color: red;
    }

    .dpAlertTypeInputSubContainer {
        display: flex; 
        flex-direction: row;
        justify-content: flex-start; 
        align-items: center; 
        grid-gap: 1em; 
        gap: 1em;
    }

    .dpAlertLAContainer {
        margin-top: 10px;
    }

    .linkAvailabilityWanTypeSelect {
        width: 120px; 
        height: 35px;
    }

    .dpAlertTypeLAInputBox {
        width: 100px;
    }

    .dpAlertTypeInputBox {
        width: 200px;
    }

    .dpAlertTypeHeaderIcon {
        color: #2F4B82;
    }

    .dpAlertTypeFormIcon {
        cursor: pointer;
        color: grey;
    }

}

.site-level-alerts-accordTree li {
    list-style-type: none;
    position: relative;
}

.site-level-alerts-accordTree li li {
    list-style-type: none;
    position: relative;
}

.site-level-alerts-accordTree li li:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid #ddd;
    width: 20px;
    height: 15px;
}

.site-level-alerts-accordTree li li:before {
    border-bottom: 1px solid #ddd;
}

.site-level-alerts-accordTree li li:after {
    position: absolute;
    content: "";
    top: 5px;
    left: -20px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    width: 20px;
    height: 100%;
}

.site-level-alerts-accordTree li li:last-child:after {
    display: none;
}

.accordionContainer {
    width: 100%;
}



.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    height: 20px;
}

.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiSvgIcon-root {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    height: 20px;
}

.accordionSummaryAlert .MuiAccordionSummary-content {
    align-items: center;
    margin: 0px;
}

.accordian .accordionSummaryAlert {
    /* border-bottom: 1px solid #ddd; */
    min-height: auto;
}

/* .gateSection,.accordionHead {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #ddd;
  } */



.site-level-alerts-accordTree ul {
    margin: 0px;
}


.site-level-alert-logs {
    margin: 0.2em 2em 0.2em 2em;

    .audit-data-container .audit-logs-font,
    .audit-filters-container .audit-logs-font {
        font-size: 12px;
        font-family: 'Inter';
        color: #45464E;
    }

    .audit-data-container .audit-logs-font-header {
        font-size: 12px;
        font-family: 'Inter';
        font-weight: 600;
        color: #45464E;
    }

    .audit-filters-container .audit-filter {
        font-size: 14px;
        font-family: 'Inter';
        font-weight: 500;
        color: #45464E;
    }

    .audit-container {
        padding: 10px 13px;
        margin-top: 75px;
        height: calc(100vh - 150px);
        overflow-y: scroll;
    }

    .audit-layout {
        background-color: #f9f9f9;
        /* position: absolute;
    left: 85px;
    top: 50px;
    right: 0;
    bottom: 0; */
        padding: 40px 5px 5px 5px;
    }

    .audit-filter-list {
        display: flex;
        align-items: center;
    }

    .audit-filter {
        font-size: 14px;
    }

    .audit-data-container .audit-filter-list .audit-filter {
        font-size: 12px;
        font-weight: 600;
    }

    .audit-filter-list .filter-clear-all {
        margin-left: auto;
        color: #2F4B82;
        text-transform: none;
        text-decoration: none;
    }

    .expand-accordin-root.MuiAccordion-root {
        width: 100%;
    }

    .expand-summary .expand-summary-content,
    .expand-summary .expand-summary-content.Mui-expanded {
        margin: 5px 0;
        color: #45464E;
    }

    .expand-summary.Mui-expanded[aria-expanded="true"] {
        min-height: 48px;
    }

    .filter-accordian {
        font-size: 12px;
        font-weight: 600;
        padding-left: 17px;
    }

    .no-padding {
        padding: 0px;
    }

    .rc--form-control .no-padding {
        padding: 0px;
    }

    .padding-control .no-padding-top-btm {
        padding-top: 0;
        padding-bottom: 0;
    }

    .filter-accordian-label {
        font-size: 10px;
        font-weight: 500;
    }

    .audit-log-item .filter-meta {
        font-size: 12px;
        font-weight: 600;
        font-family: 'Inter';
    }

    .audit-filter-inputs .MuiInputBase-root {
        height: 34px;
        font-size: 12px;
    }

    .audit-filter-inputs .MuiInputLabel-root {
        font-size: 12px;
    }

    .audit-data-head .audit-data-header {

        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */
        letter-spacing: 1px;
        -webkit-font-feature-settings: "smcp";
                font-feature-settings: "smcp";
        font-variant: small-caps;
        /* Tertiary black */
        color: #8B8D97;
    }

    .audit-filters-container {
        /* display: flex;
    gap: 10px;
    flex-direction: column;
    height: calc(100vh - 80px); */
    }

    .filter_menu_container_auditlogs {
        margin: 110px 0px 0px 210px;
    }

    /* .filter_menu_container_auditlogs>.MuiPopover-paper {
        overflow-y: hidden;
    } */

    .audit-btn-parent .audit-filter-apply {
        text-transform: none;
        background-color: #2F4B82;
        border-radius: 4px;
        margin: auto 15px 15px 15px;
        font-size: 12px;
        font-family: 'Inter';
        font-weight: 600;
        width: 90%;
        margin: 0 10px;
    }

    /* .audit-filter-items {
        overflow-y: hidden;
    } */

    .audit-table-data.MuiTable-root {
        overflow-y: auto;
    }

    .audit-table-data.MuiTable-root .MuiTableHead-root th {
        background-color: #f5f5f5;
    }

    /* .audit-table-data.MuiTable-root .MuiTableHead-root th:not(:first-child):not(:last-child),
.audit-table-data.MuiTable-root .MuiTableBody-root td:not(:first-child):not(:last-child) {
    border-right: 1px solid rgba(224, 224, 224, 1);
} */

    .audit-data-container {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
    }

    .audit-data-pagination {
        margin-top: auto;
    }

    .audit-log-item {
        display: flex;
        width: 100%;
        grid-gap: 10px;
        gap: 10px;
        flex-direction: column;
    }

    .syntax-items {
        background-color: #F4F5FA;
        display: flex;
        width: 100%;
        min-height: 50px;
        position: relative;
        flex-direction: column;
        counter-reset: syntax-line-number;
        border-radius: 4px;
    }

    .syntax-items .syntax-row {
        counter-increment: syntax-line-number;
        display: grid;
        grid-column-gap: 5px;
        -webkit-column-gap: 5px;
                column-gap: 5px;
        grid-template-columns: minmax(15px, 25px) 1fr;
    }

    .syntax-items .syntax-row>pre {
        margin: 0;
    }

    .syntax-items .audit-syntax-copy {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .audit-syntax-copy .copy-audit-metas {
        background-color: #2F4B82;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-transform: none;
    }

    .syntax-items .syntax-row>* {
        padding: 1px;
        color: #6E7079;
        font-size: 12px;
        font-family: 'Inter';
    }

    .syntax-items .syntax-row:first-child>* {
        padding-top: 8px;
    }

    .syntax-items .syntax-row:last-child>* {
        padding-bottom: 8px;
    }

    .syntax-items .syntax-line-item {
        background-color: #E3E6F3;
        color: #2F4B82;
        -webkit-user-select: none;
                user-select: none;
        text-align: center;
        font-weight: 500;
        font-family: 'Inter';
        font-size: 12px;
    }

    .syntax-items .syntax-line-item::before {
        content: counter(syntax-line-number);
    }

    .copy-audit-metas .MuiButton-startIcon .copy-icon {
        font-size: 12px;
    }

    .accordian-expand .accordian-arrow {
        font-size: 16px;
        color: #6E7079;
    }

    .pagination-position {
        height: 40px;
    }

    .audit-container .audit-container-data {
        width: 100%;
        /* overflow: hidden; */
        height: auto;
        overflow-y: auto;
        border: 1px solid #EBEBEB;
        border-radius: 0px;
        box-shadow: none;
    }

    .audit-table-root .audit-logs-no-data {
        font-size: 12px;
        font-family: 'Inter';
    }

    .audit-loader {
        position: absolute;
        top: 14px;
        right: 28px;
    }

    .audit-filter-items .audit-pop-up-height {
        overflow-y: auto;
        max-height: 50vh;
        margin-bottom: 10px;
    }
}
.charts {
    width: 100%;
}

.tab-container {
    background: #FFFFFF;
}

.topologyConatiner {
    background: #FFFFFF;
}

.topology--tabs {
    margin-top: 15px;
}
.buttonCssFleet_width.help{
    float: right;
    margin-right: 40px;
    margin-top: 3px;
    padding: 0px;
    cursor: pointer;
}
.buttonCssFleet_width.help:hover{
    background-color: transparent;
}
.helpIcon{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    color:#2860B4
}

.mr-15{
    margin: 15px;
}

.back-btn-topology .margin-left15 {
    margin-left: 19px;
}

.dis-flex {
    display: flex;
}

.topology-back {
    color: #fff;
    cursor: pointer;
}

.align-back {
    margin-left: 15px;
    vertical-align: middle;
    align-self: center;
    cursor: pointer;
}

.ha-scroll {
    height: calc(100vh - 185px);
    overflow-y: scroll;
}
.rc--topbar_contianer {

    background: #FFFFFF;
    border: 1px solid #EBEBEB;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
    align-items: center;
    padding: 1em 0;
}

.rc--topbar_site_meta, .rc--topbar_site_filters {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1em;
    gap: 1em;    
    padding-right: 1em;
}

.rc--topbar_site_name {
    font-style: normal;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 1.3em;
    color: #45464E;
}

.rc--topbar_site_id_link {
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 0.9em;
    letter-spacing: 0em;
    text-align: left;
    padding: 0.35em 0.4em;
    color: #6E7079;
}

.rc--topbar_filter_menu .rc--topbar_device {
    display: flex;
}

#select-device-label {
    font-family: 'Inter';
}

.rc--topbar_site_filters--search_icon.MuiInputAdornment-root {
    cursor: pointer;
}

.MuiTextField-root.input-box-rc-tb {
    width: 300px;
}

.rem-con-address {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    padding-left: 1em;;
    text-align: left;
    align-self: center;
}

.rcTopbar {
    flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
    margin-left: 8px;
}
.rc--filter_menu_container {
    width: 274px;
    max-height: 77vh;
    overflow-y: hidden;
}

.rc--filter_header {
    cursor: pointer;
}

.rc--apply_filter.MuiButton-root {
    background: #1B51A7;
    border-radius: 4px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-transform: none;
    width: 103px;
    height: 29px;
    left: 62px;
    padding: 0px;
}

.remoteConnectionsMenu .remoteConnectionFilterShadow {
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
    overflow-y: hidden;
}

.rc--apply_filter.MuiButton-root:hover { 
    background: #1B51A7
}

.rc--filter_footer {
    display: flex;
    padding: 20px 16px 12px 16px;
    align-items: center;
}

.rc--filter_menu--filter_button.MuiButton-root {
    border: 1px solid #1976d2;
    border-radius: 4px;
    color: #1976d2;
    font-family: "Inter";
}

.rc--filter_menu--filter_button.MuiButton-root:hover { 
    border: 1px solid #1976d2;
}

.pad-top-3 {
    padding-top: 3px;
}

.margin-l-115 {
    margin-left: 115px;
}

.rc-filter-header-padding {
    padding:  10px 16px 18px 16px;
    height: 20px;
}

.remoteConnectionsOptionOverflow {
    overflow-y: auto;
    height: calc(100vh - 480px);
    max-height: 297px;
}

.rc--generate_link_button.MuiButton-outlined {
    background: #FFFFFF;
    border: 1px solid #1B51A7;
    color: #1B51A7;
    border-radius: 4px;
    padding: 0.25em 2em;
    text-transform: none;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
}

.rc--generate_link_button.MuiButton-outlined:hover {
    border: 1px solid #1B51A7;
}

.rc--generate_link_dialog--title_container {
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
    border-bottom: 1px solid #EBEBEB;

    font-weight: 500;
    line-height: 3.25rem;
    color: #45464E;
}

.rc--generate_link_dialog--content {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    grid-gap: 1rem;
    gap: 1rem;

}

.rc--generate_link_dialog--actions {
    display: flex;
    padding: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: end;
    align-items: center;
}

.rc--generate_link_dialog--content--row {
    display: flex;
    align-items: center;
}

.rc--generate_link_dialog--content--row--title {
    display: flex;
    flex-grow: 0.4;
    flex-shrink: 0.4;
    flex-basis: 0;
}

.rc--generate_link_dialog--content--row--data {
    display: flex;
    flex-grow: 0.6;
    flex-shrink: 0.6;
    flex-basis: 0;
}

.rc--submit.MuiButton-root {
    background: #2F4B82;
}

.rc--submit.MuiButton-root:hover {
    background: #2F4B82;
}

.pad-top-10 {
    padding-top: 10px;
}
.ant-switch.MuiSwitch-root {
    width: 28px;
    height: 16px;
    padding: 0;
    display: 'flex';
}

.ant-switch:active .MuiSwitch-thumb {
    width: 15px;
}

.ant-switch:active .MuiSwitch-switchBase.Mui-checked {
    -webkit-transform: translateX(9px);
    transform: translateX(9px);
}

.ant-switch .MuiSwitch-switchBase {
    padding: 2px;
}

.ant-switch .MuiSwitch-switchBase.Mui-checked {
    -webkit-transform: translateX(12px);
    transform: translateX(12px);
    color: #fff;
}

.ant-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: #2F4B82;
}

.ant-switch .MuiSwitch-thumb {
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ant-switch .MuiSwitch-track {
    border-radius: 12px;
    opacity: 1;
    background-color: rgba(0, 0, 0, .25);
    box-sizing: border-box;
}
.rc--links_table .MuiTableContainer-root{
    overflow: hidden;
}

.rc--links_table .MuiTableCell-root {
    border: 1px solid #EBEBEB;
    border-collapse: collapse;
}

.rc--links_table .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
}

.rc--links_table .MuiTableCell-body {
    padding: 0.5em;
}

.rc--copy_button.MuiIconButton-root {
    margin-left: 0.5rem;
}

.rc--links_table .MuiTableCell-root.uppercase {
    text-transform: uppercase;
}

.rc--links_table--column--actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rc--links_table .MuiTableCell-body {
    word-break: break-all;
}

.rem-con-editable-row .rem-con-table-body {
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    padding: 10px 5px 10px 5px;
    font-weight: 500;
    width: 80px;
}

.rem-con-table-body.width30 {
    width: 30px;
}

.rem-con-table-body.width120 {
    width: 120px;
    text-wrap: nowrap;
}

.rem-con-editable-row .rem-conn-data-width {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 75px;
}

.rm-conn-scroll {
    height: calc(100vh - 180px);
    overflow-y: auto;
}

.remcon-tableRow .rem-con-table-fleetDataTable {
    color: #8B8D97;
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* width: 16%; */
    padding: 0px;
}

.rc-editable-date .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 35px;
}

.rc-editable-date .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px;
}

.remcon-tableRow .rc-type-width {
    width: 6%;
}

.remcon-tableRow .rc-expiryDate-width {
    width: 13%;
}

.remcon-tableRow .rc-actions-width {
    width: 14%;
}
.rc--sd_tab_list {
    overflow: hidden;
}

.rc--connect_icon {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
}

.rc--sd_tab--tooltip {
    display: flex;
    flex-direction: column;
}

.rc--sd_tab--tooltip .uppercase {
    text-transform: uppercase;
}

.link-status {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    padding-top: 15px;
    padding-left: 10px;
}

.rem-conn-all {
    font-weight: 500;
    font-size: 14px;
    line-height: 0.95em;
    color: #45464E;
    height: 25px;
}
.alertslabel {
    margin-right: 20px;
    font-size: 12px;
}

.alertStatelabel {
    margin-left: 20px;
    font-size: 12px;
}

.searchBar {
    width: 240px;
}

.alertsFilterContainer .alertsFilterContainerBox {
    display: flex;
    align-items: center;
}

.manual-backup-config-container .alertsFilterContainer .alertsFilterContainerBox {
    display: flex;
    align-items: center;
}

a .alertButton {
    height: 34px;
    background: #2860C8;
}

.AlertsContainer .alertsCount {
    margin:10px 0px;
}

.AlertsContainer{
    padding: 15px 21px;
}

.firingSpan {
    color: #F35656;
}

.tab-conatiner .AlertsContainer {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.alert_dashboard_pagination {
    width: 100%;
}

.alert-config-scroll {
    height: calc(100vh - 205px);
    overflow-y: auto;
}
.accordTree li {
  list-style-type: none;
  position: relative;
}
.accordTree li li {
  list-style-type: none;
  position: relative;
}
.accordTree li li:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -20px;
  border-left: 1px solid #ddd;
  width: 20px;
  height: 15px;
}

.accordTree li li:before {
  border-bottom: 1px solid #ddd;
}

.accordTree li li:after {
  position: absolute;
  content: "";
  top: 5px;
  left: -20px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 20px;
  height: 100%;
}
.accordTree li li:last-child:after {
  display: none;
}

.accordionContainer {
  width: 100%;
}

.accordian .Mui-expanded .chipsContainer .MuiChip-root {
  /* height: 18px; */
  border-bottom: 1px solid #ddd;
}

.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper  .MuiSvgIcon-root  {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  height: 20px;
}

.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiSvgIcon-root  {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  height: 20px;
}

.chipsContainer .MuiChip-root {
  margin-right: 5px;
  color: #6E7079;
  margin-left: 1px;
}

.chipsContainer {
  white-space: nowrap;
  overflow-y: hidden;
}

.accordionSummaryAlert .MuiAccordionSummary-content {
  align-items: center;
  margin: 0px;
}

.chipsContainer .chipKey {
  color: #2F4B82;
}

.chipsContainer .chipValue {
  color: #6E7079;
}

.accordian .accordionSummaryAlert {
  /* border-bottom: 1px solid #ddd; */
  min-height: auto;
}

/* .gateSection,.accordionHead {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #ddd;
} */



.accordTree ul {
  margin: 0px;
}

.accordionTableHeader {
  border: 1px solid #ddd;
  background: #F5F5F5;
  display: flex;
  border-radius: 4px 4px 0px 0px;
}

.accordionTableHeader .eachAccordionHeadItem {
  border-right: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
}

.eachAccordionHeadItem  .eachAccordColHead {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
}

.eventsHeading {
  padding: 2px;
}

.alertNameText input {
  padding: 5px;
  font-size: 12px;
}
.alertDescriptionText {
  width: 94%;
}
.alertDescriptionText input {
  padding: 5px;
  font-size: 12px;
}

.gateSection .alertNameHead {
  font-size: 12px;
  padding-left: 2px;
  padding-bottom: 2px;
}

/* .gateSection {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
} */

.gateBox {
  border: 1px solid #ddd;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
}

.gateBox .andGateText {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #2F4B82;
}
.gateContainer {
  display: flex;
}

.radioButton {
  margin-left: 50px;
}

.GreenColor {
  color: #25C381;
}

.RedColor {
  color: red
}

.YellowColor {
  color: rgb(233, 233, 3)
}

.OrangeColor {
  color: #CF833D;
}

.GreenColor,.RedColor,.YellowColor {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  font-family: 'Inter';

}

.alertStatusAccordhead {
  display: flex;
}

.alertStatusWithIcon {
  display: flex;
  align-items: center;
  margin-left: 2px;
}

.alertStatusAccordhead .alertName {
  margin-left: 30px;
  margin-right: 30px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter';
}

.alertStatusAccordhead .gateName ,.eventsHeading .rulesHead {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.alertStatusAccordhead .gateName {
  text-transform: uppercase;
  color: #2F4B82;
  height: 15px;
}

.gateName .MuiChip-label {
  padding: 6px;
}

.gateChipContainer {
  display: flex;
  align-items: center;
}

/* .firstInnerTableCol {
  height: 40px;
  display: flex;
  align-items: center;
  border-right: 1px solid #ddd;
  min-width: 165px;
} */

.accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  left: 0;
}

.childAccordion .accordionSummaryAlert {
  padding: 0px;
}

/* .accordionSummaryAlert .MuiAccordionSummary-content {
  align-items: stretch;
} */
.accordionSummaryAlert .eachChildAccordCol {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
} 

.accordionSummaryAlert.childAccord .MuiAccordionSummary-content  {
  align-items: stretch;
}

.accordian .alertsAccordions {
  box-shadow:0 0 2px rgba(224, 224, 224, 1);
}

.alerts-alertsConfig-alertsAccordions {
  background-color: #F5F5F5;
}
.accordian .alertsAccordions .accordionSummaryAlert.Mui-expanded {
  border-bottom: 1px solid #ddd;
}
.statusName {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  font-family: 'Inter'
}
.cursor-auto {
  cursor: auto;
}
.chipsContainer .cursor-pointer {
  cursor: pointer;
}
.chipsContainer .chipValue{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.display-flex {
  display: flex;
}
.chipsContainer .chip {
  width: 28%;
}
.moreLabelsDialog .MuiDialog-scrollPaper {
  justify-content: end;
}
.moreLabelsDialog .moreLabelsDialogGrid {
  padding: 10px 5px 15px 5px;
  grid-gap: 15px;
  max-height: 150px;
  overflow-y: auto;
}
.moreLabelsDialogCloseIconGrid{
  height: 15px;
  display: flex;
  justify-content: end;
}
.moreLabelsDialogCloseIconGrid img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.moreLabelsDialog .MuiPaper-root{
  margin-top: -11%;
}
.container {
    border: 1px solid black;
    padding: 0.5rem;
    margin: 0.5rem;
}

.event_alert_configuration--container {
    background: #FFFFFF;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.create_alert--container .event_alert_configuration--container {
    margin: 1rem 4rem;
    border-radius: 12px;
}

.event_alert_configuration--header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #EBEBEB;
}

.event_alert_configuration--header:hover {
    cursor: pointer;
}

.event_alert_configuration--header--meta {
    display: flex;
    flex-direction: row;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}

.event_alert_configuration--header--meta--icon {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    color: #2F4B82;
    background: #F4F5FA;
    border-radius: 8px;
}

.event_alert_configuration--header--meta--title_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.event_alert_configuration--header--meta--title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #45464E;
}

.event_alert_configuration--header--meta--subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6E7079;
}

.event_alert_configuration--header--action_dots {
    display: flex;
    align-items: center;
}

.event_alert_configuration--body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Footer */

.event_alert_configuration--footer {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    border-top: 1px solid #EBEBEB;
}

.save_config_button.MuiButton-contained, .save_config_button.MuiButton-contained:hover {
    background: #2F4B82;
}

/* Event Condition */

.event_alert_configuration--body--condition_container {
    margin: 1em 0em;
}

.event_alert_configuration--body--rule_container {
    display: flex;
    flex-direction: column;
}

.event_alert_configuration--body--rule {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    margin: 0.5em;
    

    background: rgba(244, 245, 250, 0.7);
    border-radius: 8px;
}

.event_alert_configuration--body--rule--inputs {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
}

/* More Action */

#event-configuration-more-options .MuiMenuItem-root:hover {
    background: #F4F5FA;
    border-radius: 8px;
}

.event-configuration-more-options-paper.MuiMenu-paper {
    padding: 0rem 0.5rem;
    width: 10rem;
}

/* Common */

.d-none {
    display: none;
}
.container {
    border: 1px solid black;
    padding: 0.5rem;
    margin: 0.5rem;
}

.metric_alert_configuration--container {
    background: #FFFFFF;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.create_alert--container .metric_alert_configuration--container {
    margin: 1rem 4rem;
    border-radius: 12px;
}

.metric_alert_configuration--header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #EBEBEB;
}

.metric_alert_configuration--header:hover {
    cursor: pointer;
}

.metric_alert_configuration--header--meta {
    display: flex;
    flex-direction: row;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}

.metric_alert_configuration--header--meta--icon {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    color: #2F4B82;
    background: #F4F5FA;
    border-radius: 8px;
}

.metric_alert_configuration--header--meta--title_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.metric_alert_configuration--header--meta--title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #45464E;
}

.metric_alert_configuration--header--meta--subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6E7079;
}

.metric_alert_configuration--header--action_dots {
    display: flex;
    align-items: center;
}

.metric_alert_configuration--body {
    display: flex;
    flex-direction: column;
}

.metric_alert_configuration--body--section {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #EBEBEB;
}

.metric_alert_configuration--body--section.last_section {
    border: none;
}

.metric_alert_configuration--body--section .section_title {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    line-height: 1.1rem;

    color: #45464E;
}

.metric_alert_configuration--body--section .section_input_label {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 0.9rem;

    color: #8B8D97;
}

.metric_alert_configuration--body--section .section_input_label--space_bottom {
    display: block;
    margin-bottom: 0.5rem;
}

.metric_alert_configuration--body--section--grey_box {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 1rem;

    background: rgba(244, 245, 250, 0.7);
    border-radius: 8px;
}

.select_duration {
    display: flex;
    background:#FFFFFF;
    border-radius: 4px;
    padding: 0.2rem 1rem;
}

.select_duration .MuiInput-root::before, .custom_select .MuiInput-root::before, .custom_text_field .MuiInput-root::before {
    border-bottom: unset;
}

/* .select_duration .MuiInput-root {
    padding: 0.15rem 0.75rem;
} */

.select_duration .MuiTextField-root .MuiInput-root {
    max-width: 45px;
}

.custom_text_field .MuiTextField-root .MuiInput-root {
    max-width: 65px;
}

.mid_separator {
    border-right: 3px solid #EBEBEB;
    margin: 0.25rem 0.5rem
}

.custom_select {
    display: flex;
    background:#FFFFFF;
    border-radius: 4px;
    padding: 0.2rem 0.25rem;
}

.custom_text_field {
    display: flex;
    background:#FFFFFF;
    border-radius: 4px;
    padding: 0.2rem 0.25rem;
}

.error_handling_subsection {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

/* Footer */

.metric_alert_configuration--footer {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    border-top: 1px solid #EBEBEB;
}

.save_config_button.MuiButton-contained,
.save_config_button.MuiButton-contained:hover {
    background: #2F4B82;
}

/* More Action */

#metric-configuration-more-options .MuiMenuItem-root:hover {
    background: #F4F5FA;
    border-radius: 8px;
}

.metric-configuration-more-options-paper.MuiMenu-paper {
    padding: 0rem 0.5rem;
    width: 10rem;
}

.stateHistoryDialog{
    position: absolute;
    width: 724px;
    height: 540px;
    background: #FFFFFF;
    border-radius: 10px;    
}

.stateHistoryDialogTitleDiv{
    border-bottom: 1px solid #EBEBEB;
}

.stateHistoryDialogTitle{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #45464E;
}

.stateHistoryDialogContentDiv{
    margin-top: 10px;
}

.stateHistoryDialogCrossIcon{
    position: absolute;
    width: 14px;
    height: 14px;
    left:94%;
    cursor: pointer;
}
.stateHistoryTableHeadCell{
    border: 1px solid #EBEBEB;
    border-radius: 4px 0px 0px 0px;
}

.stateHistoryTableHead{
    background: #F5F5F5;
}

.stateHistoryTableHeadTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    color: #8B8D97;
}

.stateHistoryTableRowCell{
    border: 1px solid #EBEBEB;
}
.system-health-conatner {
    margin: 25px 0px 15px 25px;
}

.system-health-conatner .system-health-devices {
    width: 260px;
}

.system-health-charts-container {
    margin-top: 16px;
    height: calc(100vh - 260px);
    overflow: auto;
}

.system-health-charts-container>div {
    padding: 0px 15px 15px 0px;
}

.system-health-filters-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 25px;
}

.sh-bin-resetZoom {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.MuiPaper-root {
    background: white;
}

.MuiToolbar-root {
    background: #f5f5f5;
    height: 49px;
}

.MuiPaper-root .MuiToolbar-root {
    min-height: 49px;
}

.logoutIcon {
    color: #000000;
    padding-top: 2px;
    margin-right: 12px;
}

.releaseNoteIcon{
    color: #2860B4;
}

.profileContainer {
    height: 35px;
    display: flex;
    margin-left: auto;
    color: black;
}

.profileContainer p {
    font-size: 15px;
}

.profileUserName {
    width: "150px";
    color: "#676984";
    overflow: "hidden";
    text-align: 'left';
    white-space: "nowrap";
    text-overflow: "ellipsis";
    cursor: "pointer";
    display: "inline-block";
    padding-bottom: "10px";
    padding-top: 2px;
    max-width: 112px;
}

.headerLogo {
    height: 35px;
    width: 100px;
    padding-top: 10px;
}

.MuiBox-root .headerContainer {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
}

.headerContainer{
    border-bottom: 1px solid #EBEBEB;
}

.menuList .MuiPaper-root{
    /* width: 140px !important; */
    padding-left: 18px !important;
    padding-right: 18px;
    padding-top: 8px;
    padding-bottom: 3px;
}

.toggleswitch {
    color: black;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 3px;
}

.menuList.user-settings li{
    display: flex;
    justify-content: left;
    padding: 5px;
}

.headerContainer .toolBarContainer {
    padding-left: 16px !important;
    padding-right: 7px;
}
 .markdown > h4 {
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
}

.color-white{
    color: #fff;
}

.version {
    font-weight: bold;
    font-size: 18px;
}
 .markdown > h4 {
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
}

.color-white{
    color: #fff;
}

.version {
    font-weight: bold;
    font-size: 18px;
}

.MaintainenceNote {
    font-size: 12px;
}
.group-actions-tab-create-group-popup.preferences-pop-up .MuiDialogContent-root {
    height: 275px;
    padding-bottom: 0px;
}

.group-actions-tab-create-group-popup.preferences-pop-up .MuiDialogContent-root>div {
    display: flex;
    align-items: center;
    height: 100%;
}

.preferences-pop-up .time-zone-selection>div {
    padding: 0px;
    width: 280px;
}

.preferences-pop-up .time-zone-selection>div>div>div {
    padding: 0px;
    border-radius: 8px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
}

.preferences-pop-up .time-zone-selection>div>div>div>div {
    max-height: 175px;
}
.clock {
    margin-top: 6px;
    font-size: 11px;
    font-family: 'Inter';
    cursor: pointer;
    font-weight: 500;
    color: rgba(110, 112, 121, 1);
    position: fixed;
    right: 186px;
    display: flex;
    align-items: center;
}

.clock>span:nth-child(1) {
    margin-right: 14px;
    text-decoration: underline;
}

.current-date-time {
    width: 115px;
    font-family: monospace;
}
.forgotText {
  font-size: 12px !important;
  line-height: 14px;
  /* margin-left: 30px !important; */
  display: flex;
  align-items: center;
  margin-top: 30px !important;
  color: #676984;
}

@media only screen and (max-width: 550px) {
  .forgotText {
    margin-left: 25px !important;
  }
}

@media only screen and (min-width: 600px) {
  .forgotText {
    margin-left: 30px !important;
  }
}

.ResetButtonCss.MuiButton-root:hover {
  background: #264c86 !important;
}

@media only screen and (max-width: 550px) {
  .ResetButtonCss {
    width: 92%;
    max-width: 350px;
    margin-bottom: 20px !important;
  }
}

@media only screen and (min-width: 600px) {
  .ResetButtonCss {
    width: 160px;
  }
}

.ResetButtonCss {
  /* width: 160px; */
  font-size: 11px;
  text-transform: uppercase;
  background-color: #264c86 !important;
  border: 1.5px solid #264c86;
  color: #ffffff !important;
  cursor: pointer;
}

.SuccessMessagePopUp .MuiPaper-rounded {
  border-radius: 20px !important;
}

.successIcon {
  width: 60px;
  height: 60px;
}

.ImageCss {
  justify-content: center;
  display: flex;
  align-items: center;
}

.dialogTitleForgetPassword {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  color: #273050;
}

.dialogTextForgetPassword {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #273050 !important;
  opacity: 0.75 !important;
  margin-top: 10px !important;
}

.dialogButtonForgetPassword {
  background: #264c86 !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  font-size: 10px !important;
  line-height: 12px !important;
  text-align: center !important;
  letter-spacing: 0.035em !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  width: 95px !important;
  height: 36px;
}

.MuiTextField-root.input-box-fgt-pass {
  margin: 8px;
  width: 100%;
  max-width: 592px;
  margin-top: 40px;
}
.dialog-title-close-icon {
    text-align: end;
}

.dialog-box-title {
    font-size: 22px !important;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #384089 !important;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #384089;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}
.changePasswordPage .changePasswordTitle {
    margin-left: 24px;
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
}


.changePasswordPage .changePasswordContainer {
    display: block;
    margin: auto;
    padding: 10px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    border-radius: 12px;   
    margin-top: 30px; 
}

.changePasswordContainer .passWordFiled {
    width: 100%;
    margin-bottom: 20px;
}

.passWordFiled .MuiTextField-root {
    width: 100%;
}

.passWordFiled .fieldLabel {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #676984;
}

.changePasswordContainer .changePassButtons {
    margin-right: 10px;
    width: 160px;
    font-size: 11px;
    text-transform: uppercase;
    background-color: #264C86 !important;
    border: 1.5px solid #264C86;
    color: #ffffff !important;
    cursor: pointer;
}

.invalidDetails {
    color: Red;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
}

.passChangeSuccess {
    color: green;
    font-size: 12px;
    font-weight: 400;
}

.changePasswordContainer .disabledUpdateButton {
    margin-right: 10px;
    width: 160px;
    font-size: 11px;
    text-transform: uppercase;
    background-color: #949598 !important;
    border: 1.5px solid #949598;
    color: #ffffff !important;
    cursor: auto;
}
.custom-password-validator {
    padding: 1rem;
    border-radius: .375rem;
    background-color: #f9fafb;
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.custom-password-validator .container {
    width: 100%
}

@media (min-width: 640px) {
    .custom-password-validator .container {
        max-width: 640px
    }
}

@media (min-width: 768px) {
    .custom-password-validator .container {
        max-width: 768px
    }
}

@media (min-width: 1024px) {
    .custom-password-validator .container {
        max-width: 1024px
    }
}

@media (min-width: 1280px) {
    .custom-password-validator .container {
        max-width: 1280px
    }
}

@media (min-width: 1536px) {
    .custom-password-validator .container {
        max-width: 1536px
    }
}

.custom-password-validator .rpv-container {
    padding: 1rem;
    border-radius: .375rem;
    width: 100%;
    background-color: #f9fafb;
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f
}

.custom-password-validator .rpv-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 1rem
}

.custom-password-validator .rpv-rule {
    display: flex;
    margin-top: .25rem;
    margin-bottom: .25rem;
    grid-column: span 1 / span 1;
    align-items: center
}

.custom-password-validator .rpv-progress-bar {
    -moz-transition-property: all;
    -moz-transition-timing-function: cubic-bezier(.435, .15, 0, 1);
    -moz-transition-duration: .8s;
    -moz-transition-delay: 0ms;
    -webkit-transition-property: all;
    -webkit-transition-timing-function: cubic-bezier(.435, .15, 0, 1);
    -webkit-transition-duration: .8s;
    -webkit-transition-delay: 0ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(.435, .15, 0, 1);
    transition-duration: .6s;
    transition-delay: 0ms;
    border-radius: .25rem;
    height: .5rem;
    position: absolute;
    bottom: 0;
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f
}

.custom-password-validator .rpv-copy-password,
.custom-password-validator .rpv-copied-to-clipboard {
    position: absolute;
    right: 2rem
}

.custom-password-validator .rpv-generate-new {
    position: absolute;
    right: .5rem
}

@media (max-width: 640px) {
    .custom-password-validator .rpv-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }
}

.custom-password-validator .rpv-suggested-password {
    display: none !important;
}

.custom-password-validator .rpv-grid {
    font-weight: 500;
}

.custom_toast{
    color: #fff !important;
    display: flex !important;
    padding: 6px 16px !important;
    flex-grow: 1 !important;
    flex-wrap: wrap !important;
    font-size: 14px !important;
    align-items: center !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    line-height: 1.43 !important;
    border-radius: 4px !important;
}
.custom_toast_success{
    background: #43a047 !important;
}
.custom_toast_error{
    
}
.Toastify .Toastify__toast-container {
    width: auto;
}
.Toastify__toast{
    cursor: default;
}
.changePasswordPage .changePasswordTitle {
    margin-left: 24px;
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
}


.changePasswordPage .changePasswordContainer {
    display: block;
    margin: auto;
    padding: 10px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    border-radius: 12px;   
    margin-top: 30px; 
}

.changePasswordContainer .passWordFiled {
    width: 100%;
    margin-bottom: 20px;
}

.passWordFiled .MuiTextField-root {
    width: 100%;
}

.passWordFiled .fieldLabel {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #676984;
}

.changePasswordContainer .changePassButtons {
    /* margin-right: 10px; */
    width: 160px;
    font-size: 11px;
    text-transform: uppercase;
    background-color: #264C86 !important;
    border: 1.5px solid #264C86;
    color: #ffffff !important;
    cursor: pointer;
}

.onChangeCancel{
    width: 160px;
    margin-right: 10px !important;
    background: #FFFFFF !important;
    border: 1px solid #676984 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    font-weight: bold !important;
    font-size: 10px !important;
    line-height: 12px !important;
    text-align: center !important;
    letter-spacing: 0.035em !important;
    text-transform: uppercase !important;
    color: #676984 !important;
}

.invalidDetails {
    color: Red;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
}

.passChangeSuccess {
    color: green;
    font-size: 12px;
    font-weight: 400;
}

.contactField {
    width: 100%;
    margin-bottom: 15px;
}

.contactField .MuiInputBase-adornedStart {
    border: none !important;
    border-radius: 8px !important;
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
}

.contactField .MuiInputBase-adornedStart input {
    border-left: 1px solid rgba(20, 99, 172, 0.24) !important;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
}

.viewPasswordEditButton {
  margin-top: 15px !important;
  margin-left: auto !important;
  /* margin-right: 57% !important; */
}

.editButton {
  font-size: 10px !important;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: uppercase !important;
  color: #ffffff !important;
  background: #264c86 !important;
  border-radius: 5px;
  width: 83.72px;
  height: 36.58px;
}

.changeProfileContainer {
  /* display: block !important; */
  margin: 22px !important;
  padding: 10px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box !important;
  border-radius: 12px;
  margin-top: 30px !important;
  width: auto;
}

.profileContainer{
  overflow-y: auto;
  max-height: calc(100vh - 10px)
}

.profileText {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em !important;
  color: #273050;
  opacity: 0.75;
}

.profileText1 {
  font-size: 16px !important;
  line-height: 19px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em !important;
  color: #273050;
  margin-top: 7px !important;
}

.textProfile {
  margin-left: 40px !important;
  margin-top: 20px !important;
}

.avatarProfile {
  margin-left: 30px !important;
  margin-top: 25px !important;
}

.chipsPolicy {
  padding: 1px !important;
  padding-top: 5px !important;
  display: inline-block !important;
  font-size: 12px !important;
  background: #fff !important;
  height: 25px !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-radius: 30px !important;
}

@media only screen and (max-width: 850px) {
  .lineWidth {
    width: 550px !important;
  }
}

@media only screen and (max-width: 700px) {
  .lineWidth {
    width: 400px !important;
  }
}

@media only screen and (max-width: 550px) {
  .lineWidth {
    width: 300px !important;
  }
}

@media only screen and (max-width: 450px) {
  .lineWidth {
    width: 200px !important;
  }
}

@media only screen and (max-width: 400px) {
  .profileText {
    font-weight: 500 !important;
    font-size: 10px !important;
    /* line-height: 14px !important; */
    display: flex;
    align-items: center;
    letter-spacing: 0.05em !important;
    color: #273050;
    opacity: 0.75;
  }
}

@media only screen and (max-width: 400px) {
  .profileText1 {
    font-weight: 500 !important;
    font-size: 10px !important;
    /* line-height: 14px !important; */
    display: flex;
    align-items: center;
    letter-spacing: 0.05em !important;
    color: #273050;
    opacity: 0.75;
  }
}

.marginTop20{
  margin-top: 20px;
}

.marginBottom20{
  margin-bottom: 20px;
}

.groupTableHeading{
  background-color:rgb(236 234 234);
  border:1px solid #cdcdcd;
}

.groupTableCell{
  border:1px solid #cdcdcd;
}

.viewUserDialog .viewUserDialogTitle{
  font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: #45464E;
border-bottom: 1px solid #EBEBEB;
padding: 20px 24px 0px 24px;

}

.viewUserCloseIcon{
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.viewUserDialog .viewUserDialogContent{
  padding: 10px;
  min-width: 400px;
  width: 600px;
  /* max-height: 450px; */
  /* //style={{ padding: "0px", minWidth: "403px", width: "600px", maxHeight: "450px" }} */
}

.menuScroll ::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
.menuScroll ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 254, 254);
  border-radius: 18px !important;
  background: rgb(255, 255, 255);
}

/* Handle */
.menuScroll ::-webkit-scrollbar-thumb {
  background:#D9D9D9 !important;
  border-radius: 0px !important;
}

/* Handle on hover */
.menuScroll  ::-webkit-scrollbar-thumb:hover {
  background:#ffffff !important;
}
 .viewUserDialogContent .viewUserRole{
  /* marginTop: "10px", fontSize: "12px", lineHeight: "14px" color: "#676984"  */
  margin-top: 10px;
  font-size: 12px;
  font-family: Inter;
line-height: 14px;
color: #676984;

 }

 .viewUserTableContainer{
  border:1px solid #EBEBEB;
  max-height: 400px;
 }

 .viewUserRoleContainer{
  margin-bottom: 10px;
 }

 .viewUserTableCell{
  border: 1px solid #EBEBEB;
  font-size: 12px;
  font-family: Inter;
 }

 .viewUserTableRow{
  background: #ffffff;
 }

 .tableHeaderFleetVesselData .viewUsertableHeader{
  font-family: Roboto Mono;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;
  color:#8B8D97;
  background: #F5F5F5;
  border: 1px solid #EBEBEB;
 }

 .scrollFix {
   height: calc(100vh - 10px);
   overflow-y: auto;
 }

 .changeProfileContainer .profile-user-name-text {
   white-space: nowrap;
   max-width: 280px;
   text-overflow: ellipsis;
   overflow: hidden;
 }
.ProductAutoComplete .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1yyslfo-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 0px;
}

.lastestVersion{
  background: #abd9fbba !important;
}

.oldVersion{
  background: #ffffff !important;
}

.LatestCheck{
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.textLatestBuildOn{
  margin-left: 5px !important;
  color: #264C86;
  font-size: 12px;
}

select-product{
  margin-top: 20px;
  margin-bottom: 10px;
}


.margin45{
  margin-top: 45px;
}

.softwareListing-table .parent-element {
  margin-top: 10px;
}

.softwareListing-table .table-title {
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
}

.softwareListing-table thead th.usage-fleetDataTable {
  text-align: center;

}

.softwareListing-table thead th.fleetDataTable span {
  padding-left: 0px;
  display: block;
}

.softwareListing-table tbody td {
  text-align: center;
}

.softwareListing-table tbody td.noDataAvailableCss {
  text-align: center;
}

.softwareListing-table tbody td a {
  text-decoration: none;
}

.softwareListing-table tbody td .arrow-down-icon,
.softwareListing-table tbody td .arrow-right-icon {
  cursor: pointer;
  opacity: 0.75;
}

.softwareListing-table .virtual-eos-quick-start-guide {
  float: right;
  text-decoration: none;
  color: #4c3aff;
  font-size: 12px;
}
@media only screen and (max-height: 400px){
  .FleetDashboard-softwareVersions {
    max-height: 140px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .FleetDashboard-softwareVersions {
    max-height: 185px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .FleetDashboard-softwareVersions {
    max-height: 235px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .FleetDashboard-softwareVersions {
    max-height: 285px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .FleetDashboard-softwareVersions {
    max-height: 335px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .FleetDashboard-softwareVersions {
    max-height: 385px;
    overflow-y: auto;
  }
}
  
  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .FleetDashboard-softwareVersions {
      max-height: 430px;
      overflow-y: auto;
    }
  }
  
   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .FleetDashboard-softwareVersions {
      max-height: 470px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .FleetDashboard-softwareVersions {
      max-height: 520px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .FleetDashboard-softwareVersions {
      max-height: 570px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .FleetDashboard-softwareVersions {
      max-height: 630px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .FleetDashboard-softwareVersions {
      max-height: 680px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:950px) and (max-height:999px){
    .FleetDashboard-softwareVersions {
      max-height: 730px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:999px) and (max-height:1050px){
    .FleetDashboard-softwareVersions {
      max-height: 780px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1050px) and (max-height:1080px){
    .FleetDashboard-softwareVersions {
      max-height: 830px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1080px) and (max-height:1140px){
    .FleetDashboard-softwareVersions {
      max-height: 860px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1140px) and (max-height:1200px){
    .FleetDashboard-softwareVersions {
      max-height: 920px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1200px) and (max-height:1300px){
    .FleetDashboard-softwareVersions {
      max-height: 980px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1300px) and (max-height:1400px){
    .FleetDashboard-softwareVersions {
      max-height: 1080px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1400px) and (max-height:1920px){
    .FleetDashboard-softwareVersions {
      max-height: 1600px;
      overflow-y: auto;
    }
  }

  .software-container {
    max-height: calc(100vh - 125px);
    margin-bottom: 10px;
  }

  .paddingRight {
    padding-left: 10px;
  }

.MuiTextField-root.input-box-sw-vt {
  margin: 8px;
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
}
#softwareListTabs {
    width: 100%;
}
#softwareListTabs > div {
    margin-left: 5px;
}
#softwareListTabs .activeTab {
    background: #2860B4; 
    color: #ffffff;
}
#softwareListTabs .inActiveTab {
    background: #ffffff;
    color: #676984;
}
#softwareListTabs > div {
    display: flex;
}
#softwareListTabs > div button {
    width: 10%;
    border: 1px solid #C7DAEB;
    border-radius: 0px;
}
#softwareListTabs > div button:first-child {
    border-radius: 8px  0px 0px 8px;
    margin-left: 25px;
}
#softwareListTabs > div button:nth-last-child(1) {
    border-radius: 0px 8px 8px 0px;
}

.software-main-container {
    width: 100%;
    background: white;
}

.software-main-container-kon {
    width: 100%;
    padding-top: 90px;
    background: rgb(244, 247, 248);
    height: calc(100vh - 10px);
    overflow-y: auto;
}

.zIndexNav {
    z-index: 1000;
}

.marginTop40 {
    margin-top: 40px;
}

.soft-scroll-container {
    margin-left: 15px;
    padding-right: 25px;
    height: calc(100vh - 160px);
    overflow-y: auto;
}
#konnectDownloadLinks { 
    margin-left: 15px;
    padding-right: 25px;
}
#konnectDownloadLinks .download-platform {
    margin-top: 15px;
}
#konnectDownloadLinks ul {
    display: flex;
    list-style: none;
    text-align: center;
    margin: 0;
    width: 100%;
}
#konnectDownloadLinks ul  li{
    width: 15.5%;
}
#konnectDownloadLinks a {
    display: block;
    width: 100%;
    padding-top: 1em;
    color: #026e00;
    background: #eaf5e9;
    text-decoration: none;   
}
#konnectDownloadLinks a:hover, #konnectDownloadLinks a:hover .versionNumber {
    color: #fff;
    background-color: #43853d;
    border-radius: 5px;
}
#konnectDownloadLinks a:hover .download-logo {
    fill: #fff;
}
#konnectDownloadLinks .download-logo {
    display: block;
    margin: 0 auto;
    fill: #43853d;
}
#konnectDownloadLinks .versionNumber {
    padding-bottom: 10px;
    color: #999;
    font-size: 0.7rem;
}

.margTop70 {
    margin-top: 70px;
}
@media only screen and (max-height: 400px){
  .softwareVersionsDeviceDetails {
    max-height: 250px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .softwareVersionsDeviceDetails {
    max-height: 300px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .softwareVersionsDeviceDetails {
    max-height: 350px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .softwareVersionsDeviceDetails {
    max-height: 400px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .softwareVersionsDeviceDetails {
    max-height: 450px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .softwareVersionsDeviceDetails {
    max-height: 500px;
    overflow-y: auto;
  }
}
  
  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .softwareVersionsDeviceDetails {
      max-height: 550px;
      overflow-y: auto;
    }
  }
  
   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .softwareVersionsDeviceDetails {
      max-height: 590px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .softwareVersionsDeviceDetails {
      max-height: 640px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .softwareVersionsDeviceDetails {
      max-height: 690px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .softwareVersionsDeviceDetails {
      max-height: 750px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .softwareVersionsDeviceDetails {
      max-height: 800px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:950px) and (max-height:999px){
    .softwareVersionsDeviceDetails {
      max-height: 850px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:999px) and (max-height:1050px){
    .softwareVersionsDeviceDetails {
      max-height: 900px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1050px) and (max-height:1080px){
    .softwareVersionsDeviceDetails {
      max-height: 950px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1080px) and (max-height:1140px){
    .softwareVersionsDeviceDetails {
      max-height: 980px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1140px) and (max-height:1200px){
    .softwareVersionsDeviceDetails {
      max-height: 1040px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1200px) and (max-height:1300px){
    .softwareVersionsDeviceDetails {
      max-height: 1100px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1300px) and (max-height:1400px){
    .softwareVersionsDeviceDetails {
      max-height: 1190px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1400px) and (max-height:1920px){
    .softwareVersionsDeviceDetails {
      max-height: 1600px;
      overflow-y: auto;
    }
  }

    .margin-5 {
      margin-left: 5px;
    }
  
    .height40 {
      height: 40px;
    }

    .margin-left25 {
      margin-left: 25px;
    }
.inventoryListContainer {
    background-color: #fff;
}

.inventoryListDataContainer {
    width: 100%;
    /* padding-left: 80px; */
}
  
#inventoryListTabs {
    width: 100%;
}

#inventoryListTabs > div {
    margin-left: 5px;
}

#inventoryListTabs button:first-child {
    border-radius: 8px  0px 0px 8px;
    border: 1px solid #C7DAEB;
    margin-left: 25px;
}

#inventoryListTabs button:last-child {
    border-radius: 0px  8px 8px 0px;
}

#inventoryListTabs .activeTab {
    background: #2860B4; 
    color: #ffffff;
    border: 1px solid #C7DAEB;
}

#inventoryListTabs .inActiveTab {
    background: #ffffff;
    color: #676984;
}
#inventoryListTabs button {
    border: 1px solid #C7DAEB;
    width: 10%;
    border-radius: 0px;
}
.inventory-top-widgets {
    display: flex;
    grid-column-gap: 50px ;
}
.inventory-top-widgets > div {
    margin-top: 15px;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    overflow: hidden;
    text-align: center;
}
#inventoryListTabs .inventoryContailer {
    margin-left: 32px;
}
.inventory-top-widgets .key {
    color: #2e1a70;
    font-size: 13px;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: .175em;
    padding: 15px 0px 10px 0px;
}
.inventory-top-widgets .value {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    padding: 0px 0px 15px 0px;
}
.inventory-content .inventoryContailer .FleetDashboard {
    max-height: 70vh;
}
.inventory-content .quota_management--dashboard {
    margin: 15px 20px 0px 20px;
    max-height: 70vh;
    padding: 0px;
}
.inventory-content .quota_management--dashboard .config-status-table-fix {
    height: calc(100vh - 335px);
    overflow-y: auto;
}
.inventory-content .quota_management--base--flex--justify--end{
    justify-content: left;
    background-color: #FFFFFF;
    padding: 0 0 15px 0;
}
.inventory-content .quota_management--base--flex--justify--end > div {
    border-radius: 10px;
}
.inventory-content .quota_management--dashboard table thead th{
    color: #8b8d97;
    letter-spacing: 1px;
    font-family: roboto mono;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    height: 35px;
}
.inventory-content .quota_management--dashboard table tbody td {
   padding: 5px 15px;
   font-size: 12px;
   color: #45464E;
   font-family: 'Inter';
   font-style: normal;
   font-weight: 400;
   letter-spacing: 1px;
   height: 40px;
}
.inventory-content .quota_management--dashboard table tbody td button {
    padding: 0;
    margin-top: -2px;
}
.inventoryListContainer {
    background-color: white;
    height: 100vh;
}

.inventory-config-tabs {
    display: flex;
    justify-content: center;
    margin: 20px 0px 20px 0px;
}

.inventory-config-tabs .backupConfig-active-tab {
    font-size: 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 26px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 1px;
    color: #0E0548;
    background-color: #fff;
    font-weight: 600;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.inventory-config-tabs .backupConfig-inactive-tab { 
    font-size: 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 26px;
    border-radius: 1px;
    color: #6E7079;
    background-color: #fff;
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}
.default-manual-config-table-data {
    height: calc(100vh - 235px);
    overflow: auto;
    margin-bottom: 20px;
}
@media only screen and (max-height: 600px){
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 48vh;
    }
}
@media only screen and (min-height: 600px) and (max-height: 625px) {
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 49vh;
    }
}
@media only screen and (min-height: 625px) and (max-height: 700px){
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 55vh;
    }
}
@media only screen and (min-height: 700px) and (max-height: 850px){
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 56vh;
    }
}
@media only screen and (min-height: 850px) and (max-height: 900px){
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 64vh;
    }
}

.inv-index {
    z-index: 1000;
}
.backup-config-container {
    padding: 0px 5px 0px 10px;
}

.TopUsersConatiner .backup-config-table-container {
    max-height: calc(100vh - 360px);
    margin-bottom: 10px;
}

.backup-config-container .golden-config-default-container {
    width: 97%;
    padding: 24px 10px 0px 5px;
    background-color: #fff;
    margin: 0px 0px 0px 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.golden-config-default-container>div:nth-child(1) {
    display: flex;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
}

.golden-config-default-container .TopUsersConatiner {
    margin-top: -10px;
}

.config-search-bar-text .config-backup-search-bar {
    margin-top: -23px;
    height: 32px;
}

.config-tableRow .config-left-align {
    text-align: left;
    background: #F5F5F5;
    height: 40px;
    font-family: 'Inter';
    font-style: normal;
}

.config-left-align .default-config-dp-name {
    color: #8B8D97;
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.usage-tableBody .config-backup-tabe-row td {
    text-align: left;
    padding-left: 20px;
    font-weight: 400;
}

.config-left-align .backup-config-headers {
    white-space: nowrap;
}

.config-pagination .MuiToolbar-root {
    min-height: 40px;
}

.config-backup-search-bar .config-search-icon {
    color: #8B8D97;
}

.config-backup-tabe-row .selected-config {
    cursor: pointer;
    color: #0000ee;
}
.usagedata-tableCell.config-left-align.selected-config .config-name,
.usagedata-tableCell.config-left-align.selected-config .update-config-name {
    display: flex;
    align-items: center;
}
.usagedata-tableCell.config-left-align.selected-config .update-config-name {
    margin-top: 10px;
}

.usagedata-tableCell.config-left-align.selected-config .config-name .copy-icon {
    width: auto;
    margin-left: 10px;
    color: #2860B4;
    cursor: pointer;
}
.usagedata-tableCell.config-left-align.selected-config .update-config-name > div{
    width: 75%;
}
.usagedata-tableCell.config-left-align.selected-config .update-config-name input{
    font-size: 12px;
    color: #45464E;
    font-family: 'Roboto';
    font-style: normal;
    letter-spacing: 1px;
}
.usagedata-tableCell.config-left-align.selected-config .update-config-name img {
    margin-right: 0px;
}

.quota_management--dashboard .config-status-table-fix {
    height: calc(100vh - 370px);
    overflow-y: auto;
}

.MuiTextField-root.input-box-bkp-conf {
    margin: 8px;
    width: 100%;
    max-width: 245px;
    margin-left: 0px;
    height: 32px;
}

.disp-flex {
    display: flex;
}

.auto-backup-table .auto-backup-status::first-letter {
    text-transform: uppercase;
}
.golden-config-default-container .create-default-config-btn {
    height: 32px;
    background: #1B51A7;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 4px;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: -7px;
    text-transform: none;
}

.golden-config-default-container .create-default-config-btn:hover {
    background: #1B51A7;
}

.default-config-component .TopUsersConatiner .backup-config-table-container  {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
}
.default-config-component .golden-config-default-container {
    padding-top: 5px;
}
.default-config-component .golden-config-default-container .default-config-search-btn{
    display: block;
}
.default-config-component .search-create-button{
    display: flex;
}
.default-config-component .create-default-config-btn{
    margin-top: -23px;
    margin-left: 30px;
}
.config-search-bar-text .default_search {
    width: 245px;
    margin-top: -9px;
    margin-bottom: 25px;
}

.config-left-align .config-active-status {
    width: 47px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    background: #E4F7EA;
    border-radius: 29px;
    font-size: 12px;
    color: #1B6F37;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
}

.config-left-align .config-inactive-status {
    color: #AAAAAA;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

/*  create default config */

.create-default-config-container {
    width: 100%;
    margin: auto;
    background: #FFFFFF;
    border-radius: 10px;
    background-color: #0000;
}

.create-default-config-container .create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #45464E;
    background-color: #f5f5f5;
    height: 15px;
    border: 1px solid #EBEBEB;
}

.create-default-config-title img {
    width: 25px;
    cursor: pointer;
}

.create-default-config-content .name-input-default-config {
    position: relative;
    display: flex;
    height: 40px;
    padding-top: -10px;
}

.create-default-config-content .name-margin-top {
    margin-top: 5px;
}

.name-input-default-config>span {
    width: 12%;
    margin-top: 15px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.name-input-default-config .config-radio-button {
    margin: 15px 25px 0px -4px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.name-input-default-config .config-select-product {
    margin-right: 15px;
}

.name-input-default-config .create-config {
    height: 265px;
    overflow-y: auto;
    overflow-x: hidden;
    border: none;
    margin-top: 15px;
    text-transform: none;
}

.create-default-config-content {
    height: 400px;
    padding: 20px 15px 0px 15px;
}

.create-default-config-content .config-create-actions {
    position: absolute;
    bottom: 0px;
    right: 0;
    padding-right: 12px
}

.config-create-actions .config-cancel-btn {
    color: #1B51A7;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin-right: 20px;
    cursor: pointer;
}

.config-create-actions .config-create-btn {
    width: 127px;
    height: 32px;
    background: #1B51A7;
    border-radius: 4px;
    color: #fff;
    text-transform: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px;
    border: 1px solid #676984;
    cursor: pointer;
}

.config-create-actions .config-create-btn:hover {
    background: #1B51A7;
}

.name-input-default-config .name-config-default {
    height: 30px;
    width: 388px;
    margin-top: 9px;
}

.create-config .dynamic-create-row-config {
    border: 1px solid #EBEBEB;
    width: 1015px;
    border-top: none;
}

.create-config .empty-dynamic-create-row-config {
    border: 1px solid #EBEBEB;
    border-top: none;
    width: 1015px;
}

.create-config .dynamic-create-row-config button, .create-config .empty-dynamic-create-row-config button {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.config-left-align img {
    margin-left: 9px;
    cursor: pointer;
    width: 18px;
}

.config-left-align .notes-serachBar {
    width: 150px;
    padding: 5px;
    height: 32px;
    margin: -4px;
}

.create-default-config-container .MuiDialog-paperWidthMd {
    max-width: 1200px ;
}

.config-tableRow .create-select-product {
    background: #F5F5F5;
}

.default-config-autoComplete .select-product-td {
    border: 1px solid #EBEBEB;
    background: none;
}

.default-config-autoComplete td {
  padding: 7px;
}

.auto-config-textField {
    width: 163px;
    font-size: 12px;
    margin: auto;
}

.config-list-box-props {
    max-height: 150px;
    font-size: 12px;
}

.config-tableRow .config-tableCell {
    width: 0.3%;
}

.create-config-table .help-icon-create {
    position: absolute;
    margin-left: 100px;
    margin-top: 17px;
    color: #45464E;
}
.select-product-td .selected-config-details {
    width: 100%;
}
.select-product-td .selected-config-details > div{
    font-family: Roboto;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px;
    color: #9A9A9A;
    display: flex;
    width: 100%;
}
.select-product-td .selected-config-details .value {
    width: 65%;
    color: #676984;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}

.MuiTextField-root.input-box-def-conf {
    width: 300px;
}
.inventory-group-device-list-popup .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #8B8D97;
    background-color: #f5f5f5;
    height: 15px;
    border: 1px solid #EBEBEB;
}

.inventory-group-device-list-popup .dialog-title .close{
    width: 25px;
    cursor: pointer;
}

.inventory-group-device-list-popup .MuiDialogContent-root.dialog-content {
    height: -webkit-max-content;
    height: max-content;
    padding: 20px 24px;
    max-width: 550px;
    min-width: 250px;
}

.inventory-group-device-list-popup .devices div {
    padding: 4px;
    font-size: 10px;
    background: #fff;
    border: 1px solid #bdbdbd;
    margin: 1px;
}
.inventory-config-status .ellipsis{
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inventory-config-status-td .bluePointer {
    cursor: pointer;
    color: #0000EE;
}

.inventory-config-status-td .Pointer {
    cursor: pointer;
}

.inventory-config-status .progress-bar-div > span{
    cursor: pointer;
    width: 125px;
    display: flex;
    align-items: center;
}

.inventory-config-status .progress-bar-div .MuiLinearProgress-root{
    width: 100px;
    margin-right: 10px;
}

.inventory-content .inventoryLogsDashboard {
    margin-top: 0px;
}
.inventory-logs-status-pop-up .MuiDialogContent-root.dialog-content {
    max-width: none;
    padding-top: 0px;
    padding-right: 15px;
}

.inventory-logs-status-pop-up .disp-flex {
    margin-bottom: 5px;
    margin-top: 5px;
}

.inventory-logs-status-pop-up .disp-flex .search-text {
    display: flex;
    align-items: center;
}
.inventory-logs-status-pop-up .disp-flex .search-text .config-status {
    margin-left: 10px;
    font-size: 14px;
    color: #45464E;
    font-family: 'Inter';
}

.inventory-logs-status-pop-up .ellipsis{
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Pointer {
    cursor: pointer;
}
.group-actions-tab-create-group-popup .MuiDialogContent-root {
    width: 430px;
    height: auto;
    overflow-y: hidden;
}

.group-actions-tab-create-group-popup .MuiDialogContent-root>div {
    /* display: block */
}

.group-actions-tab-create-group-popup .name-input-default-config {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.group-actions-tab-create-group-popup .name-input-default-config>span,
.group-actions-tab-create-group-popup .name-input-default-config .create-config,
.group-actions-tab-create-group-popup .name-input-default-config .name-config-default {
    margin-top: 0px;
}

.group-actions-tab-create-group-popup .name-input-default-config>span {
    width: 35%;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.group-actions-tab-create-group-popup .name-input-default-config,
.group-actions-tab-create-group-popup .create-config-table {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.group-actions-tab-create-group-popup .name-input-default-config .create-config {
    max-height: 265px;
    height: auto;
}

.group-actions-tab-create-group-popup .dialog-actions {
    margin-top: 20px;
}

.group-actions-tab-create-group-popup .dialog-actions button {
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

.group-actions-tab-create-group-popup .dialog-actions .create {
    margin-left: 10px;
}

.group-actions-tab-create-group-popup .dialog-actions .create.disable {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.group-actions-tab-create-group-popup .auto-config-textField {
    width: auto;
    margin: 0px;
}

.group-actions-tab-create-group-popup .group_name,
/* .group-actions-tab-create-group-popup .select_devices .MuiAutocomplete-root>div>div, */
.group-actions-tab-create-group-popup .select_organization .Form,
.group-actions-tab-create-group-popup .select-organisation-dropdown {
    height: 37px;
    width: 280px;
}

.group-actions-tab-create-group-popup .select_devices .MuiAutocomplete-root>div>div {
    padding: 0px;
    min-height: 37px;
    width: 280px;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 8px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
}

.group-actions-tab-create-group-popup .select_devices fieldset {
    border: 0px !important;
}

.group-actions-tab-create-group-popup .select_devices .MuiAutocomplete-root>div>div>input {
    padding: 7px 14px 7px 14px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
}

.group-actions-tab-create-group-popup .select_devices .MuiAutocomplete-root .search-or-select>div>input {
    padding-left: 10px;
}

.group-actions-tab-create-group-popup .group_name input {
    padding-top: 6px;
    padding-bottom: 6px;
}

.group-actions-tab-create-group-popup .select-organisation-dropdown {
    padding: 6px 14px 6px 14px;
    border-radius: 8px;
}

.group-actions-tab-create-group-popup+#dpDropdownMenu-ou .MuiPaper-root {
    width: 280px;
}
.group-actions-tab-actions-popup .MuiDialogContent-root {
    width: 400px;
    height: auto;
}

.group-actions-tab-actions-popup .MuiDialogContent-root>div {
    display: block
}

.group-actions-tab-actions-popup .name-input-default-config>span {
    width: 40%;
}

.group-actions-tab-actions-popup .name-input-default-config>div {
    width: 60%;
}

.group-actions-tab-actions-popup .name-input-default-config .MuiAutocomplete-root {
    width: 100%;
}

.group-actions-tab-actions-popup .name-input-default-config>span,
.group-actions-tab-actions-popup .name-input-default-config .create-config,
.group-actions-tab-actions-popup .name-input-default-config .name-config-default,
.group-actions-tab-actions-popup .name-input-default-config .config-radio-button {
    margin-top: 0px;
}

.group-actions-tab-actions-popup .name-input-default-config {
    display: flex;
    align-items: center;
    margin-top: 15px;
    height: 30px;
}

.group-actions-tab-actions-popup .dialog-actions {
    margin-top: 20px;
}

.group-actions-tab-actions-popup .dialog-actions button {
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

.group-actions-tab-actions-popup .dialog-actions .apply {
    margin-left: 10px;
}

.group-actions-tab-actions-popup .auto-config-textField {
    margin: 0px;
}

.group-actions-tab-actions-popup .config-radio-button input {
    margin-left: 10px;
}

.group-actions-tab-actions-popup .group-name {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}
.inventory-group-actions-tab-content {
    margin: 15px 20px 0px 20px;
}

.inventorySiteInfoMargin {
    margin: 0px 20px 0px 20px;
}

.inventory-group-actions-tab-content .group-buttons button {
    border-radius: 5px !important;
    margin-right: 15px;
    cursor: pointer;
}

.inventory-group-table .login-tableRow .CF-cell{
    padding: 0px;
}

.inventory-group-table .login-tableRow .CF-cell > span{
    justify-content: center;
}

.inventory-group-table  .loginHistory-tableRow .login-left-align{
    max-width: 100px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inventory-group-table-devices>div {
    padding: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    background: #fff;
    height: 25px;
    max-width: 150px;
    margin-right: 5px;
}

.login-left-align .inventory-group-table-more-devices {
   cursor: pointer;
}

.manual-backup-config-container {
    padding: 0px 5px 0px 5px;
    margin-right: 50px;
}

.manual-backup-config-container .golden-config-default-container {
    padding: 0px 10px 0px 5px;
    margin: 25px 15px 0px 15px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.manual-backup-config-container .golden-config-default-container>div {
    display: block;
}
.manual-backup-config-container .golden-config-default-container div:first-child {
    margin-bottom: 0px;
}

.manual-backup-config-container .title {
    margin: 4px 0 0 0;
}

.manual-backup-config-container .TopUsersConatiner .backup-config-table-container {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
}

.manual-backup-config-container .golden-config-default-container .TopUsersConatiner {
    margin-top: -10px;
}

.manual-backup-table .manual-backup-status::first-letter {
    text-transform: uppercase;
}

.manual-backup-table .short-text-action-initiated-by {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.config-search-bar-text .config-backup-search-bar {
    margin-top: -23px;
    height: 32px;
}

.config-tableRow .config-left-align {
    text-align: left;
    background: #F5F5F5;
    height: 40px;
    font-family: 'Inter';
    font-style: normal;
}

.config-left-align .default-config-dp-name {
    color: #8B8D97;
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.usage-tableBody .config-backup-tabe-row td {
    text-align: left;
    padding-left: 20px;
    font-weight: 400;
}

.config-left-align .backup-config-headers {
    white-space: nowrap;
}

.config-pagination .MuiToolbar-root {
    min-height: 40px;
}

.config-backup-search-bar .config-search-icon {
    color: #8B8D97;
}

.config-backup-tabe-row .selected-config {
    cursor: pointer;
    color: #0000ee;
}
.manual-backup-table .actions img {
    cursor: pointer;
    width: auto;
}

.MuiTextField-root.input-box-mn-bkp {
    margin: 8px;
    width: 100%;
    max-width: 245px;
    margin-left: 0px;
    height: 32px;
}
.site-info-table .loginHistory-tableRow .login-left-align{
    height: 30px;
    padding-left: 0px;
}
.site-info-tab .deployed-inputRow {
    padding-bottom: 15px;
}
.config-deploy-inventory-popup .config-apply-config-target {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.popup-fixed-position .config-apply-config-target {
    margin-top: unset;
}
.config-deploy-inventory-popup .config-apply-config-target>div {
    width: 59%;
}
.config-deploy-inventory-popup .config-apply-config-target>div>div {
    display: flex;
}

.config-deploy-inventory-popup .config-apply-config-target>div .key, .config-deploy-inventory-popup .config-note {
    color: #9A9A9A;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.config-deploy-inventory-popup .config-apply-config-target span {
    width: 40%;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.popup-fixed-position .config-apply-config-target div:nth-child(1) {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    height: 37px;
    display: flex;
    align-items: center;
    margin: 3px 0px 3px 0px;
}

.config-deploy-inventory-popup .config-apply-config-target>div .value {
    font-family: Roboto;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 14px;
    color: #45464E;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.config-deploy-inventory-popup .name-input-default-config>span {
    margin-top: 0px;
}

.config-deploy-inventory-popup .select-config-from {
    margin-bottom: 0px;
}

.config-deploy-inventory-popup .actions .config-create-btn {
    width: auto;
    background-color: #264C86;
}

.config-deploy-inventory-popup .actions .config-cancel-btn {
    border: 1px solid #264c86;
    color: #264c86;
}

.config-deploy-inventory-popup .actions .config-create-btn,
.config-deploy-inventory-popup .actions .config-cancel-btn {
    height: 35px;
    text-transform: uppercase;
}

.config-deploy-inventory-popup .actions .config-create-btn.disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.config-deploy-inventory-popup .config-note {
    color: red;
}
.user-entered-config-name input {
    padding: 8px;
    width: 170px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #676984;
}

.inventory-deploy-backup-config-popup .actions .config-create-btn {
    width: auto;
    background-color: #264C86;
}

.inventory-deploy-backup-config-popup .actions .config-cancel-btn {
    border: 1px solid #264c86;
    color: #264c86;
}

.inventory-deploy-backup-config-popup .actions .config-create-btn,
.inventory-deploy-backup-config-popup .actions .config-cancel-btn {
    height: 35px;
    text-transform: uppercase;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

.inventory-deploy-backup-config-popup .actions .config-create-btn.disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}
.inventory-upgradepopup .EOS-version {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #676984;
}
.heading{
    font-size: 20px;
    font-style: "Roborto";
    font-weight: bold;
    margin: 1rem 0;
    text-align: center;

}

.dailySelectBox{
    width: 70%;
    background-color: #ffff;
}

.selBackground{
    background-color: white;
}

.dailyReportsDataHeader{
    width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Form .innerBox{
    padding: 70px 0px 0px 100px;
}

.dailyReportsTableContainer{
    border-radius: 15px;  
    border-left: 1px solid rgba(20, 99, 172, 0.24);
}

.ml-10{
margin-left: 10px;
}

.dailyReportsTableContainer .dailyReportsTableCell{
font-family:  "Roboto" ;
font-weight: 500;
font-size: 10px;
color: #fff;
background-color: #264C86;
text-transform: uppercase;
padding: 10px;
}

.dataUsageHeader{
    height: 46px;
  }

  .iccidTable{
    font-size: 14px;
    padding: 8px;
    font-weight: bold;
  }

  .iccidTable .vessName {
    color: #264C86;
  }

.tableDiv{
    padding: 10px;
}

.MuiTableCell-root.dailyFleetRowRank {
    font-family: Roboto ;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 12px ;
    line-height: 14px ;
    text-transform: capitalize ;
    letter-spacing: 0 ;
    opacity: 0.75;
    position: relative;
    padding: 10px ;
}

.tableBody {
    z-index: 1;
  }
  
  .tableHead { 
    position: -webkit-sticky; 
    position: sticky; 
    top: 0;
    z-index: 999;
  }

  @media only screen and (max-height: 650px) {
    .dailyDataUsageScroll {
        max-height: 410px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 650px) and (max-height: 690px) {
    .dailyDataUsageScroll {
        max-height: 420px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 690px) and (max-height: 710px) {
    .dailyDataUsageScroll {
        max-height: 460px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 710px) and (max-height: 740px) {
    .dailyDataUsageScroll {
        max-height: 478px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 740px) and (max-height: 760px) {
    .dailyDataUsageScroll {
        max-height: 505px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 760px) and (max-height: 790px) {
    .dailyDataUsageScroll {
        max-height: 525px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 790px) and (max-height: 820px) {
    .dailyDataUsageScroll {
        max-height: 555px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 820px) and (max-height: 849px) {
    .dailyDataUsageScroll {
        max-height: 590px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 849px) and (max-height: 870px) {
    .dailyDataUsageScroll {
        max-height: 610px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 870px) and (max-height: 899px) {
    .dailyDataUsageScroll {
        max-height: 640px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:899px) and (max-height:950px) {
    .dailyDataUsageScroll {
        max-height: 670px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:950px) and (max-height:970px) {
    .dailyDataUsageScroll {
        max-height: 720px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:970px) and (max-height:999px) {
    .dailyDataUsageScroll {
        max-height: 740px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:999px) and (max-height:1020px) {
    .dailyDataUsageScroll {
        max-height: 770px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1020px) and (max-height:1050px) {
    .dailyDataUsageScroll {
        max-height: 790px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1050px) and (max-height:1080px) {
    .dailyDataUsageScroll {
        max-height: 820px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1080px) and (max-height:1100px) {
    .dailyDataUsageScroll {
        max-height: 850px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1100px) and (max-height:1140px) {
    .dailyDataUsageScroll {
        max-height: 870px;
        overflow-y: auto;
    }
}
@media only screen and (min-height:1140px) and (max-height:1160px) {
    .dailyDataUsageScroll {
        max-height: 910px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1160px) and (max-height:1200px) {
    .dailyDataUsageScroll {
        max-height: 930px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1200px) and (max-height:1230px) {
    .dailyDataUsageScroll {
        max-height: 970px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1230px) and (max-height:1260px) {
    .dailyDataUsageScroll {
        max-height: 995px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1260px) and (max-height:1300px) {
    .dailyDataUsageScroll {
        max-height: 1020px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1300px) and (max-height:1330px) {
    .dailyDataUsageScroll {
        max-height: 1070px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1330px) and (max-height:1360px) {
    .dailyDataUsageScroll {
        max-height: 1100px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1360px) and (max-height:1400px) {
    .dailyDataUsageScroll {
        max-height: 1130px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1400px) and (max-height:1920px) {
    .dailyDataUsageScroll {
        max-height: 1165px;
        overflow-y: auto;
    }
}
.audit-data-container .audit-logs-font,
.audit-filters-container .audit-logs-font {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 15px;
    color: #45464E;
}

.audit-data-container .audit-logs-font-header {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    color: #45464E;
}

.audit-filters-container .audit-filter{
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 500;
    line-height: 20px;
    color: #45464E;
}

.audit-filter-items .auditFilterBorderColor {
    border-color: #EBEBEB;
}

.audiFilterCloseIcon {
    position: absolute;
    left: 238px;
    cursor: pointer;
}
.audit-container {
    padding: 10px 13px;
    margin-top: 75px;
    height: calc(100vh - 150px);
    overflow-y: scroll;
}

.audit-layout {
    background-color: white;
    /* position: absolute;
    left: 85px;
    top: 50px;
    right: 0;
    bottom: 0; */
    padding:40px 5px 5px 5px;
}

.audit-pop-up-height .MuiGrid-root {
    width: 274px;
    padding-top: 0px;
}

.audit-logs-font .auditFilterActions {
    height: 16px;
    padding-bottom: 8px;
}

.audit-logs-font .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
}

.audit-logs-font .MuiFormControlLabel-label {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
}

.audit-filter-list {
    display: flex;
    align-items: center;
    padding: 10px 16px 18px 16px;
}

.audit-filter {
    font-size: 14px;
}

.audit-data-container .audit-filter-list .audit-filter {
    font-size: 12px;
    font-weight: 600;
}

.audit-filter-list .filter-clear-all {
    margin-left: auto;
    color: #2F4B82;
    text-transform: none;
    text-decoration: none;
}

.expand-accordin-root.MuiAccordion-root {
    width: 100%;
}

.expand-summary .expand-summary-content,
.expand-summary .expand-summary-content.Mui-expanded {
    margin: 5px 0;
    color: #45464E;
}

.expand-summary.Mui-expanded[aria-expanded="true"] {
    min-height: 48px;
}

.audit-logs-font .filter-accordian {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #45464E;
    padding: 16px 16px 12px 16px;
}

.no-padding {
    padding: 0px;
}

.rc--form-control .no-padding {
    padding: 0px;
}

.padding-control .no-padding-top-btm {
    padding-top: 0;
    padding-bottom: 0;
}

.filter-accordian-label {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
}

.audit-log-item .filter-meta {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Inter';
}

.audit-filter-inputs .MuiInputBase-root {
    font-family: 'Inter';
    font-weight: 400;
    height: 34px;
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 8px;
}

.audit-filter-inputs .MuiInputLabel-root {
    font-size: 12px;
}

.audit-data-head .audit-data-header {

    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    letter-spacing: 1px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    /* Tertiary black */
    color: #8B8D97;
}

.audit-filters-container {
    /* display: flex;
    gap: 10px;
    flex-direction: column;
    height: calc(100vh - 80px); */
}

.filter_menu_container_auditlogs{
   margin: 110px 0px 0px 210px;
}

.filter_menu_container_auditlogs > .MuiPopover-paper {
    overflow-y: hidden;
    width: 274px;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
}

.audit-btn-parent {
    display: flex;
    padding: 20px 16px 12px 16px;
}

.audit-btn-parent .auditClearButton {
    text-transform: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
}

.audit-btn-parent .audit-filter-apply {
    text-transform: none;
    background-color: #1B51A7;
    border-radius: 4px;
    height: 29px;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    width: 103px;
    left: 60px;
}

.audit-filter-items {
    overflow-y: hidden;
}

.audit-table-data.MuiTable-root {
    overflow-y: auto;
}

.audit-table-data.MuiTable-root .MuiTableHead-root th {
    background-color: #f5f5f5;
}

/* .audit-table-data.MuiTable-root .MuiTableHead-root th:not(:first-child):not(:last-child),
.audit-table-data.MuiTable-root .MuiTableBody-root td:not(:first-child):not(:last-child) {
    border-right: 1px solid rgba(224, 224, 224, 1);
} */

.audit-data-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.audit-data-pagination {
    margin-top: auto;
}

.audit-log-item {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
}

.syntax-items {
    background-color: #F4F5FA;
    display: flex;
    width: 100%;
    min-height: 50px;
    position: relative;
    flex-direction: column;
    counter-reset: syntax-line-number;
    border-radius: 4px;
}

.syntax-items .syntax-row {
    counter-increment: syntax-line-number;
    display: grid;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    grid-template-columns: minmax(15px, 25px) 1fr;
}

.syntax-items .syntax-row > pre {
    margin: 0;
}

.syntax-items .audit-syntax-copy {
    position: absolute;
    top: 15px;
    right: 15px;
}

.audit-syntax-copy .copy-audit-metas {
    background-color: #2F4B82;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-transform: none;
}

.syntax-items .syntax-row>* {
    padding: 1px;
    color: #6E7079;
    font-size: 12px;
    font-family: 'Inter';
}

.syntax-items .syntax-row:first-child>* {
    padding-top: 8px;
}

.syntax-items .syntax-row:last-child>* {
    padding-bottom: 8px;
}

.syntax-items .syntax-line-item {
    background-color: #E3E6F3;
    color: #2F4B82;
    -webkit-user-select: none;
            user-select: none;
    text-align: center;
    font-weight: 500;
    font-family: 'Inter';
    font-size: 12px;
}

.syntax-items .syntax-line-item::before {
    content: counter(syntax-line-number);
}

.copy-audit-metas .MuiButton-startIcon .copy-icon {
    font-size: 12px;
}

.accordian-expand .accordian-arrow {
    font-size: 16px;
    color: #6E7079;
}

.pagination-position {
    height: 40px;
}

.audit-container .audit-container-data {
    width: 100%;
    overflow: hidden;
    height: auto;
    overflow-y: auto;
    border: 1px solid #EBEBEB;
    border-radius: 0px;
    box-shadow: none;
}

.audit-table-root .audit-logs-no-data {
    font-size: 12px;
    font-family: 'Inter';
}

.audit-loader {
    position: absolute;
    top: 14px;
    right: 28px;
}

.audit-filter-items .audit-pop-up-height {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 330px);
    padding: 0px;
    max-height: 444px;
}
/* Create Alert Page */

.create_alert--background {
    height: 100vh;
    max-height: calc(100vh - 80px);
    background: #F9F9F9;
    overflow-y: auto;
}

.create_alert--container {
    margin-top: 80px;
}

.create-alert--alert_meta {
    padding: 1.75% 11%;
    margin-top: 80px;
}

.create_alert--header {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.2rem;
    color: #45464E;
}

.create_alert--sub_header {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.2rem;
    color: #6E7079;
}

.create_alert--form_group {
    display: flex;
    grid-gap: 0.65rem;
    gap: 0.65rem;
    margin: 1rem;
    flex-wrap: wrap;
}

.create_alert--form_group--column {
    flex-direction: column;
}

.create_alert--form_group--row {
    flex-direction: row;
}

.create_alert--form_group--input {
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    padding: 0.65rem 0.75rem;
}

.create_alert--form_group--input:focus {
    outline: 2px solid #E7E7E7;
    ;
}

.create_alert--form_group--input_text {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: #45464E;
}

.create_alert--form_group--label {
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #6E7079;
}

.create_alert--form_group--radio_group {
    display: flex;
}

.create_alert--form_group--radio input,
.create_alert--form_group--radio label {
    cursor: pointer;
}

.create_alert--form_group--radio {
    margin-right: 1rem;
}

.justify_content--end {
    justify-content: end;
}

/* Create Dialogue CSS */

.create_alert--alert_meta--container {
    background: #FFFFFF;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.create_alert--alert_meta--title {
    border-right: 1px solid #EBEBEB;
    padding-top: 5.5%;
    padding-left: 7.75%;
    flex-grow: 0.4;
    flex-shrink: 0.4;
    flex-basis: 0;
}

.create_alert--alert_meta--info {
    padding: 2.75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 0.6;
    flex-shrink: 0.6;
    flex-basis: 0;
}

/* Card Select */

.card_select--container {
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 1rem;
    grid-gap: 2rem;
    gap: 2rem;
}

.card_select--container:hover {
    cursor: pointer;
}

.card_select--name {
    font-weight: 500;
}

.card_select--icon_x_name {
    display: flex;
    flex-direction: column;
}

.card_select--checkcircle {
    display: flex;
    justify-content: end;
}

.card_select--group--row {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.create_alert--alert_header {
    display: flex;
    margin: 1rem 4rem;
    align-items: center;
    justify-content: space-between;
}

.create_alert--alert_header--alert_name {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #45464E;
}

.create_alert--workflow_line_container {
    display: flex;
    justify-content: center;
    position: relative;
}

.create_alert--workflow_line_icon {
    font-size: 48px;
}

.create_alert--workflow_line_root_condition {
    display: flex;
    background: #8B8D97;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    position: absolute;
    padding: 0.25em 1em;
    top: 1.5em;
}

.create_alert--add_alert_rule_menu {
    display: flex;
    justify-content: center;
}

.create_alert--create_button--container { 
    display: flex;
    justify-content: center;
}

.create_alert--create_button--button.MuiButton-root {
    margin: 1em;
    background: #8B8D97;
    border-radius: 19px
}

.create_alert--create_button--button.valid.MuiButton-root {
    background: #2F4B82;
}

.create_alert--create_button--button.MuiButton-root:hover { 
    background: #8B8D97
}

.create_alert--create_button--button.valid.MuiButton-root:hover { 
    background: #2F4B82
}
.container {
    border: 1px solid black;
    padding: 0.5rem;
    margin: 0.5rem;
}

.label_configuration--container {
    background: #FFFFFF;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.create_alert--container .label_configuration--container {
    margin: 1rem 4rem;
    border-radius: 12px;
}

.label_configuration--header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #EBEBEB;
}

.label_configuration--header:hover {
    cursor: pointer;
}

.label_configuration--header--meta {
    display: flex;
    flex-direction: row;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}

.label_configuration--header--meta--icon {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    color: #2F4B82;
    background: #F4F5FA;
    border-radius: 8px;
}

.label_configuration--header--meta--title_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.label_configuration--header--meta--title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #45464E;
}

.label_configuration--header--meta--subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6E7079;
}

.label_configuration--header--action_dots {
    display: flex;
    align-items: center;
}

.label_configuration--body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Footer */

.label_configuration--footer {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    border-top: 1px solid #EBEBEB;
}

.save_config_button.MuiButton-contained,
.save_config_button.MuiButton-contained:hover {
    background: #2F4B82;
}

/* Event Condition */

.label_configuration--body--condition_container {
    margin: 1em 0em;
}

.label_configuration--body--condition_header {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #45464E;
    padding: 0.5rem;
    margin: 0.5rem;
}

.label_configuration--body--action--add_button.MuiButton-root {

    margin: 1em;
    color: #2F4B82;
    background: #FFFFFF;
    border-radius: 40px;
}

.label_configuration--body--action--add_button.MuiButton-root:hover {
    background: #FFFFFF
}

.label_configuration--body--rule_container {
    display: flex;
    flex-direction: column;
}

.label_configuration--body--rule {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    margin: 0.5em;
    padding: 0.25em 0.5em;


    background: rgba(244, 245, 250, 0.7);
    border-radius: 8px;
}

.label_configuration--body--rule--inputs {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
}

.label_configuration--body--rule--remove_icon.MuiSvgIcon-root {
    color: #D14343;
}

/* More Action */

#label-configuration-more-options .MuiMenuItem-root:hover {
    background: #F4F5FA;
    border-radius: 8px;
}

.label-configuration-more-options-paper.MuiMenu-paper {
    padding: 0rem 0.5rem;
    width: 10rem;
}

/* Common */

.d-none {
    display: none;
}
.registration_labelTextbox {
  height: 38.56px;
  bottom: 76.77%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #676984 !important;
}

.registration_UserNameInput {
  top: 10% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #676984 !important;
}

.registration_passwordRequirement {
  margin-left: 2em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.registration_loginMobileText {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1.5rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
}

.MuiGrid-root.registration_userNameContainer {
  margin-top: 40px;
}

@media only screen and (max-width: 550px) {
  .registration_TextBoxMobileView {
    margin-left: 15px;
    width: 95.5%;
  }
}

@media only screen and (min-width: 600px) {
  .registration_TextBoxMobileView {
    margin-left: 25px;
    width: 89%;
  }
}

@media only screen and (max-width: 550px) {
  .registration_inputBoxMobileView {
    margin-right: 8% !important;
    margin-left: 14px !important;
  }
}

@media only screen and (min-width: 600px) {
  .registration_inputBoxMobileView {
    margin-left: 25px;
  }
}

@media only screen and (min-width: 960px) {
  .registration_inputBoxMobileView {
    margin-left: 25px;
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxPassword {
    margin-left: 13px !important
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxPassword {
    margin-left: 25px;
  }
}

.registerButtonContainer{
  margin-top: 20px !important;
  margin-bottom: 20px;
}

.MuiButton-root.registerButton {
  color: #ffffff;
  background: #264C86;
}

.MuiButton-root.registerButton:hover {
  background: #254b83;
}

@media only screen and (max-width: 550px) {
  .registerButtonContainer {
    margin-left: 20px !important;
    margin-right: 5% !important;
  }
}

@media only screen and (min-width: 600px) {
  .registerButtonContainer {
    margin-left: 30px !important;
    margin-right: 15% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .registerButtonContainer {
    margin-right: 15% !important;
    align-items: end;
    text-align: right;
  }
}

@media only screen and (max-width: 550px) {
  .registerButton {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .registerButton {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .registerButton {
    max-width: 160px;
  }
}

.contactNumber{
  margin-top: 15px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 550px) {
  .contactNumber {
    margin-left: 23px !important;
    width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .contactNumber {
    margin-left: 35px;
    width: 86%
  }
}

@media only screen and (max-width: 600px) {
  .registration_passwordRequirement {
    margin-left: 1.2em;
  }
}

.visibilityButton .MuiIconButton-root{
  padding: 0px !important;
}

.react-tel-input .form-control {
  height: 40px !important;
  width: 100% !important;
}

.MuiOutlinedInput-input{
  border: 0px !important;
  /* padding: 10px 14px; */
}
.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-track {
    background: #c4c4c4;
    border: 0.5px solid #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
    /* border: 3px solid orange; */
  }
  /* @media only screen and (min-width : 1200px) {
    .MuiInputBase-root{
        width: 323px !important ;
    }
}
   */
.reset-password .outerDiv {
  background-color: #ffffff;
  margin-top: 2rem;
  padding: 2rem;
  /* width: 60%; */
}

.reset-password .innerDiv {
  /* border: 2px solid #E5E5E5; */
  border: 1px solid #c2c2c2;
  padding: 1rem;
  /* margin-top: 2rem; */
}

.reset-password .horizontal-line {
  margin: 0.8rem -1rem 1rem -1rem;
  border-bottom: 1px solid #e5e5e5;
}

.reset-password_textCaption {
  font-weight: 700 !important;
  font-size: 26px !important;
  line-height: 30px;
  margin-left: 40px !important;
  max-width: 313px;
  color: #254b83;
}

@media only screen and (min-width: 800px) {
  .reset-password_textCaption {
    margin-top: 240px !important;
  }
}

@media only screen and (max-width: 780px) {
  .reset-password_textCaption {
    margin-top: 130px !important;
  }
}

.reset-password_loginMobileTextCaption {
  position: absolute;
  top: 70px;
  left: 20px;
  color: #ffffff;
  font-size: 28px;
  line-height: 26px;
}

.MuiButton-root.reset-password_confirmButtonReset {
  margin-top: 30px;
  color: #ffffff;
  background: #264C86;
}

.MuiButton-root.reset-password_confirmButtonReset:hover {
  background: #254b83;
}

@media only screen and (min-width: 800px) {
  .reset-password_confirmButtonReset {
    width: 120px !important;
    margin-left: 35px !important;
  }
}

@media only screen and (min-width: 760px) {
  .reset-password_confirmButtonReset {
    width: 75%;
    margin-left: 35px !important;
  }
}

@media only screen and (max-width: 750px) {
  .reset-password_confirmButtonReset {
    width: 80%;
    margin-left: 25px !important;
    height: 35px !important;
  }
}
.quota_management--base--container {
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #2F4B82;
    border-radius: 0.5rem;
}

.quota_management--base--margin_1x {
    margin: 1rem;
}

.quota_management--base--margin_05x {
    margin: 0.5rem;
}

.quota_management--base--mt_1x {
    margin-top: 1rem;
}

.quota_management--base--mb_1x {
    margin-bottom: 1rem;
}

.quota_management--base--ml_1x {
    margin-left: 1rem;
}

.quota_mangement--base--padding_1x {
    padding: 1rem;
}

.quota_mangement--base--padding_05x {
    padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.quota_management--base--margin_1x .quota_management--base--pb_1x {
    padding-bottom: 1rem;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
}

.quota_management--base--font_07x { 
    font-size: 0.7rem;
    font-weight: 600;
}

.quota_management--base--font_1x { 
    background: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    padding: 15px;
    color: #45464E;
    font-family: 'Inter';
    display: flex;
}

.margin-left-auto {
    margin-left: auto;
}

.quota_management--base--font_2x {
    font-size: 1.5rem;
    font-weight: 500;
}

.quota_management--base--flex {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.quota_management--base--flex--column {
    display: flex;
    flex-direction: column;
}

.quota_management--base--flex--row {
    display: flex;
    flex-direction: row;
}

.quota_management--base--flex--justify--start {
    display: flex;
    justify-content: flex-start;
}

.quota_management--base--flex--justify--end {
    display: flex;
    justify-content: flex-end;
}

.quota_management--base--flex--justify--center {
    display: flex;
    justify-content: center;
}

.quota_management--base--flex--justify--space_between {
    display: flex;
    justify-content: space-between;
}

.quota_management--base--flex--align--start {
    display: flex;
    align-items: flex-start;
}

.quota_management--base--flex--align--center {
    display: flex;
    align-items: center;
}

.tooltip-invalid-interfaces img {
    cursor: pointer;
}

.quota_management--base--cursor--pointer {
    cursor: pointer;
}

.MuiButtonBase-root.quota_management--button--cancel {
    border: 1px solid #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.quota_management--button--cancel:hover {
    border: 1px solid #EBEBEB;
}

.MuiButtonBase-root.quota_management--button--cancel:disabled {
    background-color: #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.quota_management--button--save {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.quota_management--button--save:hover {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.quota_management--button--save:disabled {
    background-color: #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.quota_management--button_outlined--primary {
    border-color: #264C86;
    color: #264C86;
}

.helpIcon-quota {
    color: #264C86;
}

.MuiButtonBase-root.quota_management--button_outlined--primary:hover {
    border-color: #264C86;
}

.MuiPaper-root.quota_management--container {
    background-color: white;
    /* position: absolute;
    left: 0px;
    top: 50px;
    right: 0;
    bottom: 0; */
    padding:10px 20px 15px 20px;
    overflow: auto;
    height: calc(100vh - 140px);
}

.quota_management--loader--container {
    position: absolute;
}

/* Pagination Unset */

.quota_management--container .pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    font-family: unset;
    margin-top: auto;
}

.quota_management--container .pagination-toolbar .records-select {
    font-size: 14px;
    font-family: unset;
    border-radius: 8px;
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
}

.quota_management--container .MuiMenuItem-root.records-select-item {
    font-size: 10px;
    font-family: unset;
}

.MuiMenuItem-root.records-select-item {
    font-size: 0.9rem;
    font-family: unset;
}

/* tooltip */

.MuiTooltip-tooltip.quota_management--tooltip {
    background-color: black;
    color: white;
    padding: unset;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
}

.MuiTooltip-tooltip.quota_management--tooltip .MuiTableCell-root {
    color: white;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.MuiTooltip-tooltip.quota_management--tooltip .quota_management--tooltip--value_title {
    opacity: 0.5;
}

/* comp level */

.quota-kontrol-note{
    position: absolute;
    top: 75px;
    font-size: 12px;
}

.quota-help-container {
    position: fixed;
    right: -20px;
}

.quota_management--table_container .MuiTableCell-root {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-collapse: collapse;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 1px;
    color: #45464E;
}

.quota_management--table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}
.quota_management--usage_tooltip.MuiTooltip-tooltip {
    padding: 0.25rem;
    max-width: unset;
}

.quota_management--usage_tooltip .MuiTableCell-root {
    border: 1px solid #EBEBEB;
    padding: 0.25rem 0.5rem;
    border-collapse: collapse;
}

.quota_management--usage_tooltip .MuiPaper-root {
    background-color: #F5F5F5;
}

.quota_management--usage_tooltip--cell_exceeded {
    color: #ee3939;
}

.quota_management--usage_tooltip--cell_alerting {
    color: #daaf02;
}
.add-quota-send-to-via-email .disbale-user-selection, .add-quota-send-to-via-email .disbale-user-selection *{
    pointer-events: none;
    cursor:not-allowed;
    opacity: 0.8;
}
.add-quota-send-to-via-email .pls-select-users{
    color: red;
    margin-top: 2px;
}

.form-parent .starlink-dual-message {
    font-family: 'Inter';
    font-size: 12px;
    color: red;
}

.MuiMenu-list .starlink-dual-message {
    font-family: 'Inter';
    font-size: 12px;
    padding-left: 15px;
    color: red;
}

.MuiMenu-list .starlink-message {
    font-family: 'Inter';
    font-size: 12px;
    padding-left: 15px;
    color: #264C86;
}

.close-btn-ht-wd {
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.quota_management--base--margin_1x .device-length-render-text {
    font-size: 12px;
    color: red;
}
.quota_management--dashboard .MuiTableCell-root {
    border-collapse: collapse;
}

.quota_management--dashboard .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
}

.quota_management--dashboard .MuiTableCell-body {
    padding: 0.5em;
}

.quota_management--dashboard--actions{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 8rem;
}

.quota_management--dashboard--usage {
    width: 17rem;
}

.quota_management--dashboard--dash_size {
margin-left: 1rem;
}

.quota_management--dashboard--sort_arrows {
    margin-left: 0.15rem;
}

.quota_management--dashboard--usage_metric_color--normal {
    color: #1B51A7
}

.quota_management--dashboard--usage_metric_color--remaining {
    color: #C2C7E3
}

.quota_management--dashboard--usage_metric_color--exceeded {
    color: #EE3939
}

.quota_management--dashboard--usage_metric_color--alerting {
    color: #ffcc00
}

.quota-container{
    display: flex;
    justify-content: space-between;
    background: #f9f9f9;
}

.wan-hover-parent .wan-hover {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.row-parent .wan-hover-parent {
    display: flex;
}

.quota-search-addQuota-div {
    align-items: center;
}

.quota_management--dashboard--superscript {
    color: black;
}

.quota_management--dashboard--system-quota-indication {
    font-size: 12px;
    text-align: right;
}
.sorting_icons {
    display: flex;
    margin-left: 0.5rem;
    cursor: pointer;
    height: 0.7rem;
    width: 0.7rem;
}
.quota-copy-config-pop-up .config-apply-config-target,
.quota-copy-config-pop-up .select-target-type {
    margin-top: 15px;
    margin-bottom: 5px;
}

.quota-copy-config-pop-up .MuiDialogContent-root {
    min-height: 250px;
}

.quota-copy-config-pop-up .manage-config-pop-up .config-apply-config-target .value {
    display: block;
    margin-left: 0px;
}
.config-container .reports-login-history {
    width: 100%;
    padding: 24px 10px 0px 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    margin: auto;
    margin-top: 20px;
}

.config-container .trends-usage-table-container {
    padding: 0px;
    margin-top: 16px;
}

.TopUsersConatiner .loginHistory-tableContainer {
    margin-top: -8px;
    margin-bottom: 20px;
    height: auto;
}

.padding-left-5 {
    padding-left: 5px;
}

.reports-login-history>div:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    align-items: flex-end;
}

.config-container .trends-usage-table-container .trends-usage-search-container {
    align-items: center;
    margin: 4px 0px -10px 0px;
}

.config-search-bar-text .login-history-search-bar {
    margin-top: 0px;
    height: 32px;
}

.login-left-align .login-history-dp-name {
    color: #8B8D97;
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    cursor: pointer;
}

.loginHistory-tableRow td {
    text-align: left;
    padding: 5px;
    padding-left: 15px;
}

.login-tableRow .login-tableCell {
    background: #f5f5f5;
}

.loginHistory-tableRow .login-left-align {
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    background: #FFFFFF;
}

.loginHistory-tableRow .selectedClick {
    cursor: pointer;
    color: #4c3aff;
}

.reports-login-history .site-details-header {
    color: #45464E;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 14px;
    margin: -10px 0px 19px 5px;
}

.reports-login-history .usage-site-details-header {
    margin: 0px 0px 0px 16px;
}

.new-summary-usage-tr .inter-font {
    font-family: 'Inter';
    font-weight: 500;
    padding: 5px 0px 5px 16px;
    font-size: 12px;
}

.reports-login-history .search-dropdown-limit-div {
    display: flex;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 10px;
    position: relative;
}

.search-dropdown-limit-div .search-list-new {
    display: flex;
    align-items: center;
}

.download-summary-btn {
    background-color: #244C84;
    border-radius: 4px;
    width: 109px;
    text-align: center;
}

.download-summary-btn button {
    color: #fff;
    text-transform: none;
    letter-spacing: unset;
    font-weight: 700;
    padding: 6px 8px;
    font-family: 'Inter';
}

.downloadBtnContainer {
    position: absolute;
    top: 10px;
    right: 0px;
}

.reports-login-history .usage-table-search-download-div {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
}

.trendsAlertsSearchbarMargin {
    margin-right: 16px;
}

.trends-usage-search-container .usage-downloadBtnContainer {
    position: unset;
    margin-top: -5px;
}

.usage-table-search-download-div .margin-left-auto {
    width: 300px;
}

.downloadImg {
    vertical-align: middle;
}

.login-tableRow .new-summary-left {
    padding-left: 16px;
}

.TopUsersConatiner .new-usage {
    margin-bottom: 7px;
}

.TopUsersConatiner .remove-shadow {
    box-shadow: none;
    margin-bottom: 8px;
}

.marg-top-2 {
    margin-top: 2px;
}

.MuiTextField-root.input-box-uptime-tbl {
    margin: 8px;
    width: 100%;
    max-width: 300px;
    margin-left: 0px;
}

.MuiTextField-root.input-box-usage-tbl {
    margin: 8px;
    width: 300px;
    margin-left: 0px;
    height: 32px;
}

.trends-usage-search-container .usage-table-search-download-div {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
}

.config-container .trends-usage-table-container .TopUsersConatiner {
    padding-left: 0px;
}

.remove-shadow .MuiToolbar-root {
    padding-left: 16px;
}

/* Alerts */

.new-summary-usage-tr .interface-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
.new-summary-single-site-table {
    margin-top: 16px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.new-top-user-table-container .summ-table {
    margin-top: 15px;
}

.summ-table .new-summary-top-users-table {
    margin-bottom: 10px;
    overflow-x: hidden;
}

.new-top-user-table-container {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 0px 0px 15px 0px;
}

.new-summary-single-site-table>span {
    color: #45464E;
    font-style: normal;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
}

.new-top-user-table-container .search-box--filter-chips {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.usageData-tabelCell .activity-top-sites {
    width: 100%;
}

.usage-tableBody .activity-new-tableRow .leftAllign {
    text-align: left;
    padding-left: 15px;
}

.summ-table .activity-users-pagination {
    margin-bottom: 5px;
    box-shadow: none;
}

.MuiTextField-root.input-box-activity-tu {
    margin: 16px 16px 0px 0px;
    width: 300px;
    max-width: 300px;
    margin-left: 0px;
}

.new-top-user-table-container .remove-shadow {
    box-shadow: none;
    margin-bottom: 7px;
}

.search-dropdown-limit-div .activity-usage-site-details-header {
    margin-left: 16px;
    margin-right: 16px;
}

.new-summary-top-users-table .user-width-7per {
    width: 7%;
}

.activity-new-tableRow .user-top-apps {
    padding-left: 10px;
}

.user-top-apps img {
    padding-left: 0px;
    padding-right: 0px;
}

.trendsUsageSearchbarMargin {
    margin-right: 16px;
  }
.usage-per-site-pie .title-text {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #45464E;
    margin: 5px 0px 20px 15px;
    padding-top: 10px;
}

.usage-per-site-pie .title-text-right {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #45464E;
    margin: 5px 0px 20px 5px;
    padding-top: 10px;
}

.usage-per-site-pie .title-text-left {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #45464E;
    margin: 5px 5px 20px 5px;
    padding-top: 10px;
}

.bucket-text{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 700;
        color: #45464E;
        margin-right: 20px;
        /* margin: 5px 0px 20px 15px;
        padding-top: 10px; */
    
}

.new-summary-chart-container .new-summary-chart-component .padding-left-22 {
    padding-left: 20px;
}

.bucket-padding {
    min-width: 40px;
    margin-left: 35px;
}

.usage-per-site-pie {
    border-radius: 4px;
    box-shadow: 0px 0px 7px 0px rgba(167, 158, 158, 0.25);
    padding: 5px;
    height: 100%;
}

.usage-per-site-pie .usage-details-pie-perSite {
    display: flex;
    justify-content: center;
    margin: 15px auto 10px 5px;
}

.usage-details-pie-perSite .summary-usage-value {
    color: #040404;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.usage-details-pie-perSite .summary-usage-unit {
    color: #6E7079;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.usage-details-pie-perSite>div {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    margin-bottom: 7px;
}

.usage-details-pie-perSite .summary-pie-usage-dot, .summary-pie-bar-legends .summary-pie-usage-dot {
    width: 9px;
    height: 9px;
    border-radius: 20px;
}

.usage-per-site-pie .wan-type-pie {
    margin-left: 30px;
    justify-content: flex-start;
}

.usage-per-site-pie .legends-pie-new-container {
    display: flex;
    justify-content: flex-end;
    grid-gap: 15px;
    gap: 15px;
    margin: 15px 15px 25px 0px;
    align-items: center;
}

.usage-per-site-pie .removeMarginTop {
    margin-top: 10px;
}

.legends-pie-new-container .summary-pie-bar-legends {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    color: #040404;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.summary-pie-bar-legends .barChart-legends {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    color: #0e0548;
    cursor: pointer;
    font-family: 'Inter';
}

.summary-pie-bar-legends .disable-barChart-legends {
    color: #a2a2a6;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
    font-family: 'Inter';
}

.usage-per-site-pie .title-chip-pie {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.legends-pie-new-container .line-bar-view {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    align-items: center;
}

.legends-pie-new-container .time-series-legends-new {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

.usage-per-site-pie .new-summary-control-limit {
    display: flex;
    align-items: center;
}

.bucket-outer{
    margin-top: 20px;
    display: flex;
    align-items: center; 
}

.usage-per-site-pie .chart-legends-histogram {
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 15px;
}

.chart-legends-histogram .legends-view-images {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

.usage-details-pie-perSite .selected-filter {
    cursor: pointer;
    color: #4c3aff;
}

.usage-details-pie-perSite .sites-list {
    margin-bottom: -3px;
}

.usage-per-site-pie .title-toggle-btn-div {
    display: flex;
    justify-content: space-between;;
    align-items: center;
}

.new-summary-control-limit .title-dropdown-pie-chart {
    display: flex;
    align-items: center;
}

.title-toggle-btn-div .toggle-wan , .title-toggle-btn-div .toggle-site {
    font-size: 12px;
    font-style: normal;
    font-family: 'Inter';
    font-weight: 500;
}

.usage-details-pie-perSite .single-value {
    display: flex;
    justify-content: center;
}

.pie-chart-summary .remove-margin-left {
    margin-left: 0px;
    justify-content: center;
}

.usage-per-site-pie .time-series-legends-new-sites {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 15px;
}

.time-series-legends-new-sites .summary-pie-bar-legends-sites {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.legends-pie-new-container .legends-view-images-sites  {
    display: flex;
    align-items: center;
}

.site-line-chart-title-div .site-line-chart-title-text {
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 0px;
}

.summary-pie-bar-legends-sites #site-legend-filter {
    color: #4c3aff;
}

.histogram-dialog{
    min-height: 250px;
}

.histogramDialogTitle{
        font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #45464E;
      border-bottom: 1px solid #EBEBEB;
      padding: 20px 24px 0px 24px;
      
}

.title-toggle-btn-div #justify-center-sites {
    justify-content: flex-start;
}
.usageReports-tableHead .login-tableRow .alert-left-align {
    text-align: left;
    padding-left: 15px;
}

.remove-padding .inter-font {
    padding: 0px 15px;
}

.alerts-tableHead .login-tableRow .details-width {
    width: 1%;
    text-align: center;
    padding-left: 0px;
}

.login-tableRow .alert-width {
    width: 9%;
}

.login-tableRow .wan-width {
    width: 4%;
}

.alert-left-align .alerts-name-a {
    color: #0c13ef;
}

.search-dropdown-limit-div .alerts-margin-left-20 {
    margin-left: 25px;
}

.search-dropdown-limit-div .desclaimer-text {
    margin-left: 16px;
}

.MuiTextField-root.input-box-alerts-tbl {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 16px;
    margin-right: 16px;
    width: 300px;
}
.content_filter--base--container {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #2f4b82;
  border-radius: 0.5rem;
}

.content_filter--base--margin_1x {
  margin: 1rem;
}

.content_filter--base--margin_05x {
  margin: 0.5rem;
}

.content_filter--base--mtb_07x {
  margin-top: 0.1rem;
  margin-bottom: 0.9rem;
  padding-top: 15px;
}

.content_filter--selectall--mtb_07x {
  margin-top: 0.01rem;
  margin-bottom: 0.5rem;
}
.content_filter--base--mt_1x {
  margin-top: 1rem;
}

.content_filter--base--mb_1x {
  margin-bottom: 1rem;
}

.content_filter--base--ml_1x {
  margin-left: 1rem;
}

.content_filter--base--mr_1x {
  margin-right: 1rem;
}

.content_filter--base--mr_05x {
  margin-right: 0.5rem;
}

.content_filter--base--padding_1x {
  padding: 1rem;
}

.content_filter--base--padding_05x {
  padding: 0.5rem;
}

.content_filter--base--pb_1x {
  padding-bottom: 1rem;
}

.content_filter--container .MuiTypography-root {
  font-family: "Inter";
  font-weight: 500;
  color: #45464e;
}

.content_filter--base--font--base_wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTypography-root.content_filter--base--font_07x {
  font-size: 0.7rem;
  font-weight: 600;
}

.MuiTypography-root.content_filter--base--font_08x {
  font-family: "Inter";
  font-size: 0.8rem;
}

.MuiTypography-root.content_filter--base--font_09x {
  font-size: 0.9rem;
  margin-top: "2px";
}

.MuiTypography-root.content_filter--base--font_1x {
  font-size: 1rem;
}

.MuiTypography-root.content_filter--base--font_15x {
  font-size: 1.25rem;
}

.MuiTypography-root.content_filter--base--font_bold {
  font-weight: 100;
}

.MuiTypography-root.content_filter--base--font_2x {
  font-size: 1.5rem;
  font-family: inter;
  font-style: normal;
}

.MuiTypography-root.content_filter--base--text_center {
  text-align: center;
}

.MuiTypography-root.content_filter--base--text_start {
  text-align: start;
}

.MuiTypography-root.content_filter--base--color_danger {
  color: #ed524a;
}

.MuiTypography-root.content_filter--base--color_success {
  color: #6cce76;
}

.MuiTypography-root.content_filter--base--color_primary {
  color: #2860b4;
}

.content_filter--base--flex {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.content_filter--base--flex--gap--05x {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.content_filter--base--flex--column {
  display: flex;
  flex-direction: column;
}

.content_filter--base--flex--row {
  display: flex;
  flex-direction: row;
}

.content_filter--base--flex--justify--start {
  display: flex;
  justify-content: flex-start;
}

.content_filter--base--flex--justify--end {
  display: flex;
  justify-content: flex-end;
}

.content_filter--base--flex--justify--center {
  display: flex;
  justify-content: center;
}

.content_filter--base--flex--justify--space_between {
  display: flex;
  justify-content: space-between;
}

.content_filter--base--flex--justify--space_around {
  display: flex;
  justify-content: space-around;
}

.content_filter--base--flex--align--start {
  display: flex;
  align-items: flex-start;
}

.content_filter--base--flex--align--center {
  display: flex;
  align-items: center;
}

.content_filter--base--cursor--pointer {
  cursor: pointer;
}

.MuiButtonBase-root.content_filter--button--cancel {
  border: 1px solid #ebebeb;
  color: #264c86;
}

.MuiButtonBase-root.content_filter--button--cancel:hover {
  border: 1px solid #ebebeb;
}

.MuiButtonBase-root.content_filter--button--cancel:disabled {
  background-color: #ebebeb;
  color: #264c86;
}

.MuiButtonBase-root.content_filter--button_outlined_filter {
  border-radius: 4px;
  border-color: #244C84;
  padding: 7px 8px;
  justify-content: center;
  align-items: center;
  color: #244C84;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.MuiButtonBase-root.content_filter--button_contained--primary {
  background-color: #244C84;
  color: #ffffff;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.MuiButtonBase-root.content_filter--button_contained--primary:hover {
  background-color: #244C84;
  color: #ffffff;
}

.MuiButtonBase-root.content_filter--button_contained--primary:disabled {
  background-color: #ebebeb;
  color: #264c86;
}

.MuiButtonBase-root.content_filter--button_outlined--primary {
  border-color: #264c86;
  color: #264c86;
}

.MuiButtonBase-root.content_filter--button_outlined--primary:hover {
  border-color: #264c86;
}

/* table style */

.content_filter--table_container .MuiTableCell-root {
  background: #ffffff;
  border-collapse: collapse;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 1px;
  color: #45464e;
}

.content_filter--table_container .MuiTableCell-root.MuiTableCell-head {
  background-color: #f5f5f5;
  color: #8b8d97;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 145%;
  letter-spacing: 1px;
  cursor: pointer;
}

/* Pagination Unset */

.content_filter--container .pagination-root .pagination-toolbar {
  min-height: 40px;
  height: 40px;
  padding-right: 2px;
  font-size: 14px;
  margin-top: auto;
  padding-left: unset;
}

.content_filter--container .pagination-toolbar .records-select {
  font-size: 14px;
  border-radius: 8px;
  padding: 0px 8px 0px 7px;
  margin-right: 5px;
}

.content_filter--container .MuiMenuItem-root.records-select-item {
  font-size: 10px;
}

.content_filter--container .pagination-toolbar .row-dropdown {
  width: unset;
  margin-left: 0.5rem;
}

.content_filter--container .pagination-toolbar .page-dropdown {
  width: unset;
}

/* tooltip */

.MuiTooltip-tooltip.content_filter--tooltip {
  background-color: black;
  color: white;
  padding: unset;
  font-family: "Inter";
  font-size: 0.75rem;
  font-weight: 500;
}

.MuiTooltip-tooltip.content_filter--tooltip .MuiTableCell-root {
  color: white;
  font-family: "Inter";
  font-size: 0.75rem;
  font-weight: 500;
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.MuiTooltip-tooltip.content_filter--tooltip
  .content_filter--tooltip--value_title {
  opacity: 0.5;
}

/* comp level */

.MuiGrid-root.content_filter--container {
  background-color: #f9f9f9;
  padding: 109px 1rem 1rem 1rem;
  overflow: auto;
  height: calc(100vh - 140px);
}

.content_filter--loader--container {
  position: absolute;
}

.content_filter--loader--container .loader {
  position: absolute;
  left: -1rem;
}

.content_filter--container .MuiChip-filled {
  margin: unset;
}

.content_filter--container .content_filter--dns_tag {
  max-width: 10rem;
}

.config-content-filter-popup {
  color: #9a9a9a;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
}

.config-deploy-inventory-popup span {
  width: 40%;
  color: #45464e;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
}

.content-filter-title-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color:rgba(69, 70, 78, 1)
}

.content-filter-tooltip-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 8px;
  gap: 8px;
}


.content_filter-div-flex-end{
  display: flex;
  justify-content: flex-end;
}

.content_filter-div-flex-start{
  display: flex;
  justify-content: flex-start;
}

.MuiTextField-root.content_filter-search_field {
  width: 300px;
  margin-right: 10px;
}

.MuiButtonBase-root.content_filter--text-button {
  border-radius: 4px;
  border-color: #244C84;
  justify-content: center;
  align-items: center;
  color: #244C84;
  margin-right: 10px;
  padding: 0px;
}
.config-create-content-filter-popup {
  color: #9a9a9a;
  font-style: normal;
  font-size: 14px;
  overflow-y: hidden;
}

.add-custom-domain-text {
  font-size: 14px;
  margin-top: 10px;
}

.create-content-filter-title-name {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #45464e;
}

.create-content-filter-close-btn {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.form-content-filter {
  display: flex;
}

.content-filter-config-details {
  flex: 1 1;
  padding: 10px;
}

.content-filter-nav-bar {
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.content-filter-nav-bar ul {
  list-style-type: none;
  padding: 0;
}

.content-filter-nav-bar li {
  display: flex;
  height: 20px;
  padding: 16px 0px 16px 24px;
  align-items: center;
  align-self: stretch;
}

.content-filter-nav-bar li:hover {
  background-color: rgba(244, 245, 250, 1);
}

.content-filter-nav-item {
  padding: 10px;
  cursor: pointer;
}

.content-filter-nav-item:hover {
  background: #f0f0f0;
}

.content-filter-nav-item.selected {
  background-color: #F4F5FA;
  font-weight: bold;
}


.MuiDialogContent-root.create-content-filter-dialog-content {
    height: 480px;
    padding: 0px;
    overflow-y: hidden;
}

.MuiGrid-root.create-content-filter-single-form-row {
  height:65px
}

.MuiGrid-root.create-content-filter-single-form-span-center{
  display:flex;
  justify-content: flex-start;
}

.MuiInputBase-input.create-content-filter-comment{
  height:100px
  
}
.MuiDialog-root.config-create-content-filter-popup .MuiDialog-container .MuiPaper-root {
  height: 600px;
  overflow: hidden;
}

.MuiButtonBase-root.content_filter-footer-create-button{
  display: flex;
  padding: 7px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #1B51A7;
}

  .MuiGrid-root.create-content-filter-single-form-row {
    height:65px
  }
  
  .MuiGrid-root.create-content-filter-single-form-span-center{
    display:flex;
    justify-content: flex-start;
  }
  
  .MuiInputBase-input.create-content-filter-comment{
    height:100px
  }
.content_filter-blocked-categories-heading{
    color: #45464E;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.MuiGrid-root.content-filter-blocked-categoies-div {
    margin-top: 15px;
}

.MuiGrid-root.content-filter-blocked-seletced-categoies-div {
    height: 65px;
    overflow-y: scroll;
}

.content_filter-blocked-categories-checkbox-text{
    color:  #45464E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}

.content_filter-blocked-categories-checkbox-select-all-text{
    color: #1B51A7;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.MuiChip-root.content-filter-selected-blocked-category-chip{
    background-color: #F4F5FA;
    margin: 5px;
}
.content-filter-domain-name-text{
    margin-top:10px;
    margin-right: 5px;
}

.MuiTableContainer-root.content_filter--domain-names-table_container{
    height: 210px;
    overflow-x: hidden;
}

.content_filter--domain-names-table_container .MuiTableCell-root {
    background: #ffffff;
    border-collapse: collapse;
    font-style: normal;
    font-size: 0.75rem;
    color: #45464e;
  }
  
.content_filter--domain-names-table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #f5f5f5;
    color: #8b8d97;
    font-style: normal;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}

.MuiGrid-root.content-filter-upload-csv-div {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
    padding: 10px;
}

.content-filter-csv-text{
    color: #797979;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.content-filter-csv-uploaded-text{
    color: #797979;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
}

.MuiButtonBase-root.content_filter--select-csv-button {
    width: 120px;
    margin-left: 22px;
}

.content-filter-csv-image{
    position: relative;
    top: 5px;
    margin-right: 5px;
}

.MuiSvgIcon-root.content-filter-delete-icon{
    color: rgba(189, 54, 54, 1);
    cursor: pointer;
}

.MuiGrid-root.content-filter-upload-csv-grid {
    display: flex;
    justify-content: flex-end;
}

.MuiGrid-root.content-filter-add-domain-grid {
    display: flex;
    justify-content: flex-end;
}

.MuiGrid-root.content-filter-domain-list-count-grid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.domain_name_validation_err_message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 5px;
}
.MuiButtonBase-root.csv-upload--button_outlined_filter {
    border-radius: 4px;
    border-color: #244C84;
    padding: 7px 8px;
    justify-content: center;
    align-items: center;
    color: #244C84;
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.MuiButtonBase-root.select-csv-button {
    border-radius: 4px;
    border-color: #244C84;
    padding: 7px 8px;
    justify-content: center;
    align-items: center;
    color: #244C84;
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.MuiButtonBase-root.select-csv-button {
    width: 120px;
}
.starlink--service_accounts--valid_status.MuiSvgIcon-root {
    margin-right: 0.5rem;
}

.MuiTextField-root.input-box-service {
    width: 300px;
  }

.MuiChip-root.blocked-category-chip{
    background-color: #F4F5FA;
    margin: 3px;
}


.MuiChip-root.allowed-blocked-domain-chip{
    background-color: white;
    margin: 3px;
}

.MuiChip-root.allowed-blocked-domain-chip-count{
    background-color: #F4F5FA;
}

.MuiChip-root.dialog-list-chip{
    background-color: #F4F5FA;
    margin: 3px;
}

.MuiDialogContent-root.dialog-list-content-filter-dialog-content{
    padding: 20px;
}

.MuiSvgIcon-root.content-filter-status-help-icon{
    width: 15px;
    height: 15px;
    cursor: default;
    position: relative;
    top: 4px;
}


.MuiPaper-root.content-filter--container {
    background-color: white;
    /* position: absolute;
    left: 0px;
    top: 50px;
    right: 0;
    bottom: 0; */
    padding:109px 20px 15px 20px;
    overflow: auto;
    height: calc(100vh - 140px);
}

.content-filter--container .pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    font-family: unset;
    margin-top: auto;
}

.content-filter--container .pagination-toolbar .records-select {
    font-size: 14px;
    font-family: unset;
    border-radius: 8px;
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
}

.content-filter--container .MuiMenuItem-root.records-select-item {
    font-size: 10px;
    font-family: unset;
}

.content-filter--base--padding_05x {
    padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.content-filter--table_container .MuiTableCell-root {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-collapse: collapse;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 1px;
    color: #45464E;
}

.content-filter--table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}

.content-filter--dashboard--actions{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 8rem;
}

.content-filter--base--flex--align--center {
    display: flex;
    align-items: center;
}

.content-filter-download-button {
    margin-left: 5px;
}
.MuiDialogContent-root.delete-content-filter-dialog-content {
    overflow-y: hidden;
    padding: 0px;
}

.MuiDialogTitle-root.delete-content-filter-title{
    border-bottom: 1px solid #EBEBEB;
}

.MuiDialogActions-root.delete-content-filter-dialog-actions {
    border-top: 1px solid #EBEBEB;
}

.delete-form-content-filter-body {
    padding: 30px;
}
.MuiDialogContent-root.apply-content-filter-dialog-content {
    padding: 20px;
    border: none;
}

.apply-cf-dropdown{
    margin-right:5px
}

.apply-traffic-policy-dialog-actions{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.traffic-policy-content{
    max-height: 325px;
    overflow-y: scroll;
}

.remove-traffic-policy-icon{
    display: flex;
    justify-content:end;
    align-items:center
}

.traffic-policy-row{
    padding: 5px;
}

.traffic-policy-container{
    padding-right:10px;
    padding-top:10px;
    padding-bottom:10px;
}
.starlink--base--container {
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #2F4B82;
    border-radius: 0.5rem;
}

.starlink--base--margin_1x {
    margin: 1rem;
}

.starlink--base--margin_05x {
    margin: 0.5rem;
}

.starlink--base--mtb_07x {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

.starlink--base--mt_1x {
    margin-top: 1rem;
}

.starlink--base--mb_1x {
    margin-bottom: 1rem;
}

.starlink--base--ml_1x {
    margin-left: 1rem;
}

.starlink--base--mr_1x {
    margin-right: 1rem;
}

.starlink--base--padding_1x {
    padding: 1rem;
}

.starlink--base--padding_05x {
    padding: 0.5rem;
}

.starlink--base--pb_1x {
    padding-bottom: 1rem;
}

.starlink--container .MuiTypography-root {
    font-family: 'Inter';
    font-weight: 500;
    color: #45464E;
}

.MuiTypography-root.starlink--base--font_07x {
    font-size: 0.7rem;
    font-weight: 600;
}

.MuiTypography-root.starlink--base--font_08x {
    font-family: 'Inter';
    font-size: 0.8rem;
}

.MuiTypography-root.starlink--base--font_09x {
    font-size: 0.9rem;
}

.MuiTypography-root.starlink--base--font_1x {
    font-size: 1rem;
}

.MuiTypography-root.starlink--base--font_bold {
    font-weight: 600;
}

.MuiTypography-root.starlink--base--font_2x {
    font-size: 1.5rem;
}

.MuiTypography-root.starlink--base--text_center {
    text-align: center;
}

.MuiTypography-root.starlink--base--text_start {
    text-align: start;
}

.MuiTypography-root.starlink--base--color_danger {
    color: #ed524a;
}

.MuiTypography-root.starlink--base--color_success {
    color: #6cce76;
}

.MuiTypography-root.starlink--base--color_primary {
    color: #2860b4;
}

.starlink--base--flex {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.starlink--base--flex--gap--05x {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.starlink--base--flex--column {
    display: flex;
    flex-direction: column;
}

.starlink--base--flex--row {
    display: flex;
    flex-direction: row;
}

.starlink--base--flex--justify--start {
    display: flex;
    justify-content: flex-start;
}

.starlink--base--flex--justify--end {
    display: flex;
    justify-content: flex-end;
}

.starlink--base--flex--justify--center {
    display: flex;
    justify-content: center;
}

.starlink--base--flex--justify--space_between {
    display: flex;
    justify-content: space-between;
}

.starlink--base--flex--justify--space_around {
    display: flex;
    justify-content: space-around;
}

.starlink--base--flex--align--start {
    display: flex;
    align-items: flex-start;
}

.starlink--base--flex--align--center {
    display: flex;
    align-items: center;
}

.starlink--base--cursor--pointer {
    cursor: pointer;
}

.MuiButtonBase-root.starlink--button--cancel {
    border: 1px solid #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.starlink--button--cancel:hover {
    border: 1px solid #EBEBEB;
}

.MuiButtonBase-root.starlink--button--cancel:disabled {
    background-color: #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.starlink--button_contained--primary {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.starlink--button_contained--primary:hover {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.starlink--button_contained--primary:disabled {
    background-color: #EBEBEB;
    color: #b1b1b1;
}

.MuiButtonBase-root.starlink--button_outlined--primary {
    border-color: #264C86;
    color: #264C86;
}

.MuiButtonBase-root.starlink--button_outlined--primary:hover {
    border-color: #264C86;
}

/* table style */

.starlink--table_container .MuiTableCell-root {
    background: #FFFFFF;
    border-collapse: collapse;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 1px;
    color: #45464E;
}

.starlink--table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}

/* Pagination Unset */

.starlink--container .pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    margin-top: auto;
    padding-left: unset;
}

.starlink--container .pagination-toolbar .records-select {
    font-size: 14px;
    border-radius: 8px;
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
}

.starlink--container .MuiMenuItem-root.records-select-item {
    font-size: 10px;
}

.starlink--container .pagination-toolbar .row-dropdown {
    width: unset;
    margin-left: 0.5rem;
}

.starlink--container .pagination-toolbar .page-dropdown {
    width: unset;
}

/* tooltip */

.MuiTooltip-tooltip.starlink--tooltip {
    background-color: black;
    color: white;
    padding: unset;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
}

.MuiTooltip-tooltip.starlink--tooltip .MuiTableCell-root {
    color: white;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.MuiTooltip-tooltip.starlink--tooltip .starlink--tooltip--value_title {
    opacity: 0.5;
}

/* comp level */

.MuiGrid-root.starlink--container {
    background-color: white;
    margin-top:109px;
}

.starlink--container .MuiPaper-root.starlink--navigation--container, .starlink--container .MuiPaper-root.starlink--admin_navigation--container {
    padding: 0rem 1rem;
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 999;
}

.starlink--container .MuiPaper-root.starlink--admin_navigation--container {
    padding: 0rem 1rem;
    z-index: 998;
}

.starlink--container .MuiTabs-root,
.starlink--container .MuiTab-root {
    min-height: 42px;
    height: 42px;
}

.MuiGrid-root.starlink--tab_panel--container {
    padding: 0rem 1rem;
    margin: 0rem 0.2rem;
    height: calc(100vh - 165px);
    overflow: auto;
}

.starlink--container .starlink-page-container {
    height: unset;
    overflow-y: unset;
}

.starlink-page-container .add-srcoll-starlink-subtab-pages {
    height: calc(100vh - 265px);
    overflow-y: auto;
}

.MuiGrid-root.starlink--tab_panel--container {
    background-color: unset;
}

.starlink--loader--container {
    position: absolute;
}

.starlink--loader--container .loader {
    position: absolute;
    left: -1rem
}

.MuiSvgIcon-root.starlink--circle_icon {
    font-size: 0.75rem;
}

.starlink--container .MuiAlert-icon {
    padding: 7px 12px;
}

.starlink--container .MuiAlert-action, .starlink--container .MuiAlert-icon {
    margin-right: unset;
}
.starlink--reports--usage_chart--container .highcharts-container, .starlink--reports--usage_chart--container .highcharts-root {
    width: 100% !important;
}

.MuiTextField-root.input-box-starlink-rp {
    width: 300px;
}

.starlink--base--flex--row .font-20 {
    font-size: 20px;
}

.Usage-ServiceLines .sl-cardtitle {
    color: #2e1a70;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    padding-top: 5px;
    letter-spacing: .175em;
    padding-left: 5px;
}

.cardHeader-serviceLines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 5px;
}

.cardHeader-serviceLines>span:nth-child(2) {
    margin-right: 5px;
}

#selectedClick-column {
    cursor: pointer;
    color: #4c3aff;
}
.MuiListItemText-root.unset-margins {
  margin: unset;
}
.quota_management--dashboard .MuiTableCell-root {
    border-collapse: collapse;
}

.quota_management--dashboard .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
}

.quota_management--dashboard .MuiTableCell-body {
    padding: 0.5em;
}

.quota_management--dashboard--actions{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 8rem;
}

.quota_management--dashboard--usage {
    width: 17rem;
}

.quota_management--dashboard--dash_size {
margin-left: 1rem;
}

.quota_management--dashboard--sort_arrows {
    margin-left: 0.15rem;
}

.quota_management--dashboard--usage_metric_color--normal {
    color: #1B51A7
}

.quota_management--dashboard--usage_metric_color--remaining {
    color: #C2C7E3
}

.quota_management--dashboard--usage_metric_color--exceeded {
    color: #EE3939
}

.quota_management--dashboard--usage_metric_color--alerting {
    color: #ffcc00
}

.quota-container{
    display: flex;
    justify-content: space-between;
    background: #f9f9f9;
}

.wan-hover-parent .wan-hover {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.row-parent .wan-hover-parent {
    display: flex;
}
.starlink--service_accounts--valid_status.MuiSvgIcon-root {
    margin-right: 0.5rem;
}

.MuiTextField-root.input-box-service {
    width: 300px;
  }

  .starlink-account-help{
    cursor: pointer;
    color:#1976d2;
  }

  .starlink-account-infoIcon{
    color:#1976d2;
    margin-top: 5px;
  }
div[aria-labelledby="service_account--dialog"] .DPvesselDropdown .MuiButton-root {
    border-radius: 8px;
}
.org--selection--option--pre0 {
    /* position: absolute; */
    left: 0.75rem;
    z-index: 1;
    /* width: 10px; */
    height: 1.5rem;
    content: "";
    border-left: 2px solid #e0e0e0;
}

.org--selection--option--pre1{
    /* position: absolute; */
    left: 0.75rem;
    z-index: 1;
    width: 0.75rem;
    height: 2px;
    content: "";
    border-bottom: 2px solid #e0e0e0;
}
.MuiTextField-root.input-box-serviceline {
    width: 300px;
}

.starlink-change-sub-org img {
    width: 23px;
}

/* Edit ActionsPopup */

.edit-actions-content .starlinkAdmin-edit-actions-cont {
    padding-right: 15px;
}

.Form .starlink-admin-edit-actions-confirm-btns .confirm-dialogue-proceed-notAllow {
    color: rgb(38, 76, 134);
}
.edit-actions-service-account-confirm-btnss{
    width: 73%;
}
.edit-toggle-btn-container .toggle-btn-edit {
    margin-top: -2px;
}

#edit-toggle-btn-text {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

.edit-toggle-btn-container {
    margin: 20px 15px 0px 10px;
}

.pause-container-starlink .select-config-from {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.pause-container-starlink .select-config-from .MuiFormControl-root {
    width: 310px;
}

.optIn-container, .starlink-admin-change-org .starlink--base--mtb_07x:nth-child(1) {
    margin-top: 20px;
}

.optIn-container .text-optIn-dialogue {
    margin-top: 15px;
}

.starlink--table_container .starlink-admin-service-lines-actions {
    margin-top: 2px;
    margin-bottom: -2px;
}

.starlink--base--flex--justify--start .create-org-btn-width {
    width: 274px;
    font-size: 12px;
}

.starlink-admin-service-lines-actions-td {
    display: flex;
    align-items: center;
    justify-content: start;
}

.edit-actions-title .editActions-loader {
    right: 55px;
    top: 21px;
}

.starlink-admin-change-org .starlink--base--mtb_07x .MuiInputLabel-sizeSmall {
    font-size: 13px;
}

.starlink--table_container .starlink-admin-serviceLines-action-colm {
    width: 13%;
}

.starlink-serviceline-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.starlink-serviceAccount-details-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
}

.starlink-serviceline-details-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px; 
}

.starlink-serviceAccount-details-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px; 
    justify-content: space-between;
}

.starlink-serviceline-details-heading {
    width: 160px; 
    font-weight: 550;
}

.starlink-serviceAccount-details-heading {
    width: 240px; 
    font-weight: 550;
}

.edit-actions-service-account-confirm-btns {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    width: 73%;
    right: 20px;
  }

.starlink-serviceline-details-value {
    margin-left: 8px; 
}

.starlink-serviceline-details-edit-button {
    margin-left: 8px;
    padding: 0px 10px;
}

.starlink-serviceline-details-row .starlink-serviceline-details-save-button {
    color: blue ; 
}

.starlink-serviceline-details-row .starlink-serviceline-details-cancel-button {
    color: red ;
}

.starlink-serviceline-details-row .starlink-serviceline-details-edit-button {
    color: var(--edit-color) ; 
}


.serviceLineDetails-container{
    margin-top: 10px;
}

.starlink-serviceline-details-edit-button.padding-left-right{
    padding: 0px 10px ;
}

/* ServiceLineDevices.css */

.starlink-sl-devices-grid {
    margin-top: 30px;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    min-height: 175px;
}

.starlink-sl-devices-table {
    min-width: 300px;
    margin-top: 10px;
}

.starlink-sl-devices-grid .starlink-sl-devices-button {
    margin-top: 10px;
    float: right;
}

.font-wt-900 {
    font-weight: 900;
}

.serviceLineDetails-divider-margin{
    margin: 10px 0px 15px 0px;
}

.serviceLineDevices-dialog-width .MuiPaper-root {
    width: 500px;
    max-width: 500px;
    margin: auto; /* Horizontally center */
  
}


.MuiTextField-root.input-box-user-ter {
    width: 300px;
}

.org--selection--option--pre0 {
    /* position: absolute; */
    left: 0.75rem;
    z-index: 1;
    /* width: 10px; */
    height: 1.5rem;
    content: "";
    border-left: 2px solid #e0e0e0;
}

.org--selection--option--pre1{
    /* position: absolute; */
    left: 0.75rem;
    z-index: 1;
    width: 0.75rem;
    height: 2px;
    content: "";
    border-bottom: 2px solid #e0e0e0;
}
.starlink--dashboard--card_progress .MuiLinearProgress-colorPrimary {
    /* height: 0.5rem; */
    background-color: #D6DAFA;
    /* border-radius: 5px; */
}

.starlink--dashboard--card_progress .MuiLinearProgress-barColorPrimary {
    background-color: #2F4B82;
}

.MuiTypography-root.starlink--dashboard--card_title {
    color: #45464E;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.2rem;
}

.MuiTextField-root.input-box-starlink-dash {
    width: 100%;
    max-width: 300px
}

.margin-left-sl-15 {
    margin-left: 15px;
}

.starlinkAlertsTableDiv{
    height: 300px;
    overflow-y: scroll;
}

.starlinkAlertsTableWidth{
    table-layout: fixed;
    width: 100%;
}

.starlinkEllipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch; 
}

.alertIconColor{
    color: #d5d516;
}

.serviceLineLink{
    color: blue;
    cursor: pointer;
}

.starlink-d-flex{
    display: flex;
}

.starlink-ml-4{
    margin-left: 4px;
}

.starlinkGeo-font{
    font-size: 12px;
}
.starlinkGrid .termsTypo{
font-family: Inter;
font-size: 24px;
font-weight: 700;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 24px;

}

.starlinkGrid .starlink-title-typo{
font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 10px;

}

.stepperStarlink .MuiStepLabel-label{
    color:  #ffffff;
    font-family: Inter;
    font-size: 16px;
}

.stepperStarlink .MuiStepLabel-label.Mui-active{
    color: #ffffff !important;
    font-weight: 700;
    font-family: Inter;
    font-size: 16px;
}

.stepperStarlink .MuiStepLabel-label.Mui-completed{
    color: #ffffff !important;
    font-weight: 700;
    font-family: Inter;
    font-size: 16px;
}

.starlinkStepperGrid{
    /* display: 'flex', justifyContent: 'center'  */
    display: flex;
    justify-content: center;
}

.MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color:green !important;
}

.starlinkGrid .starlink-title-desc{
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 16px;
}

.starlinkGrid .agreeBtnDiv{
    background-color: #244C84;
    border-radius: 4px;
    width: 150px;
    text-align: center;
    margin:20px 0px 10px auto;
}

.starlinkGrid .acceptBtn{
    margin-left: auto;
    background-color: #244C84;
    border-radius: 4px;
    width: 200px;
    text-align: center;
}

.starlinkGrid .acceptBtnfinal{
    margin-left: 10px;
    background-color: #244C84;
    border-radius: 4px;
    width: 200px;
    text-align: center;
}

.starlinkGrid .agreeBtn{
    text-transform: none;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.lastPage .btnNextGrid{
    display: flex;
    align-items: flex-end;
    margin: 10px 0px 10px auto;
}

.starlinkPadding{
    padding: 10px 20px;
    height: 35vw;
    overflow: scroll;
}

.starlinkPadding .welcomePageGrid{
    background-color: #FAFAFA;
     padding: 24px;
     height: 310px;
      overflow: scroll
}

.lastPage .welcomePageGrid{
    background-color: #FAFAFA;
     padding: 24px;
     height: 310px;
      overflow: scroll
}

.starlinkPadding .agreementPageGrid{
    background-color: #FAFAFA;
     padding: 24px;
     height: 310px;
      overflow: scroll
}

.starlinkGridMain .starlinkBackgroundImage{
    height: 225px;
    background: linear-gradient(91deg, rgb(15, 41, 78) 2.84%, rgb(10 39 110) 51.78%, rgba(14, 45, 90, 0.94) 92%);
}

.starlinkGridMain .starImageGrid{
    /* display: "flex", alignItems: "center", justifyContent: "center"  */
    display: flex;
    align-items: center;
    justify-content: center;
}

.starlinkGridMain .starImageBackground{
/*  height: "60px", width: "250px", filter: "invert(100%)" */
    height: 60px;
    width: 250px;
    -webkit-filter:invert(100%);
            filter:invert(100%)
}

.welcomePageGrid .StarlinkMainLayoutGrid{
    display: flex;
    justify-content: space-between;
}

.agreementBtn{
        display: flex;
        /* align-items: flex-end; */
        margin: 10px 0px 10px auto;
    }

.agreementBtn .agreementInnerDiv{
    /* flex: 1, display: "flex", alignItems: "baseline" */
    display: flex;
    align-items: baseline;
    flex: 1 1;
}

.starlinkTextAlign{
    text-align: right;
}

.starlinkPageLayout{
    /* style={{ position: "absolute", top: "250px", width: "100%" }} */
    position: absolute;
    top: 250px;
    width: 100%;
}

.starlink-title-desc .starlinkInfoTable{
    /* style={{ width: '50%', border: '1px solid #000' }} */
    width: 50%;
    border: 1px solid #000;
}

.starlinkTableBorder{
    border: 1px solid #000;
}

.starlinkTextAlign .starlinkTextField{
    /* width: "200px", marginRight: "10px" */
    width: 200px;
    margin-right: 10px;
}

.starlinkCircularDiv .starlinkCircularProgress{
    width:28px;
    height: 28px;
    color:#264C86;
    margin: auto;
}

.starlinkPadding .starlinkCircularDiv{
    display: flex;
    height: 100px;
}
.starlink--base--mtb_07x .starlinkInsightsMaxwidth {
    max-width: 20%;
}

.downloadHeight {
    height: 38px;
}

.alignStarlinkInsightsTopbar {
    align-items: center;
}
#justify-content-start {
    justify-content: start;
}

#justify-content-center, #justify-content-center>div {
    justify-content: center;
}

.Table-Row-Opacity .pooled-table-padding-left-15px {
    padding-right: 15px;
    padding-top: 10px;
}

.starlink-pooled-helpIcon {
    cursor: pointer;
    color: #2860b4;
    margin-right: -15px;
}

.new-summary-left .cursor-pointer {
    cursor: pointer;
}

.new-summary-left .no-cursor-pointer {
    cursor: auto;
}

.pooled-chart-legends .lineChart-legends, .pooled-chart-legends .disable-lineChart-legends {
    padding-top: 0px;
}
.starlinkpagination-root .starlinkpagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding: 0px;
    font-size: 14px;
    font-family: 'Inter';
    margin-top: auto;
}

.starlinkpagination-toolbar .records-select {
    font-size: 14px;
    font-family: 'Inter';
    padding: 0px 8px 0px 7px;
    margin-right: 0px;
    color: #6E7079;
}

.starlinkpagination-toolbar .page-dropdown {
    background: #f5f5f5;
    max-width: 80px;
    height: 23px;
    color: #6E7079;
    padding: 0px;
    border-radius: 8px;
}

.starlinkpagination-toolbar .row-dropdown {
    background: #f5f5f5;
    width: 72px;
    height: 23px;
    color: #6E7079;
    padding: 0px;
    border-radius: 8px;
    cursor: pointer;
}


.starlinkpagination-toolbar .MuiBox-root .page-arrow {
    background-color: #F5F5F5;
    border-radius: 8px;
    width: 19px;
    height: 24px;
    margin-right: 5px;
    color: #6E7079;
}

.starlinkpagination-toolbar .starlinkpagination-actions {
    flex-shrink: 0;
    margin-left: 20;
}

.starlinkpagination-spacer {
    flex: 1 1 100%;
}

.starlinkpagination-displayPages {
    flex-shrink: 0;
    color: #6E7079;
}

.starlinkpagination-selectLabel {
    flex-shrink: 0;
    color: #8B8D97;
}

.strMaps-custom-tooltip {
    background-color: black !important;
    color: white !important;
    font-size: 12px; 
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap; 
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  }
  
  .strMaps-custom-tooltip::before {
    content: none;
    position: absolute;
    bottom: -6px; 
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }


.strMaps-custom-select .MuiSelect-icon {
    color: white;
}

.TableCellPopUp .strMaps-custom-select{
    color: white;
    height: 25px;
    width: 100%;
}

.starlink-map-container {
    position: relative;
    display: flex;
    height: 100%;
  }
  
  .starlink-map-container .map-container {
    flex-basis: 70%;
    height: 100%;
    margin-top: 32px;
  }
  
  /* .overlay-container {

    position: absolute;
    top: 2%;
    left: 83%;
    transform: translateX(-50%);
    width: 25%; 
    max-width: 500px;
    z-index: 1000;
    opacity: 1;
    background-color: black;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    overflow: auto;
    pointer-events: auto; 
  } */

  .overlay-container {
    pointer-events: auto; 
    z-index: 1000;
    position: fixed;
    top: 225px;
    right: 65px;
    opacity: 1;
    background: black;
    width: 18%; 
    box-shadow: 0px 0px 12.3px 0px #97979782;
    border-radius: 8px;
    padding: 15px;
    max-height: calc(100vh - 285px);
    overflow-y: auto;
}


.overlay-container-2 {
  pointer-events: auto; 
    z-index: 1000;
    position: fixed;
    bottom: 20px;
    opacity: 1;
    background: black;
    box-shadow: 0px 0px 12.3px 0px #97979782;
    border-radius: 8px;
    padding: 15px;
    max-height: calc(100vh - 285px);
    overflow-y: auto;
}


  .starlink--button_contained--primary .strMap-SettingsIcon{
    color: white;
    padding: 0;
  }

  .strMap-mapStyle .hoveredSlDetails{
    font-size: 14px;
    color: white;
  }
  
  .overlay-container .str-mt-0{
    margin-top: 10px;
  }

  .usage-container {
    font-family: Arial, sans-serif;
    color: white;
    border-radius: 4px;
    max-width: 350px;
  }
  
  .usage-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px; 
  }
  
  .usage-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px; 
  }
  
  .usage-label {
    font-size: 14px;
  }
  
  .usage-amount {
    font-size: 14px;
    /* font-weight: bold; */
  }
  

  .starlink--button_contained--primary {
    background-color: #1e90ff;
    color: #fff;
  }
  
  .str-justify {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .selected-container {
    border-color: #1e90ff;
  }

  .terminal-container {
    font-family: Arial, sans-serif;
    color: white;
    padding-top: 5px;
    border-radius: 4px;
    max-width: 340px;
  }
  
  .terminal-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: normal; /* Consistent with usage */
    margin-bottom: 8px;
  }
  
  .terminal-dropdown {
    width: 100%; /* Full width dropdown */
    background-color: #333; /* Dark background for dropdown */
    color: white;
    font-size: 14px;
    font-weight: normal; /* Consistent with usage */
    padding: 6px 8px;
    border: 1px solid #555;
    border-radius: 4px;
    outline: none;
  }
  
  .terminal-dropdown option {
    background-color: #444; /* Darker background for dropdown items */
    color: white;
    font-size: 14px;
  }
  
  .terminal-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 1px 0;
  }
  
  .terminal-label {
    font-size: 14px;
    font-weight: normal;
  }
  
  .terminal-value {
    font-size: 14px;
    font-weight: normal;
    color: #fff; /* Keeps values prominent */
  }
  
  
  .cancel-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
    color: white;
  }
  
   .overlay-container .titleTypo {
    /* margin-bottom: 15px; */
    color: white;
    font-size: 14px;
  }
  
  .starlink-map-container .overlay-container .details-table {
    border-collapse: collapse;
    width: 100%;
  }
  
   .overlay-container .contentTypo {
    color: white;
    font-size: 14px;
    padding: 5px;
    border-bottom: none;
    width: 100px;
    /* font-weight: bold; */
  }
  
  .starlink-map-container .overlay-container .details-table td.bold {
    font-weight: bold;
  }

  .starlinkMap-Main{
    width:450px
  }

  .TableRowBorder .TableCellPopUp{
    /* style={{ color: "white", fontSize: "12px", borderBottom: "none", padding: "5px" }} */
    color: white;
    font-size: 14px;
    border-bottom: none;
    padding: 5px;
  }

  .TableRowBorder {
    border-bottom: none;
  }

  .str-mr-5{
    margin-right: 5px;
    width: 23px
  }

  .mapCustButtonsGroup  button{
    padding: 0px;
    border: none;
    background: white;
    min-width: 30px !important;
    margin-right: 2px;
  }

  .mapCustButtonsGroup #layersSel {
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 6px;
  }

  .layersIcon {
    color: black !important;
  }

  .layersIcon .vsatIc {
    margin-top: 10px !important;
  }

  .starMap-rows{
    width: 115px;
  }

  .starMap-rows-sl{
    width: 165px;
  }

  .starMap-rows-status{
    width: 85px;
  }

  .selectedRow.highlight {
    background-color: #e0f7fa;
  }

  .starlinkMapContainer .containerStyleMapOpen{
    display: flex;
    flex-direction: row;
    width: 100%;
    grid-gap: 16px;
    gap: 16px;
    height: 60vh;
    padding-top: 10px
  }

  .strMaps-filter{
    margin-top: 40px;
  }

  .starlinkMapContainer .containerStyleMapClosed{
    display: flex;
    flex-direction: row;
    width: 100%;
    grid-gap: 16px;
    gap: 16px;
    height: 75vh;
    padding-top: 10px
  }

  .starlinkMap-filterBtn{
    display: flex;
    padding-top: 10px;
  }

  .starlinkMap-selFilter{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-left: 10px;
    flex-grow: 1;
    margin: 0px 20px;
    overflow: auto;
  }

  .starlinkMap-chips{
    background-color: #e0f7fa;
    font-weight: 500;
    font-size: 14px;
  }

  .starlinkMap-filterPopup{
    font-size: 12px;
    font-weight: 400;
    line-height: 14.5px;
    height: 16px;
  }

  .starlinkMap-OpenCard{
    margin-left: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .starlinkMap-icon{
    color: #5e5e73;
  }

  .starlinkMap-serviceLine{
    cursor: pointer;
    color: blue;
  }

  .d-flex{
    display: flex;
  }

  .str-justify{
    justify-content: space-around;
  }

  .align-center{
    align-items: center;
  }
  
  .strMapIcon .str-font-15{
    font-size: 15px;
  }

  .str-font-15{
    font-size: 15px;
  }

  .strMap-table{
    flex-basis: 30%;
    height: 94%;
    overflow-y: auto;
  }

  .strMapIcon .str-alert{
    margin-top: -2px;
  }

  .strMap-sl-popup{
    border-collapse: collapse;
    width: 100%;
  }

  .strMap-sl-dropdown{
    color: white;
    height: 25px;
    width: 100%;
    background: #7d7d82;
  }

  .strMap-mapStyle{
        flex-basis: 75%;
        height: 100%;
  }

  .config-deploy-starlink-popup {
    width: 45%;
    margin: auto;
    background: #FFFFFF;
    border-radius: 10px;
    background-color: #0000;
}

.config-deploy-starlink-popup .create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    height: 10px;
    color: #45464E;
    background-color: #f5f5f5 ;
}

  
.config-deploy-starlink-popup .edit-actions-title {
  border-bottom: 1px solid #efefef;
  background: white;
  padding: 18px 10px 18px 15px;
}

.config-deploy-starlink-popup .edit-actions-content {
  padding: 0px;
}

.inventory-edit-actions-container .MuiDialog-container {
  width: 900px;
  margin-left: -100px;
}

.config-deploy-starlink-popup .edit-change-group-btn {
  position: absolute;
  bottom: 20px;
  right: 25px;
  border: 1px solid #264c86;
  background: #264c86;
  color: #fff;
  height: 35px;
  border-radius: 4px;
  width: 125px;
}

.config-deploy-starlink-popup .edit-change-group-btn:hover {
  background: #264c86
}

.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .status{
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .status .text {
  max-width: 100px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .status .text,
.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .noData {
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  background: #FFFFFF;
  text-transform: none;
}

.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .noData {
  text-align: center;
}
.NewSideMenu{

}
 .MuiDrawer-paper {
    margin-top: 49px;
}
.MuiButtonBase-root .MuiListItemIcon-root{
    margin-right: inherit ;
}
.SideNavImg{
    height: 24px;
    width: 24px;
    margin-right: 10px;
}
.sideNavText .MuiListItemText-primary{
    font-family: Inter;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.SideNavDrawer .drawerOpenIcon{
    height: 23px;
    width: 23px;
    position: fixed;
    top:9%;
    left:80px;
    z-index: 1300;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    background: #ffffff;
    box-shadow: 0px 2px 10px #b6b3b3;
    border-radius: 50%;
}

.iconDivNewSideNav {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    width: 100%;
    align-items: center;
    overflow-y: auto;
    height: calc(100vh - 150px);
}

.drawerTopBorder{
    border-top: 1px solid #EBEBEB;
}

.iconDivNewSideNav .listItemSideMenu{
    padding: 0px;
    width: 100%;
}

.navListItems {
    padding: 0px;
    height: 44px;
    justify-content: center;
}

.navIconMargin {
    margin-left: 20px;
    margin-right: 12px;
}

.iconColor-select {
    -webkit-filter: invert(7%) sepia(96%) saturate(7341%) hue-rotate(209deg) brightness(53%) contrast(135%);
            filter: invert(7%) sepia(96%) saturate(7341%) hue-rotate(209deg) brightness(53%) contrast(135%);
    font-size: "14 px  Semibold";
}

.iconColor-unselect {
    -webkit-filter: invert(46%) sepia(7%) saturate(448%) hue-rotate(192deg) brightness(92%) contrast(87%);
            filter: invert(46%) sepia(7%) saturate(448%) hue-rotate(192deg) brightness(92%) contrast(87%);
    font-size: "14 px  Medium";
}

.SideNavDrawer .sidenavPaddingTop {
    padding-top: 16px;
}

.SideNavDrawer .knowledgeCenterPadding {
    padding: 0px;
}
.Hub-new-menu-icon {
    height: 24px;
    width: 24px;
    margin-right: 12px;
}

/* padding:"0px 16px", width:"100%", */
.hub_details_container {
    margin: 0px 15px 15px 15px;
    overflow-x: hidden;
}

.hub-main-table-container .audit-container-data {
    box-shadow: none;
}

.audit-container-data .hub-table-container {
    border: 1px solid rgba(224, 224, 224, 1);
    box-shadow: none;
}

.each-row-background .padding-left-right, .hub-expanded-row .padding-left-right {
    border-left: 1px solid rgba(235, 235, 235, 1);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: rgba(69, 70, 78, 1);
    padding: 3px 5px 3px 6px;
}

#hub-expanded-row-tr .padding-left-right {
    border-top: 1px solid rgba(235, 235, 235, 1);
}

.hub-expanded-row #add-padding-top-cell, #hub-add-padding-top {
    border-top: 1px solid rgba(224, 224, 224, 1);
}

.each-row-background .no-padding {
    padding: 0px;
    text-align: center;
}

.each-row-background .remove-border-btm {
    border-bottom: none;
    padding: 0px;
    text-align: center;
}

.hub-expanded-row .no-padding-top-bottom {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;
    border-top: none;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: rgba(69, 70, 78, 1);
    padding-top: 0;
    padding-bottom: 0;
}

.audit-data-head .background-header-cell {
    background: rgba(245, 245, 245, 1);
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #EBEBEB;
    color: rgba(139, 141, 151, 1);
    font-family: 'Roboto Mono';
    font-size: 12px;
    font-weight: 500;
    line-height: 15.83px;
    letter-spacing: 1px;
    text-align: left;
    height: 25px;
    padding: 5px 8px 5px 7px;
}

.each-row-background {
    background: rgba(249, 249, 249, 1);
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: rgba(235, 235, 235, 1);
}

.each-row-background .internet-status-color-hub, .each-row-content-status .internet-status-color-hub {
    width: 7px;
}

.each-row-background .hub-row-internetStatus, .each-row-content-status .hub-row-internetStatus {
    display: flex;
    align-items: center;
    grid-gap: 1px;
    gap: 1px;
    cursor: pointer;
    margin-left: 4px;
}

.padding-left-right .MuiCollapse-wrapperInner, .no-padding-top-bottom .MuiCollapse-wrapperInner, .each-row-background .padding-left-right {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alertsFilterContainerBox .hub-search-root {
    margin-left: 0px;
    margin-bottom: 16px;
}

.hub-redirectToOtherTabs {
    text-decoration: none;
    color: #3e21ee;
    cursor: pointer;
}

.audit-data-head .hub-width-internet-status {
    width: 6%;
}

.audit-data-head .hub-width-clients {
    width: 4%;
}

.each-row-background .remove-border-bottom-hub-cell, .hub-expanded-row #expand-icon-border, .audit-logs-font .clients-no-data {
    border-bottom: none;
}

.hub-expanded-row .remove-cursor-locationClick, .each-row-background .remove-cursor-locationClick {
    cursor: auto;
    text-decoration: none;
}

.interface-profile-popup .client-data-content {
    padding: 15px 0px 0px 20px;
}

.login-tableRow .clientData-speedHeader {
    text-transform: none;
    -webkit-font-feature-settings: "kern" off;
            font-feature-settings: "kern" off;
    font-variant: none;
}

.login-tableRow .last-observed-width {
    width: 15%;
}

.login-left-align .client-data-wan-status {
    align-items: center;
    display: flex;
    grid-gap: 3px;
    gap: 3px;
}

.audit-logs-font .hub-empty-table-row {
    height: 16px;
}

.audit-logs-font .hub-empty-table-cell {
    border: none;
}

.pop-head .hub-clients-header {
    color: black;
    font-size: 14px;
}

.hub-clients-header .hub-clients-header-grid {
    margin-left: auto;
}

.hub-clients-header-grid img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: black;
    border: none;
}

.hub-table-header .sort-icon-margin {
    margin-left: 3px;
}

.hub-table-header .arrow-column {
    width: 1%;
}
.hub-table-header .hub-width-portal {
    width: 3.5%;
}
.hub-table-header .hub-width-tunnel-status {
    width: 4%;
}
.hub-table-header .hub-width-throughput {
    width: 7%;
}
.hub-table-header .hub-width-mpk-status {
    width: 5%;
}
.hub-table-header .hub-width-usage {
    width: 6%
}
.hub-table-header .hub-width-users {
    width: 3%;
}

.fleetDataTable .hub-sorting-columns {
    /* cursor: pointer; */
    text-transform: none;
}

.hub-main-table-container .hub-table-search-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hub-table-search-switch .toggle-btn {
    font-size: 12px;
    font-style: normal;
    font-family: "Inter";
    font-weight: 500;
}

.hub-main-table-container .hub-container-data {
    overflow-x: hidden;
}

.audit-logs-font .hub-agent-view-table-row td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 5px 5px 5px;
    font-weight: 400;
}

.hub-container-data .hub-topology-service-table-headers th {
    padding: 5px 5px 4px 15px;
}

.hub-topology-parent-container {
    height: calc(100vh - 170px);
    overflow: auto;
}

.hub-topology-sdwan-dropdowns-div {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}

.hub-table-container .topology-agent-table-table-row th {
    padding: 5px 15px;
}

.audit-logs-font .hub-topology-agent-view-table-row td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 15px;
    font-weight: 400;
}
.threat-dashboard-NewSummary .padding-l-r {
    padding-left: 12px;
    padding-right: 7px;
  }

  .threat-dashboard-NewSummary.MuiGrid-root {
    /* margin-left: 88px; */
    width: 100%;
    overflow-y: hidden;
    max-height: calc(100vh - 79px);
  }

  .threat-dashboard-NewSummary {
    width: inherit;
  }

  .siteNameThreat {
    font-weight: 400;
    font-size: 12px;
    padding-right: 4px;
    font-family: Inter;
    line-height: 14.52px;
    color: #6E7079;
  }

  .cardHeaderTile_NewSummary .siteNameInThreatCard {
    margin-left: auto;
  }
  

  .usage-details-pie-perSite.wan-type-pie.threat-severity-pie {
    margin-left: 60px;
  }

  .elipseThreat {
    white-space: nowrap;
    max-width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .elipseAllThreats {
    white-space: nowrap;
    max-width: 104px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .elipseTop10Threats {
    white-space: nowrap;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .elipseThreatKontrol {
    white-space: nowrap;
    max-width: 112px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .elipseThreatKontrolclasses {
    white-space: nowrap;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .elipseThreatKontrolclass {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .threatKontrolIconsAlign {
    display:flex;
  }

  .tableBody .threatKontrolOperations {
    display: flex;
    align-items: center;
  }

  .tableBody .threatTextAlignCenter {
    text-align: center;
  }

  .threatchipGap {
    grid-gap: 10px;
    gap: 10px;
  }

  .new-summary-usage-tr .addThreatPadding {
    padding-left: 50px;
  }

  .widthReduce {
    width: 5% !important;
  }

  .ThreatChips {
    margin-left: 20px;
    margin-top: 10px;
  }

  .topThreatsFilter {
    display: flex;
    margin-left: 21px;
  }

  .new-summary-Threat-container {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }

#threatsKontrolButton {
  height: 32px;
  padding: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  color: #FFFFFF;
  border: 1px solid #244C84;
  border-radius: 4px;
  text-transform: none;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #244C84;
}

.threatKontrolDialogbox .MuiPaper-root {
  width: calc(115% - 194px);
  max-height: calc(90% - 70px);
  top: 60px;
  position:fixed;
  max-width: 1059px;
}

.threatKontrolDeletebox .MuiPaper-root {
  width: 400px;
  height: 200px;
}

.displayFlex {
  display: flex;
  align-items: center;
}

.displayFlex .suppressCheckPaddingReset {
  padding: 0px;
}
.includeSupressionCheckbox .MuiCheckbox-root.Mui-checked {
  color: #244C84;
}
.threatDeleteButtonDiv {
  display: flex;
  grid-gap: 27px;
  gap: 27px;
  align-items: center;
}

#threatKontrolProceedButton {
  width: 165px;
  height: 32px;
  padding: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  color: #FFFFFF;
  border: 1px solid #244C84;
  border-radius: 4px;
  text-transform: none;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #244C84;
}

#threatKontrolBackButton {
  width: 165px;
  height: 32px;
  padding: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  color: #244C84;
  border: 1px solid #244C84;
  border-radius: 4px;
  text-transform: none;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #FFFFFF;
}

.threatKontrolMargin {
  margin: 21.5px;
}

.threatKontrolNameMargin {
  margin: 21.5px 21.5px 0px 21.5px;
}

.threatKontrolHeading {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  color: #45464E;
}

.threatKontrolClose {
  margin-left: auto;
  cursor: pointer;
  color: #45464E;
}

.threatKontrolCloseIcon {
  height: 14px;
  width: 14px;
}

.threatKontrolDivider {
  border: 1px solid #EBEBEB;
}

.enableSupression {
  height: 26px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: #45464E;
}

.threatKontrolMargin .threatKontrolSubheading {
  margin-bottom: 12px;
  height: 26px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #45464E;
}

.threadKontrolhr {
  border: 1px solid #EBEBEB;
  width: 94%;
}

.threatEmailSelect .MuiInputLabel-root {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #45464E;
}

#threatKontrolAddAction {
  width: 117px;
  height: 32px;
  color: #2F4B82;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  text-transform: none;
  padding: 0px;
  align-items: center;
  background-color: white;
  border: 1px solid #244C84;
  border-radius: 4px;
}

#addActionIcon {
  color: #2F4B82;
  background-color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.threatKontrolMargin .MuiCheckbox-root {
  padding: 0px;
}

.threatKontrolMargin .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

#threatKontrolSaveButton {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #FFFFFF;
  background: #1B51A7;
  width: 64px;
  height: 29px;
  text-transform: none;
  padding: 0px;
}

#threatKontrolgreySaveButton {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  background: #ebebeb;
  color: #1B51A7;
  width: 64px;
  height: 29px;
  text-transform: none;
  padding: 0px;
}

#threatKontrolCancelButton {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #FFFFFF;
  background: #1B51A7;
  width: 64px;
  height: 29px;
  margin-left: auto;
  text-transform: none;
  align-items: center;
  margin-right: 24px;
}

.threatKontrolNameSelector .MuiInputBase-root {
  width: 500px;
  height: 40px;
}
.threatNameSelector .MuiInputBase-root {
  width: 210px;
  height: 40px;
}

.severitySelector .MuiInputBase-root {
  width: 136px;
  height: 40px;
}

.classSelector .MuiInputBase-root {
  width: 190px;
  height: 40px;
}

.measSelector .MuiInputBase-root {
  width: 139px;
  height: 40px;
}

.countSelector .MuiInputBase-root {
  width: 92px;
  height: 40px;
}

.actionSelector .MuiInputBase-root {
  width: 135px;
  height: 40px;
}

.editthreatKontrolNameSelector .MuiInputBase-root {
  width: 100px;
  height: 40px;
}
.editthreatNameSelector .MuiInputBase-root {
  width: 221px;
  height: 40px;
}

.editseveritySelector .MuiInputBase-root {
  width: 130px;
  height: 40px;
}

.editclassSelector .MuiInputBase-root {
  width: 200px;
  height: 40px;
}

.editmeasSelector .MuiInputBase-root {
  width: 136px;
  height: 40px;
}

.editcountSelector .MuiInputBase-root {
  width: 103.5px;
  height: 40px;
}

.editactionSelector .MuiInputBase-root {
  width: 153px;
  height: 40px;
}

.threatSourceInfo {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  grid-gap: 16px;
  gap: 16px;
}
.threatCardsOpenIconMargin {
  margin-right: 30px;
}

.threatkontrolEmailGap {
  height: 12px;
}

.threatkontrolRuleCheckbox {
  height: 18px;
  display: flex;
  align-items: center;
}
.threatAction .threatActionremoveIcon {
  width: 20px;
  height: 20px;
  color: #A6A6A6;
  cursor: pointer;
}
.threatkontrolSelectorGap {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.threatAction {
  display: flex;
  align-items: center;
  grid-gap: 14.5px;
  gap: 14.5px;
}

.threatAction .MuiInputLabel-animated {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #45464E;
}
#severity {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #45464E;
}

.threatKontrolMargin .threatKontrolCheckbocName {
  display: inline;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.threatKontrolMargin .MuiCheckbox-root.Mui-checked {
  color: #244C84;
}

.threatKontrolContainer {
  display: flex;
}
.threatKontrolContainer .marg-left-auto {
  margin-left: auto;
}
.usage-per-site-pie .title-text {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #45464E;
    margin: 5px 0px 20px 15px;
    padding-top: 10px;
}

.bucket-text{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 700;
        color: #45464E;
        margin-right: 20px;
        /* margin: 5px 0px 20px 15px;
        padding-top: 10px; */
    
}

.new-summary-chart-container .new-summary-chart-component .padding-left-22 {
    padding-left: 20px;
}

.bucket-padding {
    min-width: 40px;
    margin-left: 35px;
}

.usage-per-site-pie {
    border-radius: 4px;
    box-shadow: 0px 0px 7px 0px rgba(167, 158, 158, 0.25);
    padding: 5px;
    height: 100%;
}

.usage-per-site-pie .usage-details-pie-perSite {
    display: flex;
    justify-content: center;
    margin: 15px auto 10px 5px;
}

.usage-details-pie-perSite .summary-usage-value {
    color: #040404;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.usage-details-pie-perSite .summary-usage-unit {
    color: #6E7079;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.usage-details-pie-perSite>div {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    margin-bottom: 7px;
}

.usage-details-pie-perSite .summary-pie-usage-dot, .summary-pie-bar-legends .summary-pie-usage-dot {
    width: 9px;
    height: 9px;
    border-radius: 20px;
}

.usage-per-site-pie .wan-type-pie {
    margin-left: 30px;
    justify-content: flex-start;
}

.usage-per-site-pie .legends-pie-new-container {
    display: flex;
    justify-content: flex-end;
    grid-gap: 15px;
    gap: 15px;
    margin: 15px 15px 25px 0px;
    align-items: center;
}

.usage-per-site-pie .removeMarginTop {
    margin-top: 10px;
}

.legends-pie-new-container .summary-pie-bar-legends {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    color: #040404;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.summary-pie-bar-legends .barChart-legends {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    color: #0e0548;
    cursor: pointer;
    font-family: 'Inter';
}

.summary-pie-bar-legends .disable-barChart-legends {
    color: #a2a2a6;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
    font-family: 'Inter';
}

.usage-per-site-pie .title-chip-pie {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.legends-pie-new-container .line-bar-view {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    align-items: center;
}

.legends-pie-new-container .time-series-legends-new {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

.usage-per-site-pie .new-summary-control-limit {
    display: flex;
    align-items: center;
}

.bucket-outer{
    margin-top: 20px;
    display: flex;
    align-items: center; 
}

.usage-per-site-pie .chart-legends-histogram {
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 15px;
}

.chart-legends-histogram .legends-view-images {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

.usage-details-pie-perSite .selected-filter {
    cursor: pointer;
    color: #4c3aff;
}

.usage-details-pie-perSite .sites-list {
    margin-bottom: -3px;
}

.usage-per-site-pie .title-toggle-btn-div {
    display: flex;
    justify-content: space-between;;
    align-items: center;
}

.new-summary-control-limit .title-dropdown-pie-chart {
    display: flex;
    align-items: center;
}

.title-toggle-btn-div .toggle-wan , .title-toggle-btn-div .toggle-site {
    font-size: 12px;
    font-style: normal;
    font-family: 'Inter';
    font-weight: 500;
}

.usage-details-pie-perSite .single-value {
    display: flex;
    justify-content: center;
}

.pie-chart-summary .remove-margin-left {
    margin-left: 0px;
    justify-content: center;
}

.usage-per-site-pie .time-series-legends-new-sites {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 15px;
}

.time-series-legends-new-sites .summary-pie-bar-legends-sites {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.legends-pie-new-container .legends-view-images-sites  {
    display: flex;
    align-items: center;
}

.site-line-chart-title-div .site-line-chart-title-text {
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 0px;
}

.summary-pie-bar-legends-sites #site-legend-filter {
    color: #4c3aff;
}

.histogram-dialog{
    min-height: 250px;
}

.histogramDialogTitle{
        font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #45464E;
      border-bottom: 1px solid #EBEBEB;
      padding: 20px 24px 0px 24px;
      
}

.title-toggle-btn-div #justify-center-sites {
    justify-content: flex-start;
}

.firstchartBtn {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.rule-detaills-table .MuiDialogContent-root {
    overflow-x: hidden;
}

.rule-detaills-table .create-default-config-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px;
}
.usage-details-pie-perSite .captive-pie-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 87%;
}

.charts-container-captive-dashboard .justify-content-tabs {
   justify-content: space-between;
   margin: 5px 15px 15px 0px;
}

.charts-container-captive-dashboard .usage-selectedTabs .usageActiveAppClass, .charts-container-captive-dashboard .usage-selectedTabs .usageInactiveAppClass {
    width: auto;
    padding: 0;
    margin-left: 15px;
}

.charts-container-captive-dashboard .usage-selectedTabs .usageInactiveAppClass {
    border-bottom: 2px solid #fff;
}

.usage-selectedTabs #width-45px {
    min-width: 45px;
}

#captive-addBoxShadow:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

#captive-removeBoxShadow:hover {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.captive-expired-text {
    color: #814b4d;
    padding: 3px 7px;
    background: #ffe5e5;
    border-radius: 25px;
    margin-left: 2px;
}

.login-tableRow .width-loggen-in {
    width: 5%;
}

.captive-line-chart-title {
    color: rgba(69, 70, 78, 1);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
    margin-right: 15px;
}

.captive-sites-bar-chart .highcharts-root {
    margin-top: 0px;
}

.chart-view-icons-enable {
    cursor: pointer;
}

.captive-bar-chart-pagination .MuiButtonBase-root {
    border-radius: 8px;
    width: 19px;
    height: 24px;
    margin-left: 5px;
    color: #6e7079;
}

.captive-bar-chart-pagination .MuiBox-root {
    margin-left: 0;
}

.captive-bar-chart-pagination .MuiButtonBase-root:hover {
    background-color: none;
}

.captive-plans-line-icon {
    color: rgba(228, 228, 228, 1);
    margin: 0px 10px;
}

.charts-container-captive-dashboard .usage-trend {
    margin-left: 0;
}

.charts-container-captive-dashboard .site-line-chart-title-text {
    margin-top: 15px;
    margin-bottom: 25px;
}

.captive-dashboard-NewSummary .captive-dashboard-filters-container {
    margin: 10px 10px 0px 20px;
}

.captive-filters-each-field {
    padding: 15px;
    border-bottom: 1px solid #EBEBEB;
}

.captive-filters-each-field .rc--filter_autoComplete-input {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 12px;
}

.unit-field-CD .MuiInputBase-root {
    width: 85px;
}

.login-tableRow .width-usage-sub-th {
    width: 8%;
    text-align: left;
}

.login-tableRow .width-topups-sub-th {
    width: 6%;
    text-align: left;
}

.login-tableRow .width-sessions-last-activity {
    width: 16%;
}

.captive-sessions-popup-container .sessions-table-popup, .sessions-table-popup .MuiDialog-paperWidthMd  {
    position: fixed;
    top: 50px;
    height: auto;
    overflow: hidden;
}

.config-container .captiveSessions-tableContainer {
    max-height: calc(100vh - 340px);
    overflow: auto;
    margin-bottom: 0px;
}

.siteCharts__filters-date-time-picker .date-range-captive-filters {
    width: 240px;
    margin-top: 10px;
}

.captive-dashboard-filters-container .captive-reports-filters-popup-conatiner .MuiPaper-root {
    position: fixed;
    top: 103px;
}

.login-tableRow .events-width-0 {
    width: 0%;
}

.Table-Cell-Opacity .device-type-padding-left-0 {
    padding-left: 0px;
}

.device-type-padding-left-0+.MuiButtonBase-root {
    padding-top: 0px;
    padding-bottom: 0px;
}

.Table-Row-Opacity .device-type-metadata {
    display: flex;
    align-items: center;
}

.font-size-10px {
    font-size: 10px;
}

.dash-filter-pop .clearApplyFilters .usage-applyFilterBtn-disable {
    color: #264c86;
    background-color: #e8f0fd;
    border: 1px solid #676984;
    cursor: auto;
    height: 30px;
    width: 100px;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    text-transform: none;
    margin-left: auto;
}

.card-widget .font-size-12px {
    font-size: 12px;
}

.login-tableRow .text-align-left {
    text-align: left;
}

.Table-Row-Opacity .whiteSpace-nowrap {
    white-space: nowrap;
}

.login-tableRow .os-width-7per {
    width: 7%;
}

.captive-dashboard-chips-container {
    width: calc(100vw - 340px);
    overflow-x: auto;
    padding-bottom: 5px;
}

.captive-dashboard-NewSummary #captive-dashboard-contents {
    height: calc(100vh - 205px);
    overflow-y: auto;
}


.MuiGrid-root.configuration-remove-icon{
    display: flex;
    flex-direction: column;
}
.close-btn-ht-wd {
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.rule-name-text-field .MuiInputBase-root .MuiInputBase-input{
    height: 10px;
}

.rule_name_error_text {
    position: relative;
    top: 25px;
}
.geoConfig_management--base--container {
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #2F4B82;
    border-radius: 0.5rem;
}

.geoConfig_management--base--margin_1x {
    margin: 1rem;
}

.geoConfig_management--base--margin_05x {
    margin: 0.5rem;
}

.geoConfig_management--base--mt_1x {
    margin-top: 1rem;
}

.geoConfig_management--base--mb_1x {
    margin-bottom: 1rem;
}

.geoConfig_management--base--ml_1x {
    margin-left: 1rem;
}

.geoConfig_management--base--padding_1x {
    padding-right: 1rem;
    padding-left: 1rem;
}

.geoConfig_management--base--padding_05x {
    padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.geoConfig_management--base--margin_1x .geoConfig_management--base--pb_1x {
    padding-bottom: 1rem;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
}

.geoConfig_management--base--font_07x { 
    font-size: 0.7rem;
    font-weight: 600;
}

.geoConfig_management--base--font_1x { 
    background: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    padding: 15px;
    color: #45464E;
    font-family: 'Inter';
    display: flex;
}

.margin-left-auto {
    margin-left: auto;
}

.geoConfig_management--base--font_2x {
    font-size: 1.5rem;
    font-weight: 500;
}

.geoConfig_management--base--flex {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.geoConfig_management--base--flex--column {
    display: flex;
    flex-direction: column;
}

.geoConfig_management--base--flex--row {
    display: flex;
    flex-direction: row;
}

.geoConfig_management--base--flex--justify--start {
    display: flex;
    justify-content: flex-start;
}

.geoConfig_management--base--flex--justify--end {
    display: flex;
    justify-content: flex-end;
}

.geoConfig_management--base--flex--justify--center {
    display: flex;
    justify-content: center;
}

.geoConfig_management--base--flex--justify--space_between {
    display: flex;
    justify-content: space-between;
}

.geoConfig_management--base--flex--align--start {
    display: flex;
    align-items: flex-start;
}

.geoConfig_management--base--flex--align--center {
    display: flex;
    align-items: center;
}

.tooltip-invalid-interfaces img {
    cursor: pointer;
}

.geoConfig_management--base--cursor--pointer {
    cursor: pointer;
}

.MuiButtonBase-root.geoConfig_management--button--cancel {
    border: 1px solid #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.geoConfig_management--button--cancel:hover {
    border: 1px solid #EBEBEB;
}

.MuiButtonBase-root.geoConfig_management--button--cancel:disabled {
    background-color: #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.geoConfig_management--button--save {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.geoConfig_management--button--save:hover {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.geoConfig_management--button--save:disabled {
    background-color: #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.geoConfig_management--button_outlined--primary {
    border-color: #264C86;
    color: #264C86;
}

.helpIcon-geoConfig {
    color: #264C86;
}

.MuiButtonBase-root.geoConfig_management--button_outlined--primary:hover {
    border-color: #264C86;
}

.MuiPaper-root.geoConfig_management--container {
    background-color: #f9f9f9;
    /* position: absolute;
    left: 0px;
    top: 50px;
    right: 0;
    bottom: 0; */
    padding:109px 20px 15px 20px;
    overflow: auto;
    height: calc(100vh - 140px);
}

.geoConfig_management--loader--container {
    position: absolute;
}

/* Pagination Unset */

.geoConfig_management--container .pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    font-family: unset;
    margin-top: auto;
}

.geoConfig_management--container .pagination-toolbar .records-select {
    font-size: 14px;
    font-family: unset;
    border-radius: 8px;
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
}

.geoConfig_management--container .MuiMenuItem-root.records-select-item {
    font-size: 10px;
    font-family: unset;
}

.MuiMenuItem-root.records-select-item {
    font-size: 0.9rem;
    font-family: unset;
}

/* tooltip */

.MuiTooltip-tooltip.geoConfig_management--tooltip {
    background-color: black;
    color: white;
    padding: unset;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
}

.MuiTooltip-tooltip.geoConfig_management--tooltip .MuiTableCell-root {
    color: white;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.MuiTooltip-tooltip.geoConfig_management--tooltip .geoConfig_management--tooltip--value_title {
    opacity: 0.5;
}

/* comp level */

.geoConfig-kontrol-note{
    position: absolute;
    top: 75px;
    font-size: 12px;
}

.geoConfig-help-container {
    position: fixed;
    right: -20px;
}

.geoConfig_management--table_container .MuiTableCell-root {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-collapse: collapse;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 1px;
    color: #45464E;
}

.geoConfig_management--table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}

.geoConfig_management--base--margin_top_right-bottom_1x {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 0px;
}
.geo-config-logs-container {
    margin: 15px;
}

.geo-config-logs-container .loginHistory-tableContainer {
    max-height: calc(100vh - 290px);
    overflow: auto;
}
/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .FleetDashboard  */

/* .FleetDashboard .css-1q1u3t4-MuiTableRow-root{
    background-color: #264C86;
    color: #fff !important;
} */

.FleetDashboard.MuiTableContainer-root{
    color: #fff !important;
    margin-top: 10px;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid rgba(20, 99, 172, 0.24);
}

/* .FleetDashboard .css-1ygcj2i-MuiTableCell-root {
    color: #fff !important;
} */

/* .FleetDashboard .css-1ex1afd-MuiTableCell-root{
    color:#273050 !important;
} 

.FleetDashboard .css-1yhpg23-MuiTableCell-root{
    color:#273050 !important;
} */

/* .FleetDashboard .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #F5F9FF !important;
} */

.FleetDashboard .MuiTableCell-root{
    padding-left: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 10px !important;
}

.FleetDashboard .MuiTableHead-root{
    height: 42px;
}

.FleetDashboard .MuiTableRow-root{
    height: 35.69px !important;
    background-color: #264C86;
    color: #fff !important;
}
html, body, #root, .App {
  height: 100%;
  width: 100%;
  background-color: white;
}


body {
  margin: 0 !important;
  font-family:  Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif";
}

.header2 {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 25.1px !important;
  color: #676984 !important;
  text-align: left !important;
}

.paginationDiv{
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  /* padding: 14px 20px 0px 0px; */
}
.paginationDiv > div{
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #676984;
  align-items: center;
  display: flex;
  justify-content: center;
}

.prevPage{
  margin-left:10px;
  margin-right:15px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.prevPageDisabled{
  margin-left:10px;
  margin-right:15px;
}
.nextPageDisabled{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.MuiTableRow-head .font-wt-900 {
  font-weight: 900;
}

.searchbarBox .MuiTextField-root {
  width: 330px;
  max-width: 100%;
}

.topbarWidth .searchbarMaxWidth {
  max-width: 346px;
} 

.topbarWidth .searchbarMaxWidth1 {
  max-width: 330px;
}

.topbarWidth .filterButtonMaxWidth {
  max-width: 92px;
}

.topbarWidth .selectButtonMaxWidth {
  max-width: 200px;
}

.searchbarBox .searchbarInputs {
  padding: 8px;
  height: 32px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8B8D97;
}

.searchbarInputs .MuiOutlinedInput-input {
  padding: 0px;
}

.searchbarIconsPosition {
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.topbarPaddingReset .alignTopbarItemsCenter .MuiGrid-item {
  padding:0px 0px 0px 16px;
}

.topbarMarginReset .MuiGrid-container {
  margin-top: 0px;
  margin-bottom: 0px;
}

.topbarMarginReset .MuiGrid-container .topbarPaddingReset {
  padding: 0px;
}

#filterButton {
  height: 32px;
  width: 76px;
  padding: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #2F4B82;
  border: 1px solid #244C84;
  border-radius: 4px;
  text-transform: none;
  grid-gap: 8px;
  gap: 8px;
  background-color: white;
}

#filterIcon {
  height: 16px;
  width: 16px;
  margin: 0px;
}

#filterButton .MuiButton-startIcon {
  margin: 0px;
}

.sitesTableMargin {
  margin: 0px 10px 0px 10px;
}

.alignTopbarItemsCenter {
  display: flex;
  align-items: center;
  height: 64px;
}

.config-container .reports-login-history .topbarMarginPaddingReset {
  margin: 0px;
  padding: 0px;
  align-items: center;
}

.config-container .trends-usage-table-container .tablePaddingReset {
  padding-top: 0px;
}

.backup-config-container .inventoryCMContainer {
  width: 99%;
  padding: 5px 10px 0px 5px;
  background-color: #fff;
  margin: 0px 0px 0px 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.inventoryCMContainer .inventoryCMHeading {
  display: flex;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.topbarWidth .dwn-margin-auto {
  margin-left: auto;
  display: flex;
  justify-content: end;
}
