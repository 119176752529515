.starlink--reports--usage_chart--container .highcharts-container, .starlink--reports--usage_chart--container .highcharts-root {
    width: 100% !important;
}

.MuiTextField-root.input-box-starlink-rp {
    width: 300px;
}

.starlink--base--flex--row .font-20 {
    font-size: 20px;
}

.Usage-ServiceLines .sl-cardtitle {
    color: #2e1a70;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    padding-top: 5px;
    letter-spacing: .175em;
    padding-left: 5px;
}

.cardHeader-serviceLines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 5px;
}

.cardHeader-serviceLines>span:nth-child(2) {
    margin-right: 5px;
}

#selectedClick-column {
    cursor: pointer;
    color: #4c3aff;
}