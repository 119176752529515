.dp-accordTree {
    padding: 0.2em 2em 0.2em 2em;


    .accordionTableHeader {
        border: 1px solid #ddd;
        background: #F5F5F5;
        display: flex;
        border-radius: 4px 4px 0px 0px;
    }

    .accordionTableHeader .eachAccordionHeadItem {
        border-right: 1px solid #ddd;
        padding: 10px;
        text-align: center;
        box-sizing: border-box;
    }

    .eachAccordionHeadItem .eachAccordColHead {
        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
    }

    .eventsHeading {
        padding: 2px;
    }

    .alertNameText input {
        padding: 5px;
        font-size: 12px;
    }

    .alertDescriptionText {
        width: 94%;
    }

    .alertDescriptionText input {
        padding: 5px;
        font-size: 12px;
    }

    /* .gateSection {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
  } */

    .radioButton {
        margin-left: 50px;
    }

    .GreenColor {
        color: #25C381;
    }

    .RedColor {
        color: red
    }

    .YellowColor {
        color: rgb(233, 233, 3)
    }

    .OrangeColor {
        color: #CF833D;
    }

    .GreenColor,
    .RedColor,
    .YellowColor {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        font-family: 'Inter';

    }

    .alertStatusAccordhead {
        display: flex;
    }

    .alertStatusWithIcon {
        display: flex;
        align-items: center;
        margin-left: 2px;
    }

    .alertStatusAccordhead .alertName {
        margin-left: 30px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Inter';
    }

    .alertStatusAccordhead .alertDescription {
        color: grey;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Inter';
    }

    .alertStatusAccordhead .gateName,
    .eventsHeading .rulesHead {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
    }

    .alertStatusAccordhead .gateName {
        text-transform: uppercase;
        color: #2F4B82;
        height: 15px;
    }

    /* .firstInnerTableCol {
    height: 40px;
    display: flex;
    align-items: center;
    border-right: 1px solid #ddd;
    min-width: 165px;
  } */

    .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        left: 0;
    }

    .childAccordion .accordionSummaryAlert {
        padding: 0px;
    }

    /* .accordionSummaryAlert .MuiAccordionSummary-content {
    align-items: stretch;
  } */
    .accordionSummaryAlert .eachChildAccordCol {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ddd;
        box-sizing: border-box;
    }

    .accordionSummaryAlert.childAccord .MuiAccordionSummary-content {
        align-items: stretch;
    }

    .accordian .alertsAccordions {
        box-shadow: none;
        padding: 0.7em 2em 0.7em 2em;
    }

    .alerts-config-Accordions {
        background-color: #F5F5F5;
    }

    .accordian .alertsAccordions .accordionSummaryAlert.Mui-expanded {
        border-bottom: 1px solid #ddd;
    }

    .statusName {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        font-family: 'Inter'
    }

    .cursor-auto {
        cursor: auto;
    }


    .display-flex {
        display: flex;
    }

    .moreLabelsDialog .MuiDialog-scrollPaper {
        justify-content: end;
    }

    .moreLabelsDialog .moreLabelsDialogGrid {
        padding: 10px 5px 15px 5px;
        grid-gap: 15px;
        max-height: 150px;
        overflow-y: auto;
    }

    .moreLabelsDialogCloseIconGrid {
        height: 15px;
        display: flex;
        justify-content: end;
    }

    .moreLabelsDialogCloseIconGrid img {
        height: 25px;
        width: 25px;
        cursor: pointer;
    }

    .moreLabelsDialog .MuiPaper-root {
        margin-top: -11%;
    }


    .create_alert--form_group {
        display: flex;
        gap: 0.65rem;
        margin: 1rem;
        flex-wrap: wrap;
    }

    .create_alert--form_group--input {
        border: 1px solid #E7E7E7;
        border-radius: 4px;
        padding: 0.65rem 0.75rem;
    }

    .create_alert--form_group--input_text {
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 0.8rem;
        color: #45464E;
    }

    .dpAlertTypeHeader {
        font-size: 12px;
    }

    .dpAlertTypeInputContainer {
        display: flex; 
        flex-direction: column;
        justify-content: flex-start;
    }

    .dpLAInvalidThreshold {
        font-size: 10px;
        color: red;
    }

    .dpAlertTypeInputSubContainer {
        display: flex; 
        flex-direction: row;
        justify-content: flex-start; 
        align-items: center; 
        gap: 1em;
    }

    .dpAlertLAContainer {
        margin-top: 10px;
    }

    .linkAvailabilityWanTypeSelect {
        width: 120px; 
        height: 35px;
    }

    .dpAlertTypeLAInputBox {
        width: 100px;
    }

    .dpAlertTypeInputBox {
        width: 200px;
    }

    .dpAlertTypeHeaderIcon {
        color: #2F4B82;
    }

    .dpAlertTypeFormIcon {
        cursor: pointer;
        color: grey;
    }

}

.dp-accordTree li {
    list-style-type: none;
    position: relative;
    background-color: #F5F5F5;
}

.dp-accordTree li li {
    list-style-type: none;
    position: relative;
}

.dp-accordTree li li:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid #ddd;
    width: 20px;
    height: 15px;
}

.dp-accordTree li li:before {
    border-bottom: 1px solid #ddd;
}

.dp-accordTree li li:after {
    position: absolute;
    content: "";
    top: 5px;
    left: -20px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    width: 20px;
    height: 100%;
}

.dp-accordTree li li:last-child:after {
    display: none;
}

.accordionContainer {
    width: 100%;
}



.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
    transform: rotate(270deg);
    height: 20px;
}

.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiSvgIcon-root {
    transform: rotate(180deg);
    height: 20px;
}

.accordionSummaryAlert .MuiAccordionSummary-content {
    align-items: center;
    margin: 0px;
}

.accordian .accordionSummaryAlert {
    /* border-bottom: 1px solid #ddd; */
    min-height: auto;
}

/* .gateSection,.accordionHead {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #ddd;
  } */



.dp-accordTree ul {
    margin: 0px;
}


.dp-alert-logs {
    margin: 0.2em 2em 0.2em 2em;

    .audit-data-container .audit-logs-font,
    .audit-filters-container .audit-logs-font {
        font-size: 12px;
        font-family: 'Inter';
        color: #45464E;
    }

    .audit-data-container .audit-logs-font-header {
        font-size: 12px;
        font-family: 'Inter';
        font-weight: 600;
        color: #45464E;
    }

    .audit-filters-container .audit-filter {
        font-size: 14px;
        font-family: 'Inter';
        font-weight: 500;
        color: #45464E;
    }

    .audit-container {
        padding: 10px 13px;
        margin-top: 75px;
        height: calc(100vh - 150px);
        overflow-y: scroll;
    }

    .audit-layout {
        background-color: #f9f9f9;
        /* position: absolute;
    left: 85px;
    top: 50px;
    right: 0;
    bottom: 0; */
        padding: 40px 5px 5px 5px;
    }

    .audit-filter-list {
        display: flex;
        align-items: center;
    }

    .audit-filter {
        font-size: 14px;
    }

    .audit-data-container .audit-filter-list .audit-filter {
        font-size: 12px;
        font-weight: 600;
    }

    .audit-filter-list .filter-clear-all {
        margin-left: auto;
        color: #2F4B82;
        text-transform: none;
        text-decoration: none;
    }

    .expand-accordin-root.MuiAccordion-root {
        width: 100%;
    }

    .expand-summary .expand-summary-content,
    .expand-summary .expand-summary-content.Mui-expanded {
        margin: 5px 0;
        color: #45464E;
    }

    .expand-summary.Mui-expanded[aria-expanded="true"] {
        min-height: 48px;
    }

    .filter-accordian {
        font-size: 12px;
        font-weight: 600;
        padding-left: 17px;
    }

    .no-padding {
        padding: 0px;
    }

    .rc--form-control .no-padding {
        padding: 0px;
    }

    .padding-control .no-padding-top-btm {
        padding-top: 0;
        padding-bottom: 0;
    }

    .filter-accordian-label {
        font-size: 10px;
        font-weight: 500;
    }

    .audit-log-item .filter-meta {
        font-size: 12px;
        font-weight: 600;
        font-family: 'Inter';
    }

    .audit-filter-inputs .MuiInputBase-root {
        height: 34px;
        font-size: 12px;
    }

    .audit-filter-inputs .MuiInputLabel-root {
        font-size: 12px;
    }

    .audit-data-head .audit-data-header {

        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */
        letter-spacing: 1px;
        font-variant: small-caps;
        /* Tertiary black */
        color: #8B8D97;
    }

    .audit-filters-container {
        /* display: flex;
    gap: 10px;
    flex-direction: column;
    height: calc(100vh - 80px); */
    }

    .filter_menu_container_auditlogs {
        margin: 110px 0px 0px 210px;
    }

    .filter_menu_container_auditlogs>.MuiPopover-paper {
        overflow-y: hidden;
    }

    .audit-btn-parent .audit-filter-apply {
        text-transform: none;
        background-color: #2F4B82;
        border-radius: 4px;
        margin: auto 15px 15px 15px;
        font-size: 12px;
        font-family: 'Inter';
        font-weight: 600;
        width: 90%;
        margin: 0 10px;
    }

    .audit-filter-items {
        overflow-y: hidden;
    }

    .audit-table-data.MuiTable-root {
        overflow-y: auto;
    }

    .audit-table-data.MuiTable-root .MuiTableHead-root th {
        background-color: #f5f5f5;
    }

    /* .audit-table-data.MuiTable-root .MuiTableHead-root th:not(:first-child):not(:last-child),
.audit-table-data.MuiTable-root .MuiTableBody-root td:not(:first-child):not(:last-child) {
    border-right: 1px solid rgba(224, 224, 224, 1);
} */

    .audit-data-container {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .audit-data-pagination {
        margin-top: auto;
    }

    .audit-log-item {
        display: flex;
        width: 100%;
        gap: 10px;
        flex-direction: column;
    }

    .syntax-items {
        background-color: #F4F5FA;
        display: flex;
        width: 100%;
        min-height: 50px;
        position: relative;
        flex-direction: column;
        counter-reset: syntax-line-number;
        border-radius: 4px;
    }

    .syntax-items .syntax-row {
        counter-increment: syntax-line-number;
        display: grid;
        column-gap: 5px;
        grid-template-columns: minmax(15px, 25px) 1fr;
    }

    .syntax-items .syntax-row>pre {
        margin: 0;
    }

    .syntax-items .audit-syntax-copy {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .audit-syntax-copy .copy-audit-metas {
        background-color: #2F4B82;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-transform: none;
    }

    .syntax-items .syntax-row>* {
        padding: 1px;
        color: #6E7079;
        font-size: 12px;
        font-family: 'Inter';
    }

    .syntax-items .syntax-row:first-child>* {
        padding-top: 8px;
    }

    .syntax-items .syntax-row:last-child>* {
        padding-bottom: 8px;
    }

    .syntax-items .syntax-line-item {
        background-color: #E3E6F3;
        color: #2F4B82;
        user-select: none;
        text-align: center;
        font-weight: 500;
        font-family: 'Inter';
        font-size: 12px;
    }

    .syntax-items .syntax-line-item::before {
        content: counter(syntax-line-number);
    }

    .copy-audit-metas .MuiButton-startIcon .copy-icon {
        font-size: 12px;
    }

    .accordian-expand .accordian-arrow {
        font-size: 16px;
        color: #6E7079;
    }

    .pagination-position {
        height: 40px;
    }

    .audit-container .audit-container-data {
        width: 100%;
        overflow: hidden;
        height: auto;
        overflow-y: auto;
        border: 1px solid #EBEBEB;
        border-radius: 0px;
        box-shadow: none;
    }

    .audit-table-root .audit-logs-no-data {
        font-size: 12px;
        font-family: 'Inter';
    }

    .audit-loader {
        position: absolute;
        top: 14px;
        right: 28px;
    }

    .audit-filter-items .audit-pop-up-height {
        overflow-y: auto;
        max-height: 50vh;
        margin-bottom: 10px;
    }
}