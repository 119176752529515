.am-table .AppUsageContainer {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 8px;
}

.AppUsageContainer>div:nth-child(1) {
    height: 5px;
    background: white;
    padding:'0'
}

.TopApplicationData {
    height: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    margin: 20px 0px 0px 20px;
}

.AppUsageTableContainer .AppUsage-FleetDashboard {
    width: 100%;
    height: auto;
    border: 1px solid #EBEBEB;
}

.AppUsageTableContainer .AppUsage-FleetDashboard::-webkit-scrollbar {
    width: 6px;
}

.AppUsageTableContainer .AppUsage-FleetDashboard::-webkit-scrollbar-thumb {
    background-color: #EBEBEB !important;
}

.AppUsageTableContainer .AppUsage-FleetDashboard::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f5f5f5;
}

.usage-tableHead .appUsage-tableRow {
    height: 32px;
    border: 1px solid #EBEBEB;
    position: sticky;
    top: -1px;
    z-index: 11;
}

.appUsage-tableRow .appUsage-fleetDataTable {
    width: 139px;
    height: 32px;
    border: 1px solid #EBEBEB;
    background: #F5F5F5;
    box-sizing: border-box;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #8B8D97;
    border: 1px solid #EBEBEB;
    padding: 5px 0px 5px 15px;
    letter-spacing: 1px;
}

.appUsage-tableRow .appUsage_fleetDataTable {
    width: 249px;
    height: 32px ;
    border: 1px solid #EBEBEB;
    background: #F5F5F5;
    box-sizing: border-box;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #8B8D97;
    border: 1px solid #EBEBEB;
    padding: 5px 0px 5px 15px;
    letter-spacing: 1px;
}

.appUsage-tableRow .activity-appUsage-fleetDataTable, .appUsage-tableRow .activity-appUsage_fleetDataTable {
    width: unset;
}

.tabelRow .appUsage-tableCell span {
    width: 139px;
    background: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    padding: 0;
    width: 100%;
}

.tabelRow .activity-appUsage-tableCell span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tabelRow .appUsage_tableCell {
    width: 249px;
    border: 1px solid #EBEBEB;
    background: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    padding: 0;
}

.usage-innerTableRow {
    height: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
}

.usage-innerTableRow .usage-innerTableCell, .usage-innerTableRow .usage_innerTableCell {
    border: none;
}

.usage-innerTableRow .activity-usage-innerTableCell, .usage-innerTableRow .activity-usage_innerTableCell {
    padding: 10px 0px 10px 5px;
}

.usage-innerTableRow .usage-innerTableCell>div{
    width: 105px ;
    height: 5px;
    border-radius: 24px;
}

.usage-innerTableRow .activity-usage-innerTableCell>div {
    width: unset;
}

.usage-innerTableRow .usage_innerTableCell span{
    width: 82px;
    height: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    display: flex;
}

.appUsage_tableCell .blockedHits {
    margin-left: 15px;
}

.appUsage-tableRow .activityTableCell-logo>div {
    display: flex;
    align-items: center;
}

.logo-activity {
    position: relative;
    width: 15px;
    height: 15px;
    padding-right: 5px;
}

.appUsage-tableRow td {
    padding: 10px;
    width: 50%;
}

.noData-appDetails .noDataAvailableCss {
    padding: 10px;
}

.usage-innerTableCell .MuiLinearProgress-root {
    height: 8px;
}