#justify-content-start {
    justify-content: start;
}

#justify-content-center, #justify-content-center>div {
    justify-content: center;
}

.Table-Row-Opacity .pooled-table-padding-left-15px {
    padding-right: 15px;
    padding-top: 10px;
}

.starlink-pooled-helpIcon {
    cursor: pointer;
    color: #2860b4;
    margin-right: -15px;
}

.new-summary-left .cursor-pointer {
    cursor: pointer;
}

.new-summary-left .no-cursor-pointer {
    cursor: auto;
}

.pooled-chart-legends .lineChart-legends, .pooled-chart-legends .disable-lineChart-legends {
    padding-top: 0px;
}