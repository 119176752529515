.serachBar {
  height: 38.56px;
  top: 18.99%;
  bottom: 76.77%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #676984 !important;
}
  
.serachLabel {
  top: 14.67% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #676984 !important;
}
  
.vesselPagination {
  /* position: absolute; */
  /* width: 105px; */
  height: 12px;
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  display: flex;
  align-items: center;
  color: #676984;
}
  
.vesselPageDetails {
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  align-items: center;
  color: #676984;
  justify-content: end;
}
  
/* .MuiInputBase-input {
  height: 15px !important;
} */
  
.autoCompleteBox {
  /* justify-self: center;
    display: flex;
    justify-content: center; */
  top: -10% !important;
}
  
.MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input, .MuiAutocomplete-option {
  font-family: 'Inter';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px;
  /* line-height: 16px; */
  color: #45464E;
}

.buttonCssFleet {
  width: 30%;
  height: 38.56px;
  margin-top: 8px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  text-transform: capitalize !important;
  /* border-radius: 8px !important; */
}

.buttonCssFleet_width20 {
  width: 20%;
  height: 38.56px;
  margin-top: 8px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  text-transform: capitalize !important;
  /* border-radius: 8px !important; */
}

.buttonCssFleet_width15 {
  width: 15%;
  height: 38.56px;
  margin-top: 8px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  text-transform: capitalize !important;
  /* border-radius: 8px !important; */
}

.MuiButtonBase-root.buttonCssFleet_width {
  padding-left: 1em;
  padding-right: 1em;
}

.prevPageNew{
  transform: rotate(180deg);
}

.nextPageNewDisabled{
  transform: rotate(180deg);
}

.nextPage{
  margin-left: 5px;
}
.interval_group {
  height: 29px;
}
.interval_group .MuiButton-root {
  border: 0.735125px solid #C7DAEB;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 8.8215px;
  line-height: 10px;
  text-transform: uppercase;
  color: #000000;
  width: "100%";
}
.interval_group .MuiButton-outlined {
  color: #000000 !important;
}

.interval_group .MuiButton-contained {
  background-color: #2860B4 !important;
  color: #ffffff;
}

.alertFilterButton {
  width: 50%;
  max-width: 120px;
  height: 38.56px;
  margin-top: 8px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  text-transform: capitalize !important;
  font-size: 11px !important;
}

.DashboardContainer {
  height: 63vh;
}

.dashboardMapCOntainer {
  width: 100%;
  height: 65vh;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.map__container {
  width: 100vw;
}

.dashboardMapCOntainer .LeafLetMaps {
  height: 100%;
}
.dashboard-filter button{
  margin-top: 8px;
  padding-bottom: 6.5px;
}
.rc--filter_menu_container_dashboard .MuiPaper-root{
  width: 274px;
  margin-left: 97px;
  margin-top: -5.7%;
  box-shadow:0px 0px 13px 0px rgba(203, 203, 203, 0.35);
}
.rc--filter_menu_container_dashboard  .am-table .FilterLeftContainer{
  height: auto;
}

.new-theme-dialogue .new-theme-dialogue-title {
  background: #f5f5f5;
  padding: 10px 10px 10px 25px;
}

.new-theme-dialogue-title .new-theme-dialogue-title-header {
  color: #45464E;
}

.MuiTextField-root.input-box-fleet-sel {
  margin: 8px;
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
}

.summary .pading-parent .padding-top-25 {
  padding-top: 25px;
}

.input-search-icons {
  position: absolute;
  right: 12px
}

.pading-parent .marg-left-auto {
  margin-left: auto;
}

.margin-left-filter {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 20px;
  margin-bottom: 12px;
}

.filter-divider-width {
  margin-top: 18px;
}

.apply-filter-divider-width {
  margin-top: 8px;
}

.filter-divider-width .filter-border-color {
  border-color: #EBEBEB;
}

.apply-filter-divider-width .filter-border-color {
  border-color: #EBEBEB;
}

.filter-header {
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 500;
  color: #45464E;
  line-height: 20px;
}

.internet-status-header {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color:#45464E;
  line-height:16.94px;
}

.filterComponents .sitesInternetStatusOptions {
  padding-bottom: 8px;
}

.sitesInternetStatusOptions .MuiFormControlLabel-label {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  color:#45464E;
  line-height: 14.52px;
}

.sitesInternetStatusOptions .MuiSvgIcon-fontSizeMedium {
  width: 16px;
  height: 16px;
  padding-right: 0px;
}

.filterComponents {
  color:#45464E;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter'; 
  line-height: 14.52px;
}

.sitesFiltersPadding {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.internetr-status-header-paddingBottom {
  padding-bottom: 12px;
}
.activityReportsFilter-close .clearFiletrBtnDiv .padding-right-0 {
  padding-right: 0;
}

.dialog-field-name-font-parent .dialog-field-name-font {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter';
  color: #45464E;
}

.title-head-parent .title-padding {
  padding: 10px 10px 10px 25px
}