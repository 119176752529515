.strMaps-custom-tooltip {
    background-color: black !important;
    color: white !important;
    font-size: 12px; 
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap; 
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  }
  
  .strMaps-custom-tooltip::before {
    content: none;
    position: absolute;
    bottom: -6px; 
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }


.strMaps-custom-select .MuiSelect-icon {
    color: white;
}

.TableCellPopUp .strMaps-custom-select{
    color: white;
    height: 25px;
    width: 100%;
}

.starlink-map-container {
    position: relative;
    display: flex;
    height: 100%;
  }
  
  .starlink-map-container .map-container {
    flex-basis: 70%;
    height: 100%;
    margin-top: 32px;
  }
  
  /* .overlay-container {

    position: absolute;
    top: 2%;
    left: 83%;
    transform: translateX(-50%);
    width: 25%; 
    max-width: 500px;
    z-index: 1000;
    opacity: 1;
    background-color: black;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    overflow: auto;
    pointer-events: auto; 
  } */

  .overlay-container {
    pointer-events: auto; 
    z-index: 1000;
    position: fixed;
    top: 225px;
    right: 65px;
    opacity: 1;
    background: black;
    width: 18%; 
    box-shadow: 0px 0px 12.3px 0px #97979782;
    border-radius: 8px;
    padding: 15px;
    max-height: calc(100vh - 285px);
    overflow-y: auto;
}


.overlay-container-2 {
  pointer-events: auto; 
    z-index: 1000;
    position: fixed;
    bottom: 20px;
    opacity: 1;
    background: black;
    box-shadow: 0px 0px 12.3px 0px #97979782;
    border-radius: 8px;
    padding: 15px;
    max-height: calc(100vh - 285px);
    overflow-y: auto;
}


  .starlink--button_contained--primary .strMap-SettingsIcon{
    color: white;
    padding: 0;
  }

  .strMap-mapStyle .hoveredSlDetails{
    font-size: 14px;
    color: white;
  }
  
  .overlay-container .str-mt-0{
    margin-top: 10px;
  }

  .usage-container {
    font-family: Arial, sans-serif;
    color: white;
    border-radius: 4px;
    max-width: 350px;
  }
  
  .usage-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px; 
  }
  
  .usage-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px; 
  }
  
  .usage-label {
    font-size: 14px;
  }
  
  .usage-amount {
    font-size: 14px;
    /* font-weight: bold; */
  }
  

  .starlink--button_contained--primary {
    background-color: #1e90ff;
    color: #fff;
  }
  
  .str-justify {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .selected-container {
    border-color: #1e90ff;
  }

  .terminal-container {
    font-family: Arial, sans-serif;
    color: white;
    padding-top: 5px;
    border-radius: 4px;
    max-width: 340px;
  }
  
  .terminal-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: normal; /* Consistent with usage */
    margin-bottom: 8px;
  }
  
  .terminal-dropdown {
    width: 100%; /* Full width dropdown */
    background-color: #333; /* Dark background for dropdown */
    color: white;
    font-size: 14px;
    font-weight: normal; /* Consistent with usage */
    padding: 6px 8px;
    border: 1px solid #555;
    border-radius: 4px;
    outline: none;
  }
  
  .terminal-dropdown option {
    background-color: #444; /* Darker background for dropdown items */
    color: white;
    font-size: 14px;
  }
  
  .terminal-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 1px 0;
  }
  
  .terminal-label {
    font-size: 14px;
    font-weight: normal;
  }
  
  .terminal-value {
    font-size: 14px;
    font-weight: normal;
    color: #fff; /* Keeps values prominent */
  }
  
  
  .cancel-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
    color: white;
  }
  
   .overlay-container .titleTypo {
    /* margin-bottom: 15px; */
    color: white;
    font-size: 14px;
  }
  
  .starlink-map-container .overlay-container .details-table {
    border-collapse: collapse;
    width: 100%;
  }
  
   .overlay-container .contentTypo {
    color: white;
    font-size: 14px;
    padding: 5px;
    border-bottom: none;
    width: 100px;
    /* font-weight: bold; */
  }
  
  .starlink-map-container .overlay-container .details-table td.bold {
    font-weight: bold;
  }

  .starlinkMap-Main{
    width:450px
  }

  .TableRowBorder .TableCellPopUp{
    /* style={{ color: "white", fontSize: "12px", borderBottom: "none", padding: "5px" }} */
    color: white;
    font-size: 14px;
    border-bottom: none;
    padding: 5px;
  }

  .TableRowBorder {
    border-bottom: none;
  }

  .str-mr-5{
    margin-right: 5px;
    width: 23px
  }

  .mapCustButtonsGroup  button{
    padding: 0px;
    border: none;
    background: white;
    min-width: 30px !important;
    margin-right: 2px;
  }

  .mapCustButtonsGroup #layersSel {
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 6px;
  }

  .layersIcon {
    color: black !important;
  }

  .layersIcon .vsatIc {
    margin-top: 10px !important;
  }

  .starMap-rows{
    width: 115px;
  }

  .starMap-rows-sl{
    width: 165px;
  }

  .starMap-rows-status{
    width: 85px;
  }

  .selectedRow.highlight {
    background-color: #e0f7fa;
  }

  .starlinkMapContainer .containerStyleMapOpen{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    height: 60vh;
    padding-top: 10px
  }

  .strMaps-filter{
    margin-top: 40px;
  }

  .starlinkMapContainer .containerStyleMapClosed{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    height: 75vh;
    padding-top: 10px
  }

  .starlinkMap-filterBtn{
    display: flex;
    padding-top: 10px;
  }

  .starlinkMap-selFilter{
    display: flex;
    gap: 10px;
    margin-left: 10px;
    flex-grow: 1;
    margin: 0px 20px;
    overflow: auto;
  }

  .starlinkMap-chips{
    background-color: #e0f7fa;
    font-weight: 500;
    font-size: 14px;
  }

  .starlinkMap-filterPopup{
    font-size: 12px;
    font-weight: 400;
    line-height: 14.5px;
    height: 16px;
  }

  .starlinkMap-OpenCard{
    margin-left: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .starlinkMap-icon{
    color: #5e5e73;
  }

  .starlinkMap-serviceLine{
    cursor: pointer;
    color: blue;
  }

  .d-flex{
    display: flex;
  }

  .str-justify{
    justify-content: space-around;
  }

  .align-center{
    align-items: center;
  }
  
  .strMapIcon .str-font-15{
    font-size: 15px;
  }

  .str-font-15{
    font-size: 15px;
  }

  .strMap-table{
    flex-basis: 30%;
    height: 94%;
    overflow-y: auto;
  }

  .strMapIcon .str-alert{
    margin-top: -2px;
  }

  .strMap-sl-popup{
    border-collapse: collapse;
    width: 100%;
  }

  .strMap-sl-dropdown{
    color: white;
    height: 25px;
    width: 100%;
    background: #7d7d82;
  }

  .strMap-mapStyle{
        flex-basis: 75%;
        height: 100%;
  }

  .config-deploy-starlink-popup {
    width: 45%;
    margin: auto;
    background: #FFFFFF;
    border-radius: 10px;
    background-color: #0000;
}

.config-deploy-starlink-popup .create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    height: 10px;
    color: #45464E;
    background-color: #f5f5f5 ;
}

  
.config-deploy-starlink-popup .edit-actions-title {
  border-bottom: 1px solid #efefef;
  background: white;
  padding: 18px 10px 18px 15px;
}

.config-deploy-starlink-popup .edit-actions-content {
  padding: 0px;
}

.inventory-edit-actions-container .MuiDialog-container {
  width: 900px;
  margin-left: -100px;
}

.config-deploy-starlink-popup .edit-change-group-btn {
  position: absolute;
  bottom: 20px;
  right: 25px;
  border: 1px solid #264c86;
  background: #264c86;
  color: #fff;
  height: 35px;
  border-radius: 4px;
  width: 125px;
}

.config-deploy-starlink-popup .edit-change-group-btn:hover {
  background: #264c86
}

.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .status{
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .status .text {
  max-width: 100px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .status .text,
.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .noData {
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  background: #FFFFFF;
  text-transform: none;
}

.config-deploy-starlink-popup.menuScroll.device-eos-history-popup .noData {
  text-align: center;
}