.MuiTextField-root.input-box-serviceline {
    width: 300px;
}

.starlink-change-sub-org img {
    width: 23px;
}

/* Edit ActionsPopup */

.edit-actions-content .starlinkAdmin-edit-actions-cont {
    padding-right: 15px;
}

.Form .starlink-admin-edit-actions-confirm-btns .confirm-dialogue-proceed-notAllow {
    color: rgb(38, 76, 134);
}
.edit-actions-service-account-confirm-btnss{
    width: 73%;
}
.edit-toggle-btn-container .toggle-btn-edit {
    margin-top: -2px;
}

#edit-toggle-btn-text {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

.edit-toggle-btn-container {
    margin: 20px 15px 0px 10px;
}

.pause-container-starlink .select-config-from {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.pause-container-starlink .select-config-from .MuiFormControl-root {
    width: 310px;
}

.optIn-container, .starlink-admin-change-org .starlink--base--mtb_07x:nth-child(1) {
    margin-top: 20px;
}

.optIn-container .text-optIn-dialogue {
    margin-top: 15px;
}

.starlink--table_container .starlink-admin-service-lines-actions {
    margin-top: 2px;
    margin-bottom: -2px;
}

.starlink--base--flex--justify--start .create-org-btn-width {
    width: 274px;
    font-size: 12px;
}

.starlink-admin-service-lines-actions-td {
    display: flex;
    align-items: center;
    justify-content: start;
}

.edit-actions-title .editActions-loader {
    right: 55px;
    top: 21px;
}

.starlink-admin-change-org .starlink--base--mtb_07x .MuiInputLabel-sizeSmall {
    font-size: 13px;
}

.starlink--table_container .starlink-admin-serviceLines-action-colm {
    width: 13%;
}

.starlink-serviceline-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.starlink-serviceAccount-details-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
}

.starlink-serviceline-details-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px; 
}

.starlink-serviceAccount-details-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px; 
    justify-content: space-between;
}

.starlink-serviceline-details-heading {
    width: 160px; 
    font-weight: 550;
}

.starlink-serviceAccount-details-heading {
    width: 240px; 
    font-weight: 550;
}

.edit-actions-service-account-confirm-btns {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    width: 73%;
    right: 20px;
  }

.starlink-serviceline-details-value {
    margin-left: 8px; 
}

.starlink-serviceline-details-edit-button {
    margin-left: 8px;
    padding: 0px 10px;
}

.starlink-serviceline-details-row .starlink-serviceline-details-save-button {
    color: blue ; 
}

.starlink-serviceline-details-row .starlink-serviceline-details-cancel-button {
    color: red ;
}

.starlink-serviceline-details-row .starlink-serviceline-details-edit-button {
    color: var(--edit-color) ; 
}


.serviceLineDetails-container{
    margin-top: 10px;
}

.starlink-serviceline-details-edit-button.padding-left-right{
    padding: 0px 10px ;
}

/* ServiceLineDevices.css */

.starlink-sl-devices-grid {
    margin-top: 30px;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    min-height: 175px;
}

.starlink-sl-devices-table {
    min-width: 300px;
    margin-top: 10px;
}

.starlink-sl-devices-grid .starlink-sl-devices-button {
    margin-top: 10px;
    float: right;
}

.font-wt-900 {
    font-weight: 900;
}

.serviceLineDetails-divider-margin{
    margin: 10px 0px 15px 0px;
}

.serviceLineDevices-dialog-width .MuiPaper-root {
    width: 500px;
    max-width: 500px;
    margin: auto; /* Horizontally center */
  
}

