.content-filter-domain-name-text{
    margin-top:10px;
    margin-right: 5px;
}

.MuiTableContainer-root.content_filter--domain-names-table_container{
    height: 210px;
    overflow-x: hidden;
}

.content_filter--domain-names-table_container .MuiTableCell-root {
    background: #ffffff;
    border-collapse: collapse;
    font-style: normal;
    font-size: 0.75rem;
    color: #45464e;
  }
  
.content_filter--domain-names-table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #f5f5f5;
    color: #8b8d97;
    font-style: normal;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}

.MuiGrid-root.content-filter-upload-csv-div {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
    padding: 10px;
}

.content-filter-csv-text{
    color: #797979;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.content-filter-csv-uploaded-text{
    color: #797979;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
}

.MuiButtonBase-root.content_filter--select-csv-button {
    width: 120px;
    margin-left: 22px;
}

.content-filter-csv-image{
    position: relative;
    top: 5px;
    margin-right: 5px;
}

.MuiSvgIcon-root.content-filter-delete-icon{
    color: rgba(189, 54, 54, 1);
    cursor: pointer;
}

.MuiGrid-root.content-filter-upload-csv-grid {
    display: flex;
    justify-content: flex-end;
}

.MuiGrid-root.content-filter-add-domain-grid {
    display: flex;
    justify-content: flex-end;
}

.MuiGrid-root.content-filter-domain-list-count-grid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.domain_name_validation_err_message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 5px;
}