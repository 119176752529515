.Chart {
    font-family: sans-serif;
    text-align: center;
    width:100%;
  }

.chartContainer {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  border: 0.5px solid #0000FF;
}

.customTitle {
  display: flex;
  border-bottom: 0.5px solid #0000FF;
}

.customizedChartTitle {
  font-size: 18px;
  color: #2cc1de;
  padding: 20px;
  font-weight: 700;
}

.lineChartRates {
  margin-left: auto;
  padding: 20px;
  display: flex;
}

.rateTitle {
  color: #2cc1de;
  margin-left: 20px;
  margin-top: 3px;
}

.uploadArrow {
  transform: rotate(270deg)
}

.downloadArrow {
  transform: rotate(90deg)
}

.rate {
  margin-top: 3px;
}

.usageContainer {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}

.total {
  margin-top: 3px;
  margin-left: 20px;
}

.chartsEventTableContainer .chartsEventTable {
  background: white;
  padding: 10px 10px 2px 10px;
  margin-top: 10px;
}

.chartsEventTable .usageReports-FleetDashboard {
  margin-bottom: 15px;
  border: 1px solid #EBEBEB;
  border-bottom: none;
}

.chartEvent-tableRow .chartEvent-tablecell {
  padding: 0px;
  font-size: 11px;
}

.chartsEventTableContainer .chartEvent-FleetDashboard {
  height: auto;
}

.chartEvent-tablecell-source {
  text-transform: capitalize;
}

@media only screen and (max-width: 599px) {
  .lineChartRates{
    display: block;
  }
}

.Hub-Topology-SDWAN-Progress-Chart .highcharts-xaxis-labels, .Hub-Topology-SDWAN-Progress-Chart .highcharts-xaxis {
  display: none;
}

#legendTableCollapseHeader {
  width: 1%;
}

.qoe-hmap-chat-title {
  display: flex;
  align-items: center;
  gap: 5px;
  transform: rotate(270deg);
  transform-origin: left;
  white-space: nowrap;
  margin: 5px 0px -15px 10px;
}

.qoe-hmap-chat-title>span {
  color: rgb(102, 102, 102);
  font-size: 0.8em;
}

.qoe-hmap-chat-title #helpIcon-reports {
  cursor: pointer;
  transform: rotate(90deg);
  transform-origin: center;
}

.qoe-application-score-table, .qoe-application-score-table-each-row>td {
  border: 1px solid #ebebeb;
}

.qoe-application-score-content {
  padding: 8px 16px;
  padding-top: 20px;
}